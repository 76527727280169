import {Helmet} from 'react-helmet';
import {useEffect, useRef, useState} from "react";
import { getCookie } from '../libs/cookies';
import Storage from "../libs/storage";

import {compressionFile} from "./img";
import heic2any from 'heic2any';
// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {useTranslation} from "react-i18next";
// modal end

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #00FFFF',
    boxShadow: 0,
    p: 1,
};
const styleDanger = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #EF2F2F',
    boxShadow: 0,
    p: 1,
};
export default function UnityFile() {
    const { t } = useTranslation();

    const file = useRef();
    const [filesUrl, setFilesUrl] = useState([]);
    const [filesData, setFilesData] = useState([]);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const handleOpenSuccess = () => setOpenSuccess(true);
    const handleCloseSuccess = () => setOpenSuccess(false);

    const [openFail, setOpenFail] = React.useState(false);
    const handleOpenFail = () => setOpenFail(true);
    const handleCloseFail = () => setOpenFail(false);

    const [isLoading, setIsLoading] = useState(false);

    function clickFile() {
        let fileEl: HTMLElement = document.querySelector("#file") as HTMLElement;
        fileEl.click();
    }

    async function fileChange(event) {
        if (!event.target.files) return false;
        setIsLoading(true);
        let datas = [];
        if (filesData.length) {
            for (let i in filesData) {
                datas.push(filesData[i]);
            }
        }

        let urls = [];
        if (filesUrl.length) {
            for (let i in filesUrl) {
                urls.push(filesUrl[i]);
            }
        }
        for (let fd of event.target.files) {
            if (fd.type.includes("heic")) {
                let blob = new Blob([fd], {type: fd.type});
                let reBlob = await heic2any({
                    blob: blob, toType: "image/jpeg"
                });
                // @ts-ignore
                let reFile = new File([reBlob], fd.name.split(".")[0] + ".jpeg", { type: 'image/jpeg' });
                let newFile = await compressionFile(reFile);
                const url = URL.createObjectURL(newFile);
                datas.push(newFile);
                urls.push(url);
            } else {
                let newFile = await compressionFile(fd);
                const url = URL.createObjectURL(newFile);
                datas.push(newFile);
                urls.push(url);
            }
        }
        setIsLoading(false);
        setFilesData(datas);
        setFilesUrl(urls);
    }

    function clearAll () {
        setFilesData([]);
        setFilesUrl([]);
    }

    function submitForm() {
        if (!filesData.length) return false;
        setIsLoading(true);
        const formData = new FormData();
        for (let i in filesData) {
            formData.append("files", filesData[i]);
        }
        handleCloseConfirm();
        handleCloseFail();
        let url = "https://api.metagaia.io/api/v1/user-model/create";
        if (window.location.href.includes("test-") || window.location.href.includes("localhost")) url= "https://test-api.metagaia.io/api/v1/user-model/create";
        // let url = "https://test-api.metagaia.io/api/v1/user-model/create";
        fetch(url, {
            method: "post",
            headers: {
                token: Storage.getItem("unity-token") || getCookie('ua_once') || getCookie('ua_user'),
            },
            body: formData,
        }).then(res => {
            res.json().then(resp => {
                setIsLoading(false);
                if (!resp.code) handleOpenSuccess();
                else handleOpenFail();
            }).catch(() => {
                setIsLoading(false);
                handleOpenFail();
            });
        }).catch(() => {
            setIsLoading(false);
            handleOpenFail();
        });
    }

    return (
        <div className="bg-hexahero">
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta property="og:description" content="" />
            </Helmet>

            <div className="wrapper">
                <main>
                    <section className="s-default unity-box">
                        <img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-nft"
                             data-top="transform: translateY(0%)"
                             data-top-bottom="transform: translateY(0%)" />

                        <div className="container unity-file">
                            <div className="wow fadeInUp unity-title">
                                {t("page.unity.CREATE")}
                            </div>
                            <div className="wow fadeInUp file-title">{t("page.unity.UPLOADED")}</div>
                            <div className="wow fadeInUp file-files">
                                <div className="files-box">
                                    {
                                        filesUrl && filesUrl.map((item, index) => <div className="files-item" key={index}>
                                            <img src={item}/>
                                        </div>)
                                    }
                                </div>
                            </div>
                            {
                                isLoading && <div className="wow fadeInUp file-txt">Please wait, processing is underway</div>
                            }
                            {
                                !isLoading && <>
                                <div className="s-flex s-flex-center-row">
                                  <div className="wow fadeInUp s-cursor-pointer s-flex s-flex-center-row file-btn"
                                       onClick={clickFile}>
                                    <img className="img-metagaia" src="assets/images/unity/btn-upload.png?v=20230413001"/>
                                    <span>{t("page.unity.UPLOAD")}</span>
                                  </div>
                                  <input type="file" multiple accept="image/*, .heic, .HEIC" id="file" ref={file}
                                         style={{display: "none"}} onChange={fileChange}/>
                                </div>
                                <div className="wow fadeInUp file-txt">{t("page.unity.Example")}</div>
                                <div className="wow fadeInUp file-txt" onClick={clearAll}>Clear</div>
                              </>
                            }
                            {
                                !isLoading && <>
                                <div className="wow fadeInUp s-flex s-flex-between-row file-fixed">
                                  <img className="file-fixed-left"
                                       src="assets/images/unity/btn-file-cancel.png?v=20230413001" onClick={() => {
                                      window.history.back();
                                  }}/>
                                  <img className="file-fixed-right"
                                       src="assets/images/unity/btn-file-confirm.png?v=20230413001"
                                       onClick={handleOpenConfirm}/>
                                </div>
                              </>
                            }
                        </div>
                    </section>
                </main>
            </div>

            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openConfirm}
                onClose={handleCloseConfirm}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-unity"
            >
                <Fade in={openConfirm}>
                    <Box sx={style}>
                        <div className="s-flex s-flex-center-row modal-ct-title ct-title-confirm">
                            {t("page.unity.CREATE1")}
                        </div>
                        <div className="modal-btn-box modal-btn-between">
                            <div className="modal-btn modal-btn-half modal-btn-cancel" onClick={handleCloseConfirm}>
                                {t("page.unity.CANCEL")}
                            </div>
                            <div className="modal-btn modal-btn-half modal-btn-confirm" onClick={submitForm}>
                                {t("page.unity.CONFIRM")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openSuccess}
                onClose={handleCloseSuccess}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-unity"
            >
                <Fade in={openSuccess}>
                    <Box sx={style}>
                        <div className="s-flex s-flex-center-row modal-ct-title ct-title-sub ct-title-success">
                            Your collections is created
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title ct-title-sub">
                            Please wait for notification
                        </div>
                        <div className="modal-btn-box modal-btn-center">
                            <div className="modal-btn modal-btn-half modal-btn-cancel" onClick={() => {window.history.back();}}>
                                BACK
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openFail}
                onClose={handleCloseFail}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-unity"
            >
                <Fade in={openFail}>
                    <Box sx={styleDanger}>
                        <div className="s-flex s-flex-center-row modal-ct-title ct-title-danger ct-title-fail">
                            FAIL
                        </div>
                        <div className="modal-btn-box modal-btn-between">
                            <div className="modal-btn modal-btn-half modal-btn-cancel-danger" onClick={handleCloseFail}>
                                CLOSE
                            </div>
                            <div className="modal-btn modal-btn-half modal-btn-confirm-danger" onClick={submitForm}>
                                RETRY
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
