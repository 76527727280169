import {useEffect, useRef, useState} from "react";
import {Helmet} from 'react-helmet';
import { adminUserListMint, adminMintConfirm } from "./libs/api";
import message from "./components/Message";

interface UserObj {
    id: number,
    name: string,
    email: string,
    metg: number,
    user: {
        username: string,
        email: string
    }
}
function AdminMintConfirm() {
    const [userList, setUserList] = useState<UserObj[]>([]);
    let [idArray, setIdArray] = useState<[]>([]);
    useEffect(() => {
        getUserList();
    }, []);
    function getUserList() {
        adminUserListMint({}).then((res: any) => {
            if (!res.code) {
                setUserList(res.rows);
                let ids: any = [];
                for (let o of res.rows) {
                    ids.push(o.id);
                }
                setIdArray(ids);
            }
        });
    }
    function mintConfirm() {
        adminMintConfirm({ids: idArray}).then((res: any) => {
            if (!res.code) {
                message.info("Mint SuccessFull");
                getUserList();
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>MetaGaia Investor Lite Paper</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <main>
                        <section className="s-paper s-nft s-default" style={{ padding: "0" }}>
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-paper"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container paper">
                                <div className="pdf-box" style={{ paddingTop: "50px" }}>
                                    {
                                        userList.length > 0 && <div className="table-box user-mint-box">
                                            <div className="table-title">
                                                <div className="s-flex s-flex-between-row row table-row">
                                                    <div className="col-3 table-th-column s-flex ">Name</div>
                                                    <div className="col-6 table-th-column s-flex ">Email</div>
                                                    <div className="col-3 table-th-column s-flex ">METG</div>
                                                </div>
                                            </div>
                                            {userList.map((item, index) => {
                                                return <div className="table-data" key={index}>
                                                    <div className="s-flex s-flex-between-row row table-row">
                                                        <div className="col-3 table-td-column s-flex">{item.user.username}</div>
                                                        <div className="col-6 table-td-column s-flex">{item.user.email}</div>
                                                        <div className="col-3 table-td-column s-flex">{item.metg}</div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    }
                                    {
                                        userList.length === 0 && <div className="s-flex s-flex-center-row">No Data</div>
                                    }
                                </div>
                                <div className="s-flex s-flex-center-row"><button className="btn btn-default s-btn-border-none user-mint-btn" disabled={userList.length === 0} onClick={mintConfirm}>Submit</button></div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
}

export default AdminMintConfirm;
