import {useEffect} from "react";
import AlphaVideo from "../libs/mvideo";
export default function Demo(props: any) {

    useEffect(() => {
        let mv: any;
        mv = new AlphaVideo({
            src: '',
            width: 433,
            height: 433,
            loop: true, // 是否循环播放
            canvas: document.getElementById('js_output_2'),
        });
        mv.play()

    }, []);

    return (
        <div style={{backgroundColor: "rgba(0,0,0,0)"}}>
            <video autoPlay muted loop id="hero_video" playsInline style={{backgroundColor: "transparent"}}>
                <source src="" type="video/mp4" />
            </video>
            <canvas className="js_output_2" id="js_output_2"></canvas>
        </div>
    );
};
