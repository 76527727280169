import { useState} from "react";
import {Helmet} from 'react-helmet';
import { adminLogin } from "./libs/api";
import message from "./components/Message";
import storage from "./libs/storage";
import {setCookie} from "./libs/cookies";

function AdminLogin() {
    const [userName, setUserName] = useState("admin");
    const [userPwd, setUserPwd] = useState("");

    function loginUser() {
        if (userName === "" || userName === null || userName ===  undefined) {
            message.info("User Name not null");
            return false;
        }
        if (userPwd === "" || userPwd === null || userPwd ===  undefined) {
            message.info("Password not null");
            return false;
        }
        adminLogin({
            username: userName,
            password: userPwd
        }).then((res: any) => {
            if (!res.code) {
                storage.setItem("userStatus", res.token);
                setCookie("ua_user", res.token || "");
                window.location.href = window.location.origin + "/hd-mint";
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>MetaGaia Investor Lite Paper</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <main>
                        <section className="s-paper s-nft s-default" style={{ padding: "0" }}>
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-paper"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container paper">
                                <div className="pdf-box" style={{ paddingTop: "50px" }}>
                                    <div className="row form-box form-admin-user">
                                        <div className="col col-12 form-item">
                                            <div className="form-label">User Name</div>
                                            <div className="form-value"><input type="text" readOnly={true} defaultValue={userName}/></div>
                                        </div>
                                        <div className="col col-12 form-item">
                                            <div className="form-label">User Password</div>
                                            <div className="form-value"><input type="password" onChange={(e) => {setUserPwd(e.target.value)}}/></div>
                                        </div>
                                        <div className="col col-12 form-item">
                                            <div className="form-btn-box"><button onClick={loginUser} className="btn btn-default form-btn">Login</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
}

export default AdminLogin;
