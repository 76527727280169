import {useEffect, useRef, useState} from "react";
import CloudPdfViewer from '@openbook/cloudpdf-viewer';
import {Helmet} from 'react-helmet';
import {useTranslation} from "react-i18next";

function PaperPDF() {
    const [message, setMessage] = useState("");
    const [result, setResult] = useState(false);

    useEffect(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
        document.body.appendChild(s);

    }, [])

    const viewer = useRef(null);
    useEffect(() => {
        CloudPdfViewer(
            {
                documentId: "6ab0afd2-447d-4f5b-8cb6-b5c66e765609",
                darkMode: true,
                themeColor: '#0B0E33',
                appBarColored: true
            },
            // @ts-ignore
            viewer.current
        ).then((instance) => {});
        // @ts-ignore
        // document.querySelector(".viewer").contentWindow.document.querySelector(".jss273").style.display = "none";
    }, []);
    return (
        <>
            <Helmet>
                <title>MetaGaia Investor Lite Paper</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <main>
                        <section className="s-paper s-nft s-default" style={{ padding: "0" }}>
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-paper"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container paper">
                                <div className="pdf-box" style={{ paddingTop: "50px" }}>
                                    <div className="viewer" ref={viewer}></div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
            <div className="b-popup b-popup-inline lity-hide" id="popup_inline_confirm">
                <div className="box-ctx">
                    <img style={{ width: "70px", height: "79px", margin: "auto" }} src={result ? "assets/images/message-success.png?v=20230413001" : "assets/images/message-waring.png?v=20230413001"} />
                    <div className="txt-title txt-default-title">{message}</div>
                </div>
            </div>
        </>
    );
}

export default PaperPDF;
