import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import * as React from "react";

export default function Footer() {
    const location = useLocation();
    const [isShowHK, setIsShowHK] = useState(false);
    useEffect(() => {
        if (location.pathname.includes("hello-kitty")) {
            setIsShowHK(true);
        }
    }, [location]);

    return (
      <footer className="s-footer">
        <div className="container">
          <ul className="nav nav-media wow fadeInUp">
            <li>
              <a href="https://www.instagram.com/meta_gaia/" target="_blank">
                <img src="assets/images/ico_instagram.png?v=20230413001" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/meta_gaia/" target="_blank">
                <img src="assets/images/ico_twitter.png?v=20230413001" />
              </a>
            </li>
            <li>
              <a href="https://meta-gaia.medium.com/" target="_blank">
                <img src="assets/images/ico_medium.png?v=20230413001" />
              </a>
            </li>
            <li>
                <a href="https://discord.com/invite/Nqk2P57u7Y" target="_blank">
                    <img src="assets/images/ico_discord.png?v=20230413001" />
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCKYtmmW9yeu42Gdx33bYrKQ" target="_blank">
                    <img src="assets/images/ico_youtobe.png?v=20230413001" />
                </a>
            </li>
          </ul>

          <img
            className="logo-metagaia wow fadeInUp"
            src="assets/images/logo_metagaia2_v2.png?v=20230413001"
          />

          <div className="box-powered-by wow fadeInUp" data-wow-delay="0.3s">
            <div className="txt-title txt-powered-by">POWERED BY</div>
            <a href="https://www.advokategroup.io/" target="_blank">
              <img className="logo-advokate" src="assets/images/logo_advokate.png?v=20230413001" />
            </a>
            <br />
            <a href="https://solana.com/" target="_blank">
              {/*<img className="logo-advokate" src="assets/images/logo_solana.svg?v=20230413001" />*/}
            </a>
          </div>

            <div className="txt-copyright wow fadeInUp">
                MetaGaia © 2023, All rights reserved. Used technology provided by
                <a href="https://www.gaiai.io/" target="_blank"
                   style={{marginLeft: "3px", color: "#00ffff", textDecoration: "none"}}>GaiAI</a>
            </div>
            {
                isShowHK && <div className="hello-kitty-ft wow fadeInUp">
                <img src="./assets/images/game/hello-kitty-conn.png"/>
                </div>
            }
        </div>
        <div className="b-popup b-popup-whitelist">
				<div className="box-ctx">
					<div className="box-ctx-wrapper2">
						<button className="btn btn-close"></button>
						<div className="txt-title">WHITELIST FOR <br /><span className="txt-cyan">HEXAHERO PRESALE</span>!</div>

						<div className="box-btn">
							<img className="img-deco-pc img-deco-pc1" src="assets/images/popup/arrow-lhs.png?v=20230413001" />
							<img className="img-deco-m img-deco-m1" src="assets/images/popup/deco-top.png?v=20230413001" />

							<a href="https://givelab.com/hexahero/whitelist-hexa-hero-nft-sale" target="_blank" className="btn-white">whitelist now</a>

							<img className="img-deco-pc img-deco-pc2" src="assets/images/popup/arrow-rhs.png?v=20230413001" />
							<img className="img-deco-m img-deco-m1" src="assets/images/popup/deco-bottom.png?v=20230413001" />
						</div>
					</div>
					<div>
						<img className="img-cards" src="assets/images/popup/pop-up-card-image.png?v=20230413001" />
					</div>

				</div>
			</div>
      </footer>
    );
  }
