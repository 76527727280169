import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import message from '../components/Message';
import {
    NftDetailHk,
    UserInfo,
    CreateList,
    LeaseCreate,
    LeaseCancel,
    HkTrans, HkListedCreate, HkListedCancel, HkTransSolana
} from '../libs/api';
import { PublicKey } from '@solana/web3.js';

// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {useTranslation} from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #00FFFF',
    boxShadow: 0,
    p: 1,
};
const styleMax = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#000000',
    border: '1px solid #00FFFF',
    boxShadow: 0,
    p: 1,
};
// modal end
interface NftInfo {
    id?: string;
    name?: string;
    image?: string;
    video?: string;
    description?: string;
    attributes?: [AttributesChild];
    consignmentObj?: {
        id?: string;
        metg?: string;
        price?: string;
    };
    lowestMetg?: string;

    nftId?: string;
    nftTokenId?: string;
    status?: number;
    token?: string;
    type?: number;
    userNftId?: string;

}

interface AttributesChild {
    k: string;
    v: string;
}
export default function UserNftDetail() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    // modal start
    const [isBindInfo, setIsBindInfo] = useState(false);
    const isCheckEmailPhone = () => {
        if(isBindInfo) {
            window.location.href=window.location.origin + "/bind-info?redirect=" + window.location.pathname + window.location.search;
        }
    }
    // 钱包选择
    const [walletSelect, setWalletSelect] = useState(2);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        isCheckEmailPhone();
        if (JSON.parse(window.localStorage.getItem("isPhone"))) {
            setOpen(true);
        } else {
            toPhone();
        }
    }
    function toPhone() {
        window.self.location.href = window.location.origin + '/login-phone?redirect=' + window.location.pathname + "?" + window.location.search;
    }
    const handleClose = () => setOpen(false);
    const handleCloseReset = () => {
        setWalletSelect(0);
        setOpen(false);
    };
    // nft转移
    const [openTrans, setOpenTrans] = React.useState(false);
    const handleOpenTrans = () => {
        isCheckEmailPhone();
        setOpenTrans(true);
    }
    const handleCloseTrans = () => {
        setWalletSelect(0);
        setOpenTrans(false);
        setValidMsgTransfer('');
    };
    const [openList, setOpenList] = React.useState(false);
    const handleOpenList = () => {
        isCheckEmailPhone();
        setOpenList(true);
    }
    const handleCloseList = () => {
        setOpenList(false);
        setValidMsgTransfer('');
    };
    const [openLowPrice, setOpenLowPrice] = React.useState(false);
    const handleOpenLowPrice = () => setOpenLowPrice(true);
    const handleCloseLowPrice = () => {
        setOpenLowPrice(false);
    };
    const [openRel, setOpenRel] = React.useState(false);
    const [openMsgRel, setOpenMsgRel] = React.useState('Transfer successful');
    const handleOpenRel = () => setOpenRel(true);
    const handleCloseRel = () => {
        setOpenRel(false);
        window.self.location.href = window.location.origin + '/user';
    };
    const [openBalanceHexa, setOpenBalanceHexa] = React.useState(false);
    const handleOpenBalanceHexa = () => setOpenBalanceHexa(true);
    const handleCloseBalanceHexa = () => setOpenBalanceHexa(false);
    // modal end

    const [userAmount, setUserAmount] = useState(0);
    const [isTransfer, setIsTransfer] = useState(true);
    const [validMsgTransfer, setValidMsgTransfer] = useState('');
    const [nftInfo, setNftInfo] = useState<NftInfo>();
    const [listMetg, setListMetg] = useState(0);
    const [lowestMetg, setLowestMetg] = useState(0);

    useEffect(() => {
        initData();
    }, []);
    function initData() {
        NftDetailHk({ userNftId: window.location.search.split('uuid=')[1] }).then(
            (res: any) => {
                if (!res.code) {
                    setNftInfo(res);
                    setListMetg(parseFloat(res.consignmentObj.metg));
                    setLowestMetg(parseFloat(res.lowestMetg));
                    setIsTransfer(false);
                }
            }
        );
        getUserInfo();
    }
    function getUserInfo() {
        UserInfo({}).then((res: any) => {
            if (!res.code) {
                setUserAmount(res.hk);
                setIsBindInfo(!(res.email && res.phone));
            }
        });
    }
    function checkAddress() {
        if (walletSelect === 1) return true;
        try {
            // @ts-ignore
            const val = document.querySelector('#walletAddress').value.trim();
            new PublicKey(val);
            return true;
        } catch (error: any) {
            message.info('address error');
            console.log(error);
            return false;
        }
    }
    const showTransfer = () => {
        // @ts-ignore
        const val = document.querySelector('#walletAddress').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgTransfer(t("validate.information"));
            return false;
        } else if (checkAddress()) {
            setIsLoading(true);
            setValidMsgTransfer('');
            if (walletSelect === 1) {
                HkTrans({
                    address: val,
                    userNftId:  nftInfo?.userNftId,
                })
                    .then((res: any) => {
                        setIsLoading(false);
                        handleCloseTrans();
                        if (res.code.toString() === "2001") {
                            handleOpenBalanceHexa();
                        } else if (!res.code) {
                            setOpenMsgRel('Transfer successful');
                            handleOpenRel();
                        } else {
                            setOpenMsgRel('Transfer failed');
                            handleOpenRel();
                        }
                    })
                    .catch(() => {
                        setIsLoading(false);
                        handleCloseTrans();
                        setOpenMsgRel('Transfer failed');
                        handleOpenRel();
                    });
            } else {
                HkTransSolana({
                    receiver: val,
                    userNftId:  nftInfo?.userNftId,
                })
                    .then((res: any) => {
                        setIsLoading(false);
                        handleCloseTrans();
                        if (res.code.toString() === "2001") {
                            handleOpenBalanceHexa();
                        } else if (!res.code) {
                            setOpenMsgRel('Transfer successful');
                            handleOpenRel();
                        } else {
                            setOpenMsgRel('Transfer failed');
                            handleOpenRel();
                        }
                    })
                    .catch(() => {
                        setIsLoading(false);
                        handleCloseTrans();
                        setOpenMsgRel('Transfer failed');
                        handleOpenRel();
                    });
            }

        }
    };
    const showList = () => {
        // @ts-ignore
        const val = document.querySelector('#listPrice').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgTransfer(t("validate.information"));
            return false;
        } else if (Number(val) === 0) {
            message.info('List price cannot be zero');
            return false;
        } else if (!/^(([1-9][0-9]*)+(\.\d{0,4})?)$/.test(val)) {
            message.info('Your input is incorrect');
            return false;
        } else if (Math.abs(val) < 10) {
            message.info('Minimum list price is 10 METG');
            return false;
        } else if (Number(val) < lowestMetg) {
            setListMetg(val);
            handleCloseList();
            handleOpenLowPrice();
            return false;
        }
        setValidMsgTransfer('');
        HkListedCreate({
            userNftId: nftInfo?.userNftId,
            price: String(val),
            virtualTokenType: "3",
        })
            .then((res: any) => {
                handleCloseList();
                if (res.code.toString() === "2001") {
                    handleOpenBalanceHexa();
                } else if (!res.code) {
                    setOpenMsgRel('Your item is listed');
                    handleOpenRel();
                } else {
                    setOpenMsgRel('List failed');
                    handleOpenRel();
                }
            })
            .catch(() => {
                handleCloseList();
                setOpenMsgRel('List failed');
                handleOpenRel();
            });
    };
    const [isShoMore, setIsShowMore] = React.useState(true);
    const [leaseCt, setLeaseCt] = React.useState([{
        title: "1. What is a HEX?",
        desc: ["HEX is the token used in the Metagaia game, and HEX records obtained by renting DIGITAL ASSETs can be viewed in-game."]
    }]);
    function changeShowMore() {
        setIsShowMore(!isShoMore);
        if (isShoMore) {
            setLeaseCt([
                {
                    title: "1. What is a HEX?",
                    desc: ["HEX is the token used in the Metagaia game, and HEX records obtained by renting DIGITAL ASSETs can be viewed in-game."]
                },
                {
                    title: "2. Lease Rules",
                    desc: [
                        "Owned DIGITAL ASSETs can be rented to other players in the rental market to earn HEX coins. Each DIGITAL ASSETs can be rented up to 5 times per day.",
                        "For each DIGITAL ASSET rented, the minimum rental amount is 1HEX, and the amount of each rental is an integer."
                    ]
                },
                {
                    title: "3. Exchange Rules",
                    desc: ["HEX can be exchanged with METG as follows: 1 HEX=1METG. The official exchange will be open from time to time."]
                }
            ]);
        } else {
            setLeaseCt([
                {
                    title: "1. What is a HEX?",
                    desc: ["HEX is the token used in the Metagaia game, and HEX records obtained by renting DIGITAL ASSETs can be viewed in-game."]
                }
            ]);
        }
    }
    const [openLeaseAdd, setOpenLeaseAdd] = React.useState(false);
    const handleOpenLeaseAdd = () => setOpenLeaseAdd(true);
    const handleCloseLeaseAdd = () => setOpenLeaseAdd(false);
    const [openLeaseRel, setOpenLeaseRel] = React.useState(false);
    const handleOpenLeaseRel = () => setOpenLeaseRel(true);
    const handleCloseLeaseRel = () => setOpenLeaseRel(false);
    function LeaseAdd() {
        // @ts-ignore
        const val = document.querySelector('#leasePrice').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            message.info('Price cannot be null');
            return false;
        } else if (Number(val) === 0) {
            message.info('Price cannot be zero');
            return false;
        } else if(!/^[1-9]+\d*$/ig.test(val)) {
            message.info('Price error');
            return false;
        }
        setIsLoading(true);
        LeaseCreate({ userNftId: nftInfo?.userNftId, price: Number(val) }).then((res: any) => {
            if (!res.code) {
                handleCloseLeaseAdd();
                handleOpenLeaseRel();
                initData();
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    function LeaseRemove() {
        HkListedCancel({ id: nftInfo?.consignmentObj.id, }).then((res: any) => {
            if (!res.code) {
                initData();
            }
        });
    }
    function continueList() {
        CreateList({
            userNftId: nftInfo?.userNftId,
            metg: Number(listMetg),
        })
            .then((res: any) => {
                handleCloseLowPrice();
                if (!res.code) {
                    setOpenMsgRel('Your item is listed');
                    handleOpenRel();
                }
            })
            .catch(() => {
                handleCloseLowPrice();
                setOpenMsgRel('List failed');
                handleOpenRel();
            });
    }
    function openTransModal() {
        if (!walletSelect) {
            message.info('please select wallet');
            return false;
        }
        handleClose();
        handleOpenTrans();
    }

    function toUser() {
        window.self.location.href = window.location.origin + '/user';
    }
    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta
                    name="description"
                    content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
                />
                <meta
                    property="og:description"
                    content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
                />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container nft-detail-box">
                                <h2 className="txt-default-title wow fadeInUp">
                                    <span className="txt">{nftInfo?.name}</span>
                                </h2>
                                <div className="row nft-detail">
                                    <div className="col col-12 col-lg-5 nft-detail-img">
                                        {nftInfo && nftInfo?.video ? (
                                            <video autoPlay muted loop id="hero_video" playsInline>
                                                <source src={nftInfo?.video} type="video/mp4" />
                                            </video>
                                        ) : (
                                            <img src={nftInfo?.image} />
                                        )}
                                    </div>
                                    <div className="col col-12 col-lg-7 nft-detail-info">
                                        <div className="nft-btn-box">
                                            {nftInfo?.status === 2 && (
                                                <button
                                                    className="btn btn-default btn-nft-detail-transfer"
                                                    disabled={isTransfer}
                                                    onClick={LeaseRemove}
                                                >
                                                    {t("page.detail.Remove")}
                                                </button>
                                            )}
                                            {nftInfo?.status === 1 && (
                                                <>

                                                    <button
                                                        className="btn btn-default btn-nft-detail-transfer"
                                                        disabled={isTransfer}
                                                        onClick={handleOpenList}
                                                    >
                                                        {t("page.detail.LIST")}
                                                    </button>
                                                    <button
                                                        className="btn btn-default btn-nft-detail-transfer"
                                                        disabled={isTransfer}
                                                        onClick={handleOpen}
                                                    >
                                                        {t("page.detail.TRANSFER")}
                                                    </button>
                                                </>
                                            )}
                                        </div>

                                        <div className="nft-detail-title">STATE</div>
                                        <div className="nft-detail-desc">
                                            {nftInfo?.status === 1 ? "MINT" : nftInfo?.status === 2 ? "LESTED" : nftInfo?.status === 5 ? "COMBINE" : "MINT"}
                                        </div>
                                        {/*<div className="nft-detail-title">{t("page.detail.DESCRIPTION")}</div>*/}
                                        {/*<div className="nft-detail-desc">*/}
                                        {/*    {nftInfo?.description}*/}
                                        {/*</div>*/}
                                        <div className="nft-detail-title">{t("page.detail.PROPERTIES")}</div>
                                        <div className="nft-detail-prop-item">
                                            {nftInfo?.attributes?.map((item, index) => {
                                                return (
                                                    <div className="nft-detail-props" key={index}>
                                                        <div className="nft-detail-prop-title">
                                                            {item.k}
                                                        </div>
                                                        <div className="nft-detail-prop-text">
                                                            {item.v}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleCloseReset}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-transfer-box"
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseReset}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            {t("page.detail.TRANSFER1")}
                        </div>
                        <div className="s-flex s-flex-center-row">
                            <div
                                className={`s-flex s-flex-center-row wallet-select s-cursor-pointer ${
                                    walletSelect === 1 ? 'wallet-selected' : ''
                                }`}
                                onClick={() => {
                                    setWalletSelect(1);
                                }}
                            >
                                <img
                                    style={{ width: '44px', height: '45px' }}
                                    src="./assets/images/user/invite-icon.png?v=20230413001"
                                />
                                <span>MetaGaia {t("page.detail.Wallet")}</span>
                            </div>
                        </div>
                        <div className="s-flex s-flex-center-row">
                            <div
                                className={`s-flex s-flex-center-row wallet-select s-cursor-pointer ${
                                    walletSelect === 2 ? 'wallet-selected' : ''
                                }`}
                                onClick={() => {
                                    setWalletSelect(2);
                                }}
                            >
                                <img
                                    style={{ width: '39px', height: '31px' }}
                                    src="./assets/images/user/solana-icon.png?v=20230413001"
                                />
                                <span>Solana {t("page.detail.Wallet")}</span>
                            </div>
                        </div>
                        <div className="modal-transfer-pd modal-transfer-btn modal-btn-box modal-btn-center">
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={openTransModal}
                            >
                                {t("page.detail.NEXT")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openTrans}
                onClose={handleCloseTrans}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-transfer-box"
            >
                <Fade in={openTrans}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseTrans}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            {t("page.detail.TRANSFER")}
                        </div>
                        <div className="modal-transfer-pd modal-transfer-title">
                            {t("page.detail.Enter")} {walletSelect === 1 ? 'MetaGaia' : 'Solana'} {t("page.detail.Enter1")}
                        </div>
                        <div className="modal-transfer-pd modal-transfer-value">
                            <input
                                type="text"
                                id="walletAddress"
                                maxLength={100}
                                onBlur={checkAddress}
                                placeholder="Enter Here"
                            />
                        </div>
                        <div className="modal-transfer-pd modal-transfer-valid">
                            {validMsgTransfer}
                        </div>
                        {walletSelect === 1 && (
                            <div className="modal-transfer-pd modal-transfer-title">
                                GAS FEE
                            </div>
                        )}
                        <div className="modal-transfer-pd modal-transfer-btn modal-btn-box modal-btn-between">
                            <div
                                className="modal-btn modal-btn-half modal-btn-cancel"
                                onClick={handleCloseTrans}
                            >
                                {t("page.detail.CANCEL")}
                            </div>
                            {
                                isLoading && <div className="modal-btn modal-btn-half modal-btn-confirm"><CircularProgress style={{color: "#FFF"}}></CircularProgress></div>
                            }
                            {
                                !isLoading &&
                                <div
                                  className="modal-btn modal-btn-half modal-btn-confirm"
                                  onClick={showTransfer}
                                >
                                    {t("page.detail.CONFIRM")}
                                </div>
                            }
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openList}
                onClose={handleCloseList}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-transfer-box"
            >
                <Fade in={openList}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseList}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            {t("page.detail.LIST2")}
                        </div>
                        <div className="modal-transfer-pd modal-transfer-title">
                            {t("page.detail.How")}
                        </div>
                        <div className="modal-transfer-pd modal-list-value">
                            <input
                                type="text"
                                id="listPrice"
                                maxLength={30}
                                placeholder="Enter List Price Here"
                                style={{width: "calc(100% - 6rem)"}}
                            />
                            <span>Candy Star</span>
                        </div>
                        <div className="modal-transfer-pd modal-transfer-valid">
                            {validMsgTransfer}
                        </div>
                        <div className="modal-transfer-pd modal-list-desc">
                            {t("page.detail.Minimum")} <span>200</span> Candy Star
                        </div>
                        <div className="modal-transfer-pd modal-transfer-btn modal-btn-box modal-btn-between">
                            <div
                                className="modal-btn modal-btn-half modal-btn-cancel"
                                onClick={handleCloseList}
                            >
                                {t("page.detail.CANCEL")}
                            </div>
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={showList}
                            >
                                {t("page.detail.CONFIRM")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openLowPrice}
                onClose={handleCloseLowPrice}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-transfer-box"
            >
                <Fade in={openLowPrice}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseLowPrice}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            {t("page.detail.LOW")}
                        </div>
                        <div className="s-flex s-flex-center-row low-tip">
                            <p>
                                {t("page.detail.Your1")} <span>{listMetg} METG</span> {t("page.detail.Your2")}
                                {t("page.detail.Your3")} <span>{lowestMetg} METG</span>
                            </p>
                        </div>
                        <div className="s-flex s-flex-center-row low-tip-second">
                            <p>{t("page.detail.Do")}</p>
                        </div>
                        <div className="modal-transfer-pd modal-transfer-btn modal-btn-box modal-btn-between">
                            <div
                                className="modal-btn modal-btn-half modal-btn-half-low modal-btn-cancel"
                                onClick={handleCloseLowPrice}
                            >
                                {t("page.detail.IT")}
                            </div>
                            <div
                                className="modal-btn modal-btn-half modal-btn-half-low modal-btn-confirm"
                                onClick={continueList}
                            >
                                {t("page.detail.CONTINUE")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openBalanceHexa}
                onClose={handleCloseBalanceHexa}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-balance-box"
            >
                <Fade in={openBalanceHexa}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseBalanceHexa}
                            />
                        </div>
                        <div className="modal-balance-pd modal-balance-high modal-balance-title">
                            {t("page.detail.Unfortunately")}
                        </div>
                        <div className="modal-balance-pd modal-balance-high modal-balance-title">
                            {t("page.detail.of1")} <span className="modal-balance-weight">Candy Star</span>{t("page.detail.of2")}
                            {t("page.detail.of3")}
                        </div>
                        <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text modal-balance-text-mt">
                            <span>Your Balance</span>
                            <span>{userAmount || '0'} Candy Star</span>
                        </div>
                        <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text">
                            <span>Amount Needed</span>
                            <span>200 Candy Star</span>
                        </div>
                        <div className="s-flex s-flex-between-row modal-balance-pd">
                            <div className="modal-balance-line"></div>
                        </div>
                        <div className="s-flex s-flex-center-row modal-balance-pd modal-balance-high modal-balance-desc">
                            {t("page.detail.Go")}
                        </div>
                        <div className="modal-balance-btn modal-btn-box modal-btn-center modal-pd">
                            <div
                                className="modal-btn modal-btn-all modal-btn-confirm-lang"
                                onClick={toUser}
                            >
                                {t("page.detail.TOP")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openRel}
                onClose={handleCloseRel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-transfer-rel-box"
            >
                <Fade in={openRel}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseRel}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            {openMsgRel}
                        </div>
                        <div className="modal-transfer-rel-btn modal-btn-box modal-btn-center">
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={handleCloseRel}
                            >
                                {t("page.detail.CONFIRM")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openLeaseAdd}
                onClose={handleCloseLeaseAdd}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-lease-rel-box"
            >
                <Fade in={openLeaseAdd}>
                    <Box sx={styleMax}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseLeaseAdd}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-lease-value">
                            <input
                                type="text"
                                id="leasePrice"
                                maxLength={30}
                                placeholder="Input Rental Prise"
                            />
                            <span className="modal-lease-uni">
                <span className="text-pm text-bold">HEX</span>
                <span className="text-pm text-fine">/Time</span>
              </span>
                        </div>
                        <div className="text-center text-pm text-fine">{t("page.detail.Minimum1")}</div>
                        <div className="modal-lease-ct">
                            {
                                leaseCt && leaseCt.map((item) => {
                                    return (
                                        <div className="lease-item" key={item.title}>
                                            <div className="lease-title text-pm text-bold">{item.title}</div>
                                            {item.desc.map((cItem, index) => {
                                                return (
                                                    <div className="lease-ct" key={index}>{cItem}</div>
                                                )
                                            })}
                                        </div>)
                                })
                            }
                        </div>
                        {
                            !isShoMore &&
                          <div className="text-center s-cursor-pointer show-more" onClick={changeShowMore}>
                            <div className="triangle-top"></div>
                            <div className="text-pm text-bold">{t("page.detail.CLOSE")}</div>
                          </div>
                        }
                        {
                            isShoMore &&
                          <div className="text-center s-cursor-pointer show-more" onClick={changeShowMore}>
                            <div className="text-pm text-bold">{t("page.detail.VIEW")}</div>
                            <div className="triangle-btm"></div>
                          </div>
                        }
                        <div className="modal-transfer-rel-btn modal-btn-box modal-btn-center">
                            <div
                                className="modal-btn modal-btn-half modal-btn-cancel"
                                onClick={handleCloseLeaseAdd}
                            >
                                {t("page.detail.CANCEL")}
                            </div>
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={LeaseAdd}
                            >
                                {t("page.detail.CONFIRM")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={openLeaseRel}
                onClose={handleCloseLeaseRel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-lease-rel-box"
            >
                <Fade in={openLeaseRel}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={handleCloseLeaseRel}
                            />
                        </div>
                        <div className="modal-ct-title text-center">
                            <div>{t("page.detail.Successfully")}</div>
                            <div>{t("page.detail.Leasing")}</div>
                        </div>
                        <div className="modal-transfer-rel-btn modal-btn-box modal-btn-center">
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={handleCloseLeaseRel}
                            >
                                {t("page.detail.CONFIRM")}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
