import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useState, useEffect } from 'react';
import { UserInfo, EditUserInfo } from './libs/api';
import message from './components/Message';

// modal start
import * as React from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {useTranslation} from "react-i18next";

const defaultSrc =
    "./assets/images/user/user-logo.png";

export default function User() {
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState("");
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserEmail(res.email);
        setCropData(res.avatar);
        // @ts-ignore
        document.querySelector("#userName").value = res.username;
      }
    });
  }

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    // @ts-ignore
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const showUploadFile = () => {
    // @ts-ignore
    document.getElementById("uploadFile").click();
  }


  function updateUserInfo() {
    let params = {}
    // @ts-ignore
    let uName = document.querySelector("#userName").value;
    uName = uName.trim();
    if (!uName) {
      message.info("Please input the necessary information");
      return false;
    }
    Object.assign(params, {username: uName});
    if (cropData && cropData.includes("data:image/png;base64")) {
      Object.assign(params, {avatar: cropData});
    }
    setIsLoading(true);
    EditUserInfo(params).then((res: any) => {
      setIsLoading(false);
      if (!res.code) {
        // @ts-ignore
        window.self.location.href = window.location.origin + "/user";
      }
    }).catch(() => {
      setIsLoading(false);
    });
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
                <div className="container user-box">
                  <h2 className="txt-default-title wow fadeInUp">
                    <span className="txt">{t("page.info.EDIT")}</span>
                  </h2>
                  <div className="row user-edit-box">
                    <div className="col-12 col-lg-5 edit-box-left">
                      <div className="row s-flex s-flex-between-row u-form-item">
                        <div className="col-12 u-form-title">{t("page.info.YOUR")}</div>
                        <div className="col-12 u-form-name">{userEmail || ""}</div>
                      </div>
                      <div className="row s-flex s-flex-between-row u-form-item">
                        <div className="col-12 u-form-title">{t("page.info.EDIT1")}</div>
                        <div className="col-12 u-form-name"><input type="text" id="userName" maxLength={15} /></div>
                      </div>
                      <div className="row s-flex s-flex-between-row u-form-item">
                        <div className="col-12 u-form-title">{t("page.info.EDIT2")}</div>
                        <div className="col-12 s-flex s-flex-between-row u-form-avatar">
                          <div className="s-flex s-flex-center-row user-logo-box">
                            <img className="user-logo-border" src="./assets/images/user/user-logo-border.png?v=20230413001" />
                            <img className="user-logo" src={cropData || "./assets/images/user/user-logo.png?v=20230413001"} />
                          </div>
                          <div className="user-file-box">
                            <div className="avatar-box s-cursor-pointer" onClick={showUploadFile}>
                              <button className="btn btn-default btn-high avatar-btn btn-form-border-none" style={{width: "100%", height: "100%"}}>SELECT AN IMAGE</button>
                            </div>
                            <input type="file" id="uploadFile" style={{display: "none"}} onChange={onChange} />
                          </div>
                        </div>
                      </div>
                      {
                          image && <div className="row s-flex s-flex-between-row u-form-item">
                            <div className="col-12 u-form-up">
                              <div>
                                <Cropper
                                    style={{ height: 250, width: "100%" }}
                                    zoomTo={0.3}
                                    dragMode={"move"}
                                    aspectRatio={1}
                                    initialAspectRatio={1}
                                    src={image}
                                    viewMode={1}
                                    minCropBoxHeight={1}
                                    minCropBoxWidth={1}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    onInitialized={(instance) => {
                                      setCropper(instance);
                                    }}
                                    guides={true}
                                />
                              </div>
                              <div>
                                <button  className="btn btn-default btn-high btn-form-border-none" onClick={getCropData} style={{float: "right"}}>
                                  {t("page.info.EDIT2")}
                                </button>
                              </div>
                            </div>
                          </div>
                      }

                    </div>
                    <div className="col-12 col-lg-1 edit-box-center"></div>
                    <div className="s-flex s-flex-center-row col-12 col-lg-6 edit-box-right">
                      <img src="./assets/images/user/edit-img-bg.png?v=20230413001" style={{width: "100%"}}/>
                    </div>
                  </div>


                  <div className="s-flex s-flex-center-row">
                    <button onClick={updateUserInfo} className="btn btn-default btn-border-none btn-form-edit" disabled={isLoading}>{t("page.info.Confirm")}</button>
                  </div>

                </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
