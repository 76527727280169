import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { UserInfo, EmailCode, ChangePwd } from './libs/api';
import message from './components/Message';

import * as React from 'react';
import {useTranslation} from "react-i18next";

// const timeNum = 120;
export default function PwdChange() {
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState('');
  const [validMsgOldPassword, setValidMsgOldPassword] = useState('');
  // const [isClickCode, setIsClickCode] = useState(false);
  // const [isClickCodeMsg, setIsClickCodeMsg] = useState('GET CODE');
  // const [isClickCodeNum, setIsClickCodeNum] = useState(timeNum);
  const [validMsgPassword, setValidMsgPassword] = useState('');
  const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserEmail(res.email);
      }
    });
  }

  // function getCode() {
  //   // @ts-ignore
  //   let emailVal = userEmail;
  //   EmailCode({ email: emailVal, type: 3 }).then();
  //   let time = timeNum;
  //   setIsClickCode(true);
  //   const interval = setInterval(() => {
  //     setIsClickCodeNum(time--);
  //     setIsClickCodeMsg(time + 's');
  //     if (time < 0) {
  //       setIsClickCode(false);
  //       setIsClickCodeNum(timeNum);
  //       setIsClickCodeMsg('GET CODE');
  //       clearInterval(interval);
  //     }
  //   }, 1000);
  //   return true;
  // }

  function validOldPassword() {
    // @ts-ignore
    const val = document.querySelector('#oldPassword').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgOldPassword(t("validate.information"));
      return false;
    }
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/gi.test(val)) {
      setValidMsgOldPassword('');
      return true;
    } else if (val.length > 50) {
      setValidMsgOldPassword(t("validate.long"));
      return false;
    } else {
      setValidMsgOldPassword(
        t("validate.Enter1")
      );
      return false;
    }
  }

  function validPassword() {
    // @ts-ignore
    const val = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPassword(t("validate.information"));
      return false;
    }
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/gi.test(val)) {
      setValidMsgPassword('');
      return true;
    } else if (val.length > 50) {
      setValidMsgPassword(t("validate.long"));
      return false;
    } else {
      setValidMsgPassword(
        t("validate.Enter1")
      );
      return false;
    }
  }

  function validPasswordConfirm() {
    // @ts-ignore
    const val = document.querySelector('#passwordConfirm').value.trim();
    // @ts-ignore
    const valRe = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPasswordConfirm(t("validate.information"));
      return false;
    }
    if (valRe !== val) {
      setValidMsgPasswordConfirm('The password is different. Please re-enter');
      return false;
    } else if (val.length > 50) {
      setValidMsgPasswordConfirm(t("validate.long"));
      return false;
    } else {
      setValidMsgPasswordConfirm('');
      return true;
    }
  }
  function changePwd() {
    if (validOldPassword() && validPassword() && validPasswordConfirm()) {
      // @ts-ignore
      const oldPwd = document.querySelector('#oldPassword').value.trim();
      // @ts-ignore
      const password = document.querySelector('#password').value.trim();
      setIsLoading(true);
      ChangePwd({
        oldPwd: oldPwd,
        password: password,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code) {
            window.self.location.href = window.location.origin + '/security';
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="container change-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.change.RESET")}</span>
                </h2>
                <div className="row change-pwd-box">
                  <div className="col-12 col-lg-5 edit-box-left">
                    <div className="row s-flex s-flex-between-row u-form-item no-margin">
                      <div className="col-12 u-form-title">{t("page.change.YOUR")}</div>
                      <div className="col-12 u-form-name">
                        {userEmail || ''}
                      </div>
                    </div>
                    <div className="row s-flex s-flex-between-row u-form-item have-margin">
                      <div className="col-12 u-form-title">
                        {t("page.change.Original")}
                      </div>
                      <div className="col-12 s-flex s-flex-between-row u-form-name">
                        <div className="s-flex s-flex-between-row code-box">
                          {/* <button
                            className="btn btn-default btn-border-none btn-get-code"
                            onClick={getCode}
                            disabled={isClickCode}
                          >
                            {isClickCodeMsg}
                          </button> */}
                          <input
                            type="password"
                            id="oldPassword"
                            minLength={8}
                            maxLength={50}
                            onBlur={validOldPassword}
                          />
                        </div>
                      </div>
                      <div className="col-12 form-valid">
                        {validMsgOldPassword}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-1 edit-box-center"></div>
                  <div className="col-12 col-lg-5 edit-box-right">
                    <div className="row s-flex s-flex-center-row u-form-item no-margin">
                      <div className="col-12 u-form-title">{t("page.change.Password")}</div>
                      <div className="col-12 u-form-name">
                        <input
                          type="password"
                          id="password"
                          minLength={8}
                          maxLength={50}
                          onBlur={validPassword}
                          onKeyDown={(e: any) => {if (e.key === " ") {
                            e.preventDefault();
                          }}}
                          onPaste = {(e: any) => e.preventDefault()}
                        />
                      </div>
                      {!validMsgPassword && (
                        <div className="col-12 password-tip">
                          {t("page.change.Enter")}
                        </div>
                      )}
                      {validMsgPassword && (
                        <div className="col-12 form-valid">
                          {validMsgPassword}
                        </div>
                      )}
                    </div>
                    <div className="row s-flex s-flex-center-row u-form-item">
                      <div className="col-12 u-form-title">{t("page.change.Repeat")}</div>
                      <div className="col-12 u-form-name">
                        <input
                          type="password"
                          id="passwordConfirm"
                          minLength={8}
                          maxLength={50}
                          onBlur={validPasswordConfirm}
                        />
                      </div>
                      <div className="col-12 form-valid">
                        {validMsgPasswordConfirm}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s-flex s-flex-center-row">
                  <button
                    className="btn btn-default btn-change-sure"
                    disabled={isLoading}
                    onClick={changePwd}
                  >
                    {t("page.change.Confirm")}
                  </button>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
