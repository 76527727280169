import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import {LoginEmail, LoginGoogle, LoginNew} from './libs/api';
import storage from './libs/storage';
import { setCookie, removeCookie } from './libs/cookies';

import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import message from './components/Message';
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();

  const [validMsgEmail, setValidMsgEmail] = useState('');
  const [validMsgPassword, setValidMsgPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const responseGoogle = (response: any) => {
    if (!response.error) {
      storage.setItem('googleObj', response.profileObj);
      storage.setItem('gToken', response.tokenObj.id_token);
      setIsLoading(true);
      LoginGoogle({
        gToken: response.tokenObj.id_token,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code && res.tfa) {
            storage.setItem('tfaKey', res.tfaKey);
            storage.setItem('tfaStatus', true);
            toSignSecurity();
          }
          if (res.token) {
            storage.setItem('userStatus', res.token);
            setCookie('ua_user', res.token || '');
            if (res.phone) {
              toHome();
            } else {
              toPhone();
            }
          }
        })
        .catch(() => {
          setIsLoading(false);
          storage.removeItem('googleObj');
          storage.removeItem('gToken');
          removeCookie('ua_user');
          storage.removeItem('userStatus');
          storage.removeItem('tfaStatus');
        });
    } else {
      message.info(response.error);
    }
  };

  useEffect(() => {
    if (storage.getItem('userStatus')) {
      window.self.location.href = window.location.origin;
    }
  }, []);

  function validEmail() {
    // @ts-ignore
    const val = document.querySelector('#email').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgEmail(t("validate.information"));
      return false;
    } else {
      setValidMsgEmail("");
      return true;
    }
    // if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(val)) {
    //   setValidMsgEmail("");
    //   return true;
    // } else {
    //   setValidMsgEmail(t("validate.address"));
    //   return false;
    // }
  }
  function validPassword() {
    // @ts-ignore
    const val = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPassword(t("validate.information"));
      return false;
    }
    // if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/gi.test(val)) {
    if (val.length > 7 || val.length < 51) {
      setValidMsgPassword('');
      return true;
    } else {
      setValidMsgPassword(t("validate.Password"));
      return false;
    }
  }
  function login() {
    if (validEmail() && validPassword()) {
      // @ts-ignore
      const email = document.querySelector('#email').value.trim();
      // @ts-ignore
      const password = document.querySelector('#password').value.trim();
      setIsLoading(true);
      LoginNew({
        username: email,
        password: password,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code && res.tfa) {
            storage.setItem('tfaKey', res.tfaKey);
            storage.setItem('tfaStatus', true);
            toSignSecurity();
          }
          if (res.token) {
            storage.setItem('userStatus', res.token);
            setCookie('ua_user', res.token || '');
            if (res.phone) {
              toHome();
            } else {
              toPhone();
            }
          }
        })
        .catch(() => {
          setIsLoading(false);
          removeCookie('ua_user');
          storage.removeItem('userStatus');
          storage.removeItem('tfaKey');
          storage.removeItem('tfaStatus');
        });
    }
  }
  function toHome() {
    window.localStorage.setItem("isPhone", "true");
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(
        redirectUrl.includes('forgot') ||
        redirectUrl.includes('reset') ||
        redirectUrl.includes('sign-security')
      )
    ) {
      window.self.location.href = window.location.origin + redirectUrl;
    } else {
      window.self.location.href = window.location.origin;
    }
  }
  function toPhone() {
    window.localStorage.setItem("isPhone", "false");
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
        redirectUrl &&
        !(
            redirectUrl.includes('forgot') ||
            redirectUrl.includes('reset') ||
            redirectUrl.includes('sign-security')
        )
    ) {
      window.self.location.href = window.location.origin + redirectUrl;
    } else {
      window.self.location.href = window.location.origin;
    }

  }
  function toSignSecurity() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(
        redirectUrl.includes('forgot') ||
        redirectUrl.includes('reset') ||
        redirectUrl.includes('sign-security')
      )
    ) {
      window.self.location.href =
        window.location.origin + '/sign-security?redirect=' + redirectUrl;
    } else {
      window.self.location.href = window.location.origin + '/sign-security';
    }
  }
  function toRegister() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href =
        window.location.origin + '/register?redirect=' + redirectUrl;
    } else {
      window.self.location.href = window.location.origin + '/register';
    }
  }
  function toForgot() {
    window.self.location.href = window.location.origin + '/forgot';
  }

  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.login.LOGIN")}</span>
                </h2>
                <div className="s-flex s-flex-center-row">
                  <div className="row form-box login-body">
                    <div className="login-ct">
                      <div className="col col-12 form-item">
                        <div className="form-label">UserName / Email</div>
                        <div className="form-value">
                          <input
                            type="email"
                            id="email"
                            minLength={2}
                            maxLength={100}
                            placeholder={t("placeholder.emailEnter")}
                            onBlur={validEmail}
                          />
                        </div>
                        <div className="form-valid">{validMsgEmail}</div>
                      </div>
                      <div className="col col-12 form-item">
                        <div className="form-label">{t("page.login.Password")}</div>
                        <div className="form-value">
                          <input
                            type="password"
                            id="password"
                            minLength={8}
                            maxLength={50}
                            placeholder={t("placeholder.passwordEnter")}
                            onBlur={validPassword}
                            onKeyDown={(e: any) => {if (e.key === " ") {
                              e.preventDefault();
                            }}}
                            onPaste = {(e: any) => e.preventDefault()}
                          />
                        </div>
                        <div className="form-valid">{validMsgPassword}</div>
                      </div>
                      <div className="col col-12 form-item s-flex s-flex-center-row text-fw">
                        <a
                          href="#"
                          target="_self"
                          onClick={toForgot}
                          className="text-line-bottom"
                        >
                          {t("page.login.Forgot")}
                        </a>
                      </div>
                      <div className="s-flex s-flex-center-row">
                        <button
                          onClick={login}
                          className="btn btn-default btn-form-login"
                          disabled={isLoading}
                        >
                          {t("page.login.LOGIN")}
                        </button>
                      </div>
                      <div className="col col-12 form-item s-flex s-flex-center-row">
                        {t("page.login.Don")}{' '}
                        <a
                          href="#"
                          target="_self"
                          onClick={toRegister}
                          className="text-line-bottom"
                        >
                          {t("page.login.SIGN")}
                        </a>
                      </div>
                      <div className="col col-12 form-item s-flex s-flex-center-row login-line"></div>
                      <div className="col col-12 form-item s-flex s-flex-center-row text-fw">
                        <span>{t("page.login.You")}</span>
                      </div>
                      <div className="s-flex s-flex-center-row">
                        <GoogleLogin
                          clientId="689277823450-kj6gjto7ms6uvtk0upch0u6v752e5nlc.apps.googleusercontent.com"
                          render={(renderProps) => (
                            <button
                              className="btn btn-default btn-form-google"
                              onClick={renderProps.onClick}
                              disabled={isLoading}
                            >
                              <img
                                src="assets/images/user/google-icon.png"
                                className="google-icon"
                              />
                              {t("page.login.GOOGLE")}
                            </button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        ></GoogleLogin>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
