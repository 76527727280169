import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import {ComResetPwd, urlToObj} from "../libs/api";
import * as React from "react";
import storage from "../libs/storage";
import {setCookie} from "../libs/cookies";
import {useTranslation} from "react-i18next";

export default function Login() {
    const { t } = useTranslation();

    const [validMsgPassword, setValidMsgPassword] = useState('');
    const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState("");

    useEffect(() => {
        const params: any = urlToObj(window.location.href);
        if (params && params.key) {
            setKey(params.key);
        } else {
            window.self.location.href = window.location.origin + '/login';
        }
    }, []);

    function validPassword() {
        // @ts-ignore
        const val = document.querySelector("#password").value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPassword(t("validate.information"));
            return false;
        }
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPassword(t("validate.information"));
            return false;
        }
        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/ig.test(val)) {
            setValidMsgPassword('');
            return true;
        } else if (val.length > 50) {
            setValidMsgPassword(t("validate.long"));
            return false;
        } else {
            setValidMsgPassword(t("validate.Enter1"));
            return false;
        }
    }

    function validPasswordConfirm() {
        // @ts-ignore
        const val = document.querySelector('#passwordConfirm').value.trim();
        // @ts-ignore
        const valRe = document.querySelector('#password').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPasswordConfirm(t("validate.information"));
            return false;
        }
        if (valRe !== val) {
            setValidMsgPasswordConfirm('The password is different. Please re-enter');
            return false;
        } else if (val.length > 50) {
            setValidMsgPasswordConfirm(t("validate.long"));
            return false;
        } else {
            setValidMsgPasswordConfirm('');
            return true;
        }
    }
    function resetpwd() {
        if (validPassword() && validPasswordConfirm()) {
            // @ts-ignore
            const password = document.querySelector("#password").value.trim();
            setIsLoading(true);
            ComResetPwd({
                key: key,
                password: password
            }).then((res: any) => {
                setIsLoading(false);
                if (!res.code) {
                    storage.setItem('userStatus', res.token);
                    setCookie('ua_user', res.token || '');
                    toUser();
                }
            }).catch(() => {
                setIsLoading(false);
            });
        }
    }
    function toUser() {
        window.self.location.href = window.location.origin + '/user';
    }
    return (
        <>
            <Helmet>
                <title>DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs</title>
                <meta name="description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
                <meta property="og:description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <main>
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container">
                                <h2 className="txt-default-title wow fadeInUp pwd-re-title"><span className="txt">{t("page.resetPwd.Reset")}</span></h2>
                                <div className="s-flex s-flex-center-row">
                                    <div className="row form-box login-body pwd-re-body">
                                        <div className="login-ct">
                                            <div className="col col-12 form-item">
                                                <div className="form-label">{t("page.resetPwd.Password")}</div>
                                                <div className="form-value"><input type="password" id="password" minLength={8} maxLength={50} onBlur={validPassword} /></div>
                                                {
                                                    !validMsgPassword && <div className="">{t("page.resetPwd.Enter")}</div>
                                                }
                                                {
                                                    validMsgPassword && <div className="form-valid">{validMsgPassword}</div>
                                                }
                                            </div>
                                            <div className="col col-12 form-item">
                                                <div className="form-label">{t("page.resetPwd.Confirm")}</div>
                                                <div className="form-value"><input type="password" id="passwordConfirm" minLength={8} maxLength={50} onBlur={validPasswordConfirm} /></div>
                                                <div className="form-valid">{validMsgPasswordConfirm}</div>
                                            </div>
                                            <div className="col col-12 form-item s-flex s-flex-center-row">
                                                <button onClick={resetpwd} className="btn btn-default btn-form-login" disabled={isLoading}>CONFIRM</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
};
