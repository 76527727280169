import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { ConfirmTFA } from './libs/api';
import message from './components/Message';
import { QRCodeSVG } from 'qrcode.react';
import useClipboard from 'react-use-clipboard';
import storage from './libs/storage';

import * as React from 'react';
import {useTranslation} from "react-i18next";

const timeNum = 120;
export default function Sign2FA() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState('');
  const [url, setUrl] = useState('');
  const [validMsgCode, setValidMsgCode] = useState('');

  const [copySecret, setCopySecret] = useClipboard(secret, {
    successDuration: 1000,
  });

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    if (storage.getItem('tfaObj')) {
      setSecret(storage.getItem('tfaObj').secret);
      setUrl(storage.getItem('tfaObj').key);
    }
  }

  function validCode() {
    // @ts-ignore
    const val = document.querySelector('#code').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgCode(t("validate.information"));
      return false;
    } else if (val.length < 6) {
      setValidMsgCode('Verification code error');
      return false;
    } else {
      setValidMsgCode('');
      return true;
    }
  }
  function toGetSecret() {
    if (validCode()) {
      // @ts-ignore
      const code = document.querySelector('#code').value.trim();
      setIsLoading(true);
      ConfirmTFA({
        secret: secret,
        code: code,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code) {
            storage.removeItem('tfaObj');
            storage.setItem('tfaStatus', res.status);
            window.self.location.href = window.location.origin + '/security';
          }
        })
        .catch(() => {
          setIsLoading(false);
          storage.removeItem('tfaObj');
          storage.removeItem('tfaStatus');
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="container change-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.2FA.SIGN")}</span>
                </h2>
                <div className="row change-pwd-box sign-2fa-box">
                  <div className="col-12 col-lg-5">
                    <div className="s-flex s-flex-center-row u-form-item">
                      <div className="col-12 u-form-name">
                        <div className="sign-left">
                          <div className="col-12 left-title">
                            {t("page.2FA.How")}
                          </div>
                          <div className="col-12 left-tip">
                            {t("page.2FA.1")}
                          </div>
                          <div className="col-12 left-tip">
                            {t("page.2FA.2")}
                          </div>
                          <div className="col-12 left-tip">
                            {t("page.2FA.3")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-1 edit-box-center"></div>
                  <div className="col-12 col-lg-5">
                    <div className="s-flex s-flex-center-row u-form-item">
                      <div className="col-12 qrcode-box u-form-name">
                        <div className="qrcode-img">
                          <QRCodeSVG
                            value={url}
                            size={128}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                            level={'M'}
                            includeMargin={true}
                          />
                        </div>
                        <div className="secret-box">
                          <div className="sign-secret">{secret}</div>
                          <button
                            className="btn btn-default btn-form-border-none btn-copy"
                            onClick={() => {
                              message.info('Copied');
                              setCopySecret();
                            }}
                          >
                            {t("page.2FA.COPY")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="s-flex s-flex-center-row u-form-item">
                      <div className="col-12 u-form-name qrcode-input">
                        <input
                          type="text"
                          id="code"
                          minLength={6}
                          maxLength={6}
                          placeholder="Enter code from 2FA app"
                          onBlur={validCode}
                        />
                      </div>
                    </div>
                    <div className="s-flex s-flex-between-row u-form-item">
                      <div className="form-valid">{validMsgCode}</div>
                    </div>
                  </div>
                </div>

                <div className="s-flex s-flex-center-row">
                  <button
                    className="btn btn-default btn-change-sure"
                    disabled={isLoading}
                    onClick={toGetSecret}
                  >
                    {t("page.2FA.CONFIRM")}
                  </button>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
