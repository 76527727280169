export default {
    EnumsHKArrayList: [{
        name: "东区",
        label: "东区",
    }, {
        name: "大埔区",
        label: "大埔区",
    }, {
        name: "观塘区",
        label: "观塘区",
    }, {
        name: "黄大仙区",
        label: "黄大仙区",
    }, {
        name: "九龙城区",
        label: "九龙城区",
    }, {
        name: "葵青区",
        label: "葵青区",
    }, {
        name: "离岛区",
        label: "离岛区",
    }, {
        name: "南区",
        label: "南区",
    }, {
        name: "荃湾区",
        label: "荃湾区",
    }, {
        name: "深水埗区",
        label: "深水埗区",
    }, {
        name: "沙田区",
        label: "沙田区",
    }, {
        name: "屯门区",
        label: "屯门区",
    }, {
        name: "湾仔区",
        label: "湾仔区",
    }, {
        name: "西贡区",
        label: "西贡区",
    }, {
        name: "元朗区",
        label: "元朗区",
    }, {
        name: "油尖旺区",
        label: "油尖旺区",
    }, {
        name: "中西区",
        label: "中西区",
    }],
    EnumGiftOrderStatus: {
        "1": "UNPAID",
        "2": "PAID",
        "3": "SHIPPED",
        "4": "COMPLETED",
        "5": "CANCELED",
    }
}
