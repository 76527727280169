import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {boxHkProdDetail, ShopOrderDetail, PayStripe, PayWx, PayWxOpenid, qrGenerate, urlToObj} from "../libs/api";
import message from "./Message";

interface IBoxDetail {
    blindBox?: any;
    nfts?: any[];
    orderId?: string;
    orderStatus?: number;
}

// 盲盒测试 Strip /hello-kitty?orderId=1670905006540406784&type=2
// 周边测试 Strip /hello-kitty?orderId=1670905853747871744&type=3
const DialogPay = forwardRef((props: any, ref) => {
    const [orderId, setOrderId] = useState(props.orderId || "");
    const [orderType, setOrderType] = useState(props.orderType || "2");
    // 支付选择
    const [modalPayType, setModalPayType] = React.useState(false);
    const openPayType = (val: any) => {
        setOrderId(val.orderId);
        setOrderType(val.orderType);
        setModalPayType(true);
    }
    const closePayType = () => setModalPayType(false);
    // 支付 微信二维码
    const [modalPayWxQr, setModalPayWxQr] = React.useState(false);
    const openPayWxQr = () => setModalPayWxQr(true);
    const closePayWxQr = () => setModalPayWxQr(false);
    // 支付等待
    const [modalPayLoading, setModalPayLoading] = React.useState(false);
    let searchDetail = null;
    const openPayLoading = () => {
        if (orderId) {
            searchDetail = setInterval(() => {
                detailLoop(orderId, orderType);
            }, 5000);
        }
        closePayWxQr();
        setModalPayLoading(true);
    }
    const closePayLoading = () => {
        if (searchDetail) {
            clearInterval(searchDetail);
            searchDetail = null;
        }
        setModalPayLoading(false);
    }

    const [isWxBrowser, setIsWxBrowser] = useState(false);
    // 选择支付方式 1 strap支付 2 微信境外支付
    const [payMethod, setPayMethod] = useState(1);
    const changePayMethod = () => {
        // 	根据选择的支付方式，进行获取信息
        if (payMethod.toString() ===  "1") {
            openPayLoading();
            payStrip();
        }
        if (payMethod.toString() ===  "2" && !isWxBrowser) {
            payWxQrCode();
        }
        if (payMethod.toString() ===  "2" && isWxBrowser) {
            payWxCode(orderId);
        }
        closePayType();
    }
    // strip支付
    const payStrip = () => {
        PayStripe({ orderId: orderId, type: orderType })
            .then((res: any) => {
                if (!res.code && res.url) {
                    // @ts-ignore
                    window.location.href = res.url;
                }
            })
            .catch(() => {
                // setOpenMsgRel('Top-up failed');
                // handleOpenRel();
            });
    }
    // 1、非微信浏览器，生成二维码
    const [wxQrCode, setWxQrCode] = useState("");
    const payWxQrCode = () => {
        // @ts-ignore
        const text = `${window.location.origin}/hello-kitty?payType=${payMethod}&orderId=${orderId}`;
        qrGenerate({text: text}).then((res: any) => {
            if (!res.code) {
                setWxQrCode(res.qrCode);
                openPayWxQr();
            }
        });
    }
    // 2、通过二维码调用URL并解析回调的uri参数，获取code
    const payWxCode = (id) => {
        // @ts-ignore
        const redirectUri = encodeURIComponent(`${window.location.origin}/hello-kitty?payType=${orderType}&orderId=${id || orderId}`);
        // @ts-ignore
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx459f9b30b8d5b725&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=#wechat_redirect`;
    }
    // 3、通过code获取openid
    const [wxPayCode, setWxPayCode] = useState("");
    const [wxPayOpenId, setWxPayOpenId] = useState("");
    const payWxOpenId = (code, order) => {
        openPayLoading();
        PayWxOpenid({code: code || wxPayCode}).then((res: any) => {
            if (res.openid) {
                setWxPayOpenId(res.openid);
                payWx(res.openid, order);
            }
        });
    }
    // 4、通过openid唤起支付
    const payWx = (id, order) => {
        PayWx({orderId: order || orderId, type: orderType, openid: id || wxPayOpenId}).then((res: any) => {
            if (!res.code) {
                wxPay(res, order);
            }
        });
    }
    const wxPay = (params, order) => {
        // @ts-ignore
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId": params.appId, //Official Account name, passed by the merchant
                "timeStamp": params.timeStamp, //Timestamp, number of seconds since 1970
                "nonceStr": params.nonceStr, //Random string
                "package": params.package,
                "signType": params.signType, //WeChat signature method:
                "paySign": params.paySign //WeChat signature
            },
            function(res) {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    detailLoop(order, orderType);
                }
                if (res.err_msg === "get_brand_wcpay_request:cancel") {
                    closePayLoading();
                }
                if (res.err_msg === "get_brand_wcpay_request:fail") {
                    closePayLoading();
                } // The above method can be used to judge the value returned from the frontend. WeChat team reminds you that res.err_msg will return OK after users make payment successfully, but this value is not guaranteed to be absolute reliable.
            }
        );
    }

    // 手办 3 购买结果
    const [modalBoxResultGift, setModalBoxResultGift] = useState(false);
    const openBoxResultGift = () => setModalBoxResultGift(true);
    const closeBoxResultGift = () => setModalBoxResultGift(false);
    const [boxDetailGift, setBoxDetailGift] = useState<any>(null);
    // 手办 3 支付成功之后开启展示的商品信息 1623856174158004224
    const getGiftDetail = (id) => {
        ShopOrderDetail({id: id || orderId}).then((res: any) => {
            if (!res.code) {
                if (res.status.toString() === "2") {
                    openBoxResultGift();
                    setBoxDetailGift(res);
                    closePayLoading();
                }
            }
        }).catch(() => {
            closePayLoading();
        });
    }
    const getGiftDetailClick = (id) => {
        ShopOrderDetail({id: id || orderId}).then((res: any) => {
            if (!res.code) {
                if (res.status.toString() === "2") {
                    openBoxResultGift();
                    setBoxDetailGift(res);
                    closePayLoading();
                } else {
                    message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
                }
            } else {
                message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
            }
        }).catch(() => {
            message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
        })
    }

    // 盲盒 2 购买结果
    const [modalBoxResult, setModalBoxResult] = React.useState(false);
    const openBoxResult = () => setModalBoxResult(true);
    const closeBoxResult = () => setModalBoxResult(false);
    // 盲盒 2 支付成功之后开启展示的商品信息 1623856174158004224
    const [boxDetail, setBoxDetail] = useState<IBoxDetail>(null);
    const getBoxDetail = (id) => {
        boxHkProdDetail({id: id || orderId}).then((res: any) => {
            if (!res.code) {
                if (res.orderStatus.toString() === "2") {
                    openBoxResult();
                    setBoxDetail(res);
                    closePayLoading();
                }
            }
        }).catch(() => {
            closePayLoading();
        });
    }
    const getBoxDetailClick = (id) => {
        boxHkProdDetail({id: id || orderId}).then((res: any) => {
            if (!res.code) {
                if (res.orderStatus.toString() === "2") {
                    openBoxResult();
                    setBoxDetail(res);
                    closePayLoading();
                } else {
                    message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
                }
            } else {
                message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
            }
        }).catch(() => {
            message.info("No payment information yet, Please try again later. Please view in my wallet if paid.");
        })
    }

    // 支付结果 循环查询结果
    const detailLoop = (id: any, type: any) => {
        if (type.toString() === "2") {
            getBoxDetail(id);
        }
        if (type.toString() === "3") {
            getGiftDetail(id);
        }
    }
    // 支付结果 查询，一次
    const detailClick = (id: any, type: any) => {
        if (type.toString() === "2") {
            getBoxDetailClick(id);
        }
        if (type.toString() === "3") {
            getGiftDetailClick(id);
        }
    }

    // 跳转个人中心
    const toUser = () => {
        window.self.location.href = window.location.origin + '/token-list?type=2';
    }

    useEffect(() => {
        // 判断是否在微信浏览器打开
        // @ts-ignore
        setIsWxBrowser(`${navigator.userAgent.toLowerCase().match(/MicroMessenger/i)}` === 'micromessenger');

        // 判断有无参数，有参数再处理是微信支付还是strip支付
        // strip 支付回调地址参数：payType=1&orderId=123
        // 微信 支付回调地址参数：orderId
        // @ts-ignore
        const params: any = urlToObj(window.location.href);
        if (Object.keys(params).length > 0) {
            if (params.payType && params.orderId && params.code) {
                setOrderId(String(params.orderId));
                setOrderType(String(params.payType));
                setWxPayCode(String(params.code));
                payWxOpenId(String(params.code), String(params.orderId));
            } else if (params.payType && params.orderId) {
                setOrderId(String(params.orderId));
                setOrderType(String(params.payType));
                payWxCode(String(params.orderId));
            } else if (params.orderId) {
                setOrderId(String(params.orderId));
                setOrderType(String(params.type));
                detailLoop(String(params.orderId), params.type);
            }
        }
    }, []);

    // 对外开放的调用
    useImperativeHandle(ref, () => ({
        openPayType,
    }));

    return <>
        {/*支付渠道选择*/}
        <Dialog onClose={closePayType} open={modalPayType}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-payType">
                <div className="modal-body modal-body-hk">
                    <div className="s-flex s-flex-center-row modal-title">
                        <div className="s-flex s-flex-center-row title-text-btn">
                            <span className="">Payment</span>
                        </div>
                        <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closePayType}/>
                    </div>
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row title-bold payType-title">
                            Choose your Payment
                        </div>
                        <div className="s-flex s-flex-center-row payType-btn payType-btn-top">
                            <img className="s-cursor-pointer btn-pay" src={payMethod === 1 ? "./assets/images/hellokity/btn-pay-stripe-sel.png" : "./assets/images/hellokity/btn-pay-stripe-un.png"} alt="" onClick={() => {setPayMethod(1)}}/>
                        </div>
                        <div className="s-flex s-flex-center-row payType-btn">
                            <img className="s-cursor-pointer btn-pay" src={payMethod === 2 ? "./assets/images/hellokity/btn-pay-wx-sel.png" : "./assets/images/hellokity/btn-pay-wx-un.png"} alt="" onClick={() => {setPayMethod(2)}}/>
                        </div>
                    </div>
                    <div className="s-flex s-flex-center-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={changePayMethod}>CONFIRM</div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*支付二维码 如果是微信*/}
        <Dialog onClose={closePayWxQr} open={modalPayWxQr}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-payWxQr">
                <div className="modal-body modal-body-hk">
                    <div className="s-flex s-flex-center-row modal-title">
                        <div className="s-flex s-flex-center-row title-text-btn">
                            <span className="">WeChat</span>
                        </div>
                        <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closePayWxQr}/>
                    </div>
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row title-bold payWxQr-title">
                            Please save this code to pay in WeChat
                        </div>
                        {/*播放一遍*/}
                        <div className="s-flex s-flex-center-row payWxQr-qr">
                            <img className="qr-img" src={wxQrCode} alt=""/>
                        </div>
                    </div>
                    <div className="s-flex s-flex-center-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={closePayWxQr}>CANCEL</div>
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={() => {detailClick(orderId, orderType)}}>PAID</div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*支付等待*/}
        <Dialog onClose={closePayLoading} open={modalPayLoading}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-load">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row modal-title">
                            <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closePayLoading}/>
                        </div>
                        <div className="s-flex s-flex-center-row load-img">
                            <img className="loading-img" src="./assets/images/hellokity/ico-loading.png" alt=""/>
                        </div>
                        <div className="s-flex s-flex-center-row title-bold load-title">
                            Please hold
                        </div>
                        <div className="s-flex s-flex-center-row title-normal load-title-sub">
                            The payment is processing
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*盲盒详情*/}
        <Dialog onClose={closeBoxResult} open={modalBoxResult}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-result">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row">
                            <img className="result-title-img" src="./assets/images/hellokity/combine-result-title.png" alt="" />
                        </div>
                        <div className="s-flex s-flex-center-row title-normal result-title">
                            You successfully purchased a blind box
                        </div>
                        {/*播放一遍*/}
                        <div className="s-flex s-flex-center-row result-video">
                            {
                                boxDetail && <video id="videoPlay" autoPlay muted disablePictureInPicture preload="auto"
                                                    width="100%">
                                    {
                                        boxDetail?.nfts.length === 1 && <source src={boxDetail?.nfts[0].animation} type="video/mp4" />
                                    }
                                    {
                                        boxDetail?.nfts.length > 1 && <source src={boxDetail?.nfts[0].groupAnimation} type="video/mp4" />
                                    }
                              </video>
                            }
                        </div>
                    </div>
                    <div className="s-flex s-flex-between-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-cancel" onClick={toUser}>MY COLLECTION</div>
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={closeBoxResult}>PURCHASE AGAIN</div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*手办详情*/}
        <Dialog onClose={closeBoxResultGift} open={modalBoxResultGift}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-result modal-box-shop-cart">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row">
                            <img className="result-title-img" src="./assets/images/hellokity/combine-result-title.png" alt="" />
                        </div>
                        <div className="s-flex s-flex-center-row title-normal result-title">
                            You successfully purchased
                        </div>
                        <div className="s-flex s-flex-center-row title-small">
                            *If your purchase includes Wonder Pass, please check Collection
                        </div>
                        <div className="result-bold">
                            {
                                boxDetailGift && boxDetailGift.products.map((item, index) => {
                                    return <div className="s-flex s-flex-center-row result-bold-item" key={index}>{item.name || ""}</div>
                                })
                            }
                        </div>
                    </div>
                    <div className="s-flex s-flex-between-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-cancel" onClick={toUser}>MY ORDER</div>
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={closeBoxResultGift}>BROWSE MORE</div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>
});
export default DialogPay;
