import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import {urlToObj} from "../libs/api";
import Storage from "../libs/storage";
import {useTranslation} from "react-i18next";

export default function UnitySelect() {
    const { t } = useTranslation();

    function toPage(url: string) {
        window.self.location.href = window.location.origin + url;
    }

    useEffect(() => {
        initData();
    }, []);

    function initData() {
        const params: any = urlToObj(window.location.href);
        const token = params.token || "";
        Storage.setItem("unity-token", token);
    }
    return (
        <div className="bg-hexahero">
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta property="og:description" content="" />
            </Helmet>

            <div className="wrapper">
                <main>
                    <section className="s-default unity-box">
                        <img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".unity-box"
                             data-top="transform: translateY(0%)"
                             data-top-bottom="transform: translateY(0%)" />

                        <div className="container s-flex s-flex-center-column unity-select">
                            <div className="s-cursor-pointer wow fadeInUp s-flex s-flex-center-row select-btn"
                                onClick={() => {toPage("/ut-file")}}>
                                <img className="img-metagaia" src="assets/images/unity/btn-upload.png?v=20230413001" />
                                <span>{t("page.unity.UPLOAD")}</span>
                            </div>
                            <div className="s-cursor-pointer wow fadeInUp s-flex s-flex-center-row select-btn"
                                onClick={() => {toPage("/ut-list")}}>
                                <img className="img-metagaia" src="assets/images/unity/btn-upload.png?v=20230413001" />
                                <span>{t("page.unity.VIEW")}</span>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}
