import { useEffect, useState } from 'react';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {HkListedList, MarketListData, UserInfo} from './libs/api';
import message from './components/Message';
import storage from './libs/storage';
import { setCookie } from './libs/cookies';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Transition from './components/Transition';
import Message from './components/Message/Message';
import {useTranslation} from "react-i18next";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface MarketObj {
  class?: string;
  id?: string;
  name?: string;
  number?: number;
  price?: string;
  rarity?: string;
  type?: number;
  url?: string;
  usdPrice?: number;

  NAME?: string;
  cover_image?: string;
  series?: string;
  token?: string;
}
function MarketList() {
  const { t } = useTranslation();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [tabIndex, setTabIndex] = useState(2);
  const [pageSize, setPageSize] = useState(12);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageTotalStr, setPageTotalStr] = useState('0');
  function handlePageTotal(value: number) {
    let num = value.toString();
    const reg = /\d{1,3}(?=(\d{3})+$)/g;
    const res = num.replace(/^(-?)(\d+)((\.\d+)?)$/, function (
      match,
      s1,
      s2,
      s3
    ) {
      return s1 + s2.replace(reg, '$&,') + s3;
    });
    setPageTotalStr(res);
    handlePageCurrent(Math.ceil(value / pageSize).toString());
  }
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageCurrentStr, setPageCurrentStr] = useState('1');
  function handlePageCurrent(num: string) {
    const reg = /\d{1,3}(?=(\d{3})+$)/g;
    const res = num.replace(/^(-?)(\d+)((\.\d+)?)$/, function (
      match,
      s1,
      s2,
      s3
    ) {
      return s1 + s2.replace(reg, '$&,') + s3;
    });
    setPageCurrentStr(res);
  }
  const [sort, setSort] = useState('3');
  const rarityList = [
    {
      name: 'Common',
      value: 'common',
    },
    {
      name: 'Rare',
      value: 'rare',
    },
    {
      name: 'Super Rare',
      value: 'super rare',
    },
    {
      name: 'Ultra Rare',
      value: 'ultra rare',
    },
    {
      name: 'Extreme',
      value: 'extreme',
    },
  ];
  const classList = [
    {
      name: 'Demolisher',
      value: 'Demolisher',
    },
    {
      name: 'Sniper',
      value: 'Sniper',
    },
    {
      name: 'Tanker',
      value: 'Tanker',
    },
    {
      name: 'Striker',
      value: 'Striker',
    },
    {
      name: 'Shooter',
      value: 'Shooter',
    },
    {
      name: 'Bomber',
      value: 'Bomber',
    },
    {
      name: 'Supporter',
      value: 'Supporter',
    },
    {
      name: 'Enchanter',
      value: 'Influencer',
    },
    {
      name: 'Commander',
      value: 'Commander',
    },
  ];
  const classListHk = [
    {
      name: 'Chef',
      value: 'Chef',
    },
    {
      name: 'Dreamcatcher',
      value: 'Dreamcatcher',
    },
    {
      name: 'Inventor',
      value: 'Inventor',
    },
    {
      name: 'Pilot',
      value: 'Pilot',
    },
    {
      name: 'Poet',
      value: 'Poet',
    },
    {
      name: 'Spellcaster',
      value: 'Spellcaster',
    },
    {
      name: 'Treasure Hunter',
      value: 'Treasure Hunter',
    },
  ];
  const [searchRarity, setSearchRarity] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [userAmount, setUserAmount] = useState(0);
  const [userAmountHk, setUserAmountHk] = useState(0);
  const [isShowToken, setIsShowToken] = useState(false);

  useEffect(() => {
    if (
      storage.getItem('userStatus') &&
      storage.getItem('userStatus') !== undefined
    ) {
      setIsShowToken(true);
      getUserInfo();
    }
  }, []);

  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserAmount(res.metg);
        setUserAmountHk(res.hk);
      }
    });
  }

  function tabChange(value: number) {
    if (value === tabIndex) return false;
    setTabIndex(value);
    setPageCurrent(1);
    setSort('3');
    setSearchRarity('');
    setSearchClass('');
  }

  function sortChange(event: SelectChangeEvent) {
    const val = event.target.value;
    setSort(val);
  }
  function rarityChange(value: string) {
    if (value === searchRarity) {
      setSearchRarity('');
    } else {
      setSearchRarity(value);
    }
  }
  function classChange(value: string) {
    if (value === searchClass) {
      setSearchClass('');
    } else {
      setSearchClass(value);
    }
  }
  function changePageCurrent(val: any) {
    let tempVal = pageCurrent;
    val = Number(val);
    if (/^[1-9]*$/gi.test(val)) {
      if (val <= Math.ceil(pageTotal / pageSize)) {
        setPageCurrent(val);
      } else {
        setPageCurrent(tempVal);
      }
    } else {
      setPageCurrent(tempVal);
    }
  }
  function pageChange(value: number) {
    if (pageCurrent === 1 && value < 0) {
    }
    let nextIndex = pageCurrent + value;
    setPageCurrent(nextIndex);
  }

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const FilterHero = () => (
    <div className="market-search">
      <div className="search-title">RARITY</div>
      {rarityList.map((item, index) => (
        <div
          className={`search-item s-flex s-flex-between-row s-cursor-pointer ${
            searchRarity === item.value ? 'item-active' : ''
          }`}
          key={'hexa-r' + index}
          onClick={() => rarityChange(item.value)}
        >
          <span>{item.name}</span>
          {searchRarity === item.value && (
            <ArrowForwardIosIcon className="icon-left" />
          )}
        </div>
      ))}
      <div className="search-title">CLASS</div>
      {classList.map((item, index) => (
        <div
          className={`search-item s-flex s-flex-between-row s-cursor-pointer ${
            searchClass === item.value ? 'item-active' : ''
          }`}
          key={'hexa-c' + index}
          onClick={() => classChange(item.value)}
        >
          <span>{item.name}</span>
          {searchClass === item.value && (
            <ArrowForwardIosIcon className="icon-left" />
          )}
        </div>
      ))}
    </div>
  );

  const FilterHexa = () => (
    <div className="market-search">
      <div className="search-title">CLASS</div>
      {classList.map((item, index) => (
        <div
          className={`search-item s-flex s-flex-between-row s-cursor-pointer ${
            searchClass === item.value ? 'item-active' : ''
          }`}
          key={'hero-r' + index}
          onClick={() => classChange(item.value)}
        >
          <span>{item.name}</span>
          {searchClass === item.value && (
            <ArrowForwardIosIcon className="icon-left" />
          )}
        </div>
      ))}
    </div>
  );

  const FilterHk = () => (
    <div className="market-search">
      <div className="search-title">CLASS</div>
      {classListHk.map((item, index) => (
        <div
          className={`search-item s-flex s-flex-between-row s-cursor-pointer ${
            searchClass === item.value ? 'item-active' : ''
          }`}
          key={'hero-r' + index}
          onClick={() => classChange(item.value)}
        >
          <span>{item.name}</span>
          {searchClass === item.value && (
            <ArrowForwardIosIcon className="icon-left" />
          )}
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    getMarketList(tabIndex.toString());
  }, [tabIndex, pageCurrent, sort, searchRarity, searchClass]);

  const [marketList, setMarketList] = useState<MarketObj[]>([]);
  function getMarketList(type: string) {
    if (type === "3") {getMarkListHk(type);} else {
      let params = {
        page: pageCurrent,
        pageSize: pageSize,
        type: type,
        order: sort.toString(),
      };
      if (searchRarity) {
        Object.assign(params, { rarityFilter: searchRarity });
      }
      if (searchClass) {
        Object.assign(params, { classFilter: searchClass });
      }
      MarketListData(params).then((res: any) => {
        if (!res.code) {
          setMarketList(res.rows);
          setPageTotal(res.count || 0);
          handlePageTotal(res.count || 0);
        }
      });
    }
  }

  function getMarkListHk(type: string) {
    let params = {
      page: pageCurrent,
      pageSize: pageSize,
      order: sort.toString(),
    };
    if (searchClass) {
      Object.assign(params, { seriesFilter: searchClass });
    }
    HkListedList(params).then((res: any) => {
      if (!res.code) {
        setMarketList(res.rows);
        setPageTotal(res.count || 0);
        handlePageTotal(res.count || 0);
      }
    });
  }

  function toDetail(id: any) {
    window.self.location.href =
      window.location.origin + `/market-detail?id=${id}`;
  }
  function toDetailHk(id: any) {
    window.self.location.href =
      window.location.origin + `/market-detail-hk?id=${id}`;
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Investor Lite Paper</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <main className="market-bg market-box market-list">
        <header>
          <div className="s-flex s-flex-center-row market-header">
            <a href="/home" target="_self">
              <img
                className="img-logo wow fadeIn"
                src="assets/images/logo_metagaia2_v2.png?v=20230413001"
              />
            </a>
            <h1 className="market-title market-title-big wow fadeIn">
              {t("page.mplace.Market")}
            </h1>
          </div>
        </header>

        {/* 登录后，手机端metg token部分展示start */}
        {isShowToken && (
          <div className="phone-token-box">
            {
              tabIndex === 3 && <span className="s-flex s-flex-center-row user-money-title">
              <img src="./assets/images/hellokity/ico-token-hk.png" alt="" style={{width: "3rem"}}/>
              Star Chips:
              </span>
            }
            {
              tabIndex !== 3 &&
                <span className="user-money-title">METG {t("page.mplace.Token")}:</span>
            }
            <span className="user-money">{userAmount}</span>
          </div>
        )}
        {/* 登录后，手机端metg token部分展示end */}

        <section className="market-list-data">
          {/* metg token部分展示start */}
          <div className="s-flex s-flex-between-row market-token-box">
            <div className="tabs-tabs s-flex">
              <div
                  className={`tabs-tab s-flex s-flex-center-row s-cursor-pointer ${
                      tabIndex === 2 ? 'tab-active' : ''
                  }`}
                  onClick={() => tabChange(2)}
              >
                <div className="tab-line tab-line-top"></div>
                <div className="tab-line tab-line-top-left"></div>
                <div className="tab-line tab-line-top-right"></div>
                <div className="tab-line tab-line-left"></div>
                <div className="tab-line tab-line-right"></div>
                <div className="s-flex s-flex-center-row tab-title">HEXA</div>
              </div>
              <div
                  className={`tabs-tab s-flex s-flex-center-row s-cursor-pointer ${
                      tabIndex === 1 ? 'tab-active' : ''
                  }`}
                  onClick={() => tabChange(1)}
              >
                <div className="tab-line tab-line-top"></div>
                <div className="tab-line tab-line-top-left"></div>
                <div className="tab-line tab-line-top-right"></div>
                <div className="tab-line tab-line-left"></div>
                <div className="tab-line tab-line-right"></div>
                <div className="s-flex s-flex-center-row tab-title">HERO</div>
              </div>
              <div
                  className={`tabs-tab s-flex s-flex-center-row s-cursor-pointer ${
                      tabIndex === 3 ? 'tab-active' : ''
                  }`}
                  style={{display: 'none'}}
              >
                <div className="tab-line tab-line-top"></div>
                <div className="tab-line tab-line-top-left"></div>
                <div className="tab-line tab-line-top-right"></div>
                <div className="tab-line tab-line-left"></div>
                <div className="tab-line tab-line-right"></div>
                <div className="s-flex s-flex-center-row tab-title">HELLO KITTY</div>
              </div>
            </div>
          </div>
          {/* metg token部分展示end */}

          <div className="tabs-panels">
            {tabIndex === 2 && (
              <div className="tabs-panel">
                <div className="panel-left">
                  <div className="drawer-change-pc">{FilterHexa()}</div>
                  <button
                    className="drawer-change-mobile btn btn-search-market"
                    onClick={toggleDrawer('left', true)}
                  >
                    Filter
                  </button>
                  <Drawer
                    className="drawer-change-mobile"
                    anchor="left"
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                  >
                    <Box className="market-search-box">
                      <div className="s-flex btn-close-box">
                        <div
                          className="btn-close s-cursor-pointer"
                          aria-label="Toggle navigation"
                          onClick={toggleDrawer('left', false)}
                        >
                          X
                        </div>
                      </div>
                      {FilterHexa()}
                    </Box>
                  </Drawer>
                </div>

                <div className="panel-right">
                  <div className="data-count-sort">
                    <div className="data-count">{pageTotalStr} HEXAs</div>
                    <div className="s-flex">
                      {
                        isShowToken && <div className="s-flex s-flex-center-end-row market-list-token">
                            <span className="user-money-title">METG {t("page.mplace.Token")}:</span>
                            <span className="user-money">{userAmount}</span>
                          </div>
                      }

                      <Select
                          value={sort}
                          onChange={sortChange}
                          IconComponent={() => (
                              <ExpandMoreIcon className="icon-left" />
                          )}
                          inputProps={{ 'aria-label': 'Without label' }}
                          className="market-filter"
                      >
                        <MenuItem value="1">lowest ID</MenuItem>
                        <MenuItem value="2">highest ID</MenuItem>
                        <MenuItem value="3">lowest price</MenuItem>
                        <MenuItem value="4">highest price</MenuItem>
                        <MenuItem value="5">latest</MenuItem>
                      </Select>
                    </div>
                  </div>
                  {marketList.length === 0 && (
                    <div className="s-flex s-flex-center-row no-data">
                      {t("page.mplace.NO")}
                    </div>
                  )}
                  <div className="market-data">
                    {marketList &&
                      marketList.map((item, index) => (
                        <div
                          key={index}
                          className="s-flex s-flex-center-row s-cursor-pointer data-item"
                          onClick={() => toDetail(item.id)}
                        >
                          <img
                            className="data-img-bg"
                            src="./assets/images/market/market-data-bg.png"
                          />
                          <div className="s-flex s-flex-between-row data-title">
                            <div className="s-flex data-type">
                              <img
                                className="data-type-img"
                                src={
                                  item.class
                                    ? `./assets/images/market/class-${item.class}.png`
                                    : './assets/images/market/class-Default.png'
                                }
                              />
                              <div className="data-name">{item.name}</div>
                            </div>
                            <div className="data-num">#{item.number}</div>
                          </div>
                          <img className="data-img" src={item.url} />
                          <div className="data-mount">
                            {Number(item.price)} METG
                          </div>
                        </div>
                      ))}
                  </div>
                  {pageTotal > 0 && (
                    <div className="s-flex s-flex-center-row page-box">
                      {pageCurrent === 1 && (
                        <div className="page-change">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageCurrent !== 1 && (
                        <div className="page-change s-cursor-pointer">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img
                            src="./assets/images/market/icon-page-active.png"
                            className="s-cursor-pointer"
                            onClick={() => pageChange(-1)}
                          />
                        </div>
                      )}
                      <div className="page-left">Page</div>
                      <div className="page-left">
                        <input
                          className="page-input"
                          type="number"
                          defaultValue={pageCurrent}
                          onChange={(e: any) =>
                            changePageCurrent(e.target.value)
                          }
                        ></input>
                      </div>
                      <div className="page-left">of {pageCurrentStr}</div>
                      {(pageTotal === 0 ||
                        pageTotal <= pageSize ||
                        pageCurrent >= Math.ceil(pageTotal / pageSize)) && (
                        <div className="page-change page-left">
                          <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageTotal > pageSize &&
                        pageCurrent < Math.ceil(pageTotal / pageSize) && (
                          <div className="page-change s-cursor-pointer page-left">
                            <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                            <img
                              src="./assets/images/market/icon-page-active.png"
                              className="s-cursor-pointer"
                              onClick={() => pageChange(1)}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {tabIndex === 1 && (
              <div className="tabs-panel">
                <div className="panel-left">
                  <div className="drawer-change-pc">{FilterHero()}</div>
                  <button
                    className="drawer-change-mobile btn btn-search-market"
                    onClick={toggleDrawer('left', true)}
                  >
                    {t("page.mplace.Filter")}
                  </button>
                  <Drawer
                    className="drawer-change-mobile"
                    anchor="left"
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                  >
                    <Box className="market-search-box">
                      <div className="s-flex btn-close-box">
                        <div
                          className="btn-close s-cursor-pointer"
                          aria-label="Toggle navigation"
                          onClick={toggleDrawer('left', false)}
                        >
                          X
                        </div>
                      </div>
                      {FilterHero()}
                    </Box>
                  </Drawer>
                </div>

                <div className="panel-right">
                  <div className="data-count-sort">
                    <div className="data-count">{pageTotalStr} HEROs</div>
                    <div className="s-flex">
                      <div className="s-flex s-flex-center-end-row market-list-token">
                        <span className="user-money-title">METG {t("page.mplace.Token")}:</span>
                        <span className="user-money">{userAmount}</span>
                      </div>
                      <Select
                          value={sort}
                          onChange={sortChange}
                          IconComponent={() => (
                              <ExpandMoreIcon className="icon-left" />
                          )}
                          className="market-filter"
                      >
                        <MenuItem value="1">lowest ID</MenuItem>
                        <MenuItem value="2">highest ID</MenuItem>
                        <MenuItem value="3">lowest price</MenuItem>
                        <MenuItem value="4">highest price</MenuItem>
                        <MenuItem value="5">latest</MenuItem>
                      </Select>
                    </div>
                  </div>
                  {marketList.length === 0 && (
                    <div className="s-flex s-flex-center-row no-data">
                      {t("page.mplace.NO")}
                    </div>
                  )}
                  <div className="market-data">
                    {marketList &&
                      marketList.map((item, index) => (
                        <div
                          key={index}
                          className="s-flex s-flex-center-row s-cursor-pointer data-item"
                          onClick={() => toDetail(item.id)}
                        >
                          <img
                            className="data-img-bg"
                            src="./assets/images/market/market-data-bg.png"
                          />
                          <div className="s-flex s-flex-between-row data-title">
                            <div className="s-flex data-type">
                              <img
                                className="data-type-img"
                                src={
                                  item.class
                                    ? `./assets/images/market/class-${item.class}.png`
                                    : './assets/images/market/class-Default.png'
                                }
                              />
                              <div className="data-name">{item.name}</div>
                            </div>
                            <div className="data-num">#{item.number}</div>
                          </div>
                          <img className="data-img" src={item.url} />
                          <div className="data-mount">
                            {Number(item.price)} METG
                          </div>
                        </div>
                      ))}
                  </div>
                  {pageTotal > 0 && (
                    <div className="s-flex s-flex-center-row page-box">
                      {pageCurrent === 1 && (
                        <div className="page-change">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageCurrent !== 1 && (
                        <div className="page-change s-cursor-pointer">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img
                            src="./assets/images/market/icon-page-active.png"
                            className="s-cursor-pointer"
                            onClick={() => pageChange(-1)}
                          />
                        </div>
                      )}
                      <div className="page-left">{t("page.mplace.Page")}</div>
                      <div className="page-left">
                        <input
                          className="page-input"
                          type="number"
                          value={pageCurrent}
                          onChange={(e: any) =>
                            changePageCurrent(e.target.value)
                          }
                        ></input>
                      </div>
                      <div className="page-left">of {pageCurrentStr}</div>
                      {(pageTotal === 0 ||
                        pageTotal <= pageSize ||
                        pageCurrent >= Math.ceil(pageTotal / pageSize)) && (
                        <div className="page-change page-left">
                          <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageTotal > pageSize &&
                        pageCurrent < Math.ceil(pageTotal / pageSize) && (
                          <div className="page-change s-cursor-pointer page-left">
                            <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                            <img
                              src="./assets/images/market/icon-page-active.png"
                              className="s-cursor-pointer"
                              onClick={() => pageChange(1)}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {tabIndex === 3 && (
              <div className="tabs-panel">
                <div className="panel-left">
                  <div className="drawer-change-pc">{FilterHk()}</div>
                  <button
                    className="drawer-change-mobile btn btn-search-market"
                    onClick={toggleDrawer('left', true)}
                  >
                    {t("page.mplace.Filter")}
                  </button>
                  <Drawer
                    className="drawer-change-mobile"
                    anchor="left"
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                  >
                    <Box className="market-search-box">
                      <div className="s-flex btn-close-box">
                        <div
                          className="btn-close s-cursor-pointer"
                          aria-label="Toggle navigation"
                          onClick={toggleDrawer('left', false)}
                        >
                          X
                        </div>
                      </div>
                      {FilterHk()}
                    </Box>
                  </Drawer>
                </div>

                <div className="panel-right">
                  <div className="data-count-sort">
                    <div className="data-count">{pageTotalStr} KITTYs</div>
                    <div className="s-flex">
                      <div className="s-flex s-flex-center-end-row market-list-token">
                        <span className="s-flex s-flex-center-row user-money-title">
                          <img src="./assets/images/hellokity/ico-token-hk.png" alt="" style={{width: "3rem"}}/>
                          Star Chips:
                        </span>
                        <span className="user-money">{userAmountHk}</span>
                      </div>
                      <Select
                          value={sort}
                          onChange={sortChange}
                          IconComponent={() => (
                              <ExpandMoreIcon className="icon-left" />
                          )}
                          className="market-filter"
                      >
                        <MenuItem value="1">Number ASC</MenuItem>
                        <MenuItem value="2">Number Desc</MenuItem>
                        <MenuItem value="3">price ASC</MenuItem>
                        <MenuItem value="4">price Desc</MenuItem>
                        <MenuItem value="5">time Desc</MenuItem>
                      </Select>
                    </div>
                  </div>
                  {marketList.length === 0 && (
                    <div className="s-flex s-flex-center-row no-data">
                      {t("page.mplace.NO")}
                    </div>
                  )}
                  <div className="market-data">
                    {marketList &&
                      marketList.map((item, index) => (
                        <div
                          key={index}
                          className="s-flex s-flex-center-row s-cursor-pointer data-item"
                          onClick={() => toDetailHk(item.id)}
                        >
                          <img
                            className="data-img-bg"
                            src="./assets/images/market/market-data-bg.png"
                          />
                          <div className="s-flex s-flex-between-row data-title">
                            <div className="s-flex data-type">
                              <div className="data-name data-name-hk">{item.NAME}</div>
                            </div>
                            <div className="data-num">#{item.token}</div>
                          </div>
                          <img className="data-img data-img-hk" src={item.cover_image} />
                          <div className="data-mount data-mount-hk">
                            {Number(item.price)} Star Chips
                          </div>
                        </div>
                      ))}
                  </div>
                  {pageTotal > 0 && (
                    <div className="s-flex s-flex-center-row page-box">
                      {pageCurrent === 1 && (
                        <div className="page-change">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageCurrent !== 1 && (
                        <div className="page-change s-cursor-pointer">
                          <ArrowBackIosIcon className="icon-page-change page-prev"></ArrowBackIosIcon>
                          <img
                            src="./assets/images/market/icon-page-active.png"
                            className="s-cursor-pointer"
                            onClick={() => pageChange(-1)}
                          />
                        </div>
                      )}
                      <div className="page-left">{t("page.mplace.Page")}</div>
                      <div className="page-left">
                        <input
                          className="page-input"
                          type="number"
                          value={pageCurrent}
                          onChange={(e: any) =>
                            changePageCurrent(e.target.value)
                          }
                        ></input>
                      </div>
                      <div className="page-left">of {pageCurrentStr}</div>
                      {(pageTotal === 0 ||
                        pageTotal <= pageSize ||
                        pageCurrent >= Math.ceil(pageTotal / pageSize)) && (
                        <div className="page-change page-left">
                          <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                          <img src="./assets/images/market/icon-page.png" />
                        </div>
                      )}
                      {pageTotal > pageSize &&
                        pageCurrent < Math.ceil(pageTotal / pageSize) && (
                          <div className="page-change s-cursor-pointer page-left">
                            <ArrowForwardIosIcon className="icon-page-change page-next"></ArrowForwardIosIcon>
                            <img
                              src="./assets/images/market/icon-page-active.png"
                              className="s-cursor-pointer"
                              onClick={() => pageChange(1)}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default MarketList;
