import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useRef, useState} from "react";
import {downloadCount} from "./libs/api";
import {useTranslation} from "react-i18next";

function Download() {
	const { t } = useTranslation();

	const [tabIndex, setTabIndex] = useState(0);
	const videoDom = useRef(null);
	const [videoHeight, setVideoHeight] = useState("");

	const clickDownload = (type: string) => {
		downloadCount({type: type}).then();
	}

	useEffect(() => {
		// @ts-ignore
		const bw = document.querySelector("body").clientWidth;
		if (videoDom && window.matchMedia("(min-width: 750px)").matches) {
			// @ts-ignore
			videoDom.current.addEventListener("loadedmetadata", () => {
				// @ts-ignore
				const vh = document.querySelector(".video-box").clientHeight;
				setVideoHeight(vh * 0.8 + "px");
			});
		}
	}, [])
	return (
		<div className="wrapper wrapper-home download-box">
			<Header />
			<main>
				<section className="s-hero">
					<img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-hero"
						data-center="transform: translateY(0%)"
						data-top-bottom="transform: translateY(40%)" />
					<div className="container">
						<div className="box-planet">
							<div className="video-box">
								<img className="img-planet" src="assets/images/planet_v3_1.png?v=20230413001" />
								<div className="video-hi" style={{height: videoHeight}}>
									<video autoPlay muted loop id="hero_video" playsInline ref={videoDom}>
										<source src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/movie_011_hd-hb2.mp4?v=20230413001" type="video/mp4" />
									</video>
								</div>
							</div>

							<div className="box-title" data-anchor-target=".s-hero"
								data-center="transform: translateX(-50%) translateY(-50%); opacity(1)"
								data-top-bottom="transform: translateX(-50%) translateY(-100%); opacity(0)">
								<img className="img-metagaia" src="assets/images/logo_metagaia2_v2.png?v=20230413001" />
								<div className="download-title">{t("page.down.PRIVATE")}</div>
								<div className="download-title-sub">{t("page.down.Welcome")}</div>
								<div className="download-link">
									<div className="s-flex s-flex-center-row s-cursor-pointer download-select" onClick={() => {
										clickDownload('1');
										window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/MetaGaiaClientWindows.zip";
									}}>
										<img className="download-select-icon" src="./assets/images/ico/icon-windows.png?v=20230413001" />
										<span className="s-flex s-flex-center-row">{t("page.down.DOWNLOAD")}</span>
									</div>
									<div className="s-flex s-flex-center-row s-cursor-pointer download-select" onClick={() => {
										clickDownload('2');
										window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/MetaGaiaClient.app.zip";
									}}>
										<img className="download-select-icon" src="./assets/images/ico/icon-mac.png?v=20230413001" />
										<span className="s-flex s-flex-center-row">{t("page.down.DOWNLOAD")}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="download-info">
					<div className="container">
						<div className="row download-tabs">
							<div className="col col-12 s-flex s-flex-center-start-row">
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 0 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(0);}}>
									<img className="" src="./assets/images/ico/icon-windows.png?v=20230413001" />
									<span className="s-flex s-flex-center-row">Windows</span>
								</div>
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 1 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(1);}}>
									<img className="" src="./assets/images/ico/icon-mac.png?v=20230413001" />
									<span className="s-flex s-flex-center-row">Mac</span>
								</div>
							</div>
						</div>
						{
							tabIndex === 0 && <div className="row download-tabs-body">
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.down.Minimum")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Operating")}</div>
									<div className="tabs-body-desc">{t("page.down.Windows")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Video")}</div>
									<div className="tabs-body-desc">{t("page.down.NVIDIA")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Storage")}</div>
									<div className="tabs-body-desc">{t("page.down.Solid")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Input")}</div>
									<div className="tabs-body-desc">{t("page.down.Keyboard")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.down.Processor")}</div>
									<div className="tabs-body-desc">{t("page.down.Intel")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Memory")}</div>
									<div className="tabs-body-desc">{t("page.down.4GB")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Internet")}</div>
									<div className="tabs-body-desc">{t("page.down.Broadband")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Resolution")}</div>
									<div className="tabs-body-desc">{t("page.down.1024")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.down.Recommended")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Operating1")}</div>
									<div className="tabs-body-desc">{t("page.down.Windows1")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Video")}</div>
									<div className="tabs-body-desc">{t("page.down.NVIDIA1")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Storage")}</div>
									<div className="tabs-body-desc">{t("page.down.Solid1")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Input")}</div>
									<div className="tabs-body-desc">{t("page.down.Multi")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.down.Processor")}</div>
									<div className="tabs-body-desc">{t("page.down.Intel2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Memory")}</div>
									<div className="tabs-body-desc">{t("page.down.8GB")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Internet")}</div>
									<div className="tabs-body-desc">{t("page.down.Broadband1")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Resolution")}</div>
									<div className="tabs-body-desc">{t("page.down.10241")}</div>
								</div>
							</div>
						}
						{
							tabIndex === 1 && <div className="row download-tabs-body">
								<div className="col col-12" style={{marginBottom: "2rem"}}>
									<div className="tabs-body-title">{t("page.down.Open1")}</div>
									<div className="tabs-body-title-sub"></div>
									<div className="tabs-body-desc">{t("page.down.Open2")}</div>
									<div className="tabs-body-desc">{t("page.down.Open3")}</div>
									<div className="tabs-body-desc">{t("page.down.Open4")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.down.Minimum")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Operating")}</div>
									<div className="tabs-body-desc">{t("page.down.macOS")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Video")}</div>
									<div className="tabs-body-desc">{t("page.down.NVIDIA2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Storage")}</div>
									<div className="tabs-body-desc">{t("page.down.Solid2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Input")}</div>
									<div className="tabs-body-desc">{t("page.down.Keyboard2")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.down.Processor")}</div>
									<div className="tabs-body-desc">{t("page.down.Intel2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Memory")}</div>
									<div className="tabs-body-desc">{t("page.down.4GB2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Internet")}</div>
									<div className="tabs-body-desc">{t("page.down.Broadband2")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Resolution")}</div>
									<div className="tabs-body-desc">{t("page.down.10242")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.down.Recommended")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Operating")}</div>
									<div className="tabs-body-desc">{t("page.down.macOS1")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Video")}</div>
									<div className="tabs-body-desc">{t("page.down.AMD")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Storage")}</div>
									<div className="tabs-body-desc">{t("page.down.Solid3")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Input")}</div>
									<div className="tabs-body-desc">{t("page.down.Multi3")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.down.Processor")}</div>
									<div className="tabs-body-desc">{t("page.down.Intel3")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Memory")}</div>
									<div className="tabs-body-desc">{t("page.down.8GB3")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Memory")}</div>
									<div className="tabs-body-desc">{t("page.down.Broadband3")}</div>
									<div className="tabs-body-title-sub">{t("page.down.Resolution")}</div>
									<div className="tabs-body-desc">{t("page.down.10243")}</div>
								</div>
							</div>
						}
					</div>
				</section>
				<Footer />
			</main>
		</div>
	);
}

export default Download;
