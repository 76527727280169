import Header from "../Header";
import { useState} from "react";
import { downloadCount } from "../libs/api";
import {useTranslation} from "react-i18next";
import * as React from "react";

function Download() {
	const { t } = useTranslation();

	const [tabIndex, setTabIndex] = useState(0);
	const clickDownload = (type: string) => {
    downloadCount({ type: type }).then((res: any) => {
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      if (type === '3') {
        window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/Hello_Kitty/Metagaia.apk";
      }
    });
	}
	return (
		<div className="wrapper wrapper-home download-box bg-faq">
			<Header />
			<main>
				<section className="s-hero s-download">
					<img className="img-bg" src="./assets/images/game-bg.jpg?v=20230413001" data-anchor-target=".s-hero"
						 data-center="transform: translateY(0%)"
						 data-top-bottom="transform: translateY(40%)" alt="" />
					<div className="container">
						<div className="box-planet" style={{width: "100%"}}>
							<div className="box-title" data-anchor-target=".s-hero"
								 data-center="transform: translateX(-50%) translateY(-50%); opacity(1)"
								 data-top-bottom="transform: translateX(-50%) translateY(-100%); opacity(0)"
								style={{marginTop: "28%"}}>
								<div className="download-title">{t("page.download.MetaGaia")}</div>
								{/*<div className="download-title-sub">{t("page.download.MetaGaia1", {version: "2.0.2"})}</div>*/}
								<div className="s-show-pc">
									<div className="download-link">
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 window.location.href = "https://apps.apple.com/hk/app/metagaia/id1638854848?l=en";
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_ios.png?v=20230413001"
												 alt=""/>
										</div>
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 window.location.href = "https://play.google.com/store/search?q=metagaia&c=apps";
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_google.png?v=20230413001"
												 alt=""/>
										</div>
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 clickDownload('3');
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_and.png?v=20230413001"
												 alt=""/>
										</div>
									</div>
								</div>
								<div className="s-show-m">
									<div className="download-link">
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 window.location.href = "https://apps.apple.com/hk/app/metagaia/id1638854848?l=en";
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_ios.png?v=20230413001"
												 alt=""/>
										</div>
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 window.location.href = "https://play.google.com/store/search?q=metagaia&c=apps";
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_google.png?v=20230413001"
												 alt=""/>
										</div>
										<div className="s-flex s-flex-center-row s-cursor-pointer download-select-hk"
											 onClick={() => {
												 clickDownload('3');
											 }}>
											<img className="s-cursor-pointer download-img"
												 src="./assets/images/hellokity/btn-down_sd_and.png?v=20230413001"
												 alt=""/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="s-show-pc download-info" style={{display: "none"}}>
					<div className="container">
						<div className="row download-tabs">
							<div className="col col-12 s-flex s-flex-center-start-row">
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 0 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(0);}}>
									<img className="" src="./assets/images/ico/icon-windows.png?v=20230413001" alt="" />
									<span className="s-flex s-flex-center-row">Windows</span>
								</div>
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 1 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(1);}}>
									<img className="" src="./assets/images/ico/icon-mac.png?v=20230413001" alt="" />
									<span className="s-flex s-flex-center-row">Mac</span>
								</div>
							</div>
						</div>
						{
							tabIndex === 0 && <div className="row download-tabs-body">
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.download.Minimum")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Operating")}</div>
									<div className="tabs-body-desc">{t("page.download.Windows")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Video")}</div>
									<div className="tabs-body-desc">{t("page.download.NVIDIA")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Storage")}</div>
									<div className="tabs-body-desc">{t("page.download.Solid")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Input")}</div>
									<div className="tabs-body-desc">{t("page.download.Keyboard")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.download.Processor")}</div>
									<div className="tabs-body-desc">{t("page.download.Intel")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Memory")}</div>
									<div className="tabs-body-desc">{t("page.download.4GB")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Internet")}</div>
									<div className="tabs-body-desc">{t("page.download.Broadband")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Resolution")}</div>
									<div className="tabs-body-desc">{t("page.download.1024")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.download.Recommended")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Operating1")}</div>
									<div className="tabs-body-desc">{t("page.download.Windows1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Video1")}</div>
									<div className="tabs-body-desc">{t("page.download.NVIDIA1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Storage1")}</div>
									<div className="tabs-body-desc">{t("page.download.Solid1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Input1")}</div>
									<div className="tabs-body-desc">{t("page.download.Multi")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.download.Processor1")}</div>
									<div className="tabs-body-desc">{t("page.download.Intel1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Memory1")}</div>
									<div className="tabs-body-desc">{t("page.download.8GB")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Internet1")}</div>
									<div className="tabs-body-desc">{t("page.download.Broadband1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Resolution1")}</div>
									<div className="tabs-body-desc">{t("page.download.10241")}</div>
								</div>
							</div>
						}
						{
							tabIndex === 1 && <div className="row download-tabs-body">
								<div className="col col-12" style={{marginBottom: "2rem"}}>
									<div className="tabs-body-title">{t("page.download.Open")}</div>
									<div className="tabs-body-title-sub"></div>
									<div className="tabs-body-desc">{t("page.download.Right")}</div>
									<div className="tabs-body-desc">{t("page.download.Click")}</div>
									<div className="tabs-body-desc">{t("page.download.An")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.download.Minimum1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Operating2")}</div>
									<div className="tabs-body-desc">{t("page.download.macOS")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Video2")}</div>
									<div className="tabs-body-desc">{t("page.download.NVIDIA2")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Storage2")}</div>
									<div className="tabs-body-desc">{t("page.download.Solid2")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Input2")}</div>
									<div className="tabs-body-desc">{t("page.download.Keyboard1")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.download.Processor2")}</div>
									<div className="tabs-body-desc">{t("page.download.Intel2")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Memory2")}</div>
									<div className="tabs-body-desc">{t("page.download.4GB1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Internet2")}</div>
									<div className="tabs-body-desc">{t("page.download.Broadband2")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Resolution2")}</div>
									<div className="tabs-body-desc">{t("page.download.10242")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title">{t("page.download.Recommended1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Operating3")}</div>
									<div className="tabs-body-desc">{t("page.download.macOS1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Video3")}</div>
									<div className="tabs-body-desc">{t("page.download.AMD")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Storage3")}</div>
									<div className="tabs-body-desc">{t("page.download.Solid3")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Input3")}</div>
									<div className="tabs-body-desc">{t("page.download.Multi1")}</div>
								</div>
								<div className="col col-12 col-lg-3">
									<div className="tabs-body-title-space"></div>
									<div className="tabs-body-title-sub">{t("page.download.Processor3")}</div>
									<div className="tabs-body-desc">{t("page.download.Intel3")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Memory3")}</div>
									<div className="tabs-body-desc">{t("page.download.8GB1")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Memory4")}</div>
									<div className="tabs-body-desc">{t("page.download.Broadband3")}</div>
									<div className="tabs-body-title-sub">{t("page.download.Resolution3")}</div>
									<div className="tabs-body-desc">{t("page.download.10243")}</div>
								</div>
							</div>
						}
					</div>
					<div className="download-fixed-btm"></div>
				</section>
				<section className="download-box-footer">
					<div className="s-flex s-flex-center-row download-tips-box" style={{display: "none"}}>
						<div className="download-tips">
							{t("page.download.Currently")}
						</div>
					</div>
					<div className="s-flex s-flex-center-row download-footer">
						<a href="https://twitter.com/meta_gaia/" target="_blank">
							<img src="assets/images/ico_twitter.png?v=20230413001" alt=""/>
						</a>
						<a href="https://discord.com/invite/Nqk2P57u7Y" target="_blank">
							<img src="assets/images/ico_discord.png?v=20230413001" alt=""/>
						</a>
						<a href="https://www.instagram.com/meta_gaia/" target="_blank">
							<img src="assets/images/ico_instagram.png?v=20230413001" alt=""/>
						</a>
					</div>
				</section>
			</main>
		</div>
	);
}

export default Download;
