import { HexaRanking, urlToObj } from '../libs/api';
import { dateCalculation } from '../utils';
import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function Ranking() {
  const { t } = useTranslation();

  const [season, setSeason] = React.useState(0);
  const [leftTime, setLeftTime] = React.useState('');
  const [rankList, setRankList] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState<any>();
  const initData = () => {
    const params: any = urlToObj(window.location.href);
    const token = params.token || '';
    HexaRanking({ token: token }).then((res: any) => {
      if (res) {
        setSeason(res.season);
        getTime(res.leftTime);
      }
      if (res.list) {
        setRankList(res.list);
      }
      if (res.player) {
        setUserInfo(res.player);
      }
    });
    function getTime(leftTime) {
      let timeValue = dateCalculation(leftTime);
      setLeftTime(timeValue);
    }
  };
  const clickJsToC = () => {
    // @ts-ignore
    if (window.vuplex) {
      sendMessageToCSharp();
    } else {
      window.addEventListener('vuplexready', sendMessageToCSharp);
    }
  }
  function sendMessageToCSharp() {
    // @ts-ignore
    window.vuplex.postMessage({ type: 'greeting', message: 'Hello from JavaScript!' });
  }
  React.useEffect(() => {
    document.body.style.backgroundColor = '#BFBFBF';
    initData();
  }, []);
  return (
    <main className="s-flex s-flex-center-column">
      <div className="gm-rk-box rk-score rk-hexa">
        {/* <img className="gm-rk-bg" src="./assets/images/ranking/hexa-bg.png" /> */}
        <div className="ranking-content ranking-main">
          <div className="s-flex s-flex-center-row rk-left-time">
            <div className="hexa-time-bg">
              <img
                className="rk-time-bg"
                src="./assets/images/ranking/hexa-time-bg.png"
              />
              <span className="hexa-week">{season}</span>
            </div>
            <div className="hexa-left-time">
              {leftTime} <span>Left</span>
            </div>
          </div>
          <div className="s-flex s-flex-center-row ranking-rk">
            <div className="rk-item">
              <div className="s-flex s-flex-center-row rk-item-img">
                {/* <div className="rk-item-img2">
                  <img
                    className="rk-item-avatar"
                    src={
                      rankList && rankList[1]
                        ? rankList[1].avatar
                        : './assets/images/team/team-A05.png'
                    }
                  />
                </div> */}
                <img
                  className="rk-item-img-bg2"
                  src="./assets/images/ranking/hexa-2.png"
                />
              </div>
              <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">
                {rankList && rankList[1] ? rankList[1].username : '-'}
              </div>
              <div className="s-flex s-flex-center-row rk-text-bold">
                {rankList && rankList[1] ? rankList[1].total : '-'}
              </div>
            </div>
            <div className="rk-item">
              <div className="s-flex s-flex-center-row rk-item-img">
                {/* <div className="rk-item-img1">
                  <img
                    className="rk-item-avatar"
                    src={
                      rankList && rankList[0]
                        ? rankList[0].avatar
                        : './assets/images/team/team-A05.png'
                    }
                  />
                </div> */}
                <img
                  className="rk-item-img-bg1"
                  src="./assets/images/ranking/hexa-1.png"
                />
              </div>
              <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">
                {rankList && rankList[0] ? rankList[0].username : '-'}
              </div>
              <div className="s-flex s-flex-center-row rk-text-bold">
                {rankList && rankList[0] ? rankList[0].total : '-'}
              </div>
            </div>
            <div className="rk-item">
              <div className="s-flex s-flex-center-row rk-item-img">
                {/* <div className="rk-item-img3">
                  <img
                    className="rk-item-avatar"
                    src={
                      rankList && rankList[2]
                        ? rankList[2].avatar
                        : './assets/images/team/team-A05.png'
                    }
                  />
                </div> */}
                <img
                  className="rk-item-img-bg3"
                  src="./assets/images/ranking/hexa-3.png"
                />
              </div>
              <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">
                {rankList && rankList[2] ? rankList[2].username : '-'}
              </div>
              <div className="s-flex s-flex-center-row rk-text-bold">
                {rankList && rankList[2] ? rankList[2].total : '-'}
              </div>
            </div>
          </div>
          <div className="ranking-other">
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#4</div>
                {/* <div className="other-avatar">
                  <img
                    className="other-avatar-img"
                    src={
                      rankList && rankList[3]
                        ? rankList[3].avatar
                        : './assets/images/team/team-A05.png'
                    }
                  />
                </div> */}
                <div className="other-name rk-text-normal">
                  {rankList && rankList[3] ? rankList[3].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[3] ? rankList[3].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#5</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[4] ? rankList[4].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[4] ? rankList[4].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#6</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[5] ? rankList[5].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[5] ? rankList[5].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#7</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[6] ? rankList[6].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[6] ? rankList[6].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#8</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[7] ? rankList[7].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[7] ? rankList[7].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#9</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[8] ? rankList[8].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[8] ? rankList[8].total : '-'}
              </div>
            </div>
            <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">#10</div>
                <div className="other-name rk-text-normal">
                  {rankList && rankList[9] ? rankList[9].username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {rankList && rankList[9] ? rankList[9].total : '-'}
              </div>
            </div>
            {/* <div className="s-flex s-flex-between-row other-bg">
              <div className="s-flex">
                <div className="rk-text-num-bold">
                  {userInfo ? '#' + userInfo.no : '-'}
                </div>
                <div className="other-name rk-text-normal">
                  {userInfo ? userInfo.username : '-'}
                </div>
              </div>
              <div className="other-number rk-text-normal">
                <img
                  className="hexa-score-img"
                  src="./assets/images/ranking/hexa-score.png"
                />
                {userInfo ? userInfo.total : '-'}
              </div>
            </div> */}
          </div>
        </div>
        <div className="ranking-content ranking-cur">
          <div className="s-flex s-flex-between-row other-bg">
            <div className="s-flex">
              <div className="rk-text-num-bold">
                {userInfo ? '#' + userInfo.no : '-'}
              </div>
              <div className="other-name rk-text-normal">
                {userInfo ? userInfo.username : '-'}
              </div>
            </div>
            <div className="other-number rk-text-normal">
              <img
                className="hexa-score-img"
                src="./assets/images/ranking/hexa-score.png"
              />
              {userInfo ? userInfo.total : '-'}
            </div>
          </div>
        </div>
        <div className="s-flex s-flex-center-row rk-btn-btm">
          <img className="s-cursor-pointer rk-btn-btn" src="assets/images/ranking/btn-hexa.png" onClick={clickJsToC}/>
        </div>
      </div>
    </main>
  );
}
