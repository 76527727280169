// import Header from "../Header";
// import Footer from "../Footer";
import message from '../components/Message';
import { FtAnswer } from "../libs/api";

// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #FFFFFF',
    borderRadius: "5px",
    boxShadow: 0,
    p: 1,
};
// modal end

export default function Question() {
    const { t } = useTranslation();

    const path = window.location.pathname;
    const params = window.location.search;
    const indexQues = path.split("/FT_Q")[1];
    const tmp = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE0NjAwMjgwNTAzMzQwMjc3NzYiLCJlbWFpbCI6Ijg5Njk1NjY5MkBxcS5jb20iLCJwaG9uZSI6IjE4Ni0xMDAwMDAwMCIsImlhdCI6MTY1NTcxMTE0MywiZXhwIjoxNjU4MzAzMTQzfQ.uREnvlZOxiqWci9bTCGWTRLvIGNtKlY-yaxoXtfSehU";
    let token = "";
    if (params && params.indexOf("=") > -1) {
        token = params.split("=")[1].replace(/^\{/ig, "").replace(/\}$/ig, "");
    }
    if (token && token === tmp) {
        token = "";
    }

    if (!["1", "2", "3", "4", "5", "6"].includes(indexQues)) message.info("Error Path");
    const questions = [
        {
            index: 1,
            cnQues: "富通保險是哪個集團的成員？",
            enQues: "Which of the following group did FTLife join?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "",
            enTips: "",
            answers: [{
                index: "A",
                isTrue: false,
                cnAnswer: "富甲一方集團",
                enAnswer: "Wealthy Group",
            }, {
                index: "B",
                isTrue: true,
                cnAnswer: "新世界集團",
                enAnswer: "New World Group",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "新一代集團",
                enAnswer: "New Gen Group",
            }, {
                index: "D",
                isTrue: false,
                cnAnswer: "新亞洲國際集團",
                enAnswer: "New Asia International Group",
            }]
        }, {
            index: 2,
            cnQues: "以下哪項是富通保險「人生規劃師」的使命？",
            enQues: "Which of the following is the mission of FTLife’s “Life Artisans”?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "",
            enTips: "",
            answers: [{
                index: "A",
                isTrue: false,
                cnAnswer: "陪伴客戶規劃人生",
                enAnswer: "Helping the clients to fulfilling lives at different stages",
            }, {
                index: "B",
                isTrue: false,
                cnAnswer: "策劃未來",
                enAnswer: "Planning the future for the clients",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "關心客戶健康",
                enAnswer: "Caring for clients’ health",
            }, {
                index: "D",
                isTrue: true,
                cnAnswer: "以上皆是",
                enAnswer: "All of the above",
            }]
        }, {
            index: 3,
            cnQues: "以下哪項是LEAP的主要優勢？",
            enQues: "Which of the following is the advantage(s) of the LEAP Programme?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "*以上內容只供參考，富通保險有機會按實際情況及需要作出修改，有關安排亦需符合其他內部要求。",
            enTips: "*The above content is for reference only. FTLife may make changes according to the actual situation and needs, and the relevant arrangements are also subject to internal requirements.",
            answers: [{
                index: "A",
                isTrue: false,
                cnAnswer: "培育MDRT 百萬年薪殊榮*",
                enAnswer: "Nurturing MDRTs with million dollar annual income*",
            }, {
                index: "B",
                isTrue: false,
                cnAnswer: "快速晉升階梯",
                enAnswer: "Ideal promotion ladder",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "專業培訓及發展",
                enAnswer: "Professional training & development",
            }, {
                index: "D",
                isTrue: true,
                cnAnswer: "以上皆是",
                enAnswer: "All of the above",
            }]
        }, {
            index: 4,
            cnQues: "富通保險首創的「遊戲人生」遊戲 ，是屬於以下哪種技術？",
            enQues: "Which kind of game is \"The Game of Life\" which is pioneered by FTLife?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "",
            enTips: "",
            answers: [{
                index: "A",
                isTrue: false,
                cnAnswer: "人工智能",
                enAnswer: "Artificial Intelligence (AI)",
            }, {
                index: "B",
                isTrue: true,
                cnAnswer: "虛擬實境",
                enAnswer: "Virtual Reality (VR)",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "元宇宙",
                enAnswer: "Metaverse",
            }, {
                index: "D",
                isTrue: false,
                cnAnswer: "以上皆是",
                enAnswer: "All of the above",
            }]
        }, {
            index: 5,
            cnQues: "在富通保險取得MDRT榮譽，可享有以下哪些福利？",
            enQues: "Which of the followings are the benefits of being a MDRT member in FTLife?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "",
            enTips: "",
            answers: [{
                index: "A",
                isTrue: false,
                cnAnswer: "MDRT專屬支援",
                enAnswer: "MDRT exclusive support",
            }, {
                index: "B",
                isTrue: false,
                cnAnswer: "獲得榮譽獎項",
                enAnswer: "Receiving honorary awards",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "獲邀參與不同的大型交流會議，包括海外精英高峰會議",
                enAnswer: "Being invited to participate in different large-scale agency meetings, including overseas conventions",
            }, {
                index: "D",
                isTrue: true,
                cnAnswer: "以上皆是",
                enAnswer: "All of the above",
            }]
        }, {
            index: 6,
            cnQues: "富通保險之旗艦產品「傳家寶」系列最多可轉換幾多次受保人？",
            enQues: "How many times at most we could change the Insured for the FTLife “Regent” Insurance Series products?",
            cnDesc: "(提示：答案可於附近的影片內找到。)",
            enDesc: "(Tips: the correct answer can be found in the video nearby.)",
            cnTips: "",
            enTips: "",
            answers: [{
                index: "A",
                isTrue: true,
                cnAnswer: "無限次",
                enAnswer: "Unlimited times",
            }, {
                index: "B",
                isTrue: false,
                cnAnswer: "5次",
                enAnswer: "5 times",
            }, {
                index: "C",
                isTrue: false,
                cnAnswer: "3次",
                enAnswer: "3 times",
            }, {
                index: "D",
                isTrue: false,
                cnAnswer: "1次",
                enAnswer: "1 time only",
            }]
        }
    ];
    const question = questions[Number(indexQues)-1] || null;

    const [modalResult, setModalResult] = React.useState(false);
    const handleOpenModalResult = () => setModalResult(true);
    const handleCloseModalResult = () => setModalResult(false);

    const [modalResultTips, setModalResultTips] = React.useState(false);
    const handleOpenModalResultTips = () => setModalResultTips(true);
    const handleCloseModalResultTips = () => setModalResultTips(false);

    const [answer, setAnswer] = React.useState(null);
    const [isSubmit, setIsSubmit] = React.useState(false);

    const selectAnswer = (item) => {
        if (isSubmit) return false;
        setAnswer(item);
    }

    const confirmQuestion = () => {
        if (!answer) {
            message.info("Select at least one item");
            return false;
        }
        handleOpenModalResult();
    }

    const submitQuestion = () => {
        setIsSubmit(true);
        handleCloseModalResult();
        if (token) {
            FtAnswer({number: Number(indexQues), answer: answer.isTrue ? 1 : 2, token: token}).then((res: any) => {
                if (!res.code) {
                    handleOpenModalResultTips();
                    setTimeout(() => {
                        handleCloseModalResultTips();
                    }, 2000);
                } else {
                    setIsSubmit(false);
                    message.info(res.message);
                }
            }).catch((e) => {
                setIsSubmit(false);
                message.info("" + e);
            });
        } else {
            handleOpenModalResultTips();
            setTimeout(() => {
                handleCloseModalResultTips();
            }, 2000);
        }
    }
    useEffect(() => {
        // document.oncontextmenu = () => { return false; }
    }, []);

    function reFlush() {
        setAnswer(null);
        setIsSubmit(false);
    }

    return (<div className="wrapper wrapper-home download-box">
        {/*<Header />*/}
        <main>
            <section className="s-hero">
                <img className="img-bg" src="assets/images/bg1.png" data-anchor-target=".s-hero"
                     data-center="transform: translateY(0%)"
                     data-top-bottom="transform: translateY(40%)" />
                <div className="container ft-q-box">
                    {
                        question && (
                            <div>
                                <div className="img-title">
                                    <img className="img-index" src={`assets/images/ft/icon-q-${question.index}.png`}/>
                                </div>
                                <div className="q-content">
                                    <div>{question.cnQues}</div>
                                    <div>{question.enQues}</div>
                                </div>
                                <div className="q-desc">
                                    <div>{question.cnDesc}</div>
                                    <div>{question.enDesc}</div>
                                </div>
                                <div className="q-select">
                                    {
                                        question.answers.map((item) => (
                                            <div key={item.index}
                                                 className={answer && answer.index === item.index ? "select-item select-selected" : "select-item"}
                                                 onClick={() => selectAnswer(item)}
                                                // onContextMenu={() => selectAnswer(item)}
                                                >
                                                <div className="select-index">
                                                    <img className="img-select" src={`assets/images/ft/qa-${item.index}.png`}/>
                                                </div>
                                                <div className="select-content">
                                                    <div>{item.cnAnswer}</div>
                                                    <div>{item.enAnswer}</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="q-desc">
                                    <div>{question.cnTips}</div>
                                    <div>{question.enTips}</div>
                                </div>
                                <div className="q-btn">
                                    {
                                        !isSubmit && (<img className="btn-img" src="assets/images/ft/btn-answer-click.png" onClick={confirmQuestion}/>)
                                    }
                                    {
                                        answer && answer.isTrue && isSubmit && (<img className="btn-img" src="assets/images/ft/btn-answer-unclick.png"/>)
                                    }
                                    {
                                        answer && !answer.isTrue && isSubmit && (<img className="btn-img" src="assets/images/ft/btn-answer-try.png" onClick={reFlush}/>)
                                    }
                                </div>
                                <div>
                                    <div>按鼠標右鍵選擇答案</div>
                                    <div>Right click to activate Cursor Mode to select answer</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
            {/*<Footer />*/}
        </main>
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={modalResult}
            onClose={handleCloseModalResult}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="modal-box modal-ft-box"
        >
            <Fade in={modalResult}>
                {
                    answer && (
                        <Box sx={style}>
                            <div className="answer-title-cn">是否確認選項？</div>
                            <div className="answer-title-en">Confirm selected this section</div>
                            <div className="answer-index"><img className="img-select" src={`assets/images/ft/qa-${answer.index}.png`}/></div>
                            <div className="answer-body">{answer.cnAnswer}</div>
                            <div className="answer-body answer-body-en">{answer.enAnswer}</div>
                            <div className="btn-box">
                                <img className="img-btn" src="assets/images/ft/btn-answer-cancel.png" onClick={handleCloseModalResult}/>
                                <img className="img-btn" src="assets/images/ft/btn-answer-confirm.png" onClick={submitQuestion}/>
                            </div>
                        </Box>
                    )
                }
            </Fade>
        </Modal>
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={modalResultTips}
            onClose={handleCloseModalResultTips}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="modal-box modal-ft-box"
        >
            <Fade in={modalResultTips}>
                <Box sx={style}>
                    {
                        answer && !answer.isTrue && (
                            <div className="answer-wrong">
                                <div className="answer-img"><img className="img-as" src="assets/images/ft/answer-wrong.png"/></div>
                                <div className="answer-cn">回答錯誤</div>
                                <div className="answer-en">INCORRECT</div>
                            </div>
                        )
                    }
                    {
                        answer && answer.isTrue && (
                            <div className="answer-right">
                                <div className="answer-img"><img className="img-as" src="assets/images/ft/answer-right.png"/></div>
                                <div className="answer-cn">回答正確</div>
                                <div className="answer-en">CORRECT</div>
                            </div>
                        )
                    }
                </Box>
            </Fade>
        </Modal>
    </div>);
}
