import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { RegisterGoogle } from './libs/api';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import storage from './libs/storage';
import { removeCookie, setCookie } from './libs/cookies';
import * as React from 'react';
import { getUrlParams } from './utils';
import {useTranslation} from "react-i18next";

export default function Register() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState('');
  const [validMsgUserName, setValidMsgUserName] = useState('');
  const [validMsgInviteCode, setValidMsgInviteCode] = useState('');
  const [validMsgPassword, setValidMsgPassword] = useState('');
  const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
  const [agreement, setAgreement] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserInfo();
    if (
      storage.getItem('userStatus') &&
      storage.getItem('userStatus') !== undefined
    ) {
      window.self.location.href = window.location.origin;
    }
  }, []);

  function getUserInfo() {
    let userName =
      storage.getItem('googleObj') && storage.getItem('googleObj').name;
    let email =
      storage.getItem('googleObj') && storage.getItem('googleObj').email;
    if (userName) {
      // @ts-ignore
      document.querySelector('#userName').value = userName;
    }
    if (email) {
      setEmail(email);
    }
  }

  function validUserName() {
    // @ts-ignore
    const val = document.querySelector('#userName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgUserName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgUserName(t("validate.long"));
      return false;
    } else {
      setValidMsgUserName('');
      return true;
    }
  }

  function validInviteCode() {
    // @ts-ignore
    const val = document.querySelector('#inviteCode').value.trim();
    if (val === '' || val === null || val === undefined || isNaN(val)) {
      setValidMsgInviteCode('');
      return true;
    }
    if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
      setValidMsgInviteCode('');
      return true;
    } else if (val.length > 6) {
      setValidMsgInviteCode(t("validate.long"));
      return false;
    } else {
      setValidMsgInviteCode('Invite Code error');
      return false;
    }
  }
  function validPassword() {
    // @ts-ignore
    const val = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPassword(t("validate.information"));
      return false;
    }
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/gi.test(val)) {
      setValidMsgPassword('');
      return true;
    } else if (val.length > 50) {
      setValidMsgPassword(t("validate.long"));
      return false;
    } else {
      setValidMsgPassword(
        t("validate.Enter1")
      );
      return false;
    }
  }
  function validPasswordConfirm() {
    // @ts-ignore
    const val = document.querySelector('#passwordConfirm').value.trim();
    // @ts-ignore
    const valRe = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPasswordConfirm(t("validate.information"));
      return false;
    }
    if (valRe !== val) {
      setValidMsgPasswordConfirm('The password is different. Please re-enter');
      return false;
    } else if (val.length > 50) {
      setValidMsgPasswordConfirm(t("validate.long"));
      return false;
    } else {
      setValidMsgPasswordConfirm('');
      return true;
    }
  }

  function register() {
    if (validPassword() && validPasswordConfirm()) {
      // @ts-ignore
      const userName = document.querySelector('#userName').value.trim();
      // @ts-ignore
      const inviteCode = document.querySelector('#inviteCode').value.trim();
      // @ts-ignore
      const password = document.querySelector('#password').value.trim();
      setIsLoading(true);
      RegisterGoogle({
        gToken: storage.getItem('gToken'),
        username: userName,
        password: password,
        inviteCode: inviteCode.toUpperCase(),
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code) {
            storage.setItem('userStatus', res.token);
            setCookie('ua_user', res.token || '');
            storage.removeItem('googleObj');
            storage.removeItem('gToken');
            toPhone();
          }
        })
        .catch(() => {
          setIsLoading(false);
          removeCookie('ua_user');
          storage.removeItem('userStatus');
        });
    }
  }
  function toPhone() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href =
        window.location.origin + '/login-phone?redirect=' + redirectUrl;
    } else {
      window.self.location.href = window.location.origin + '/login-phone';
    }
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.google.SIGN")}</span>
                </h2>

                <div className="s-flex s-flex-center-row google-register-ct">
                  <div className="row form-box google-register-body">
                    <div className="col col-12 form-item google-tips">
                      {t("page.google.We")}
                    </div>
                    {/* <div className="col col-12 form-item username">{name}</div> */}
                    <div className="col col-12 form-item form-item1 email">
                      {email}
                    </div>
                    <div className="col col-12 form-item">
                      <div className="form-label text-high-green">
                        {t("page.google.We")}
                      </div>
                      <div className="form-value">
                        <input
                          type="userName"
                          id="userName"
                          maxLength={50}
                          onBlur={validUserName}
                        />
                      </div>
                      <div className="form-valid">{validMsgUserName}</div>
                    </div>
                    <div className="col col-12 form-item">
                      <div className="form-label text-high-green">
                        {t("page.google.Invite1")} <span>{t("page.google.Invite2")}</span>
                      </div>
                      <div className="form-value">
                        <input
                          type="inviteCode"
                          id="inviteCode"
                          minLength={6}
                          maxLength={6}
                          onBlur={validInviteCode}
                        />
                      </div>
                      <div className="form-valid">{validMsgInviteCode}</div>
                    </div>
                    <div className="col col-12 form-item">
                      <div className="form-label">{t("page.google.Password")}</div>
                      <div className="form-value">
                        <input
                          type="password"
                          id="password"
                          minLength={8}
                          maxLength={50}
                          onBlur={validPassword}
                        />
                      </div>
                      {!validMsgPassword && (
                        <div className="password-tip">
                          {t("page.google.Enter")}
                        </div>
                      )}
                      {validMsgPassword && (
                        <div className="form-valid">{validMsgPassword}</div>
                      )}
                    </div>
                    <div className="col col-12 form-item">
                      <div className="form-label">{t("page.google.Confirm")}</div>
                      <div className="form-value">
                        <input
                          type="password"
                          id="passwordConfirm"
                          minLength={8}
                          maxLength={50}
                          onBlur={validPasswordConfirm}
                        />
                      </div>
                      <div className="form-valid">
                        {validMsgPasswordConfirm}
                      </div>
                    </div>
                    <div className="col col-12 form-item s-flex s-flex-center-row">
                      <button
                        onClick={register}
                        className="btn btn-default btn-form-register"
                        disabled={isLoading}
                      >
                        {t("page.google.NEXT")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={open}
        message="I love snacks"
        key="snackbar-reg-1"
      />
    </>
  );
}
