import { LuckyWheel } from "@lucky-canvas/react";
import {useRef, useState, useEffect} from "react";
import Header from "../Header";
import Footer from "../Footer";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {LotteryInfoCheck, LotteryOne} from "../libs/api";
import storage from "../libs/storage";

export default function Lottery() {
    const prizeList = [
        {img: "assets/images/mark/prize_01.png", name: "= Free Wonder Pass"},
        {img: "assets/images/mark/prize_02.png", name: "= 50% discount off"},
        {img: "assets/images/mark/prize_03.png", name: "= 35% discount off"},
        {img: "assets/images/mark/prize_04.png", name: "= 30% discount off"},
        {img: "assets/images/mark/prize_05.png", name: "= 25% discount off"},
        {img: "assets/images/mark/prize_06.png", name: "= 20% discount off"},
        {img: "assets/images/mark/prize_07.png", name: "= 15% discount off"},
        {img: "assets/images/mark/prize_08.png", name: "= 10% discount off"},
    ]
    const [defaultStyle] = useState({
        background: "#fff"
    });
    const [blocks] = useState([
        { padding: '20px', imgs: [{ src: 'assets/images/mark/bg-border.png', width: "300px", height: "300px", top: "0px", rotate: true }] },
    ])
    const [prizes] = useState([
        { id: "01", name: "Wonder Pass Gift", background: 'rgba(0, 0, 0, 0)', imgs: [{ src: 'assets/images/mark/prize_01.png', width: "70px", height: "70px" }] },
        { id: "02", name: "Discount 20", background: 'rgba(255, 255, 255, 0)', imgs: [{ src: 'assets/images/mark/prize_02.png', width: "70px", height: "70px" }] },
        { id: "03", name: "Discount 40", background: 'rgba(0, 0, 0, 0)', imgs: [{ src: 'assets/images/mark/prize_03.png', width: "70px", height: "70px" }] },
        { id: "04", name: "Discount 60", background: 'rgba(255, 255, 255, 0)', imgs: [{ src: 'assets/images/mark/prize_04.png', width: "70px", height: "70px" }] },
        { id: "05", name: "Discount 80", background: 'rgba(0, 0, 0, 0)', imgs: [{ src: 'assets/images/mark/prize_05.png', width: "70px", height: "70px" }] },
        { id: "06", name: "Discount 100", background: 'rgba(255, 255, 255, 0)', imgs: [{ src: 'assets/images/mark/prize_06.png', width: "70px", height: "70px" }] },
        { id: "07", name: "Discount 120", background: 'rgba(0, 0, 0, 0)', imgs: [{ src: 'assets/images/mark/prize_07.png', width: "70px", height: "70px" }] },
        { id: "08", name: "Discount 140", background: 'rgba(0, 0, 0, 0)', imgs: [{ src: 'assets/images/mark/prize_08.png', width: "70px", height: "70px" }] },
    ])
    const [buttons] = useState([
        {}
    ])
    const myLucky = useRef();
    const [lotteryInfo, setLotteryInfo] = useState<{
        conponName?: string;
        couponEx?: string;
        couponPrice?: string;
        name?: string;
        couponRemark?: string;
        nftImg?: string;
    }>();
    const [openFlag, setOpenFlag] = useState(0);
    const startLottery  = () => {
        if (storage.getItem('userStatus') &&
            storage.getItem('userStatus') !== undefined) {
            startLotteryPrize();
        } else {
            toLogin();
        }
    }
    const endLottery = () => {
        if (openFlag === 2) {
            openCoupon();
        }
        if (openFlag === 1) {
            openNft();
        }
    }

    const [dialogCoupon, setDialogCoupon] = useState(false);
    const openCoupon = () => { setDialogCoupon(true); }
    const closeCoupon = () => { setDialogCoupon(false); }
    const [dialogNft, setDialogNft] = useState(false);
    const openNft = () => { setDialogNft(true); }
    const closeNft = () => { setDialogNft(false); }

    const [isLottery, setIsLottery] = useState(true);
    const checkLottery = () => {
        LotteryInfoCheck().then((res: any) => {
            setIsLottery(res.isLottery);
        })
    }
    const startLotteryPrize = () => {
        LotteryInfoCheck().then((res: any) => {
            setIsLottery(res.isLottery);
            if (res.isLottery) {
                // @ts-ignore
                myLucky.current.play();

                getLottery();
            }
        })
    }
    const getLottery = () => {
        LotteryOne({lotteryId: "10001"}).then((res: any) => {
            if (!res.code) {
                setIsLottery(false);
                setOpenFlag(Number(res.prizeType));
                setLotteryInfo(res.prizeDetail);
                setTimeout(() => {
                    const index = prizes.findIndex(({name}) => name === res.prizeName);
                    // @ts-ignore
                    myLucky.current.stop(index);
                }, 2000);
            } else {
                // @ts-ignore
                myLucky.current.stop(0);
            }
        }).catch(() => {
            // @ts-ignore
            myLucky.current.stop(0);
        });
    }
    useEffect(() => {
        if (storage.getItem('userStatus') &&
            storage.getItem('userStatus') !== undefined) {
            checkLottery();
        }
    }, []);
    const toLogin = () => {
        window.location.href = window.location.origin + '/login?redirect=/lottery';
    }
    const toList = () => {
        window.location.href = window.location.origin + "/lottery-list";
    }
    const toAgreement = () => {
        window.location.href = window.location.origin + "/lottery-agree";
    }
    const toSale = () => {
        window.location.href = window.location.origin + "/hello-kitty#hk_box";
    }
    const toUser = () => {
        window.location.href = window.location.origin + "/user";
    }
    return <>
        <div className="hello-kitty mark-lottery">
            <div className="wrapper">
                <Header />
                <main className="hello-kitty-bg-fixed">
                    <section className="hk-info hk-bg-ico">
                        <div className="s-flex s-flex-center-row lottery-logo">
                            <img className="logo-img" src="assets/images/mark/logo.png" alt=""/>
                        </div>
                        <div className="lottery-raffle">
                            <div className="s-flex s-flex-center-row lottery-pointer">
                                <img className="pointer-img" src="assets/images/mark/bg-pointer.png" alt=""/>
                            </div>
                            <div className="s-flex s-flex-center-row lottery-prizes">
                                <LuckyWheel
                                    className="prizes-option"
                                    ref={myLucky}
                                    width="300px"
                                    height="300px"
                                    defaultStyle={defaultStyle}
                                    blocks={blocks}
                                    prizes={prizes}
                                    buttons={buttons}
                                    onStart={startLottery}
                                    onEnd={endLottery}
                                />
                            </div>
                            <div className="s-flex s-flex-center-row lottery-info">
                                <div className="info-option">
                                    <div className="s-flex s-flex-center-row text-primary-title option-title">WONDER PASS ROULETTE </div>
                                    {/*<div className="s-flex s-flex-center-row text-primary-title option-sub-title">WONDER PASS ROULETTE SPIN</div>*/}
                                    <div className="s-flex s-flex-center-row option-btn">
                                        {
                                            isLottery ? <div className="s-flex s-flex-center-row btn-full btn-large" onClick={startLottery}>SPIN</div>
                                            :
                                                <div className="s-flex s-flex-center-row btn-full btn-large_un">SPIN</div>
                                        }
                                    </div>
                                    <div className="s-flex s-flex-center-row option-btn">
                                        <div className="s-flex s-flex-center-row btn-half btn-color" onClick={toList}>MY PRIZE</div>
                                        <div className="s-flex s-flex-center-row btn-half btn-green" onClick={toAgreement}>RULES</div>
                                    </div>
                                    <div className="s-flex s-flex-center-row option-btn">
                                        <div className="option-line"></div>
                                    </div>
                                    <div className="s-flex s-flex-center-row prize-list-title">
                                        Prize List
                                    </div>
                                    <div className="prize-list">
                                        {
                                            prizeList.map((item, index) => (
                                                <div className={`s-flex s-flex-center-row prize-list-item ${index === 0 ? "prize-list-item-one" : ""}`}>
                                                    <img className="prize-item-img" src={item.img} alt=""/>
                                                    <div className="prize-item-text">{item.name}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </div>

        {/*优惠券*/}
        <Dialog onClose={closeCoupon} open={dialogCoupon}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-lottery">
                <div className="modal-body modal-body-hk">
                    <div className="s-flex s-flex-center-row modal-title">
                        <img className="s-cursor-pointer btn-close" src="./assets/images/modal/dialog-close.png" alt="" onClick={closeCoupon}/>
                    </div>
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row">
                            <img className="lottery-sub-title-img" src="./assets/images/modal/dialog-title-congrats.png" alt="" />
                        </div>
                        <img  className="dialog-light" src="./assets/images/modal/dialog-light1.png" alt=""/>
                        <div className="lottery-content">
                            <div className="s-flex s-flex-center-row lottery-sub-title-desc">
                                You received
                            </div>
                            <div className="lottery-content_info lottery-content_info_cp">
                                <div className="s-flex s-flex-center-row coupon-text-type">COUPON</div>
                                <div className="s-flex s-flex-center-row coupon-text-sale">{lotteryInfo && lotteryInfo.couponRemark}% OFF</div>
                                <div className="s-flex s-flex-center-row coupon-text-desc">For Purchasing Wonder Pass</div>
                                <div className="s-flex s-flex-center-row coupon-text-date">Expiry Date: { lotteryInfo && lotteryInfo.couponEx }</div>
                            </div>
                        </div>
                        <div className="s-flex s-flex-center-row modal-btn">
                            <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-confirm" onClick={toSale}>GO TO PURCHASE</div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*nft*/}
        <Dialog onClose={closeNft} open={dialogNft}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-lottery">
                <div className="modal-body modal-body-hk">
                    <div className="s-flex s-flex-center-row modal-title">
                        <img className="s-cursor-pointer btn-close" src="./assets/images/modal/dialog-close.png" alt="" onClick={closeNft}/>
                    </div>
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row">
                            <img className="lottery-sub-title-img" src="./assets/images/modal/dialog-title-congrats.png" alt="" />
                        </div>
                        <img  className="dialog-light" src="./assets/images/modal/dialog-light.png" alt=""/>
                        <div className="lottery-content">
                            <div className="s-flex s-flex-center-row lottery-sub-title-desc">
                                You received
                            </div>
                            <div className="s-flex s-flex-center-row lottery-content_info">
                                <img  className="dialog-lottery_nft" src={lotteryInfo && lotteryInfo.nftImg} alt=""/>
                            </div>
                        </div>
                        <div className="s-flex s-flex-center-row modal-btn">
                            <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-confirm" onClick={toUser}>CHECK</div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>;
}
