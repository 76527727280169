import Header from "../Header";
import Footer from "../Footer";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

function Download() {
	const { t } = useTranslation();

	const [tabIndex, setTabIndex] = useState(0);
	const videoDom = useRef(null);
	const [videoHeight, setVideoHeight] = useState("");
	useEffect(() => {
		// @ts-ignore
		const bw = document.querySelector("body").clientWidth;
		if (videoDom && window.matchMedia("(min-width: 750px)").matches) {
			// @ts-ignore
			videoDom.current.addEventListener("loadedmetadata", () => {
				// @ts-ignore
				const vh = document.querySelector(".video-box").clientHeight;
				setVideoHeight(vh * 0.8 + "px");
			});
		}
	}, [])
	return (
		<div className="wrapper wrapper-home download-box">
			<Header />
			<main>
				<section className="s-hero">
					<img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-hero"
						data-center="transform: translateY(0%)"
						data-top-bottom="transform: translateY(40%)" />
					<div className="container">
						<div className="box-planet" style={{ width: "100%" }}>
							<div className="video-box" style={{ width: "100%", height: videoHeight }}>
								<img className="img-planet" src="assets/images/planet_v3_1.png?v=20230413001" style={{height: 0}} />
								<div className="video-hi" style={{height: 0}}>
									<video autoPlay muted loop id="hero_video" playsInline ref={videoDom}>
										<source src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/movie_011_hd-hb2.mp4?v=20230413001" type="video/mp4" />
									</video>
								</div>
							</div>

							<div className="box-title" data-anchor-target=".s-hero"
								data-center="transform: translateX(-50%) translateY(-50%); opacity(1)"
								data-top-bottom="transform: translateX(-50%) translateY(-100%); opacity(0)">
								<div><img className="img-metagaia" src="assets/images/logo_metagaia_ft.png?v=20230413001" /></div>
								<div className="download-title download-title-h">JOIN FTLIFE METAVERSE EXPERIENCE</div>
								<div className="download-link">
									<div className="s-flex s-flex-center-row s-cursor-pointer download-select" onClick={() => {
										window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FTlife/MetaGaiaClientWindows.zip";
									}}>
										<img className="download-select-icon" src="./assets/images/ico/icon-windows.png?v=20230413001" />
										<span className="s-flex s-flex-center-row">DOWNLOAD</span>
									</div>
									<div className="s-flex s-flex-center-row s-cursor-pointer download-select" onClick={() => {
										window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FTlife/MetaGaiaClient.pkg";
									}}>
										<img className="download-select-icon" src="./assets/images/ico/icon-mac.png?v=20230413001" />
										<span className="s-flex s-flex-center-row">DOWNLOAD</span>
									</div>
								</div>

							</div>

						</div>
					</div>
				</section>

				<section className="download-info ft-download-info">
					<div className="container">
					<div className="row download-tabs">
						<div className="col col-12 s-flex s-flex-between-row">
							<div className="s-flex s-flex-center-start-row">
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 0 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(0)}}>
									<img className="" src="./assets/images/ico/icon-windows.png?v=20230413001" />
									<span className="s-flex s-flex-center-row">Windows</span>
								</div>
								<div className={`s-flex s-flex-center-row s-cursor-pointer tabs-item ${tabIndex === 1 ? 'tabs-active' : ''}`} onClick={() => {setTabIndex(1)}}>
									<img className="" src="./assets/images/ico/icon-mac.png?v=20230413001" />
									<span className="s-flex s-flex-center-row">Mac</span>
								</div>
							</div>
							{/*<div className="download-link" style={{marginTop: 0, justifyContent: "flex-end"}}>*/}
							{/*	{*/}
							{/*		tabIndex === 0 && <div className="s-flex s-flex-center-row s-cursor-pointer download-select-bg" onClick={() => {*/}
							{/*			window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FTlife/MetaGaiaClientWindows.zip";*/}
							{/*		}} style={{margin: 0}}>*/}
							{/*			<img className="download-icon" src="./assets/images/ft-dwn-icon.png?v=20230413001" />*/}
							{/*			<span className="s-flex s-flex-center-row">DOWNLOAD</span>*/}
							{/*		</div>*/}
							{/*	}*/}
							{/*	{*/}
							{/*		tabIndex === 1 && <div className="s-flex s-flex-center-row s-cursor-pointer download-select-bg" onClick={() => {*/}
							{/*			window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FTlife/MetaGaiaClient.app.zip";*/}
							{/*		}} style={{margin: 0}}>*/}
							{/*			<img className="download-icon" src="./assets/images/ft-dwn-icon.png?v=20230413001" />*/}
							{/*			<span className="s-flex s-flex-center-row">DOWNLOAD</span>*/}
							{/*		</div>*/}
							{/*	}*/}
							{/*</div>*/}
						</div>
					</div>
					{
						tabIndex === 0 && <div className="row download-tabs-body">
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Operating System</div>
								<div className="tabs-body-desc">64-bit Windows 10</div>
								<div className="tabs-body-title-sub">Memory</div>
								<div className="tabs-body-desc">8 GB</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Processor (AMD)</div>
								<div className="tabs-body-desc">Ryzen 3 @ 3.1 GHz or better</div>
								<div className="tabs-body-title-sub">Processor (Intel)</div>
								<div className="tabs-body-desc">Intel Core i5 @ 3.3 GHz</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Graphics card (AMD)</div>
								<div className="tabs-body-desc">Radeon RX 5300</div>
								<div className="tabs-body-title-sub">Graphics card (NVIDIA)</div>
								<div className="tabs-body-desc">NVIDIA GTX 1650</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Online Connection Requirement</div>
								<div className="tabs-body-desc">512 KB/sec</div>
								<div className="tabs-body-title-sub">Hard-drive space</div>
								<div className="tabs-body-desc">4 GB</div>
							</div>
						</div>
					}
					{
						tabIndex === 1 && <div className="row download-tabs-body">
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Operating System</div>
								<div className="tabs-body-desc">macOS 10.15 Catalina</div>
								<div className="tabs-body-title-sub">Hard-drive space</div>
								<div className="tabs-body-desc">4 GB</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Processor</div>
								<div className="tabs-body-desc">Apple M1, Intel i7 model year 2018</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Memory</div>
								<div className="tabs-body-desc">8 GB</div>
							</div>
							<div className="col col-12 col-lg-3">
								<div className="tabs-body-title-sub">Online Connection Requirement</div>
								<div className="tabs-body-desc">512 KB/sec</div>
							</div>
						</div>
					}
					</div>
				</section>
				<Footer />
			</main>
		</div>
	);
}

export default Download;
