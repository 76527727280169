import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import {useState, useEffect} from "react";
import * as React from "react";
import {urlToObj} from "../libs/api";
import message from "../components/Message";
import useClipboard from "react-use-clipboard";
import {useTranslation} from "react-i18next";

function InviteChannel() {
	const { t } = useTranslation();

	const [inviteCode, setInviteCode] = useState("");
	const [copyAdd, setCopyAdd] = useClipboard(inviteCode, {
		successDuration: 1000,
	});
	useEffect(() => {
		setInviteCode(urlToObj(window.location.href).id);
	}, []);


	return (
		<div className="bg-hexahero bg-faq invite-code">
			<Helmet>
				<title>MetaGaia Metaverse FAQs & Support</title>
				<meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
				<meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
			</Helmet>

			<div className="wrapper">
				<Header />

				<main>
					<section className="s-nft s-default">
						<div className="container">
							<div className="box-title wow fadeInUp s-nft-hero">
								<img className="img-title" src="./assets/images/game/title-invitecode.png?v=20230413001" />

								<div className="container">
									<div className="invite-code-info">
										<img className="invite-code-img" alt="" src="./assets/images/game/invite-code-bg2.png?v=20230413001" />
										<div className="invite-code-infos">
											<div className="s-flex s-flex-center-row infos-title">
												<img className="infos-title-img" alt="" src="./assets/images/game/invite-code-title.png?v=20230413001" />
											</div>
											<div className="s-flex s-flex-center-row infos-title-sub infos-title-margin">
												<span className="infos-title-sub-blod">{t("page.channel.Congratulations")}</span>  100METG
											</div>
											<div className="s-flex s-flex-center-row infos-tips">{t("page.channel.Invite")}</div>
											<div className="s-flex s-flex-center-row infos-btn">
												<div className="s-flex s-flex-center-row infos-btn-txt">
													<span>{inviteCode}</span>
													<img className="s-cursor-pointer infos-copy" src="./assets/images/ico/icon-copy2.png" alt=""
														 onClick={() => {
															 message.info(t("page.channel.Copied"));
															 setCopyAdd();
														 }}/>
												</div>
											</div>
											<div className="s-flex s-flex-center-row infos-tips">{t("page.channel.Download")}</div>
											<div className="s-flex s-flex-center-row infos-tips">{t("page.channel.and")}</div>
											<div className="s-flex s-flex-center-row infos-tips">{t("page.channel.See")}</div>
											<div className="s-flex s-flex-center-row infos-down">
												<div className="s-flex s-flex-center-row s-cursor-pointer infos-down-btn" onClick={() => {
													window.location.href = "https://apps.apple.com/hk/app/metagaia/id1638854848?l=en";
												}}>
													<img className="infos-down-icon" alt="" src="./assets/images/ico/icon-mac-invite.png?v=20230413001" />
												</div>
												<div className="s-flex s-flex-center-row s-cursor-pointer infos-down-btn" onClick={() => {
													window.location.href = "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/Metagaia.apk";
												}}>
													<img className="infos-down-icon" alt="" src="./assets/images/ico/icon-android-invite.png?v=20230413001" />
												</div>
											</div>
										</div>
									</div>
									<div className="invite-code-title">{t("page.channel.THE1")} <span className="invite-code-title-primary"> {t("page.channel.THE2")} </span> {t("page.channel.THE3")}</div>
									<div className="invite-code-desc">{t("page.channel.Enter")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-1.png?v=20230413001" alt="" />
									</div>
									<div className="invite-code-title"><span className="invite-code-title-primary">{t("page.channel.CREATE1")} </span> {t("page.channel.CREATE2")}</div>
									<div className="invite-code-title">{t("page.channel.AVATAR1")} <span className="invite-code-title-primary">{t("page.channel.AVATAR2")}</span> {t("page.channel.AVATAR3")}</div>
									<div className="invite-code-desc">{t("page.channel.Step")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-2.png?v=20230413001" alt="" />
									</div>
									<div className="invite-code-title"><span className="invite-code-title-primary">{t("page.channel.ENGAGE1")}</span> {t("page.channel.ENGAGE2")}</div>
									<div className="invite-code-desc">{t("page.channel.With")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-3.png?v=20230413001" alt="" />
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</main>
			</div>
		</div>
	);
}

export default InviteChannel;
