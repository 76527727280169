import { Wallet } from '../interfaces/wallet';

export const GET_WALLET = 'GET_WALLET';

export interface GetWalletStateType {
    wallet: Wallet;
  }

interface GetWalletActionType {
  type: typeof GET_WALLET;
  payload: Wallet;
}
export type WalletActionType = GetWalletActionType;