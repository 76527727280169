import * as React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useState} from "react";

export default function Matrix() {
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} arrow placement="top"/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            borderRadius: 0,
            backgroundColor: '#000000',
            maxWidth: 426,
            fontSize: theme.typography.pxToRem(16),
            border: '1px solid #00FFFF',
        },
        [`& .${tooltipClasses.arrow}`]: {
            border: 'none',
        },
        [`& .${tooltipClasses.arrow}::after`]: {
            border: 'none',
        },
        [`& .${tooltipClasses.arrow}::before`]: {
            backgroundColor: "#000",
            borderTop: 'none',
            borderBottom: '1px solid #00FFFF',
            borderLeft: 'none',
            borderRight: '1px solid #00FFFF',
        },
    }));
    const [showDetail, setShowDetail] = useState(false);
    const [showDetailIndex, setShowDetailIndex] = useState(1);
    const detailList = [
        {
            imgGif: "./assets/images/matrix/enterbay.gif",
            img: "./assets/images/matrix/detail/enterbay.png",
            imgSmall: "./assets/images/matrix/small/enterbay.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "Enterbay",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/hexahero.gif",
            img: "./assets/images/matrix/detail/hexahero.png",
            imgSmall: "./assets/images/matrix/small/hexahero.png",
            imgBtn: "./assets/images/matrix/matrix-btn-new.png",
            isBtnDis: false,
            title: "HexaHero Beta",
            info: ["Single-Player", "GAME"],
            desc: "Build. Battle. Earn. First game of the ultimate experience-to-earn metaverse: MetaGaia.",
            url: "https://hexahero.metagaia.io/game"
        },
        {
            imgGif: "./assets/images/matrix/soleaddictt.gif",
            img: "./assets/images/matrix/detail/soleaddictt.png",
            imgSmall: "./assets/images/matrix/small/soleaddictt.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "SoleAddict",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/storm.gif",
            img: "./assets/images/matrix/detail/storm.png",
            imgSmall: "./assets/images/matrix/small/storm.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "Storm Collectibles",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/legends.gif",
            img: "./assets/images/matrix/detail/legends.png",
            imgSmall: "./assets/images/matrix/small/legends.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "Legends DIGITAL ASSET",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/ftlife.gif",
            img: "./assets/images/matrix/detail/ftlife.png",
            imgSmall: "./assets/images/matrix/small/ftlife.png",
            imgBtn: "./assets/images/matrix/matrix-btn-new.png",
            isBtnDis: false,
            title: "FTLife Open Day",
            info: ["Multiplayer", "SOCIAL"],
            desc: "FTLife builds the first virtual tower in Metaverse crossing boundaries of time and space for novel experiences.",
            url: "/FT_download"
        },
        {
            imgGif: "./assets/images/matrix/metgallery.gif",
            img: "./assets/images/matrix/detail/metgallery.png",
            imgSmall: "./assets/images/matrix/small/metgallery.png",
            imgBtn: "./assets/images/matrix/matrix-btn-new.png",
            isBtnDis: false,
            title: "METGallery",
            info: ["Single-Player", "SHOP"],
            desc: "Showcase digital collections and experience a new way of shopping in MetaGaia. Remember to test drive the Hexa!",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/gaiabeach.gif",
            img: "./assets/images/matrix/detail/gaiabeach.png",
            imgSmall: "./assets/images/matrix/small/gaiabeach.png",
            imgBtn: "./assets/images/matrix/matrix-btn-new.png",
            isBtnDis: false,
            title: "Gaia Beach",
            info: ["Single-Player", "SOCIAL"],
            desc: "Redefine airdrop: meet our spectacular whale airdropping $METG, catch it if you can!",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/taiksake.gif",
            img: "./assets/images/matrix/detail/taiksake.png",
            imgSmall: "./assets/images/matrix/small/taiksake.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "Taiko Sake",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
        {
            imgGif: "./assets/images/matrix/washi.gif",
            img: "./assets/images/matrix/detail/washi.png",
            imgSmall: "./assets/images/matrix/small/washi.png",
            imgBtn: "./assets/images/matrix/matrix-btn-un.png",
            isBtnDis: true,
            title: "Washi",
            info: ["Multiplayer", "SHOP"],
            desc: "",
            url: "/download"
        },
    ];
    const changeShowDetail = (index: number) => {
        setShowDetailIndex(index-1);
        setShowDetail(true);
    }
    const closeDialog = () => {
        setShowDetail(false);
    }
    const toUrl = (url: string) => {
        if (url.includes("http"))
            window.location.href = url;
        else
            window.location.href = window.location.origin + url;
    }
    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-paper s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/matrix/bg-matrix.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container">
                                <h2 className="wow fadeInUp" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                    <div className="col col-14 col-lg-6 col-img"><img src="assets/images/matrix/title-matrix.png?v=20230413001" style={{ width: "100%", height: "100%" }}/></div>
                                </h2>
                                <div className="matrix-images">
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[0].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[0].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[0].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[0].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[0].imgGif} className="matrix-img matrix-i1" onClick={() => changeShowDetail(1)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[1].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[1].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[1].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[1].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[1].imgGif} className="matrix-img matrix-i2" onClick={() => changeShowDetail(2)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[2].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[2].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[2].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[2].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[2].imgGif} className="matrix-img matrix-i3" onClick={() => changeShowDetail(3)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[3].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[3].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[3].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[3].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[3].imgGif} className="matrix-img matrix-i4" onClick={() => changeShowDetail(4)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[4].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[4].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[4].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[4].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[4].imgGif} className="matrix-img matrix-i5" onClick={() => changeShowDetail(5)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[5].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[5].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[5].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[5].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[5].imgGif} className="matrix-img matrix-i6" onClick={() => changeShowDetail(6)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[6].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[6].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[6].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[6].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[6].imgGif} className="matrix-img matrix-i7" onClick={() => changeShowDetail(7)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[7].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[7].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[7].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[7].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[7].imgGif} className="matrix-img matrix-i8" onClick={() => changeShowDetail(8)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[8].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[8].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[8].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[8].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[8].imgGif} className="matrix-img matrix-i9" onClick={() => changeShowDetail(9)}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className="matrix-tips">
                                                    <div><img src={detailList[9].imgSmall} className="tips-img"/></div>
                                                    <div className="matrix-ct">
                                                        <div className="matrix-title">{detailList[9].title}</div>
                                                        <div className="matrix-info">
                                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                                            <span>{detailList[9].info[0]}</span>
                                                            <span>|</span>
                                                            <span>{detailList[9].info[1]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={detailList[9].imgGif} className="matrix-img matrix-i10" onClick={() => changeShowDetail(10)}/>
                                    </HtmlTooltip>
                                </div>
                                {
                                    showDetail && <div className="matrix-dwn">
                                        <div className="matrix-dwn-close">
                                            <img
                                                src="./assets/images/icon-modal-close.png"
                                                onClick={closeDialog}
                                            />
                                        </div>
                                        <div className="matrix-dwn-img">
                                            <img src={detailList[showDetailIndex].img} className=""/>
                                        </div>
                                        <div className="matrix-dwn-title">{ detailList[showDetailIndex].title }</div>
                                        <div className="matrix-dwn-info">
                                            <img src="./assets/images/matrix/matrix-icon.png"/>
                                            <span>{ detailList[showDetailIndex].info[0] }</span>
                                            <span>|</span>
                                            <span>{ detailList[showDetailIndex].info[1] }</span>
                                        </div>
                                        <div className="matrix-dwn-desc">
                                            { detailList[showDetailIndex].desc }
                                        </div>
                                        <div className="matrix-dwn-btn">
                                            {
                                                detailList[showDetailIndex].isBtnDis && <img src={detailList[showDetailIndex].imgBtn}/>
                                            }
                                            {
                                                !detailList[showDetailIndex].isBtnDis && <img src={detailList[showDetailIndex].imgBtn} onClick={() => toUrl(detailList[showDetailIndex].url)}/>
                                            }
                                        </div>
                                        {/*<div className="matrix-dwn-bg" onClick={closeDialog}></div>*/}
                                    </div>
                                }
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
}
