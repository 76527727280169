import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import * as React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ShopOrderDetail, urlToObj} from "./libs/api";
import {useEffect, useRef, useState} from "react";
import enums from "./libs/enums";
import DialogPay from "./components/DialogPay";

export default function UserGiftDetail() {

  function createData(
      unityResourcesName: string,
      unityResourcesId: string,
      totalPrice: string,
      spec: string,
      quantity: number,
      name: string,
      hkPrice: string,
      description: string,
      coverImg: string,
  ) {
    return { unityResourcesName, unityResourcesId, totalPrice, spec, quantity, name, hkPrice, description, coverImg };
  }

  const [detailData, setDetailData] = useState<any>({});
  const [productData, setProductData] = useState<any[]>([]);
  const getDetail = (id: any) => {
    ShopOrderDetail({id: id}).then((res: any) => {
      if (!res.code) {
        setDetailData(res);
        setProductData(res.products || []);
      }
    });
  }

  // 跳转个人中心
  const toUser = () => {
    window.self.location.href = window.location.origin + '/user';
  }

  useEffect(() => {
    // @ts-ignore
    const params: any = urlToObj(window.location.href);
    if (Object.keys(params).length > 0) {
      getDetail(params.id);
    } else {
      toUser();
    }
  }, []);

  // 支付周边商品
  const dialogPayRef = useRef(null);
  const openPayDialog = (rel: any) => {
    dialogPayRef.current.openPayType(rel);
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
                <div className="container user-box user-gift-detail">
                  <div className="user-gift-status">State: {enums.EnumGiftOrderStatus[detailData && detailData.status]}</div>
                  <div className="user-gift-info">
                    <div className="user-gift-body">
                      <div className="user-gift-title">Delivery Address：</div>
                      <div className="user-gift-text">{detailData && detailData.address && detailData.address.name}</div>
                      <div className="user-gift-text">{detailData && detailData.address && detailData.address.mobile}</div>
                      <div className="user-gift-text">
                        {detailData && detailData.address && detailData.address.district} {detailData && detailData.address && detailData.address.address}
                      </div>
                      <div className="user-gift-title">Order Information</div>
                      <div className="user-gift-text">Order Code: {detailData && detailData.id}</div>
                      <div className="user-gift-text">Created Time: {detailData && detailData.createdAt}</div>
                    </div>
                    <div className="s-flex s-flex-center-row user-gift-option">
                      {
                          detailData && detailData.status === 1 && <button className="btn btn-default s-btn-border-none user-gift-btn" onClick={() => openPayDialog({orderId: detailData.id, orderType: "3"})}>PAY</button>
                      }
                      {
                          detailData && detailData.status !== 1 && <div className="user-gift-btn"></div>
                      }
                    </div>
                  </div>
                  <div className="user-gift-table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Item</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="center">Type</TableCell>
                          <TableCell align="center">Price</TableCell>
                          <TableCell align="center">Discount</TableCell>
                          <TableCell align="center">Paid</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productData.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell scope="row" style={{width: "5rem"}}>
                                <img className="user-gift-img" src={row.specImage} alt=""/>
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="center">{row.spec}</TableCell>
                              <TableCell align="center">HK$ {row.totalPrice}</TableCell>
                              <TableCell align="center">HK$ {row.discountPrice}</TableCell>
                              <TableCell align="center">HK$ {row.payPrice}</TableCell>
                              <TableCell align="center">{row.quantity}</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <DialogPay ref={dialogPayRef}></DialogPay>
    </>
  );
}
