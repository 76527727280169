import {useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import copy from 'copy-to-clipboard';
import { getChatHis } from "../libs/api";
import message from '../components/Message';
import "./Questions.css";
export default function Questions() {
    const params = useParams();
    const [hisList, setHisList] = useState<any[]>([]);
    function getData() {
        getChatHis({downloadMark: params.id}).then((res: any) => {
            if (!res.code) {
                setHisList(res.rows);
            }
        });
    }
    function copyChange(text: string) {
        message.info("Copied");
        copy(text);
    }

    useEffect(() => {
       getData();
    }, []);

    return (
        <div className="wrapper wrapper-home ai-message-box">
            <main>
                <section className="ai-message">
                    <div className='ai-message-title'>Gaia Chat</div>
                    <div className="wow fadeInUp container">
                        {
                            hisList && hisList.map((item, index) =>
                                <div className="wow fadeInUp msg-his-box" key={index}>
                                    <div className="wow fadeInUp msg-his-item item-question">
                                        <div className="item-question-ico"></div>
                                        <div className="item-msg-ct item-question-ct">
                                            <div>{item.content}</div>
                                            <div className="item-question-pt"></div>
                                        </div>
                                        <img className="msg-copy" src="/assets/images/ico/icon-copy1.png" alt="copy" onClick={() => {
                                            copyChange(item.content);
                                        }}/>
                                    </div>
                                    {
                                        item.reply && <div className="wow fadeInUp msg-his-item item-answer">
                                        <div className="item-answer-ico"></div>
                                        <div className="item-msg-ct item-answer-ct">
                                          <div style={{color: "#38FFFF"}}>Chat1</div>
                                          <div>{item.reply}</div>
                                          <div className="item-answer-pt"></div>
                                        </div>
                                        <img className="msg-copy" src="/assets/images/ico/icon-copy1.png" alt="copy" onClick={() => {
                                            copyChange(item.reply);
                                        }}/>
                                      </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                </section>
            </main>
        </div>
    )
}
