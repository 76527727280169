import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { EmailCode, RegisterNew, LoginNew } from './libs/api';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import storage from './libs/storage';
import { removeCookie, setCookie } from './libs/cookies';
import * as React from 'react';
import { getUrlParams } from './utils';
import {useTranslation} from "react-i18next";

const timeNum = 120;
export default function Register() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [validMsgUserName, setValidMsgUserName] = useState('');
  const [validMsgFirstName, setValidMsgFirstName] = useState('');
  const [validMsgLastName, setValidMsgLastName] = useState('');
  const [validMsgEmail, setValidMsgEmail] = useState('');
  const [validMsgEmailCode, setValidMsgEmailCode] = useState('');
  const [validMsgInviteCode, setValidMsgInviteCode] = useState('');
  const [validMsgPassword, setValidMsgPassword] = useState('');
  const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickEmailMsg, setIsClickEmailMsg] = useState(t("button.CODE"));
  const [isClickEmailNum, setIsClickEmailNum] = useState(timeNum);
  const [isLoading, setIsLoading] = useState(false);
  const [agreement, setAgreement] = useState(true);

  useEffect(() => {
    getInviteCode();
    if (storage.getItem('userStatus') && storage.getItem('userStatus') !== undefined) {
      window.self.location.href = window.location.origin;
    }
  }, []);

  function getInviteCode() {
    let code = getUrlParams('code', window.self.location.search);
    // @ts-ignore
    document.querySelector('#inviteCode').value = code;
  }

  function validUserName() {
    // @ts-ignore
    const val = document.querySelector('#userName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgUserName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgUserName(t("validate.long"));
      return false;
    } else {
      setValidMsgUserName('');
      return true;
    }
  }
  function validFirstName() {
    // @ts-ignore
    const val = document.querySelector('#firstName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgFirstName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgFirstName(t("validate.long"));
      return false;
    } else {
      setValidMsgFirstName('');
      return true;
    }
  }
  function validLastName() {
    // @ts-ignore
    const val = document.querySelector('#lastName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgLastName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgLastName(t("validate.long"));
      return false;
    } else {
      setValidMsgLastName('');
      return true;
    }
  }
  function validEmail() {
    // @ts-ignore
    const val = document.querySelector('#email').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgEmail(t("validate.information"));
      return false;
    }
    if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(val)) {
      setValidMsgEmail('');
      return true;
    } else if (val.length > 100) {
      setValidMsgEmail(t("validate.long"));
      return false;
    } else {
      setValidMsgEmail(t("validate.address"));
      return false;
    }
  }
  function validEmailCode() {
    // @ts-ignore
    const val = document.querySelector('#emailCode').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgEmailCode(t("validate.information"));
      return false;
    } else if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
      setValidMsgEmailCode('');
      return true;
    } else if (val.length > 6) {
      setValidMsgEmailCode(t("validate.long"));
      return false;
    } else {
      setValidMsgEmailCode(t("validate.code"));
      return false;
    }
  }
  function validInviteCode() {
    // @ts-ignore
    const val = document.querySelector('#inviteCode').value.trim();
    if (val === "" || val === null || val === undefined || isNaN(val)) {
      setValidMsgInviteCode('');
      return true;
    }
    // if (val && /^[a-zA-Z\d]{50}$/gi.test(val)) {
    //   setValidMsgInviteCode('');
    //   return true;
    // } else {
    //   setValidMsgInviteCode(t("validate.Referral"));
    //   return false;
    // }
  }
  function validPassword() {
    // @ts-ignore
    const val = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPassword(t("validate.information"));
      return false;
    }
    // if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/ig.test(val)) {
    if (val.length > 7 || val.length < 51) {
      setValidMsgPassword('');
      return true;
    } else if (val.length > 50) {
      setValidMsgPassword(t("validate.long"));
      return false;
    } else {
      setValidMsgPassword(t("validate.passwordNumbers"));
      return false;
    }
  }
  function validPasswordConfirm() {
    // @ts-ignore
    const val = document.querySelector('#passwordConfirm').value.trim();
    // @ts-ignore
    const valRe = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPasswordConfirm(t("validate.information"));
      return false;
    }
    if (valRe !== val) {
      setValidMsgPasswordConfirm(t("validate.different"));
      return false;
    } else if (val.length > 50) {
      setValidMsgPasswordConfirm(t("validate.long"));
      return false;
    } else {
      setValidMsgPasswordConfirm('');
      return true;
    }
  }
  function getEmailCode() {
    // @ts-ignore
    let emailVal = document.querySelector('#email').value.trim();
    if (!validEmail()) {
      return false;
    }
    setValidMsgEmailCode('');
    // setOpen(true);
    EmailCode({ email: emailVal, type: 1 }).then();
    let time = timeNum;
    setIsClickEmail(true);
    const interval = setInterval(() => {
      setIsClickEmailNum(time--);
      setIsClickEmailMsg(time + 's');
      if (time < 0) {
        setIsClickEmail(false);
        setIsClickEmailNum(timeNum);
        setIsClickEmailMsg(t("button.CODE"));
        clearInterval(interval);
      }
    }, 1000);
    return true;
  }
  function register() {
    if (
      validUserName() &&
      validPassword() &&
      validPasswordConfirm()
    ) {
      // @ts-ignore
      const inviteCode = document.querySelector('#inviteCode').value.trim();
      // @ts-ignore
      const password = document.querySelector('#password').value.trim();
      // @ts-ignore
      const userName = document.querySelector('#userName').value.trim();
      setIsLoading(true);
      RegisterNew({
        username: userName,
        inviteCode: inviteCode,
        password: password,
      }).then((res: any) => {
        setIsLoading(false);
        if (!res.code) {
          loginUser();
        }
      }).catch(() => {
        setIsLoading(false);
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }
  function loginUser() {
    // @ts-ignore
    const userName = document.querySelector('#userName').value.trim();
    // @ts-ignore
    const password = document.querySelector('#password').value.trim();
    LoginNew({
      username: userName,
      password: password,
    })
      .then((res: any) => {
        storage.setItem('userStatus', res.token);
        setCookie('ua_user', res.token || '');
        toHome();
      })
      .catch(() => {
        removeCookie('ua_user');
        storage.removeItem('userStatus');
      });
  }
  function toHome() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
        redirectUrl &&
        !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href = window.location.origin + redirectUrl;
    } else {
      window.self.location.href = window.location.origin;
    }
  }
  function toPhone() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
        redirectUrl &&
        !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href = window.location.origin + '/login-phone?redirect=' + redirectUrl;
    } else {
      window.self.location.href = window.location.origin + '/login-phone';
    }
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.register.SIGN")}</span>
                </h2>

                  <div className="s-flex s-flex-center-row register-ct">
                      <div className="row form-box register-body">
                    <div className="col col-12 form-item">
                              <div className="form-label">{t("page.register.Name")}</div>
                              <div className="form-value"><input type="userName" id="userName" maxLength={50} onBlur={validUserName} /></div>
                            <div className="form-valid">{validMsgUserName}</div>
                          </div>
                          {/* <div className="col col-12 col-lg-3 offset-lg-1 form-item">
                              <div className="form-label">{t("page.register.First")}</div>
                              <div className="form-value"><input type="firstName" id="firstName" maxLength={50} onBlur={validFirstName} /></div>
                            <div className="form-valid">{validMsgFirstName}</div>
                          </div>
                          <div className="col col-12 col-lg-3 offset-lg-1 form-item">
                              <div className="form-label">{t("page.register.Last")}</div>
                              <div className="form-value"><input type="lastName" id="lastName" maxLength={50} onBlur={validLastName} /></div>
                            <div className="form-valid">{validMsgLastName}</div>
                          </div> */}
                          {/* <div className="col col-12 col-lg-5 form-item">
                              <div className="form-label">{t("page.register.Email")}</div>
                              <div className="form-value"><input type="email" id="email" maxLength={100} placeholder={t("placeholder.emailEnter")} onBlur={validEmail} /></div>
                            <div className="form-valid">{validMsgEmail}</div>
                          </div>
                          <div className="col col-12 col-lg-3 offset-lg-1 form-item">
                              <div className="form-label label-email-code">{t("page.register.EmailConfirm")}</div>
                              <div className="form-value s-flex">
                                  <button className="btn btn-default btn-email-code" onClick={getEmailCode} disabled={isClickEmail}>{isClickEmailMsg}</button>
                                  <input type="text" id="emailCode" minLength={6} maxLength={6} className="input-email-code" placeholder={t("placeholder.Code")} onBlur={validEmailCode} />
                              </div>
                            <div className="form-valid">{validMsgEmailCode}</div>
                          </div> */}
                        <div className="col col-12 form-item" style={{display: ""}}>
                          <div className="form-label text-high-green">{t("page.register.Referral1")} <span>{t("page.register.Referral2")}</span></div>
                          <div className="form-value">
                            <input type="inviteCode" id="inviteCode" minLength={1} maxLength={50} onBlur={validInviteCode} />
                          </div>
                          <div className="form-valid">{validMsgInviteCode}</div>
                        </div>
                          <div className="col col-12 form-item">
                              <div className="form-label">{t("page.register.Password")}</div>
                              <div className="form-value"><input type="password" id="password" minLength={8} maxLength={50} onBlur={validPassword}
                                                                 onKeyDown={(e: any) => {if (e.key === " ") {
                                                                   e.preventDefault();
                                                                 }}}
                                                                 onPaste = {(e: any) => e.preventDefault()} /></div>
                            {
                                !validMsgPassword && <div className="">{t("page.register.Enter")}</div>
                            }
                            {
                                validMsgPassword && <div className="form-valid">{validMsgPassword}</div>
                            }
                          </div>
                          <div className="col col-12 form-item">
                              <div className="form-label">{t("page.register.PasswordConfirm")}</div>
                              <div className="form-value"><input type="password" id="passwordConfirm" minLength={8} maxLength={50} onBlur={validPasswordConfirm} /></div>
                            <div className="form-valid">{validMsgPasswordConfirm}</div>
                          </div>
                        <div className="col col-12 form-item register-tips">
                          {t("page.register.By")}
                          <a
                              href="/agreement?type=1"
                              target="_blank"
                              className="text-line-bottom text-line-lr"
                          >
                            {t("page.register.Terms")}
                          </a>{' '}
                          &
                          <a
                              href="/agreement?type=0"
                              target="_blank"
                              className="text-line-bottom text-line-l"
                          >
                            {t("page.register.Privacy")}
                          </a>
                          .
                        </div>
                        <div className="col col-12 form-item s-flex s-flex-center-row register-agree" style={{display: "none",}}>
                          {agreement ? (
                              <img
                                  className="checkbox-agree"
                                  src="./assets/images/user/icon-checkbox-select.png?v=20230413001"
                                  onClick={() => {
                                    setAgreement(false);
                                  }}
                              />
                          ) : (
                              <img
                                  className="checkbox-agree"
                                  src="./assets/images/user/icon-checkbox.png?v=20230413001"
                                  onClick={() => {
                                    setAgreement(true);
                                  }}
                              />
                          )}
                          <span>
                          {t("page.register.subscribe")}
                        </span>
                        </div>
                          <div className="col col-12 form-item s-flex s-flex-center-row">
                              <button onClick={register} className="btn btn-default btn-form-register" disabled={isLoading}>{t("button.SIGN")}</button>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={open}
        message="I love snacks"
        key="snackbar-reg-1"
      />
    </>
  );
}
