import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import storage from "./libs/storage";
import { EmailCode, CheckEmailCode } from './libs/api';
import * as React from "react";
import {useTranslation} from "react-i18next";

const timeNum = 120;
export default function Login() {
    const { t } = useTranslation();

    const [validMsgEmail, setValidMsgEmail] = useState('');
    const [validMsgEmailCode, setValidMsgEmailCode] = useState('');
    const [isClickEmail, setIsClickEmail] = useState(false);
    const [isClickEmailMsg, setIsClickEmailMsg] = useState(t("button.CODE"));
    const [isClickEmailNum, setIsClickEmailNum] = useState(timeNum);
    const [isLoading, setIsLoading] = useState(false);

    function validEmail() {
        // @ts-ignore
        const val = document.querySelector("#email").value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmail(t("validate.information"));
            return false;
        }
        if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig.test(val)) {
            setValidMsgEmail('');
            return true;
        } else {
            setValidMsgEmail(t("validate.address"));
            return false;
        }
    }
    function validEmailCode() {
        // @ts-ignore
        const val = document.querySelector('#emailCode').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmailCode(t("validate.information"));
            return false;
        } else if (val.length > 6) {
            setValidMsgEmailCode(t("validate.long"));
            return false;
        } else if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
            setValidMsgEmailCode('');
            return true;
        } else {
            setValidMsgEmailCode(t("validate.code"));
            return false;
        }
    }
    function getEmailCode() {
        // @ts-ignore
        let emailVal = document.querySelector('#email').value.trim();
        if (!validEmail()) {
            return false;
        }
        setValidMsgEmailCode('');
        // setOpen(true);
        EmailCode({ email: emailVal, type: 2 }).then();
        let time = timeNum;
        setIsClickEmail(true);
        const interval = setInterval(() => {
            setIsClickEmailNum(time--);
            setIsClickEmailMsg(time + 's');
            if (time < 0) {
                setIsClickEmail(false);
                setIsClickEmailNum(timeNum);
                setIsClickEmailMsg(t("button.CODE"));
                clearInterval(interval);
            }
        }, 1000);
        return true;
    }
    function checkCode() {
        if (validEmail() && validEmailCode()) {
            // @ts-ignore
            const email = document.querySelector("#email").value.trim();
            // @ts-ignore
            const emailCode = document.querySelector("#emailCode").value.trim();
            setIsLoading(true);
            CheckEmailCode({
                email: email,
                code: emailCode
            }).then((res: any) => {
                setIsLoading(false);
                if (!res.code) {
                    storage.setItem("emailCode", res.id);
                    toReset();
                }
            }).catch(() => {
                setIsLoading(false);
                storage.removeItem("emailCode");
            });
        }
    }
    function toReset() {
        window.self.location.href = window.location.origin + '/reset';
    }
    return (
        <>
            <Helmet>
                <title>DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs</title>
                <meta name="description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
                <meta property="og:description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container">
                                <h2 className="txt-default-title wow fadeInUp pwd-re-title"><span className="txt">{t("page.forgot.Forgot")}</span></h2>
                                <div className="s-flex s-flex-center-row">
                                    <div className="row form-box login-body pwd-re-body">
                                        <div className="login-ct">
                                            <div className="col col-12 form-item">
                                                <div className="form-label">{t("page.forgot.Email")}</div>
                                                <div className="form-value"><input type="email" id="email" maxLength={100} placeholder={t("placeholder.emailEnter")} onBlur={validEmail} /></div>
                                                <div className="form-valid">{validMsgEmail}</div>
                                            </div>
                                            <div className="col col-12 form-item form-email-code">
                                                <div className="form-label">{t("page.forgot.Code")}</div>
                                                <div className="s-flex form-value">
                                                    <button className="btn btn-default btn-border-none pwd-re-btn-code" onClick={getEmailCode} disabled={isClickEmail}>{isClickEmailMsg}</button>
                                                    <input type="text" id="emailCode" placeholder={t("placeholder.Code")} minLength={6} maxLength={6} onBlur={validEmailCode} />
                                                </div>
                                                <div className="form-valid">{validMsgEmailCode}</div>
                                            </div>
                                            <div className="col col-12 form-item s-flex s-flex-center-row">
                                                <button onClick={checkCode} className="btn btn-default btn-form-login" disabled={isLoading}>{t("page.forgot.CONFIRM")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
};
