import Header from '../Header';
import Footer from '../Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { EmailCode, RegisterFT, LoginEmail } from '../libs/api';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import storage from '../libs/storage';
import { removeCookie, setCookie } from '../libs/cookies';
import * as React from 'react';
import { getUrlParams } from '../utils';
import Radio from '@mui/material/Radio';
import message from '../components/Message';
import {useTranslation} from "react-i18next";
const timeNum = 120;
export default function Register() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [validMsgUserName, setValidMsgUserName] = useState('');
  const [validMsgFirstName, setValidMsgFirstName] = useState('');
  const [validMsgLastName, setValidMsgLastName] = useState('');
  const [validMsgEmail, setValidMsgEmail] = useState('');
  const [validMsgEmailCode, setValidMsgEmailCode] = useState('');
  const [validMsgInviteCode, setValidMsgInviteCode] = useState('');
  const [validMsgInviteName, setValidMsgInviteName] = useState('');
  const [validMsgInvitePhone, setValidMsgInvitePhone] = useState('');
  const [validMsgPassword, setValidMsgPassword] = useState('');
  const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickEmailMsg, setIsClickEmailMsg] = useState('GET CODE');
  const [isClickEmailNum, setIsClickEmailNum] = useState(timeNum);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInviteCode();
    if (storage.getItem('userStatus') && storage.getItem('userStatus') !== undefined) {
      window.self.location.href = window.location.origin;
    }
  }, []);

  function getInviteCode() {
    let code = getUrlParams('code', window.self.location.search);
    // @ts-ignore
    document.querySelector('#inviteCode').value = code;
  }

  function validUserName() {
    // @ts-ignore
    const val = document.querySelector('#userName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgUserName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgUserName(t("validate.long"));
      return false;
    } else {
      setValidMsgUserName('');
      return true;
    }
  }
  function validFirstName() {
    // @ts-ignore
    const val = document.querySelector('#firstName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgFirstName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgFirstName(t("validate.long"));
      return false;
    } else {
      setValidMsgFirstName('');
      return true;
    }
  }
  function validLastName() {
    // @ts-ignore
    const val = document.querySelector('#lastName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgLastName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgLastName(t("validate.long"));
      return false;
    } else {
      setValidMsgLastName('');
      return true;
    }
  }

  const [selectedValue, setSelectedValue] = React.useState('y');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  function validEmail() {
    // @ts-ignore
    const val = document.querySelector('#email').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgEmail(t("validate.information"));
      return false;
    }
    if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(val)) {
      setValidMsgEmail('');
      return true;
    } else if (val.length > 100) {
      setValidMsgEmail(t("validate.long"));
      return false;
    } else {
      setValidMsgEmail('Please enter correct email address');
      return false;
    }
  }
  function validEmailCode() {
    // @ts-ignore
    const val = document.querySelector('#emailCode').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgEmailCode(t("validate.information"));
      return false;
    } else if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
      setValidMsgEmailCode('');
      return true;
    } else if (val.length > 6) {
      setValidMsgEmailCode(t("validate.long"));
      return false;
    } else {
      setValidMsgEmailCode('Please enter correct email verification code');
      return false;
    }
  }
  function validInviteCode() {
    // @ts-ignore
    const val = document.querySelector('#inviteCode').value.trim();
    if (val === "" || val === null || val === undefined || isNaN(val)) {
      setValidMsgInviteCode(t("validate.information"));
      return false;
    }
    if (val && /^[a-zA-Z\d]{7}$/gi.test(val)) {
      setValidMsgInviteCode('');
      return true;
    } else if (val.length > 7) {
      setValidMsgInviteCode(t("validate.long"));
      return false;
    } else {
      setValidMsgInviteCode('Invite Code error');
      return false;
    }
  }
  function validInviteName() {
    // @ts-ignore
    const val = document.querySelector('#inviteName').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgInviteName(t("validate.information"));
      return false;
    } else if (val.length > 50) {
      setValidMsgInviteName(t("validate.long"));
      return false;
    } else {
      setValidMsgInviteName('');
      return true;
    }
  }
  function validInvitePhone() {
    // @ts-ignore
    const val = document.querySelector('#invitePhone').value.trim();
    if (val === "" || val === null || val === undefined || isNaN(val)) {
      setValidMsgInvitePhone(t("validate.information"));
      return false;
    }
    if (val && /^\d{8}$/gi.test(val)) {
      setValidMsgInvitePhone('');
      return true;
    } else if (val.length > 6) {
      setValidMsgInvitePhone(t("validate.long"));
      return false;
    } else {
      setValidMsgInvitePhone('Phone error');
      return false;
    }
  }
  function validPassword() {
    // @ts-ignore
    const val = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPassword(t("validate.information"));
      return false;
    }
    if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/ig.test(val)) {
      setValidMsgPassword('');
      return true;
    } else if (val.length > 50) {
      setValidMsgPassword(t("validate.long"));
      return false;
    } else {
      setValidMsgPassword(t("validate.Enter1"));
      return false;
    }
  }
  function validPasswordConfirm() {
    // @ts-ignore
    const val = document.querySelector('#passwordConfirm').value.trim();
    // @ts-ignore
    const valRe = document.querySelector('#password').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPasswordConfirm(t("validate.information"));
      return false;
    }
    if (valRe !== val) {
      setValidMsgPasswordConfirm('The password is different. Please re-enter');
      return false;
    } else if (val.length > 50) {
      setValidMsgPasswordConfirm(t("validate.long"));
      return false;
    } else {
      setValidMsgPasswordConfirm('');
      return true;
    }
  }
  function getEmailCode() {
    // @ts-ignore
    let emailVal = document.querySelector('#email').value.trim();
    if (!validEmail()) {
      return false;
    }
    setValidMsgEmailCode('');
    // setOpen(true);
    EmailCode({ email: emailVal, type: 1 }).then();
    let time = timeNum;
    setIsClickEmail(true);
    const interval = setInterval(() => {
      setIsClickEmailNum(time--);
      setIsClickEmailMsg(time + 's');
      if (time < 0) {
        setIsClickEmail(false);
        setIsClickEmailNum(timeNum);
        setIsClickEmailMsg('GET CODE');
        clearInterval(interval);
      }
    }, 1000);
    return true;
  }
  function register() {
    if (
      validUserName() &&
      validFirstName() &&
      validLastName() &&
      validEmail() &&
      validEmailCode() &&
      validPassword() &&
      validPasswordConfirm()
    ) {
      // @ts-ignore
      const email = document.querySelector('#email').value.trim();
      // @ts-ignore
      const emailCode = document.querySelector('#emailCode').value.trim();
      // @ts-ignore
      const password = document.querySelector('#password').value.trim();
      // @ts-ignore
      const lastName = document.querySelector('#lastName').value.trim();
      // @ts-ignore
      const firstName = document.querySelector('#firstName').value.trim();
      // @ts-ignore
      const userName = document.querySelector('#userName').value.trim();
      let params = {
        username: userName,
        surname: firstName,
        givenname: lastName,
        email: email,
        code: emailCode,
        password: password,
      };
      if (selectedValue === 'y') {
        // @ts-ignore
        const inviteCode = document.querySelector('#inviteCode').value.trim();
        if (validInviteCode()) {
          Object.assign(params, { agentCode: inviteCode.toUpperCase(), });
        } else {
          return false;
        }
      } else {
        // @ts-ignore
        const inviteName = document.querySelector('#inviteName').value.trim();
        // @ts-ignore
        const invitePhone = document.querySelector('#invitePhone').value.trim();
        if (validInviteName()) {
          Object.assign(params, { agentName: inviteName, });
        } else if (validInvitePhone()) {
          Object.assign(params, { agentMobileNumber: invitePhone, });
        }
      }
      setIsLoading(true);
      RegisterFT(params).then((res: any) => {
        setIsLoading(false);
        if (!res.code) {
          loginUser();
        }
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }
  function loginUser() {
    // @ts-ignore
    const email = document.querySelector('#email').value.trim();
    // @ts-ignore
    const password = document.querySelector('#password').value.trim();
    LoginEmail({
      email: email,
      password: password,
    })
      .then((res: any) => {
        storage.setItem('userStatus', res.token);
        setCookie('ua_user', res.token || '');
        toPhone();
      })
      .catch(() => {
        removeCookie('ua_user');
        storage.removeItem('userStatus');
      });
  }
  function toPhone() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
        redirectUrl &&
        !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href = window.location.origin + '/FT_phone?redirect=' + redirectUrl;
    } else {
      window.self.location.href = window.location.origin + '/FT_phone';
    }
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">SIGN UP</span>
                </h2>
                <h5 className="txt-default-title wow fadeInUp bind-phone-title-sub">
                  <span className="txt">
                    step 1 of 2
                  </span>
                </h5>

                  <div className="s-flex s-flex-center-row register-ct">
                      <div className="row form-box register-body register-body-ft">
                          <div className="col col-12 col-lg-5 form-item">
                              <div className="form-label">User Name</div>
                              <div className="form-value"><input type="userName" id="userName" maxLength={50} onBlur={validUserName} /></div>
                            {
                                !validMsgUserName && <div className="form-valid-tips">Maximum 15 characters long with a combination of alphanumeric letters</div>
                            }
                            {
                                validMsgUserName && <div className="form-valid">{validMsgUserName}</div>
                            }
                          </div>
                          <div className="col col-12 col-lg-3 offset-lg-1 form-item">
                              <div className="form-label">Surname</div>
                              <div className="form-value"><input type="firstName" id="firstName" maxLength={50} onBlur={validFirstName} placeholder={t("placeholder.Chan")} /></div>
                            <div className="form-valid">{validMsgFirstName}</div>
                          </div>
                          <div className="col col-12 col-lg-3 offset-lg-1 form-item">
                              <div className="form-label">Given Name</div>
                              <div className="form-value"><input type="lastName" id="lastName" maxLength={50} onBlur={validLastName} placeholder={t("placeholder.Tai")} /></div>
                            <div className="form-valid">{validMsgLastName}</div>
                          </div>
                          <div className="col col-12 form-item">
                            <div className="form-label">Are you a FTLife agent?</div>
                            <div className="form-value-no s-flex s-flex-center-start-row">
                              <span>YES</span><Radio {...controlProps('y')} sx={{
                              color: "#00ffff",
                              padding: 1,
                              '&.Mui-checked': {
                                color: "#00ffff",
                              },
                            }} size="small" className="form-select"/>
                              <span>NO</span><Radio {...controlProps('n')} sx={{
                              color: "#00ffff",
                              padding: 1,
                              '&.Mui-checked': {
                                color: "#00ffff",
                              },
                            }} size="small" className="form-select"/>
                            </div>
                            {
                                selectedValue === "n" && <div className="form-label">Do you have a friend from FTLife?</div>
                            }
                            {
                                selectedValue === "n" && <div className="form-valid-tips">Please fill in your friend's information in the fields below</div>
                            }
                            {
                              selectedValue === "y" && <div className="col col-12">
                                  <div className="form-value">
                                    <input type="inviteCode" id="inviteCode" minLength={7} maxLength={7} onBlur={validInviteCode} placeholder={t("placeholder.agent")}/>
                                  </div>
                                  <div className="form-valid">{validMsgInviteCode}</div>
                                </div>
                            }
                            {
                                selectedValue === "n" && <div className="row form-row">
                                  <div className="col col-12 col-lg-6">
                                    <div className="form-value">
                                      <input type="inviteName" id="inviteName" maxLength={50} placeholder={t("placeholder.Name")} />
                                    </div>
                                    {/*<div className="form-valid">{validMsgInviteName}</div>*/}
                                  </div>
                                  <div className="col col-12 col-lg-6 offset-lg-1">
                                    <div className="form-value">
                                      <input type="invitePhone" id="invitePhone" minLength={8} maxLength={8} placeholder={t("placeholder.Mobile")} />
                                    </div>
                                    {/*<div className="form-valid">{validMsgInvitePhone}</div>*/}
                                  </div>
                                </div>
                            }
                          </div>
                          <div className="col col-12 col-lg-6 form-item">
                              <div className="form-label">Email</div>
                              <div className="form-value"><input type="email" id="email" maxLength={100} placeholder={t("placeholder.emailEnter")} onBlur={validEmail} /></div>
                            <div className="form-valid">{validMsgEmail}</div>
                          </div>
                          <div className="col col-12 col-lg-6 offset-lg-1 form-item">
                              <div className="form-label label-email-code">Confirm Email</div>
                              <div className="form-value s-flex">
                                  <button className="btn btn-default btn-email-code" onClick={getEmailCode} disabled={isClickEmail}>{isClickEmailMsg}</button>
                                  <input type="text" id="emailCode" minLength={6} maxLength={6} className="input-email-code" placeholder={t("placeholder.Code")} onBlur={validEmailCode} />
                              </div>
                            <div className="form-valid">{validMsgEmailCode}</div>
                          </div>
                          <div className="col col-12 col-lg-6 form-item">
                              <div className="form-label">Password</div>
                              <div className="form-value"><input type="password" id="password" minLength={8} maxLength={50} onBlur={validPassword} /></div>
                            {
                                !validMsgPassword && <div className="form-valid-tips">Enter 8-50 digits password with a combination of alphanumeric letters</div>
                            }
                            {
                                validMsgPassword && <div className="form-valid">{validMsgPassword}</div>
                            }
                          </div>
                          <div className="col col-12 col-lg-6 offset-lg-1 form-item">
                              <div className="form-label">Confirm Password</div>
                              <div className="form-value"><input type="password" id="passwordConfirm" minLength={8} maxLength={50} onBlur={validPasswordConfirm} /></div>
                            <div className="form-valid">{validMsgPasswordConfirm}</div>
                          </div>
                      </div>
                  </div>

                <div className="col col-12 form-item s-flex s-flex-center-row" style={{ marginTop: "2rem"}}>
                  <button onClick={register} className="btn btn-default btn-form-register" disabled={isLoading}>NEXT</button>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={open}
        message="I love snacks"
        key="snackbar-reg-1"
      />
    </>
  );
}
