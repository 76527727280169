import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import * as React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {useEffect, useRef, useState} from "react";
import ShopCartGift from "../components/ShopCartGift";
import DialogPay from "../components/DialogPay";
import {ShopDetail, urlToObj, ShopCartCreate} from "../libs/api";
import message from "../components/Message";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {animated, useSpring} from "react-spring";

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: '#fff',
	border: '1px solid #00FFFF',
	boxShadow: 0,
	p: 1,
};
interface FadeProps {
	children?: React.ReactElement;
	in: boolean;
	onEnter?: () => {};
	onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
	props,
	ref
) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	);
});

function HelloKitty() {
	const [selectTag, setSelectTag] = useState(1);
	const [changeNumber, setChangeNumber] = useState(1);
	const [backIndex, setBackIndex] = useState("");
	const addNumber = () => {
		setChangeNumber(changeNumber+1);
	}
	const reduceNumber = () => {
		if (changeNumber === 1) {
			return false;
		}
		setChangeNumber(changeNumber-1);
	}
	const tabList = [{
		name: "DESCRIPTION",
		value: 1,
	}, {
		name: "DELIVERY & PAYMENT",
		value: 2,
	}];
	const [tabValue, setTabValue] = useState(1);

	const dialogPayRef = useRef(null);
	const openPayDialog = (rel: any) => {
		dialogPayRef.current.openPayType(rel);
	}

	const toGift = () => {
		// @ts-ignore
		window.location.href = window.location.origin + "/hello-kitty#" + backIndex;
	}

	const [isLoading, setIsLoading] = useState(false);
	const [shopGift, setShopGift] = useState<any>({});
	const [shopGiftImgs, setShopGiftImgs] = useState<any[]>([]);
	const getShopGiftDetail = (id: string) => {
		ShopDetail({id: id}).then((res: any) => {
			if(!res.code) {
				setShopGift(res);
				let isLoad = true;
				for (let i in res.specs) {
					if (res.specs[i].quantity > 0) {
						isLoad = false;
						setSelectTag(res.specs[i].id);
						break;
					}
				}
				setIsLoading(isLoad);
				if (res.detailImg) {
					setShopGiftImgs(res.detailImg.split(","));
				}
			}
		})
	}

	// @ts-ignore
	const isLogin = !!window.localStorage.getItem('userStatus');
	const addShopCart = () => {
		if (!isLogin) {
			// @ts-ignore
			window.self.location.href = window.location.origin + '/login?redirect=/hello-kitty#gift_shop';
		}
		setIsLoading(true);
		ShopCartCreate({productId: shopGift.id, productSpecId: selectTag, quantity: String(changeNumber)}).then((res: any) => {
			if (!res.code) {
				message.info("Success");
			}
			setIsLoading(false);
		}).catch(() => {
			setIsLoading(false);
		});
	}

	const changeSelect = (id: any, index: number) => {
		setSelectTag(id);
		changeSwipperIndex(index);
	}
	// option swipper
	const [isClickDetail, setIsClickDetail] = useState(false);
	const changeSwipperIndex = (index: number) => {
		setIsClickDetail(true);
		let imgs = [];
		for(const o of shopGift.specs) {
			imgs.push(o.image || "");
		}
		setShopGiftImgs(imgs);
		// @ts-ignore
		const mySwipper = document.querySelector("#swipper-gift").swiper;
		mySwipper.slideTo(index, 1000, false);
	}

	const [imgScale, setImgScale] = useState(false);
	const openImgScale = () => setImgScale(true);
	const closeImgScale = () => setImgScale(false);
	const [imgScaleUrl, setImgScaleUrl] = useState("");
	const openDialogImg = (url) => {
		openImgScale();
		setImgScaleUrl(url);
	}

	// 初始化调用
	useEffect(() => {
		// @ts-ignore
		const params: any = urlToObj(window.location.href);
		if (Object.keys(params).length > 0) {
			getShopGiftDetail(params.id);
			setBackIndex(params.index);
		} else {
			toGift();
		}
	}, []);
	return (
		<div className="hello-kitty">
			<Helmet>
				<title>MetaGaia: Hello Kitty Seven Wonders </title>
				<meta name="description" content="Hello Kitty" />
				<meta property="og:description" content="Hello Kitty" />
			</Helmet>

			<div className="wrapper">
				<Header />

				<main className="hello-kitty-bg-fixed">
					<section className="hk-info hk-bg-ico">
						<div className="space-title"></div>
						<div className="box-container-small container box-container box-active">
							<div className="s-flex s-flex-center-row gift-shop-title" id="gift_shop">
								<img src="./assets/images/hellokity/shopping-back.png" alt="" className="s-cursor-pointer shopping-back" onClick={toGift}/>
								<div className="s-flex s-flex-center-row active-title box-title">Gift Shop</div>
								<ShopCartGift handlePay={openPayDialog}></ShopCartGift>
							</div>

							<div className="gift-detail">
								<div className="gift-detail-top"></div>
								<div className="gift-detail-center">
									<div className="gift-detail-info">
										<div className="gift-detail-imgs">
											<Swiper
												id="swipper-gift"
												modules={[Autoplay, Navigation, Pagination]}
												navigation={true}
												pagination={{
													type: 'fraction',
												}}
												loop
												style={{height: "100%"}}
											>
												{
													shopGiftImgs.map((item, index) => {
														return <SwiperSlide className="" key={"swiper" + index}>
															<img className="gift-detail-img s-cursor-pointer" src={item}
																 alt="" onClick={() => openDialogImg(item)}/>
														</SwiperSlide>
													})
												}
											</Swiper>
										</div>
										<div className="gift-detail-text">
											<div className="detail-text-title">{shopGift && shopGift.name}</div>
											<div className="detail-text-info">
												<div
													className="text-info-price">HK$ {shopGift && shopGift.payPrice}</div>
												{
													shopGift.checkWonderPass && !!Number(shopGift.discountPrice) && <div
														className="text-info-status text-line-through">HK$ {shopGift && shopGift.hkPrice}</div>
												}

												<div className="text-info-status">In Stock</div>
												<div className="text-info-tags s-flex">
													{
														shopGift && shopGift.specs && shopGift.specs.map((item, index) => {
															return <div key={"detail" + index}>
																{
																	item.quantity > 0 && <div
																		className={`text-info-tag s-cursor-pointer ${item.id === selectTag ? "tag-active" : ""}`}
																		onClick={() => changeSelect(item.id, index)}>{item.name}</div>
																}
																{
																	item.quantity <= 0 &&
																	<div
																		className="text-info-tag s-cursor-pointer tag-disabled"
																		onClick={() => changeSwipperIndex(index)}>{item.name}</div>
																}
															</div>
														})
													}
												</div>
												<div className="text-info-number s-flex s-flex-between-row">
													<div
														className="number-option number-option-reduce s-flex s-flex-center-row s-cursor-pointer"
														onClick={reduceNumber}>-
													</div>
													<div
														className="number-input s-flex s-flex-center-row s-cursor-pointer">{changeNumber}</div>
													<div
														className="number-option number-option-add s-flex s-flex-center-row s-cursor-pointer"
														onClick={addNumber}>+
													</div>
												</div>
												{
													shopGift && shopGift.limit &&
													<div className="text-info-status s-flex s-flex-center-row"
														 style={{marginTop: "0.2rem"}}>Limited to <span
														className="text-info-status-blod">{shopGift.limit}</span> pieces
													</div>
												}
												{
													isLoading && <div
														className="text-info-btn text-info-btn-dis s-flex s-flex-between-row s-cursor-pointer">
														<span>ADD TO CART</span>
													</div>
												}
												{
													!isLoading && <div
														className="text-info-btn s-flex s-flex-between-row s-cursor-pointer"
														onClick={addShopCart}>
														<span>ADD TO CART</span>
													</div>
												}
											</div>
										</div>
									</div>
									<div className="gift-detail-tab s-flex">
										{
											tabList.map((item, index) => {
												return <div
													className={`detail-tab-item s-cursor-pointer ${item.value === tabValue ? "tab-active" : ""}`}
													key={index}
													onClick={() => setTabValue(item.value)}>{item.name}</div>
											})
										}
									</div>
									<div className="gift-detail-desc">
										{
											tabValue === tabList[0].value && shopGift && <>
												{
													shopGift.description && shopGift.description.split("^").map((item, index) =>
														<div key={index}>
															{
																item && <>
																<span
																	className={item.split("|")[1] && "gift-detail-title"}>
																	{
																		item.split("|")[0]
																	}
																</span>
																	<span>
																	{
																		item.split("|")[1]
																	}
																</span>
																</>
															}
														</div>)
												}
											</>
										}
										{
											tabValue === tabList[1].value && <div className="">
												<div className="">
													<span className="gift-detail-title">Delivery Options</span>
													<span>S.F.Express - Office / Home Delivery | Macau (S.F.Express)</span>
												</div>
												<div className="">
													<span className="gift-detail-title">Payment Options</span>
													<span>Credit Card (Visa / Master / Amex) | Wechat Pay</span>
												</div>
											</div>
										}
									</div>
								</div>
								<div className="gift-detail-bottom"></div>
							</div>
						</div>
					</section>
				</main>

				<Footer/>
			</div>
			<DialogPay ref={dialogPayRef}></DialogPay>

			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={imgScale}
				onClose={closeImgScale}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className="modal-box modal-scale-box"
			>
				<Fade in={imgScale}>
					<Box sx={style}>
						<div className="s-flex close-box">
							<img
								src="./assets/images/hellokity/ico-close.png"
								onClick={closeImgScale}
							/>
						</div>
						<div>
							<img style={{width: "100%"}} src={imgScaleUrl}/>
						</div>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}

export default HelloKitty;
