import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useState, useEffect, useRef} from "react";
import * as React from "react";
import message from './components/Message';
import {emailKey} from "./libs/api";
import {useTranslation} from "react-i18next";

export default function JujuRaiders() {
	const { t } = useTranslation();

	let [videoVolume, setVideoVolume] = useState(true);
	const [show,setShow] = useState(false);

	return (
		<div className="bg-hexahero bg-faq invite-code juju-raiders">
			<Helmet>
				<title>JujuRaiders</title>
				<meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
				<meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
			</Helmet>

			<div className="wrapper juju-raiders-bg">
				<Header />

				<main>
					<section className="s-nft s-default">
						<div className="">
							<div className="s-nft-hero">
								<div className="container s-flex s-flex-center-row wow fadeInUp">
									<img className="img-title-sub"
										 src="./assets/images/juju/title-sub-01.png?v=20230413001" alt=""/>
								</div>
								<div className="container s-flex s-flex-center-row wow fadeInUp">
									<img className="img-title-logo"
										 src="./assets/images/juju/juju-title.png?v=20230413001" alt=""/>
								</div>
								<div className="container s-flex s-flex-center-row wow fadeInUp">
									<img className="img-title-sub"
										 src="./assets/images/juju/title-sub-02.png?v=20230413001" alt=""/>
								</div>
								<div className="container desc-text wow fadeInUp">
									<div className="desc-text-box">Experience the Thrill of JuJuRaiders - Where Strategy
										and
										Speed Collide!
									</div>
									<div className="desc-text-box">Join the fast-paced and exhilarating universe of
										JuJuRaiders, the latest multiplayer sensation exclusive to the MetaGaia
										Metaverse. In this futuristic world, strategy and action merge to create an
										adventure like no other. Are you ready to embark on a quest filled with
										excitement, challenges and endless fun?
									</div>
								</div>
								<div className="juju-down">
									<div className="s-flex s-flex-center-row down-title wow fadeInUp">Download the
										MetaGaia App and Play JuJuRaiders
									</div>
									<div className="down-btn-box wow fadeInUp">
										<div
											className="s-flex s-flex-center-row s-cursor-pointer down-btn down-btn-text"
											onClick={() => {
												window.location.href = "https://apps.apple.com/hk/app/metagaia/id1638854848?l=en";
											}}>
											<img className="ico-down"
												 src="./assets/images/juju/ico-ios.png?v=20230413001" alt=""/>
											<span className="s-flex s-flex-center-row">IOS Download</span>
										</div>
										<div
											className="s-flex s-flex-center-row s-cursor-pointer down-btn down-btn-text"
											onClick={() => {
												window.location.href = "https://play.google.com/store/search?q=metagaia&c=apps";
											}}>
											<img className="ico-down"
												 src="./assets/images/juju/ico-android.png?v=20230413001" alt=""/>
											<span className="s-flex s-flex-center-row">Android Download</span>
										</div>
									</div>
								</div>
							</div>
							<section className="s-trailer video-ctrl-box home-video-ctrl-box">
								<video onPlay={() => {
									setShow(true)
								}} className="trail-video" autoPlay loop muted={videoVolume} playsInline
									   preload="metadata">
									<source
										src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/jujuraiders4-2.mp4"
										type="video/mp4"/>
								</video>
								{
									show && <img className="video-ctrl-audio shape-pointer" onClick={() => {
										setVideoVolume(!videoVolume)
									}
									}
												 src={videoVolume ? "assets/images/video-volume-close.png?v=20230413001" : "assets/images/video-volume-open.png?v=20230413001"}/>
								}
							</section>
							{/*s-nft-hero*/}
							<div className="box-title wow fadeInUp ">
								<div className="container">
									<div className="p-default-title max-lg item-card">
										<h2 className="txt-default-title wow fadeInUp">
											<span className="txt-cyan">HIGH-SPEED </span>
											<span className="txt"> STRATEGY</span>
										</h2>
										<div className="txt-default-desc wow fadeInUp text-tips" data-wow-delay="0.3s">
											JuJuRaiders is more than just a game; it's a high-octane experience! Capture
											the mystical JuJu and race against time and opponents in a high-tech
											landscape. Each match is a new adventure, with dynamic environments that
											keep you guessing and your adrenaline pumping.
										</div>
										<div className="s-flex s-flex-center-row">
											<img className="card-img" src="./assets/images/juju/cac.png?v=20230413001" alt=""/>
										</div>
									</div>
									<div className="p-default-title max-lg item-card">
										<h2 className="txt-default-title wow fadeInUp">
											<span className="txt-cyan">CONNECT </span>
											<span className="txt"> AND </span>
											<span className="txt-cyan"> CONQUER</span>
										</h2>
										<div className="txt-default-desc wow fadeInUp text-tips" data-wow-delay="0.3s">
											Whether competing with friends or forging relationships with new companions, JuJu Raiders is the perfect battleground to test your strategic skills. Strategize, compete and show your prowess in a world where quick thinking and fast reflexes reign supreme.
										</div>
										<div className="txt-default-desc wow fadeInUp text-tips s-flex" data-wow-delay="0.3s">
											<img className="ico-star"
												 src="./assets/images/juju/ico-star-blue.png?v=20230413001" alt=""/>
											<span>Dynamic Multiplayer Battles: Engage in matches against players from around the globe.</span>
										</div>
										<div className="txt-default-desc wow fadeInUp text-tips s-flex" data-wow-delay="0.3s">
											<img className="ico-star"
												 src="./assets/images/juju/ico-star-blue.png?v=20230413001" alt=""/>
											<span>Search for JuJu capsules throughout the futuristic landscape or steal JuJu directly from your competitors.</span>
										</div>
										<div className="txt-default-desc wow fadeInUp text-tips s-flex" data-wow-delay="0.3s">
											<img className="ico-star"
												 src="./assets/images/juju/ico-star-blue.png?v=20230413001" alt=""/>
											<span>Stunning Graphics: Immerse yourself in a visually spectacular space aged world.</span>
										</div>
										<div className="s-flex s-flex-center-row">
											<img className="card-img" src="./assets/images/juju/cac.png?v=20230413001" alt=""/>
										</div>
									</div>
									<div className="p-default-title max-lg item-card">
										<h2 className="txt-default-title wow fadeInUp">
											<span className="txt">READY TO </span>
											<span className="txt-cyan"> JOIN </span>
											<span className="txt"> THE </span>
											<span className="txt-cyan"> RAID?</span>
										</h2>
										<div className="s-flex s-flex-center-row">
											<div className="row row-ppl row-ppl1 wow fadeIn raid-grid">
												<div className="col col-12 col-sm-6 col-lg-4 col-xl-3 s-flex s-flex-center-row">
													<div className="raid-grid-item">
														<img className="grid-img"
															 src="./assets/images/juju/raid-1.png?v=20230413001"
															 alt=""/>
														<div className="grid-title">
															<div>Download</div>
															<div>the MetaGaia App</div>
														</div>
														<div className="grid-text">
															<div>Get started by</div>
															<div>downloading the app</div>
															<div>on your preferred</div>
															<div>device.</div>
														</div>
													</div>
												</div>
												<div className="col col-12 col-sm-6 col-lg-4 col-xl-3 s-flex s-flex-center-row">
													<div className="raid-grid-item">
														<img className="grid-img"
															 src="./assets/images/juju/raid-2.png?v=20230413001"
															 alt=""/>
														<div className="grid-title">
															<div>Create</div>
															<div>Your Avatar</div>
														</div>
														<div className="grid-text">
															<div>Customize your</div>
															<div>character to reflect</div>
															<div>your unique style.</div>
														</div>
													</div>
												</div>
												<div className="col col-12 col-sm-6 col-lg-4 col-xl-3 s-flex s-flex-center-row">
													<div className="raid-grid-item">
														<img className="grid-img"
															 src="./assets/images/juju/raid-3.png?v=20230413001"
															 alt=""/>
														<div className="grid-title">
															<div>Jump</div>
															<div>into JuJuRaiders</div>
														</div>
														<div className="grid-text">
															<div>Navigate to the game</div>
															<div>within the MetaGaia</div>
															<div>Metaverse to begin.</div>
														</div>
													</div>
												</div>
												<div className="col col-12 col-sm-6 col-lg-4 col-xl-3 s-flex s-flex-center-row">
													<div className="raid-grid-item">
														<img className="grid-img"
															 src="./assets/images/juju/raid-4.png?v=20230413001"
															 alt=""/>
														<div className="grid-title">
															<div>Make</div>
															<div>new friends</div>
														</div>
														<div className="grid-text">
															<div>Play and create</div>
															<div>memories.</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="p-default-title max-lg item-card">
										<h2 className="txt-default-title wow fadeInUp">
											<span className="txt">DON‘T MISS OUT ON THE </span>
											<span className="txt-cyan"> ACTION!</span>
										</h2>
										<div className="txt-default-desc wow fadeInUp text-tips" data-wow-delay="0.3s">
											Download the MetaGaia app now and step into the world of JuJuRaiders. Exciting challenges, thrilling battles and endless fun await. Bring your friends along or make new ones in this jam-packed adventure.
										</div>
									</div>
									<div className="s-flex s-flex-center-row p-default-title max-lg item-card">
										<h2 className="wow fadeInUp txt-tips">
											<div className="s-flex s-flex-center-row">Join the JuJuRaiders community today</div>
											<div className="s-flex s-flex-center-row">- Your adventure starts now!</div>
										</h2>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer/>
				</main>
			</div>
		</div>
	);
}
