import { useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./i18n";
import Storage from "./libs/storage";

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from './reactui';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

import Login from './Login';
import InfoPhone from './InfoPhone';
import PwdForgot from './PwdForgot';
import PwdReset from './PwdReset';
import Register from './Register';
import GoogleRegister from './GoogleRegister';
import Home from './Home';
import GameHall from './GameHall';
import HexaHero from './HexaHero';
import Nft from './Nft';
import Download from './Download';
import NftHistory from './NftHistory';
import Faq from './Faq';
import PaperPDF from './PaperPDF';
import User from './User';
import UserInfo from './UserInfo';
import UserNftDetail from './UserNftDetail';
import AutoLogin from './AutoLogin';
import Agreement from './Agreement';
import Invitation from './Invitation';
import InvitationHis from './InvitationHis';
import AdminLogin from './AdminLogin';
import AdminMintConfirm from './AdminMintConfirm';
import Settings from './Settings';
import Security from './Security';
import PwdChange from './PwdChange';
import Sign2FA from './Sign2FA';
import SignSecurity from './SignSecurity';
import TokenHistory from './TokenHistory';
import ListHistory from './ListHistory';
import ListDetail from './ListDetail';
import GameDetail from './GameDetail';
import Matrix from './Matrix';

import MarketList from './MarketList';
import MarketDetail from './MarketDetail';
import MarketDetailHk from './MarketDetailHk';

import DemoIframe from './demo/DemoIframe';
import DemoSolana from './demo/DemoSolana';

import FTRegister from './ft/RegisterFT';
import FTInfoPhone from './ft/InfoPhoneFT';
import FTDownload from './ft/DownloadFT';
import FTGoPage from './ft/GoPage';
import MoraMoraBeach from './ft/MoraMoraBeach';
import FTPhotoWall from './ft/PhotoWall';
import FTQuestion from './ft/Question';
import FTRanking from './ft/Ranking';
import FTStart from './ft/Start';

import RKYouGotGame from './game/RankingYouGotGame';
import RKBallofFury from './game/RankingBallofFury';
import RKMonsterBuster from './game/RankingMonsterBuster';
import RKMetaSuperStars from './game/RankingMetaSuperStars';
import RKHexaHero from './game/RankingHexaHero';
import GameDownload from './game/GameDownload';

import ResetPwd from './ft/ResetPwd';

import Land from "./land/Land";
import LandBox from "./land/LandBox";
import LandBoxDetail from "./land/LandBoxDetail";
import LandBoxNft from "./land/LandBoxNft";
import LandNftDetail from "./land/LandNftDetail";

import UnitySelect from "./unity/Select";
import UnityFile from "./unity/SelectFile";
import UnityList from "./unity/List";
import UnityDetail from "./unity/Detail";

import Questions from "./ai/Questions";

import InviteCode from "./game/InviteCode";
import InviteChannel from "./game/InviteChannel";

import HelloKitty from "./game/HelloKitty";
import GiftDetail from "./game/GiftDetail";
import HelloKittyCoupon from "./game/HelloKittyCoupon";
import NftDetailHk from "./game/NftDetailHK";

import PlayVideo from "./game/PlayVideo";
import {useTranslation} from "react-i18next";
import BindInfo from "./user/BindInfo";

import Lottery from "./marketing/Lottery";
import LotteryList from "./marketing/LotteryList";
import LotteryAgree from "./marketing/LotteryAgree";
import UserGiftDetail from "./UserGiftDetail";

import JujuRaiders from "./JujuRaiders";

require('./reactui/styles.css');
require('./components/Message/index.css');

export default function App() {
  const {i18n} = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(Storage.getItem("i18n-local") || "en-US");
  }, []);


  const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork; //
  // const endpoint = process.env.REACT_APP_SOLANA_RPC_HOST!;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider featuredWallets={3}>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login-phone" element={<InfoPhone />} />
              <Route path="/forgot" element={<PwdForgot />} />
              <Route path="/reset" element={<PwdReset />} />
              <Route path="/register" element={<Register />} />
              <Route path="/google-register" element={<GoogleRegister />} />
              <Route path="/" element={<Home />} />
              <Route path="/game-hall" element={<GameHall />} />
              <Route path="/hexahero" element={<HexaHero />} />
              <Route path="/hexahero.html" element={<HexaHero />} />
              <Route path="/nft" element={<Nft />} />
              <Route path="/nft-sale" element={<Nft />} />
              <Route path="/nft-presale" element={<Nft />} />

              <Route path="/nft-list" element={<NftHistory />} />
              <Route path="/faq" element={<Faq />} />
              {/*<Route path="/paper" element={<Paper />} />*/}
              <Route path="/Metagaia_Investor_Lite" element={<PaperPDF />} />
              <Route path="/user" element={<User />} />
              <Route path="/user-update" element={<UserInfo />} />
              <Route path="/nft-detail" element={<UserNftDetail />} />
              <Route path="/auto-login" element={<AutoLogin />} />
              <Route path="/agreement" element={<Agreement />} />
              <Route path="/invitation" element={<Invitation />} />
              <Route path="/invitation-his" element={<InvitationHis />} />
              <Route path="/hd-login" element={<AdminLogin />} />
              <Route path="/hd-mint" element={<AdminMintConfirm />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/security" element={<Security />} />
              <Route path="/change" element={<PwdChange />} />
              <Route path="/sign-2fa" element={<Sign2FA />} />
              <Route path="/sign-security" element={<SignSecurity />} />
              <Route path="/token-list" element={<TokenHistory />} />
              <Route path="/bind-info" element={<BindInfo />} />

              <Route path="/market-list" element={<MarketList />} />
              <Route path="/market-detail" element={<MarketDetail />} />
              <Route path="/market-detail-hk" element={<MarketDetailHk />} />

              <Route path="/list-history" element={<ListHistory />} />
              <Route path="/list-detail" element={<ListDetail />} />
              <Route path="/game-detail" element={<GameDetail />} />
              <Route path="/hk-detail" element={<NftDetailHk />} />

              <Route path="/download" element={<Download />} />
              <Route path="/Matrix" element={<Matrix />} />

              <Route path="/FT_phone" element={<FTInfoPhone />} />
              <Route path="/FT_register" element={<FTRegister />} />
              <Route path="/FT_download" element={<FTDownload />} />
              <Route path="/FT_live" element={<FTGoPage />} />
              <Route path="/FT_photoswall" element={<FTPhotoWall />} />
              <Route path="/FT_Qstart" element={<FTStart />} />
              <Route path="/FT_ranking" element={<FTRanking />} />
              <Route path="/FT_Q:name" element={<FTQuestion />} />

              <Route path="/MoraMoraBeach" element={<MoraMoraBeach />} />

              <Route path="/RK_YouGotGame" element={<RKYouGotGame />} />
              <Route path="/RK_BallofFury" element={<RKBallofFury />} />
              <Route path="/RK_MonsterBuster" element={<RKMonsterBuster />} />
              <Route path="/RK_MetaSuperStars" element={<RKMetaSuperStars />} />
              <Route path="/RK_HexaHero" element={<RKHexaHero />} />

              <Route path="/reset-pwd" element={<ResetPwd />} />

              <Route path="/ut-select" element={<UnitySelect />} />
              <Route path="/ut-file" element={<UnityFile />} />
              <Route path="/ut-list" element={<UnityList />} />
              <Route path="/ut-detail" element={<UnityDetail />} />

              <Route path="/game-download" element={<GameDownload />} />

              {/* no in */}
              <Route path="/ai-history/:id" element={<Questions />} />

              {/* no in */}
              <Route path="/invitekey" element={<InviteCode />} />
              {/* no in */}
              <Route path="/invite-channel" element={<InviteChannel />} />

              <Route path="/gift-detail" element={<GiftDetail />} />
              <Route path="/coupon-code" element={<HelloKittyCoupon />} />
              <Route path="/user-gift" element={<UserGiftDetail />} />

              {/* no in */}
              <Route path="/play-video" element={<PlayVideo />} />

              {/* no in update use */}
              <Route path="/land" element={<Land />} />
              <Route path="/land-box" element={<LandBox />} />
              <Route path="/land-box-detail" element={<LandBoxDetail />} />
              <Route path="/land-box-nft" element={<LandBoxNft />} />
              <Route path="/land-nft-detail" element={<LandNftDetail />} />


              <Route path="/lottery" element={<Lottery />} />
              <Route path="/lottery-list" element={<LotteryList />} />
              <Route path="/lottery-agree" element={<LotteryAgree />} />

              <Route path="/juju-raiders" element={<JujuRaiders />} />

              <Route path="/demo-iframe" element={<DemoIframe />} />
              <Route path="/demo-solana" element={<DemoSolana />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
