import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import {useState, useEffect, useRef} from "react";
import * as React from "react";
import message from '../components/Message';
import {EmailSubscribe, HkCoupon} from "../libs/api";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import {sub} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

function HelloKittyCoupon() {
	const { t } = useTranslation();

	// 兑换成功弹窗，兑换失败toast
	const [modalVoucherRel, setModalVoucherRel] = useState(false);
	const openVoucherRel = () => setModalVoucherRel(true);
	const closeVoucherRel = () => {
		setModalVoucherRel(false);
		toSale();
	}
	const [voucherVal, setVoucherVal] = useState("");
	const [voucherRel, setVoucherRel] = useState<{image?: string;}>(null);
	const voucherRef = useRef(null);
	const changeVoucherVal = () => {
		setVoucherVal(voucherRef.current.value);
	}
	const voucherSubmit = () => {
		if (!voucherVal) {
			message.info("Please input code");
			return false;
		}
		HkCoupon({code: voucherVal}).then((res: any) => {
			if (!res.code) {
				setVoucherRel(res);
				openVoucherRel();
			}
		})
	}
const toSale = () => {
		window.location.href = window.location.origin + "/hello-kitty";
}
	return (
		<div className="hello-kitty">
			<Helmet>
				<title>MetaGaia Metaverse FAQs & Support</title>
				<meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
				<meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
			</Helmet>

			<div className="wrapper">
				<Header />
				<main>
					<section className="coupon-box">
						<div>
							<img className="coupon-img" src="./assets/images/hellokity/coupon-top-img.png" alt=""/>
						</div>
						<div className="coupon-container">
							<div className="container wow fadeInUp">
								<div className="coupon-info-box coupon-input">
									<div className="s-flex s-flex-center-row coupon-input-title">Input coupon code</div>
									<div className="s-flex s-flex-center-row coupon-input-text">
										<div className="s-flex s-flex-center-row input-text-box">
											<input className="input-text" maxLength={12} defaultValue={""} ref={voucherRef} onChange={changeVoucherVal}/>
										</div>
									</div>
									<div className="s-flex s-flex-center-row input-btn-box">
										<div className="s-flex s-flex-center-row input-btn s-cursor-pointer" onClick={voucherSubmit}>CONFIRM</div>
									</div>
								</div>
								<div className="coupon-info-box coupon-info">
									<div className="s-flex s-flex-center-row coupon-input-title">Intro</div>
									<div className="s-flex s-flex-center-row coupon-input-tips">intro intr o introintro in t rointro introint  r o introin t r o  intro introintro introin tro int  roint ro introin tro intro intr oint rintro intr o introintro in t rointro introint  r o introin t r o</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</main>
				{/*兑换结果详情*/}
				<Dialog onClose={closeVoucherRel} open={modalVoucherRel}>
					<div className="s-flex s-flex-center-row modal-box modal-box-hk modal-voucher-result">
						<div className="modal-body modal-body-hk">
							<div className="modal-container">
								<div className="s-flex s-flex-center-row">
									<img className="result-title-img" src="./assets/images/hellokity/combine-result-title.png" alt="" />
								</div>
								<div className="s-flex s-flex-center-row result-desc">
									You just get a coupon
								</div>
								<div className="s-flex s-flex-center-row">
									<img className="result-img" src={voucherRel && voucherRel.image} alt="" />
								</div>
								<div className="s-flex s-flex-center-row result-desc">
									<div className="result-desc-tips">
										This coupon could be used for purchaseing <span>Wonder Pass</span>
									</div>
								</div>
							</div>
							<div className="s-flex s-flex-center-row modal-btn">
								<div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={closeVoucherRel}>GO TO PURCHASE</div>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	);
}

export default HelloKittyCoupon;
