import { useEffect, useState } from 'react';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {
  MarketDetailData,
  MarketBuy,
  UrlParams2Obj,
  UserInfo, HkListedDetail, HkListedBuy,
} from './libs/api';
import message from './components/Message';
import storage from './libs/storage';
import { setCookie } from './libs/cookies';

import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#0E0E0E',
  border: '1px solid #9F1DED',
};

interface MarketObj {
  attributes?: AttributesObj[];
  consignmentObj?: ConsignObj;
  number?: string;
  description?: string;
  id?: string;
  image?: string;
  name?: string;
  status?: number;
  userNftId?: string;
  video?: string;

  nftId?: string;
}
interface AttributesObj {
  k?: string;
  v?: string;
}
interface ConsignObj {
  id?: string;
  metg?: string;
  price?: number;
  lowestMetg?: string;
  userId?: string;
}
function MarketDetailHk() {
  const { t } = useTranslation();

  const [openRecharge, setOpenRecharge] = React.useState(false);
  const handleOpenRecharge = () => setOpenRecharge(true);
  const handleCloseRecharge = () => setOpenRecharge(false);
  const [openResult, setOpenResult] = React.useState(false);
  const handleOpenResult = () => setOpenResult(true);
  const handleCloseResult = () => setOpenResult(false);
  const [openOrder, setOpenOrder] = React.useState(false);
  const handleOpenOrder = () => {
    if (JSON.parse(window.localStorage.getItem("isPhone"))) {
      setOpenOrder(true);
    } else {
      toPhone();
    }
  }
  function toPhone() {
    window.self.location.href = window.location.origin + '/login-phone?redirect=' + window.location.pathname + "?" + window.location.search;
  }
  const handleCloseOrder = () => setOpenOrder(false);
  const [isShowToken, setIsShowToken] = useState(false);

  useEffect(() => {
    getDetail();
    if (
      storage.getItem('userStatus') &&
      storage.getItem('userStatus') !== undefined
    ) {
      setIsShowToken(true);
      getUserInfo();
    }
  }, []);

  const [userAmount, setUserAmount] = useState(0);
  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserAmount(res.hk);
      }
    });
  }
  const [marketData, setMarketData] = useState<MarketObj>();
  function getDetail() {
    const params = UrlParams2Obj(window.location.search);
    HkListedDetail({ id: params.id }).then((res: any) => {
      if (!res.code) {
        setMarketData(res);
      }
    });
  }

  function orderBuy() {
    getUserInfo();
    HkListedBuy({ id: marketData && marketData.consignmentObj.id })
      .then((res: any) => {
        handleCloseOrder();
        if (res.code === 2001) {
          handleOpenRecharge();
        } else if (!res.code) {
          handleOpenResult();
        }
      })
      .catch(() => {
        handleCloseOrder();
      });
  }
  function toList(id: any) {
    window.self.location.href = window.location.origin + `/market-list`;
  }
  function toUser() {
    window.self.location.href = window.location.origin + '/user';
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Investor Lite Paper</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <main className="market-bg market-box">
        <header>
          {isShowToken && (
            <div className="row s-flex s-flex-between-row market-header">
              <a href="/home" target="_self">
                <img
                  className="img-logo wow fadeIn"
                  src="assets/images/logo_metagaia2_v2.png?v=20230413001"
                />
              </a>
              <div className="s-flex s-flex-center-start-row col col-12 col-lg-8">
                <h1 className="market-title wow fadeIn">
                  {marketData && marketData.name}
                </h1>
              </div>
              <div className="col col-12 col-lg-4">
                <div className="s-flex s-flex-center-end-row">
                  <span className="user-money-title">Star Chips:</span>
                  <span className="user-money">{userAmount}</span>
                </div>
              </div>
            </div>
          )}
          {!isShowToken && (
            <div className="s-flex s-flex-center-row market-header">
              <a href="/home" target="_self">
                <img
                  className="img-logo wow fadeIn"
                  src="assets/images/logo_metagaia2_v2.png?v=20230413001"
                />
              </a>
              <h1 className="market-title market-title-nomargin wow fadeIn">
                {marketData && marketData.name}
              </h1>
            </div>
          )}
        </header>
        <section className="market-detail-data">
          <div className="row">
            <div className="col col-12 col-lg-4 detail-img-box">
              {marketData && marketData.video ? (
                <video
                  autoPlay
                  muted
                  loop
                  id="hero_video"
                  className="detail-img"
                  playsInline
                >
                  <source src={marketData?.video} type="video/mp4" />
                </video>
              ) : (
                <img className="detail-img" src={marketData?.image} />
              )}
              <div className="s-flex detail-info-position">
                <div className="info-position-bg"></div>
                <div className="info-name">
                  #{marketData && marketData.number}
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-8 detail-info-box">
              <div className="info-mount">
                <span className="market-color-primary">
                  {marketData &&
                    marketData.consignmentObj &&
                    Number(marketData.consignmentObj.price)}
                </span>{' '}
                Star Chips{' '}
                {/*<span className="market-text-default">*/}
                {/*  ≈ ${' '}*/}
                {/*  {marketData &&*/}
                {/*    marketData.consignmentObj &&*/}
                {/*    Number(marketData.consignmentObj.price)}{' '}*/}
                {/*  USD*/}
                {/*</span>*/}
              </div>
              <div className="info-btn-buy">
                <button
                  className="market-btn market-btn-primary"
                  onClick={handleOpenOrder}
                >
                  {t("page.mdetail.BUY")}
                </button>
              </div>
              <div className="info-title-sub">STATE</div>
              <div className="info-desc">
                {marketData?.status === 1 ? "MINT" : marketData?.status === 2 ? "LESTED" : marketData?.status === 5 ? "COMBINE" : "MINT"}
              </div>
              {/*<div className="info-title-sub">{t("page.mdetail.DESCRIPTION")}</div>*/}
              {/*<div className="info-desc">*/}
              {/*  {marketData && marketData.description}*/}
              {/*</div>*/}
              <div className="info-title-sub">{t("page.mdetail.PROPERTIES")}</div>
              <div className="info-attr-box">
                {marketData &&
                  marketData.attributes &&
                  marketData.attributes.map((item, index) => (
                    <div className="info-attr-item" key={index}>
                      <div className="attr-item-content">{item.k}</div>
                      <div className="attr-item-title">{item.v}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal
        open={openRecharge}
        onClose={handleCloseRecharge}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="market-modal market-modal-recharge">
          <div className="s-flex modal-close">
            <ClearIcon
              className="s-cursor-pointer modal-close-ico"
              onClick={handleCloseRecharge}
            ></ClearIcon>
          </div>
          <div className="modal-main">
            <div className="modal-desc recharge-desc">
              Unfortunately, you have insufficient balance of Star Chips to complete this purchase
            </div>
            <div className="modal-text s-flex s-flex-between-row recharge-balance">
              <div>Your Balance</div>
              <div>{userAmount} Star Chips</div>
            </div>
            <div className="modal-text s-flex s-flex-between-row recharge-amount">
              <div>{t("page.mdetail.Amount")}</div>
              <div>
                {marketData &&
                  marketData.consignmentObj &&
                  Number(marketData.consignmentObj.price)}{' '}
                Star Chips
              </div>
            </div>
            <div className="modal-line"></div>
            <div className="modal-desc recharge-desc recharge-tips">
              {t("page.mdetail.Go")}
            </div>
            <div className="modal-btn recharge-btn-box">
              <button
                className="modal-btn modal-btn-all recharge-btn"
                onClick={toUser}
              >
                {t("page.mdetail.TOP")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openOrder}
        onClose={handleCloseOrder}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="market-modal market-modal-order">
          <div className="s-flex modal-close">
            <ClearIcon
              className="s-cursor-pointer modal-close-ico"
              onClick={handleCloseOrder}
            ></ClearIcon>
          </div>
          <div className="modal-main">
            <div className="modal-title">{t("page.mdetail.CONFIRM1")}</div>
            <div className="modal-text s-flex s-flex-between-row order-price">
              <div>{t("page.mdetail.PRICE")}</div>
              <div>
                {marketData &&
                  marketData.consignmentObj &&
                  Number(marketData.consignmentObj.price)}{' '}
                Star Chips
              </div>
            </div>
            <div className="modal-line"></div>
            <div className="modal-text s-flex s-flex-between-row order-subtotal">
              <div>{t("page.mdetail.SUBTOTAL")}</div>
              <div>
                <span className="modal-text-bold">
                  {marketData &&
                    marketData.consignmentObj &&
                    Number(marketData.consignmentObj.price)}
                </span>{' '}
                Star Chips
              </div>
            </div>
            <div className="modal-btn s-flex s-flex-between-row order-btn-box">
              <button
                className="modal-btn modal-btn-half modal-btn-default"
                onClick={handleCloseOrder}
              >
                {t("page.mdetail.CANCEL")}
              </button>
              <button
                className="modal-btn modal-btn-half modal-btn-primary"
                onClick={orderBuy}
              >
                {t("page.mdetail.CONFIRM")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openResult}
        onClose={handleCloseResult}
        hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="market-modal market-modal-tips">
          <div className="modal-main">
            <div className="modal-title">
              {t("page.mdetail.PURCHASE")}
              <br /> {t("page.mdetail.SUCCESSFUL")}
            </div>
            <div className="modal-btn tips-btn-box">
              <button
                className="modal-btn modal-btn-all modal-btn-default tips-btn"
                onClick={toUser}
              >
                {t("page.mdetail.GO1")}
              </button>
              <button
                className="modal-btn modal-btn-all modal-btn-primary tips-btn"
                onClick={toList}
              >
                {t("page.mdetail.GO2")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default MarketDetailHk;
