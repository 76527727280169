import {useEffect, useState} from "react";

export default function PlayVideo() {
    const [videoVolume, setVideoVolume] = useState(true);
    const [show,setShow] = useState(false);
    useEffect(() => {

    }, []);

    return <>
        <div style={{width: "100vw", height: "100vh", position: "relative", zIndex: "0"}}>
            <video onPlay={()=>{
                setShow(true)
            }} autoPlay controls={false} loop muted={videoVolume} playsInline preload="metadata" style={{width: "100%", height: "100%"}}>
                <source src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/running-trailer-v2.mp4?v=20230413001" type="video/mp4" />
            </video>
            {
                show && <img alt="" onClick={()=>{
                    setVideoVolume(!videoVolume)}
                } src={videoVolume ? "assets/images/video-volume-close.png?v=20230413001" : "assets/images/video-volume-open.png?v=20230413001"} style={{position: "absolute", zIndex: "99", top: "1rem", right: "1rem", width: "5rem", height: "5rem", cursor: "pointer"}}/>
            }
        </div>
    </>
}
