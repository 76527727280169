import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import {AccountRemove} from "./libs/api";

// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {useTranslation} from "react-i18next";

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
  );
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#000000',
  border: '1px solid #00FFFF',
  boxShadow: 0,
  p: 1,
};
// modal end

export default function Settings() {
  const { t } = useTranslation();


  const [modalRm, setModalRm] = useState(false);
  const handleOpenRm = () => setModalRm(true);
  const handleCloseRm = () => setModalRm(false);

  const onRemoveAccount = () => {
    AccountRemove({}).then((res: any) => {
      if (!res.code) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.self.location.href = window.location.origin + '/login';
      }
    })
  }

  function toEditUser() {
    window.self.location.href = window.location.origin + '/user-update';
  }
  function toSecurity() {
    window.self.location.href = window.location.origin + '/security';
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero s-settings">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container settings-body">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">SETTINGS</span>
                </h2>
                <div className="col col-12 s-flex s-flex-center-row">
                  <button
                    className="btn btn-default btn-set-high edit-btn btn-form-border-none margin-to-b"
                    onClick={toEditUser}
                  >
                    {t("page.setting.EDIT")}
                  </button>
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <button
                    className="btn btn-default btn-set-high edit-btn btn-form-border-none margin-to-s"
                    onClick={toSecurity}
                  >
                    {t("page.setting.SECURITY")}
                  </button>
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <button
                    className="btn btn-default btn-set-error edit-btn btn-form-border-none margin-to-s"
                    onClick={handleOpenRm}
                  >
                    DELETE YOUR ACCOUNT
                  </button>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>



        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={modalRm}
            onClose={handleCloseRm}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className="modal-box modal-recharge-box"
        >
          <Fade in={modalRm}>
            <Box sx={style}>
              <div className="s-flex close-box">
                <img
                    src="./assets/images/icon-modal-close.png"
                    onClick={handleCloseRm}
                />
              </div>
              <div className="s-flex s-flex-center-row modal-ct-title">

              </div>
              <div className="modal-recharge-pd modal-recharge-tip s-text-center">
                Your account will be deleted in 30 days, are you sure?
              </div>


              <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between modal-btn-top">
                <div
                    className="modal-btn modal-btn-half modal-btn-cancel"
                    onClick={handleCloseRm}
                >
                  {t("page.user.CANCEL")}
                </div>
                <div
                    className="modal-btn modal-btn-half modal-btn-confirm"
                    onClick={onRemoveAccount}
                >
                  {t("page.user.CONFIRM")}
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}
