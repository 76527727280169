import {Helmet} from 'react-helmet';
import {useEffect, useState, Suspense, useMemo} from "react";
import Storage from "../libs/storage";
import { Mesh } from "three";
import { useLoader,  Canvas } from "@react-three/fiber";
import { OrbitControls, useTexture } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import {useTranslation} from "react-i18next";

const style = {
    // width: "100vw",
    marginTop: 100,
    height: 500 // we can control scene size by setting container dimensions
};

export default function UnityDetail() {

    const [objUrl, setObjUrl] = useState("source/output/baked_mesh.obj");
    const [textureMapUrl, setTextureMapUrl] = useState("source/output/baked_mesh_tex0.png");
    const [normalMapUrl, setNormalMapUrl] = useState("source/output/baked_mesh_norm0.png");
    const [aoMapUrl, setAoMapUrl] = useState("source/output/baked_mesh_ao0.png");
    useEffect(() => {
        const info = Storage.getItem("unityData");
        // https://test-api.metagaia.io/api/v1/sys/get-image?url=
        const baseUrl = "";
        if (info.id) {
            for (let i in info.modelImage) {
                if (info.modelImage[i].imageUrl.includes(info.id.toString()) && info.modelImage[i].imageUrl.includes(".obj")) {
                    setObjUrl(baseUrl + info.modelImage[i].imageUrl);
                }
                if (info.modelImage[i].imageUrl.includes(info.id.toString()) && info.modelImage[i].imageUrl.includes("_tex")) {
                    setTextureMapUrl(baseUrl + info.modelImage[i].imageUrl);
                }
                if (info.modelImage[i].imageUrl.includes(info.id.toString()) && info.modelImage[i].imageUrl.includes("_norm")) {
                    setNormalMapUrl(baseUrl + info.modelImage[i].imageUrl);
                }
                if (info.modelImage[i].imageUrl.includes(info.id.toString()) && info.modelImage[i].imageUrl.includes("_ao")) {
                    setAoMapUrl(baseUrl + info.modelImage[i].imageUrl);
                }
            }
        }

        return () => {}
    }, []);
    function Scene() {
        const obj = useLoader(OBJLoader, objUrl);
        // const mtl = useLoader(MTLLoader, "source/output/baked_mesh.mtl");
        // mtl.setMaterials(mtl);
        const [colorMap, normalMap, aoMap] = useTexture([
            textureMapUrl,
            normalMapUrl,
            aoMapUrl,
        ])
        const geometry = useMemo(() => {
            let g;
            obj.traverse((c) => {
                if (c.type === "Mesh") {
                    const _c = c as Mesh;
                    g = _c.geometry;
                }
            });
            return g;
        }, [obj]);

        return <>
            <mesh geometry={geometry} scale={1}>
                <meshPhysicalMaterial map={colorMap} normalMap={normalMap} aoMap={aoMap} />
            </mesh>
        </>
    }
    return (
        <div className="bg-hexahero bg-faq">
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta property="og:description" content="" />
            </Helmet>

            <div className="wrapper">
                <main>
                    <section className="s-default unity-box">
                        <img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-nft"
                             data-top="transform: translateY(0%)"
                             data-top-bottom="transform: translateY(0%)" />

                        <div className="container unity-detail">
                            <div className="wow fadeInUp unity-title">
                                MY COLLECTION
                                <div className="wow fadeInUp s-flex s-flex-center-row s-cursor-pointer unity-back"
                                     onClick={() => {window.history.back();}}>
                                    <img className="unity-back-icon" src="assets/images/unity/btn-back.png?v=20230413001"/>
                                    <img className="unity-back-txt" src="assets/images/unity/btn-back-txt.png?v=20230413001"/>
                                </div>
                            </div>
                            <div className="wow fadeInUp" style={style}>
                                <Suspense fallback={null}>
                                    <Canvas camera={{ position: [2, 0, 2], fov: 15 }}>
                                        <ambientLight intensity={1.25} />
                                        <ambientLight intensity={0.1} />
                                        <directionalLight intensity={0.5} />
                                        <pointLight position={[10, 10, 10]} />
                                        <Scene></Scene>
                                        <OrbitControls></OrbitControls>
                                    </Canvas>
                                </Suspense>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}
