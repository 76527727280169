import { useState } from "react";

export default function GoPage() {
    const [url, setUrl] = useState("");
    const video = "dAkUxpg4Bj0";
    setUrl(`https://www.youtube.com/embed/${video}?rel=0&autoplay=1&fs=0&autohide=0&hd=0&loop=1&version=3&playlist=${video}`); // &mute=1
    // setUrl(`https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/running-trailer-v2.mp4`); // &mute=1
    window.location.href = url;
    return (<template></template>);
}
