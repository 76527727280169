import { useState } from "react";

export default function GoPage() {
    const [url, setUrl] = useState("");
    const video = "silLd2kxhlY";
    // setUrl(`https://www.youtube.com/embed/${video}?rel=0&autoplay=1&fs=0&autohide=0&hd=0&loop=1&version=3&playlist=${video}`); // &mute=1
    setUrl(`https://ftlifehk.wistia.com/medias/mwl8xidnrj`); // &mute=1
    window.location.href = url;
    return (<template></template>);
}
