import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import message from './components/Message';
import { UserNftHistory } from './libs/api';
import * as React from 'react';
import {useTranslation} from "react-i18next";

interface NftTransferObject {
  id: string;
  image: string;
  metg: string;
  name: string;
  nftId: string;
  transforType: number;
  txid: string;
  updatedAt: string;
  userId: string;
  walleetType: number;
}
export default function NftHistory() {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [isClick, setIsClick] = useState({});
  const [nftList, setNftList] = useState([]);
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    getNftList();
  }, []);
  function getNftList() {
    UserNftHistory({ pageSize: pageSize, page: pageCurrent }).then(
      (res: any) => {
        setShowNoData(true);
        if (nftList && nftList.length > 0) {
          setNftList(nftList.concat(res.rows));
        } else {
          setNftList(res.rows);
        }
        setPageTotal(res.count);
        setPageCurrent(pageCurrent + 1);
        if (pageCurrent * pageSize >= res.count) {
          setIsClick({ pointerEvents: 'none', color: '#7e7e7e' });
        } else {
          setIsClick({});
        }
      }
    );
  }
  function getPageMore() {
    getNftList();
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="container nft-his-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.history.NFT")}</span>
                </h2>

                <div className="his-list">
                  {showNoData && !nftList.length && (
                    <h3 className="no-data wow fadeInUp">{t("page.history.NO")}</h3>
                  )}
                  {nftList &&
                    nftList.map((item: any, index: number) => {
                      return (
                        <div className="row his-item" key={index}>
                          <div className="col col-12 col-lg-3 his-item-img">
                            <img src={item.image} />
                          </div>
                          <div className="col col-12 col-lg-9 s-flex his-item-info">
                            <div className="item-info-ts">
                              <div className="s-flex s-flex-center-start-row item-info-title">
                                {item.name}
                              </div>
                              <div className="s-flex s-flex-center-end-row item-info-status">
                                {item.transforType === 10 && 'MINT'}
                                {item.transforType === 1 && 'TRANSFER'}
                                {item.transforType === 2 && 'RECEIVE'}
                                {item.transforType === 8 && 'SOLD'}
                                {item.transforType === 9 &&
                                  'PURCHASE FROM MARKET PLACE'}
                              </div>
                            </div>
                            {item.transforType === 10 && (
                              <div className="item-info-amount">
                                <span className="item-info-num">
                                  {parseFloat(item.metg)}
                                </span>
                                <span className="item-info-unit">METG</span>
                              </div>
                            )}
                            <div>
                              <div className="item-info-time">
                                {item.updatedAt}
                              </div>
                              {item.transforType !== 10 && (
                                <div className="item-info-address">
                                  <span className="address-title">
                                    {item.transforType === 1 &&
                                      "Receiver's Address:"}
                                    {item.transforType === 2 &&
                                      "Sender's Address:"}
                                    {item.transforType === 8 &&
                                      'Buyer Address:'}
                                    {item.transforType === 9 &&
                                      'Seller Address:'}
                                  </span>
                                  <span className="address-info">
                                    {item.address}
                                  </span>
                                </div>
                              )}
                              {item.transforType !== 10 && (
                                <div className="item-info-address">
                                  <span className="address-title">
                                    {item.transforType === 1 && 'To:'}
                                    {item.transforType === 2 && 'From:'}
                                    {item.transforType === 8 && 'From:'}
                                    {item.transforType === 9 && 'From:'}
                                  </span>
                                  <span className="address-info">
                                    {item.walleetType === 1 &&
                                      'MetaGaia Wallet'}
                                    {item.walleetType === 2 && 'Solana Wallet'}
                                    {item.walleetType === 3 && 'Market Place'}
                                  </span>
                                </div>
                              )}
                              <div className="item-info-address">
                                <span className="address-title">Txid:</span>
                                <span className="address-info">
                                  {item.txid}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {nftList && pageTotal > 0 && (
                  <div className="s-flex s-flex-center-row user-nft-count">
                    <span className="nft-count">({pageTotal})</span>
                    <div onClick={getPageMore} style={isClick}>
                      <span className="nft-count-see">SEE</span>
                      <span className="nft-count-more">MORE</span>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
