import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useState} from "react";
import {useTranslation} from "react-i18next";

export default function HexHero() {
	const { t } = useTranslation();

	let [videoVolume, setVideoVolume] = useState(true);
	const [show,setShow] = useState(false);

	function toUrl(url: string) {
		window.location.href = url;
	}
	function toUri(url: string) {
		window.location.href = window.location.origin + url;
	}

	const [isShow, setIsShow] = useState(true);

	function closeBanner() {
		setIsShow(false);
	}
  return (
    <div className="wrapper wrapper-home">
		<Helmet>
            <title>HexaHero: A Premiere Play-to-Earn Gaming Experience</title>
            <meta name="description" content="Are you ready to Build, Battle, & Earn? HexaHero is a tournament in the future where teams build robots to compete for fame, glory and cash prizes. Do you have what it takes?" />
            <meta property="og:description" content="Are you ready to Build, Battle, & Earn? HexaHero is a tournament in the future where teams build robots to compete for fame, glory and cash prizes. Do you have what it takes?" />
          </Helmet>
      <Header />

      <main>
      <section className="h-full s-hexahero-hero">
				<img className="img-bg" src="assets/images/hexahero/img_bg.png?v=20230413001" data-anchor-target=".s-hexahero-hero"
					data-center = "transform: translateY(-10%)"
					data-top-bottom = "transform: translateY(30%)"/>

				<img className="img-intro img-intro1" src="assets/images/hexahero/img_intro1.png?v=20230413001"
					data-anchor-target=".s-hexahero-hero"
					data-center = "transform: translate(-50%, -50%) translateZ(0) scale(1) rotate(0deg)"
					data-top-bottom = "transform: translate(-50%, -50%) translateZ(0) scale(1.05) rotate(-30deg)"

				/>
				<img className="img-intro img-intro2" src="assets/images/hexahero/img_intro2.png?v=20230413001"
				 	data-anchor-target=".s-hexahero-hero"
					data-center = "transform: translate(-50%, -50%) translateZ(0) scale(0.8) rotate(0deg)"
					data-top-bottom = "transform: translate(-50%, -50%) translateZ(0) scale(1.4) rotate(270deg)"/>
				<img className="img-intro img-intro3" src="assets/images/hexahero/img_intro3.png?v=20230413001" />

				<div className="box-ctx">
					<div className="txt-title txt-tagline">Build.battle.earn</div>
					<div className="btn-pbn-box s-flex s-flex-center-row">
						<button className="btn-pbn" onClick={() => toUrl("https://hexahero.metagaia.io/")}>PLAY BETA NOW</button>
					</div>
					<ul className="nav nav-media wow fadeInUp">
						<li><a href="https://www.instagram.com/hexaherogame/" target="_blank">
							<img src="assets/images/hexahero/ico_instagram.png?v=20230413001" />
						</a></li>
						<li><a href="https://twitter.com/hexahero" target="_blank">
							<img src="assets/images/hexahero/ico_twitter.png?v=20230413001" />
						</a></li>
						<li><a href="https://hexaherogame.medium.com/" target="_blank">
							<img src="assets/images/hexahero/ico_medium.png?v=20230413001" />
						</a></li>
						<li><a href="https://discord.com/invite/Nqk2P57u7Y" target="_blank">
							<img src="assets/images/hexahero/ico_discord.png?v=20230413001" />
						</a></li>
						<li><a href="https://www.youtube.com/channel/UCKYtmmW9yeu42Gdx33bYrKQ" target="_blank">
							<img src="assets/images/hexahero/ico_youtobe.png?v=20230413001" />
						</a></li>
					</ul>
					<div className="powered-by-box">
						<div className="s-flex s-flex-center-row txt-title txt-powered-by wow fadeInUp">POWERED BY</div>
						<div className="s-flex s-flex-center-row">
							<a href="https://www.advokategroup.io/" target="_blank"><img className="img-logo logo-advokate wow fadeInUp" data-wow-delay="0.2s" src="assets/images/logo_advokate.png?v=20230413001" /></a>
						</div>
					</div>
				</div>

			</section>

	     	<section className="s-default s-trailer">
				<div className="video-ctrl-box">
					<video onPlay={()=>{
						setShow(true)
					}} className="trail-video" id="videoCtrl" autoPlay loop muted={videoVolume} playsInline preload="metadata">
						{/*<source src="assets/video/running-trailer-v2.mp4?v=20230413001" type="video/mp4" />*/}
						<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/running-trailer-v3.mp4?v=20230413001" type="video/mp4" />
					</video>
					{
						show && <img className="video-ctrl-audio shape-pointer" onClick={()=>{
						setVideoVolume(!videoVolume)}
						} src={videoVolume ? "assets/images/video-volume-close.png?v=20230413001" : "assets/images/video-volume-open.png?v=20230413001"}/>
					}
				</div>
			</section>

			<section className="s-select-role s-default">
				<div className="containter">
					<div className="box-select">
						<div className="box-select-hexa">
							<img className="img-title-select txt-hexa img-m" src="./assets/images/hexahero_select/txt_the_hexa.png?v=20230413001" />
							<img className="img-title-select txt-hexa img-pc" src="./assets/images/hexahero_select/txt_title_hexa.png?v=20230413001" />

							<div className="txt-desc txt-hexa txt-m">{t("page.game.a")}</div>
							<div className="swiper-card swiper swiper-hexa">
								<div className="swiper-wrapper">
									<div className="swiper-slide swiper-select-hexa b-select-hexa1 active">
										<div className="txt txt-num">01</div>
										<div className="txt txt-name">Demolisher</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa1b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa1a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa2">
										<div className="txt txt-num">02</div>
										<div className="txt txt-name">Sniper</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa2b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa2a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa3">
										<div className="txt txt-num">03</div>
										<div className="txt txt-name">Tanker</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa3b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa3a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa4">
										<div className="txt txt-num">04</div>
										<div className="txt txt-name">Striker</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa4b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa4a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa5">
										<div className="txt txt-num">05</div>
										<div className="txt txt-name">Shooter</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa5b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa5a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa6">
										<div className="txt txt-num">06</div>
										<div className="txt txt-name">Bomber</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa6b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa6a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa7">
										<div className="txt txt-num">07</div>
										<div className="txt txt-name">Supporter</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa7b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa7a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa8">
										<div className="txt txt-num">08</div>
										<div className="txt txt-name">Enchanter</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa8b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa8a.png?v=20230413001" />
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa9">
										<div className="txt txt-num">09</div>
										<div className="txt txt-name">Commander</div>
										<img className="img img1" src="./assets/images/hexahero_select/card_hexa9b.png?v=20230413001" />
										<img className="img img2" src="./assets/images/hexahero_select/card_hexa9a.png?v=20230413001" />
									</div>
								</div>
								<div className="swiper-button-prev">
									<img style={{width: '8rem', height: '8rem'}} src="./assets/images/swiper/swiper-prev.png" />
								</div>
								<div className="swiper-button-next">
									<img style={{width: '8rem', height: '8rem'}} src="./assets/images/swiper/swiper-next.png" />
								</div>
							</div>
						</div>

						<div className="box-selected">
							<div className="box-txt-selected">
								<div className="txt-selected txt-selected-hexa">
									<span className="txt txt1 active">DEMOLISHER</span>
									<span className="txt txt2">SNIPER</span>
									<span className="txt txt3">TANKER</span>
									<span className="txt txt4">STRIKER</span>
									<span className="txt txt5">SHOOTER</span>
									<span className="txt txt6">BOMBER</span>
									<span className="txt txt7">SUPPORTER</span>
									<span className="txt txt8">ENCHANTER</span>
									<span className="txt txt9">COMMANDER</span>
								</div>
								<div className="txt-selected txt-selected-hero">
									<span className="txt txt1 active">DEMOLISHER</span>
									<span className="txt txt2">SNIPER</span>
									<span className="txt txt3">TANKER</span>
									<span className="txt txt4">STRIKER</span>
									<span className="txt txt5">SHOOTER</span>
									<span className="txt txt6">BOMBER</span>
									<span className="txt txt7">SUPPORTER</span>
									<span className="txt txt8">ENCHANTER</span>
									<span className="txt txt9">COMMANDER</span>
								</div>
							</div>

							<div className="box-selected-hexa">
								<div className="img-selected img-selected1 active" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-01.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected2" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-02.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected3" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-03.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected4" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-04.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected5" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-05.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected6" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-06.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected7" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-07.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected8" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-08.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected9" style={{backgroundImage: 'url(assets/images/hexahero_select/robot-09.png?v=20230413001)'}}></div>
							</div>
							<div className="box-selected-hero">
								<div className="img-selected img-selected1 active" style={{backgroundImage: 'url(assets/images/hexahero_select/person-01.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected2" style={{backgroundImage: 'url(assets/images/hexahero_select/person-02.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected3" style={{backgroundImage: 'url(assets/images/hexahero_select/person-03.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected4" style={{backgroundImage: 'url(assets/images/hexahero_select/person-04.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected5" style={{backgroundImage: 'url(assets/images/hexahero_select/person-05.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected6" style={{backgroundImage: 'url(assets/images/hexahero_select/person-06.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected7" style={{backgroundImage: 'url(assets/images/hexahero_select/person-07.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected8" style={{backgroundImage: 'url(assets/images/hexahero_select/person-08.png?v=20230413001)'}}></div>
								<div className="img-selected img-selected9" style={{backgroundImage: 'url(assets/images/hexahero_select/person-09.png?v=20230413001)'}}></div>
							</div>
						</div>

						<div className="box-select-hero">
							<div className="swiper-card swiper swiper-hero">
								<div className="swiper-wrapper">
									<div className="swiper-slide swiper-select-hexa b-select-hexa1 active">
										<img className="img img1" src="assets/images/hexahero_select/card_hero1b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero1a.png?v=20230413001" />
										<div className="txt txt-name2">Demolisher</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa2">
										<img className="img img1" src="assets/images/hexahero_select/card_hero2b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero2a.png?v=20230413001" />
										<div className="txt txt-name2">Sniper</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa3">
										<img className="img img1" src="assets/images/hexahero_select/card_hero3b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero3a.png?v=20230413001" />
										<div className="txt txt-name2">Tanker</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa4">
										<img className="img img1" src="assets/images/hexahero_select/card_hero4b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero4a.png?v=20230413001" />
										<div className="txt txt-name2">Striker</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa5">
										<img className="img img1" src="assets/images/hexahero_select/card_hero5b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero5a.png?v=20230413001" />
										<div className="txt txt-name2">Shooter</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa6">
										<img className="img img1" src="assets/images/hexahero_select/card_hero6b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero6a.png?v=20230413001" />
										<div className="txt txt-name2">Bomber</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa7">
										<img className="img img1" src="assets/images/hexahero_select/card_hero7b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero7a.png?v=20230413001" />
										<div className="txt txt-name2">Supporter</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa8">
										<img className="img img1" src="assets/images/hexahero_select/card_hero8b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero8a.png?v=20230413001" />
										<div className="txt txt-name2">Enchanter</div>
									</div>
									<div className="swiper-slide swiper-select-hexa b-select-hexa9">
										<img className="img img1" src="assets/images/hexahero_select/card_hero9b.png?v=20230413001" />
										<img className="img img2" src="assets/images/hexahero_select/card_hero9a.png?v=20230413001" />
										<div className="txt txt-name2">Commander</div>
									</div>
								</div>
								<div className="swiper-button-prev">
									<img style={{width: '8rem', height: '8rem'}} src="./assets/images/swiper/swiper-prev.png" />
								</div>
								<div className="swiper-button-next">
									<img style={{width: '8rem', height: '8rem'}} src="./assets/images/swiper/swiper-next.png" />
								</div>
							</div>

							{/*<div className="b-select-hexa b-select-null">*/}
							{/*	<img className="img img1" src="assets/images/hexahero_select/card_hero.png" />*/}
							{/*</div>*/}
							<img className="img-title-select txt-hero img-m" src="assets/images/hexahero_select/txt_the_hero.png" />
							<img className="img-title-select txt-hero img-pc" src="assets/images/hexahero_select/txt_title_hero.png" />
							<div className="txt-desc txt-hero txt-m">The human pilot who increases the abilities of the synced Hexa during battle.</div>

						</div>
					</div>
				</div>
			</section>

			<section className="s-play-to-earn s-default">
				<div className="container">
					<div className="box-title">
						<img className="img-title" src="assets/images/hexahero/txt_play_to_earn.png" />
						<div className="txt-desc">{t("page.game.hh")}</div>
					</div>
				</div>
				<img className="img-play-to-earn img-play-to-earn-pc" src="assets/images/hexahero/img_play_to_earn_v2.png" />
				<img className="img-play-to-earn img-play-to-earn-m" src="assets/images/hexahero/img_play_to_earn_v2.png" />

			</section>

			<section className="s-game-mode s-default">
				<img className="img-title" src="assets/images/hexahero/txt_game_modes.png" />
				<div className="swiper-container b-slider-game-mode">
						<div className="b-pagination"></div>

						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="box-game-mode" style={{backgroundImage:'url(assets/images/hexahero/game_mode1_bg.jpg)'}}>

									<div className="box-game-mode-wrapper">
										<img className="mode-bg" src="assets/images/hexahero/game_mode1_bg.jpg" />
										<div className="mode-m">
											<img className="mode-img" src="assets/images/hexahero/game_mode1_img.png?v=20230413001" />
											<img className="mode-ico" src="assets/images/hexahero/game_mode1_ico.png" />
											<img className="mode-title" src="assets/images/hexahero/game_mode1_title.png" />
										</div>
										<div className="mode-ctx">
											<div>
												<p>{t("page.game.battle")}</p>
												<p>{t("page.game.Tournaments")}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="box-game-mode" style={{backgroundImage:'url(assets/images/hexahero/game_mode1_bg.jpg)'}}>
									<div className="box-game-mode-wrapper">
										<img className="mode-bg" src="assets/images/hexahero/game_mode2_bg.jpg" />
										<div className="mode-m">
											<img className="mode-img" src="assets/images/hexahero/game_mode2_img.png" />
											<img className="mode-ico" src="assets/images/hexahero/game_mode2_ico.png" />
											<img className="mode-title" src="assets/images/hexahero/game_mode2_title.png" />
										</div>

										<div className="mode-ctx">
											<div>
												<p></p>
												<p>
													<img className="img-subtitle" src="assets/images/hexahero/txt_fuse.png" />
													{t("page.game.Two")}
												</p>
												<p>
												<img className="img-subtitle" src="assets/images/hexahero/txt_land_sale.png" />
													{t("page.game.Players")}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="box-game-mode" style={{backgroundImage:'url(assets/images/hexahero/game_mode3_bg.jpg)' }}>
									<div className="box-game-mode-wrapper">
										<img className="mode-bg" src="assets/images/hexahero/game_mode3_bg.jpg" />

										<div className="mode-m">
											<img className="mode-img" src="assets/images/hexahero/game_mode3_img.png" />
											<img className="mode-ico" src="assets/images/hexahero/game_mode3_ico.png" />
											<img className="mode-title" src="assets/images/hexahero/game_mode3_title.png" />
										</div>
										<div className="mode-ctx">
											<div>
												<p>{t("page.game.Explore")}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="btn-ctrl btn-prev"></div>
						<div className="btn-ctrl btn-next"></div>

					</div>
			</section>

		  {
			  isShow && <section className="s-default s-down-ft" style={{display: 'none'}}>
				  <div className="container s-flex s-flex-center-column">
					  <img className="s-down-close s-cursor-pointer wow fadeInUp" src="./assets/images/ico/ico_close_line.png" onClick={closeBanner}  alt=""/>
					  <img className="ft-down-img ft-down-img-pc s-cursor-pointer wow fadeInUp" alt="" src="./assets/images/ft/down-bg-4-m.png" onClick={() => toUri("/hello-kitty")}/>
					  <img className="ft-down-img ft-down-img-m s-cursor-pointer wow fadeInUp" alt="" src="./assets/images/ft/down-bg-4-m.png" onClick={() => toUri("/hello-kitty")}/>
					  <div className="s-cursor-pointer ft-down-btn" onClick={() => toUri("/hello-kitty")}>Learn More</div>
				  </div>
			  </section>
		  }
        <Footer />
      </main>
    </div>
  );
}


