import { GMRanking, urlToObj } from "../libs/api";
import * as React  from "react";
import {useTranslation} from "react-i18next";

export default function Ranking() {
    const { t } = useTranslation();

    const [rankList, setRankList] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState<any>();
    const initData = () => {
        const params: any = urlToObj(window.location.href);
        const token = params.token || "";
        GMRanking({applicationMark: "MonsterBuster", token: token}).then((res: any) => {
            if (res.rows) {
                setRankList(res.rows);
            }
            if (res.user) {
                setUserInfo(res.user);
            }
        });
    }
    const clickJsToC = () => {
        // @ts-ignore
        if (window.vuplex) {
            sendMessageToCSharp();
        } else {
            window.addEventListener('vuplexready', sendMessageToCSharp);
        }
    }
    function sendMessageToCSharp() {
        // @ts-ignore
        window.vuplex.postMessage({ type: 'greeting', message: 'Hello from JavaScript!' });
    }
    React.useEffect(() => {
        document.body.style.backgroundColor = "#BFBFBF";
        initData();
    }, []);
    return (<main className="s-flex s-flex-center-column" style={{ backgroundColor: "rgba(121,54,168)"}}>
        <div className="gm-rk-box rk-score">
            <img className="gm-rk-bg" src="./assets/images/ranking/score-bg.png"/>
            <div className="ranking-content ranking-main">
                <div className="s-flex s-flex-center-row ranking-rk">
                    <div className="rk-item">
                        <div className="s-flex s-flex-center-row rk-item-img">
                            <div className="rk-item-img2">
                                <img className="rk-item-avatar" src={
                                    rankList && rankList[1] ? rankList[1].avatar : "./assets/images/team/team-A05.png"
                                }/>
                            </div>
                            <img className="rk-item-img-bg2" src="./assets/images/ranking/score-2.png"/>
                        </div>
                        <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">{ rankList && rankList[1] ? rankList[1].username : "-" }</div>
                        <div className="s-flex s-flex-center-row rk-text-bold">{ rankList && rankList[1] ? rankList[1].val : "-" }</div>
                    </div>
                    <div className="rk-item">
                        <div className="s-flex s-flex-center-row rk-item-img">
                            <div className="rk-item-img1">
                                <img className="rk-item-avatar" src={
                                    rankList && rankList[0] ? rankList[0].avatar : "./assets/images/team/team-A05.png"
                                }/>
                            </div>
                            <img className="rk-item-img-bg1" src="./assets/images/ranking/score-1.png"/>
                        </div>
                        <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">{ rankList && rankList[0] ? rankList[0].username : "-" }</div>
                        <div className="s-flex s-flex-center-row rk-text-bold-big">{ rankList && rankList[0] ? rankList[0].val : "-" }</div>
                    </div>
                    <div className="rk-item">
                        <div className="s-flex s-flex-center-row rk-item-img">
                            <div className="rk-item-img3">
                                <img className="rk-item-avatar" src={
                                    rankList && rankList[2] ? rankList[2].avatar : "./assets/images/team/team-A05.png"
                                }/>
                            </div>
                            <img className="rk-item-img-bg3" src="./assets/images/ranking/score-3.png"/>
                        </div>
                        <div className="s-flex s-flex-center-row rk-text-normal rk-title-ellipsis">{ rankList && rankList[2] ? rankList[2].username : "-" }</div>
                        <div className="s-flex s-flex-center-row rk-text-bold">{ rankList && rankList[2] ? rankList[2].val : "-" }</div>
                    </div>
                </div>
                <div className="ranking-other">
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">4</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[3] ? rankList[3].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[3] ? rankList[3].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[3] ? rankList[3].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">5</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[4] ? rankList[4].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[4] ? rankList[4].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[4] ? rankList[4].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">6</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[5] ? rankList[5].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[5] ? rankList[5].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[5] ? rankList[5].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">7</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[6] ? rankList[6].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[6] ? rankList[6].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[6] ? rankList[6].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">8</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[7] ? rankList[7].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[7] ? rankList[7].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[7] ? rankList[7].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num rk-text-num-bold">9</div>
                            <div className="other-avatar"><img className="other-avatar-img" src={
                                rankList && rankList[8] ? rankList[8].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[8] ? rankList[8].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[8] ? rankList[8].val : "-" }</div>
                    </div>
                    <div className="s-flex s-flex-between-row other-bg">
                        <div className="s-flex">
                            <div className="other-num other-num-big rk-text-num-bold">10</div>
                            <div className="other-avatar other-avatar-big"><img className="other-avatar-img" src={
                                rankList && rankList[9] ? rankList[9].avatar : "./assets/images/team/team-A05.png"
                            }/></div>
                            <div className="other-name rk-text-normal">{ rankList && rankList[9] ? rankList[9].username : "-" }</div>
                        </div>
                        <div className="other-number rk-text-normal">{ rankList && rankList[9] ? rankList[9].val : "-" }</div>
                    </div>
                </div>
            </div>
            <div className="ranking-content ranking-cur">
                <div className="s-flex s-flex-between-row other-bg">
                    <div className="s-flex">
                        <div className="other-num rk-text-num-bold">{ userInfo ? userInfo.ranking : "-" }</div>
                        <div className="other-avatar"><img className="other-avatar-img" src={
                            userInfo ? userInfo.avatar : "./assets/images/team/team-A05.png"
                        }/></div>
                        <div className="other-name rk-text-normal">{ userInfo ? userInfo.username : "-" }</div>
                    </div>
                    <div className="other-number rk-text-normal">{ userInfo ? userInfo.val : "-" }</div>
                </div>
            </div>
        </div>
        <div className="s-flex s-flex-center-row rk-btn-box" style={{    width: "100%",
            borderLeft: "3px solid #FFF",
            borderRight: "3px solid #FFF",
            borderBottom: "5px solid #FFF"}}>
            <img className="s-cursor-pointer rk-btn-btn" src="assets/images/ranking/btn-mb.png" onClick={clickJsToC}/>
        </div>
    </main>);
}
