// import Header from "../Header";
// import Footer from "../Footer";
import { FtAnsRel } from "../libs/api";
import * as React  from "react";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";

export default function Start() {
    const { t } = useTranslation();

    const params = window.location.search;
    const tmp = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE0NjAwMjgwNTAzMzQwMjc3NzYiLCJlbWFpbCI6Ijg5Njk1NjY5MkBxcS5jb20iLCJwaG9uZSI6IjE4Ni0xMDAwMDAwMCIsImlhdCI6MTY1NTcxMTE0MywiZXhwIjoxNjU4MzAzMTQzfQ.uREnvlZOxiqWci9bTCGWTRLvIGNtKlY-yaxoXtfSehU";
    let token = "";
    if (params && params.indexOf("=") > -1) {
        token = params.split("=")[1].replace(/^\{/ig, "").replace(/\}$/ig, "");
    }
    if (token && token === tmp) {
        token = "";
    }
    const [result, setResult] = useState(0);

    function initData() {
        if (token) {
            FtAnsRel({ token: token }).then((res: any) => {
                if (!res.code) {
                    setResult(res.answerResult ? res.agent ? 3 : 2 : 1);
                }
            });
        } else {
            setResult(1);
        }
    }
    useEffect(() => {
        initData();
    }, []);
    return (<div className="wrapper wrapper-home">
        {/*<Header />*/}
        <main>
            <section className="s-hero">
                <img className="img-bg" src="assets/images/bg1.png" data-anchor-target=".s-hero"
                     data-center="transform: translateY(0%)"
                     data-top-bottom="transform: translateY(40%)" />
                <div className="container">
                    {
                        result === 2 &&
                      <div className="ft-ques-start">
                        <div className="ft-xx-title ft-title-cn ft-text-center">恭喜您！</div>
                        <div className="ft-xx-title ft-title-cn ft-text-center">您已成功獲取保險中介人(IIQE)考試費用豁免。</div>
                        <div className="ft-xx-ct ft-ct-cn">提醒您，必須經富通保險報名參加相關考試，方可豁免考試費用。</div>
                        <div className="ft-xx-title ft-title-en ft-text-center">Congratulations! </div>
                        <div className="ft-xx-title ft-title-en ft-text-center">You are entitled to a waiver of IIQE exam fee.</div>
                        <div className="ft-xx-ct ft-ct-en">Remarks: You are required to enroll the IIQE exam via FTLife Insurance Co Ltd.</div>
                      </div>
                    }
                    {
                        result === 3 &&
                      <div className="ft-ques-start">
                        <div className="ft-ss-title ft-title-cn ft-text-center">恭喜您！</div>
                        <div className="ft-ss-title ft-title-cn ft-text-center">您已成功摘取100粒星星。</div>
                        <div className="ft-ss-title ft-title-en">Congratulations! </div>
                        <div className="ft-ss-title ft-title-en">You are entitled to 100 stars.</div>
                      </div>
                    }
                    {
                        result === 1 &&
                      <div className="ft-ques-start">
                        <div className="ft-wc-title ft-title-cn">歡迎來到「富通保險總部大樓」！</div>
                        <div className="ft-wc-ct ft-ct-cn">大樓展區內容豐富，您還可以到不同展區參與答問遊戲，贏取禮品！</div>
                        <div className="ft-wc-ct ft-ct-cn">（註：只需在6個指定展區正確回答問題，便可獲得禮品1份，萬勿錯過！每個帳號最多只能獲取１份獎賞。）</div>
                        <div className="ft-wc-ct ft-ct-cn">馬上展開您的新旅程吧！</div>
                        <div className="ft-wc-ct ft-ct-last ft-ct-cn">
                          <div>完成回答六條問題後，可回來我這裡領取獎品。</div>
                          <div className="ft-ct-line"></div>
                        </div>
                        <div className="ft-wc-title ft-title-en">Welcome to "FTLife Tower "!</div>
                        <div className="ft-wc-ct ft-ct-en">The exhibition halls are rich in content. You can explore everywhere and win a prize by completing quizzes!</div>
                        <div className="ft-wc-ct ft-ct-en">(Note: You must complete all 6 quizzes with correct answers to get the prize. Each account is entitled to ONE reward at most.)</div>
                        <div className="ft-wc-ct ft-ct-en">Act now!</div>
                        <div className="ft-wc-ct ft-ct-top ft-ct-en">
                          <div>Please visit me again once you answered all 6 questions for your prize.</div>
                          <div className="ft-ct-line"></div>
                        </div>
                      </div>
                    }
                </div>
            </section>
            {/*<Footer />*/}
        </main>
    </div>);
}
