import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { UserInfo, GameList } from './libs/api';
import storage from './libs/storage';
import message from './components/Message';

// modal start
import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function GameHall() {
  const { t } = useTranslation();

  const [userAmount, setUserAmount] = useState(0);
  const [isShowToken, setIsShowToken] = useState(false);
  const [gameList, setGameList] = useState([]);

  useEffect(() => {
    getGameList();
    if (
      storage.getItem('userStatus') &&
      storage.getItem('userStatus') !== undefined
    ) {
      setIsShowToken(true);
      getUserInfo();
    }
  }, []);

  function getGameList() {
    GameList({}).then((res: any) => {
      if (!res.code) {
        setGameList(res);
      }
    });
  }

  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserAmount(res.metg);
      }
    });
  }

  function toDetail(item: any) {
    if (
      !storage.getItem('userStatus') ||
      storage.getItem('userStatus') === undefined
    ) {
      window.self.location.href =
        window.location.origin + '/login?redirect=/game-hall';
      return;
    }
    if (item.enable === 1) {
      message.info('Game does not exist');
      return;
    }
    if (!item.isPlay) {
      message.info('This game has reached its limit today');
      return;
    }
    window.self.location.href =
      window.location.origin + `/game-detail?id=${item.id}`;
  }

  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="hall-box">
                <h2 className="txt-default-title wow fadeInUp user-title">
                  <span className="txt">{t("page.hall.ALL")}</span>
                </h2>
                {isShowToken && (
                  <div className="s-flex s-flex-center-end-row hall-list-token">
                    <span className="user-money-title">{t("page.hall.METG")}</span>
                    <span className="user-money">{userAmount}</span>
                  </div>
                )}
                <div className="hall-data">
                  {gameList &&
                    gameList.map((item: any, index) => (
                      <div
                        key={index}
                        className="s-flex s-flex-center-start-row data-item s-cursor-pointer"
                        onClick={() => toDetail(item)}
                      >
                        <img className="data-img" src={item.image} />
                        {item.enable === 1 && (
                          <div className="s-flex s-flex-center-column text-box">
                            <img
                              className="text-img-bg"
                              src="./assets/images/game/game-text-bg.png"
                            />
                            <img
                              className="coming-soon"
                              src="./assets/images/game/coming-soon.png"
                            />
                          </div>
                        )}
                        {item.enable !== 1 && item.playStatus === 1 && (
                          <div className="s-flex s-flex-center-column text-box">
                            <img
                              className="text-img-bg"
                              src="./assets/images/game/game-text-bg.png"
                            />
                            <img
                              className="play-free"
                              src="./assets/images/game/play-free.png"
                            />
                          </div>
                        )}
                        {item.enable !== 1 &&
                          (item.playStatus === 2 || item.playStatus === 3) && (
                            <div className="s-flex s-flex-center-column text-box">
                              <img
                                className="text-img-bg"
                                src="./assets/images/game/game-text-bg.png"
                              />
                              <div className="s-flex s-flex-center-column pay-box">
                                <div className="s-flex s-flex-center-row pay-how">
                                  <span className="pay-num">
                                    {Number(item.value)}
                                  </span>
                                  <img
                                    className="pay-icon"
                                    src="./assets/images/game/game-icon.png"
                                  />
                                </div>
                                <img
                                  className="pay-img"
                                  src="./assets/images/game/game-pay.png"
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
