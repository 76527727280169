import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import message from './components/Message';
import { NftDetail, ListCancel } from './libs/api';
import * as React from 'react';
import {useTranslation} from "react-i18next";

interface NftInfo {
  id?: string;
  name?: string;
  image?: string;
  video?: string;
  description?: string;
  attributes?: [AttributesChild];
  consignmentObj: {
    id: string;
    metg: string;
    price: string;
  };
  lowestMetg?: string;
}

interface AttributesChild {
  trait_type: string;
  value: string;
}
export default function ListDetail() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [nftInfo, setNftInfo] = useState<NftInfo>();
  const [listMetg, setListMetg] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);

  useEffect(() => {
    NftDetail({ id: window.location.search.split('uuid=')[1] }).then(
      (res: any) => {
        if (!res.code) {
          setNftInfo(res);
          setListMetg(parseFloat(res.consignmentObj.metg));
          setLowPrice(parseFloat(res.lowestMetg));
        }
      }
    );
  }, []);
  function toUser() {
    window.self.location.href = window.location.origin + '/user';
  }
  function removeList() {
    ListCancel({ consignmentId: nftInfo?.consignmentObj.id }).then(
      (res: any) => {
        if (!res.code) {
          window.self.location.href = window.location.origin + '/user';
        }
      }
    );
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container nft-detail-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{nftInfo?.name}</span>
                </h2>
                <div className="row nft-detail">
                  <div className="col col-12 col-lg-5 nft-detail-img">
                    {nftInfo && nftInfo?.video ? (
                      <video autoPlay muted loop id="hero_video" playsInline>
                        <source src={nftInfo?.video} type="video/mp4" />
                      </video>
                    ) : (
                      <img src={nftInfo?.image} />
                    )}
                  </div>
                  <div className="col col-12 col-lg-7 nft-detail-info">
                    <div className="metg-price">
                      <span className="metg-num">{listMetg}</span> METG
                      <span className="metg-equal">
                        ≈ ${nftInfo?.consignmentObj.price}
                      </span>
                    </div>
                    <div className="metg-low-price">
                      {t("page.listDetail.Lowest")} <span>{lowPrice} METG</span>
                    </div>
                    <div>
                      <button
                        className="btn btn-default btn-nft-detail-list no-left"
                        onClick={removeList}
                      >
                        {t("page.listDetail.Remove")}
                      </button>
                    </div>
                    <div className="nft-detail-title">{t("page.listDetail.DESCRIPTION")}</div>
                    <div className="nft-detail-desc">
                      {nftInfo?.description}
                    </div>
                    <div className="nft-detail-title">{t("page.listDetail.PROPERTIES")}</div>
                    <div className="nft-detail-prop-item">
                      {nftInfo?.attributes?.map((item, index) => {
                        return (
                          <div className="nft-detail-props" key={index}>
                            <div className="nft-detail-prop-title">
                              {item.trait_type}
                            </div>
                            <div className="nft-detail-prop-text">
                              {item.value}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
