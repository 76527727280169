import Header from '../Header';
import Footer from '../Footer';
import {Helmet} from 'react-helmet';
import { useState } from 'react';
import { SendPhoneCode, BindPhone } from '../libs/api';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import * as React from 'react';
import message from "../components/Message";
import {useTranslation} from "react-i18next";

const timeNum = 120;
export default function InfoPhone() {
  const { t } = useTranslation();

  const phoneAreaList = [
    { name: '+86', value: '+86' },
    { name: '+861', value: '+861' },
    { name: '+862', value: '+862' },
    { name: '+863', value: '+863' },
    { name: '+864', value: '+864' },
    { name: '+865', value: '+865' },
    { name: '+866', value: '+866' },
  ];
  const [agreement, setAgreement] = useState(true);
  const [phoneArea, setPhoneArea] = React.useState(phoneAreaList[0].value);
  const [validMsgPhone, setValidMsgPhone] = useState('');
  const [validMsgCode, setValidMsgCode] = useState('');
  const [isClickPhone, setIsClickPhone] = useState(false);
  const [isClickPhoneMsg, setIsClickPhoneMsg] = useState('GET CODE');
  const [isClickPhoneNum, setIsClickPhoneNum] = useState(timeNum);
  const [isLoading, setIsLoading] = useState(false);
  const [agreementFT, setAgreementFT] = useState(true);

  const handleChange = (event: SelectChangeEvent) => {
    setPhoneArea(event.target.value);
  };

  function SelectIcon(e: any) {
    if (e.className.includes('MuiSelect-iconOpen')) {
      return (
        <div className="MuiOutlinedInput-select-icon">
          <div
            style={{
              width: '0.5rem',
              height: '0.5rem',
              transform: 'rotate(45deg)',
              borderLeft: '1px solid #00FFFF',
              borderTop: '1px solid #00FFFF',
              position: 'relative',
              top: '',
            }}
          ></div>
        </div>
      );
    }
    return (
      <div className="MuiOutlinedInput-select-icon">
        <div
          style={{
            width: '0.5rem',
            height: '0.5rem',
            transform: 'rotate(45deg)',
            borderRight: '1px solid #00FFFF',
            borderBottom: '1px solid #00FFFF',
            position: 'relative',
            top: '-0.25rem',
          }}
        ></div>
      </div>
    );
  }

  function validPhone() {
    // @ts-ignore
    const val = document.querySelector('#phone').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPhone(t("validate.information"));
      return false;
    }
    if (/^\d+$/gi.test(val)) {
      setValidMsgPhone('');
      return true;
    } else {
      setValidMsgPhone(t("validate.number"));
      return false;
    }
  }
  function validPhoneArea() {
    // @ts-ignore
    const val = document.querySelector('#phoneArea').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgPhone(t("validate.information"));
      return false;
    }
    if (/^\d+$/gi.test(val)) {
      setValidMsgPhone('');
      return true;
    } else {
      setValidMsgPhone(t("validate.number"));
      return false;
    }
  }
  function validCode() {
    // @ts-ignore
    const val = document.querySelector('#phoneCode').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgCode(t("validate.information"));
      return false;
    }
    if (/^\d{6}$/gi.test(val)) {
      setValidMsgCode('');
      return true;
    } else {
      setValidMsgCode(t("validate.number"));
      return false;
    }
  }
  function sendCode() {
    if (validPhoneArea() && validPhone()) {
      // @ts-ignore
      const phoneArea = document.querySelector('#phoneArea').value.trim();
      // @ts-ignore
      const phone = document.querySelector('#phone').value.trim();
      if (!validPhone()) {
        return false;
      }
      setValidMsgCode('');
      SendPhoneCode({
        phone: phone,
        areaCode: phoneArea,
      }).then(() => {
        let time = timeNum;
        setIsClickPhone(true);
        const interval = setInterval(() => {
          setIsClickPhoneNum(time--);
          setIsClickPhoneMsg(time + 's');
          if (time < 0) {
            setIsClickPhone(false);
            setIsClickPhoneNum(timeNum);
            setIsClickPhoneMsg('GET CODE');
            clearInterval(interval);
          }
        }, 1000);
      }).catch(() => {
        setIsClickPhone(false);
      });
      return true;
    }
  }
  function bindPhone() {
    if (validPhoneArea() && validPhone() && validCode() && agreementFT) {
      // @ts-ignore
      const phoneArea = document.querySelector('#phoneArea').value.trim();
      // @ts-ignore
      const phone = document.querySelector('#phone').value.trim();
      // @ts-ignore
      const phoneCode = document.querySelector('#phoneCode').value.trim();
      setIsLoading(true);
      BindPhone({
        phone: phone,
        areaCode: phoneArea,
        phoneCode: phoneCode
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code) {
            toHome();
          }
        });
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
    if (!agreementFT) {
      message.info("Check the agreement");
    }
  }
  function toHome() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      if (redirectUrl.indexOf(".") > -1) {
        window.self.location.href = redirectUrl;
      } else {
        window.self.location.href = window.location.origin + redirectUrl;
      }
    } else {
      window.self.location.href = window.location.origin;
    }
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container">
                <h2 className="txt-default-title wow fadeInUp bind-phone-title">
                  <span className="txt">SMS VERIFICATION</span>
                </h2>
                <h5 className="txt-default-title wow fadeInUp bind-phone-title-sub">
                  <span className="txt">
                    step 2 of 2
                  </span>
                </h5>
                <div className="s-flex s-flex-center-row">
                  <div className="row form-box bind-phone">
                    <div className="login-ct">
                      <div className="col col-12 form-item">
                        <div className="form-label">Phone Number</div>
                        <div className="s-flex s-flex-between-row form-value">
                          {/*<Select*/}
                          {/*    value={phoneArea}*/}
                          {/*    onChange={handleChange}*/}
                          {/*    IconComponent={SelectIcon}*/}
                          {/*>*/}
                          {/*    {*/}
                          {/*        phoneAreaList.map((item, index) => {*/}
                          {/*            return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>;*/}
                          {/*        })*/}
                          {/*    }*/}
                          {/*</Select>*/}
                          <div className=" btn-border-none s-flex s-flex-between-row">
                            <div>+</div>
                            <input
                              type="text"
                              id="phoneArea"
                              minLength={1}
                              maxLength={5}
                              defaultValue={852}
                              placeholder=""
                              onBlur={validPhoneArea}
                            />
                            <div
                              style={{
                                width: '1px',
                                height: '2rem',
                                backgroundColor: '#00FFFF',
                              }}
                            ></div>
                          </div>
                          <input
                            type="phone"
                            id="phone"
                            minLength={1}
                            maxLength={15}
                            placeholder=""
                            onBlur={validPhone}
                          />
                        </div>
                        <div className="form-valid">{validMsgPhone}</div>
                      </div>
                      <div className="col col-12 form-item">
                        <div className="form-label">Confirm SMS Code</div>
                        <div className="s-flex s-flex-between-row form-value">
                          <button
                            className="btn btn-default btn-border-none"
                            onClick={sendCode}
                            disabled={isClickPhone}
                          >
                            {isClickPhoneMsg}
                          </button>
                          <input
                            type="text"
                            id="phoneCode"
                            minLength={6}
                            maxLength={6}
                            placeholder={t("placeholder.Code")}
                            onBlur={validCode}
                          />
                        </div>
                        <div className="form-valid">{validMsgCode}</div>
                      </div>
                      <div className="col col-12 form-item register-tips">
                        By signing up, you agree to the
                        <a
                          href="/agreement?type=1"
                          target="_blank"
                          className="text-line-bottom text-line-lr"
                        >
                          Terms and Conditions
                        </a>{' '}
                        &
                        <a
                          href="src/Agreement?type=0"
                          target="_blank"
                          className="text-line-bottom text-line-l"
                        >
                          Privacy Policy
                        </a>
                        .
                      </div>
                      <div className="col col-12 form-item s-flex s-flex-center-start-row register-agree">
                        {agreement ? (
                          <img
                            className="checkbox-agree"
                            src="./assets/images/user/icon-checkbox-select.png?v=20230413001"
                            onClick={() => {
                              setAgreement(false);
                            }}
                          />
                        ) : (
                          <img
                            className="checkbox-agree"
                            src="./assets/images/user/icon-checkbox.png?v=20230413001"
                            onClick={() => {
                              setAgreement(true);
                            }}
                          />
                        )}
                        <span>
                          I would like to subscribe MetaGaia newsletter
                        </span>
                      </div>

                      <div className="col col-12 form-item s-flex s-flex-center-start-row register-agree">
                        {agreementFT ? (
                            <img
                                className="checkbox-agree"
                                src="./assets/images/user/icon-checkbox-select.png?v=20230413001"
                                onClick={() => {
                                  setAgreementFT(false);
                                }}
                            />
                        ) : (
                            <img
                                className="checkbox-agree"
                                src="./assets/images/user/icon-checkbox.png?v=20230413001"
                                onClick={() => {
                                  setAgreementFT(true);
                                }}
                            />
                        )}
                        <span>
                          I agree with the
                            <a
                                href="/agreement?type=2"
                                target="_blank"
                                className="text-line-bottom-wt text-line-l"
                            >
                            Personal Information Collection Statement
                          </a>
                        </span>
                      </div>
                      <div className="s-flex s-flex-center-row">
                        <button
                          onClick={bindPhone}
                          className="btn btn-default btn-form-login btn-from-bind-phone"
                          disabled={isLoading}
                        >
                          SIGN UP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
