// Mint
import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import * as React from "react";

export default function LandBox() {

    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default s-land">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container land-box">
                                <div className="s-flex s-flex-center-row">
                                    <h2 className="wow fadeInUp land-title">S Land Blind Box</h2>
                                </div>
                                <div className="wow fadeInUp box-list">
                                    <div className="detail-ct">
                                        <div className="detail-img-box"><img className="detail-img" src="assets/images/heros/15.png?v=20230413001" alt=""/></div>
                                        <div className="nft-swiper nft-swiper-m">
                                            <div className="swiper slider-land-box">
                                                <div className="swiper-wrapper">
                                                    {
                                                        ["1","2","3","4","5","6","7","8"].map((item, index) =>
                                                            <div className="swiper-slide s-flex">
                                                                { index === 0 && <div className="box-space"></div>}
                                                                <div style={{width: "100%"}}>
                                                                    <div className="slide-img-box">
                                                                        <img className="slide-img" src="assets/images/heros/15.png?v=20230413001" alt=""/>
                                                                    </div>
                                                                    <div className="slide-text">
                                                                        Slide {item}
                                                                    </div>
                                                                </div>
                                                                { index === ["1","2","3","4","5","6","7","8"].length - 1 && <div className="box-space"></div>}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="swiper-button-prev"></div>
                                                <div className="swiper-button-next"></div>
                                            </div>
                                        </div>
                                        <div className="detail-text">
                                            <div className="title">Mint a S Land Blind Box</div>
                                            <div className="title-sub">Selling Price:  0.4 METG ≈ $ 90 USD</div>
                                            <div className="text-attribute">
                                                <div className="item-left">
                                                    <div className="item-title-sub">Land Style: Moon Land</div>
                                                    <div className="item-title-sub">landform: Plain</div>
                                                    <div className="item-title-sub desc">DESCRIPTION</div>
                                                    <div className="item-text">jdhsdfk. hdshdlsdhlkashd. skhdkd dasdkhd akshdoeur oruwp eo eucjnc kdjfh kskfhh nvnv o  dn an dnfqi mxlzoiucnmawo mxmc jdhsdfk. hdshdlsdhlkashd. skhdkd dasdkhd akshdoeur oruwp eo </div>
                                                    <div>
                                                        {
                                                            false && <div className="btn btn-default btn-mint btn-soldout">SOLD OUT</div>
                                                        }
                                                        {
                                                            true && <div className="btn btn-default btn-mint btn-soldout">START AT AUG. 24TH 10:30 AM EDT</div>
                                                        }
                                                        {
                                                            false && <button className="btn btn-default btn-mint">MINT</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="item-right">
                                                    <div className="item-title-sub">Wallet: <span className="item-text">Dgjskwoep20dcnwikw</span></div>
                                                    <div className="item-line"></div>
                                                    <div className="item-title">Collection:</div>
                                                    <div className="item-title-sub collect">Total Available: <span>50</span></div>
                                                    <div className="item-title-sub collect">Redeemed: <span>50</span></div>
                                                    <div className="item-title-sub collect">Remaining: <span>50</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nft-swiper nft-swiper-pc">
                                        <div className="swiper slider-land-box">
                                            <div className="swiper-wrapper">
                                                {
                                                    ["1","2","3","4","5","6","7","8"].map((item, index) =>
                                                        <div className="swiper-slide s-flex">
                                                            { index === 0 && <div className="box-space"></div>}
                                                            <div style={{width: "100%"}}>
                                                                <div className="slide-img-box">
                                                                    <img className="slide-img" src="assets/images/heros/15.png?v=20230413001" alt=""/>
                                                                </div>
                                                                <div className="slide-text">
                                                                    Slide {item}
                                                                </div>
                                                            </div>
                                                            { index === ["1","2","3","4","5","6","7","8"].length - 1 && <div className="box-space"></div>}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="swiper-button-prev"></div>
                                            <div className="swiper-button-next"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
}
