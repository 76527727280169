import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import message from './components/Message';
import {
  GameDetailInfo,
  GameLogs,
  UserInfo,
  UrlParams2Obj,
  OrderRecharge,
  PayStripe,
  GameStart,
  GameEnd,
} from './libs/api';
import { removeCookie, getCookie } from './libs/cookies';
import { PublicKey } from '@solana/web3.js';

// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import storage from './libs/storage';

// table start
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import encrypt from "./libs/encrypt";
import {useTranslation} from "react-i18next";

const tableStyle = {
  backgroundColor: 'transparent',
  border: 0,
  color: '#ffffff',
  boxShadow: 0,
  fontSize: '1rem',
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: 'Barlow-Regular, Barlow',
    fontWeight: '400',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Barlow-Bold, Barlow',
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    background: 'linear-gradient(180deg, #0C4B68 0%, #0D2245 100%)',
  },
}));
// table end
interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#000000',
  border: '1px solid #00FFFF',
  boxShadow: 0,
  p: 1,
};
// modal end
interface GameInfo {
  id?: string;
  name?: string;
  detailImage?: string;
  video?: string;
  detail?: any;
  value?: string;
  roleType: number;
  playStatus: number;
  isPlay: boolean;
  enable: number;
  eventId: string;
  image: string;
  url: string;
}

interface OrderConfirmInfo {
  countPrice: number;
  id: string;
  metg: number;
  price: number;
  serviceFee: number;
}

interface FormObject {
  id: string;
  key: string;
}

interface GameObject {
  // 游戏得分
  score: number;
  // 游戏换算值
  value: number;
}
export default function GameDetail() {
  const { t } = useTranslation();

  // modal start
  const [openPay, setOpenPay] = React.useState(false);
  const handlePayOpen = () => setOpenPay(true);
  const handlePayClose = () => setOpenPay(false);
  const [openNft, setOpenNft] = React.useState(false);
  const handleNftOpen = () => setOpenNft(true);
  const handleNftClose = () => setOpenNft(false);
  const [openBalanceHero, setOpenBalanceHero] = React.useState(false);
  const handleOpenBalanceHero = () => setOpenBalanceHero(true);
  const handleCloseBalanceHero = () => setOpenBalanceHero(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const [openRel, setOpenRel] = React.useState(false);
  const [openMsgRel, setOpenMsgRel] = React.useState('Top-up successful');
  const handleOpenRel = () => setOpenRel(true);
  const handleCloseRel = () => setOpenRel(false);
  const [openGameRel, setOpenGameRel] = React.useState(false);
  const handleOpenGameRel = () => setOpenGameRel(true);
  const handleCloseGameRel = () => setOpenGameRel(false);
  const [openRewardRel, setOpenRewardRel] = React.useState(false);
  const handleOpenRewardRel = () => setOpenRewardRel(true);
  const handleCloseRewardRel = () => setOpenRewardRel(false);
  // modal end

  const [userAmount, setUserAmount] = useState(0);
  const [rateAmount, setRateAmount] = useState(0);
  const [agreement, setAgreement] = useState(true);
  const [roleType, setRoleType] = useState(0);
  const [gameLogs, setGameLogs] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [isClick, setIsClick] = useState({});
  const [showNoData, setShowNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameInfo, setGameInfo] = useState<GameInfo>();
  const [orderConfirmInfo, setOrderConfirmInfo] = useState<OrderConfirmInfo>();

  const [formId, setFormId] = useState('');
  let formKey = '';
  const [game, setGame] = useState<GameObject>();
  useEffect(() => {
    getGameDetail();
    getGameLogs(1);
    getUserInfo();
    // if (window.location.search && getCookie('ua_once')) {
    //   if (window.location.search.includes('rel=1')) {
    //     setOpenMsgRel('Top-up successful');
    //     removeCookie('ua_once');
    //     handleOpenRel();
    //   } else {
    //     setOpenMsgRel('Top-up failed');
    //     handleOpenRel();
    //   }
    // }
    // game iframe
    setFormId(UrlParams2Obj(window.location.search).id);
  }, []);

  function playGameStart() {
    handlePayClose();
    GameStart({ id: formId }).then((res: any) => {
      if (!res.code) {
        formKey = res.key;
        window.addEventListener('message', playGameEnd, false);

        let iframeEl = document.createElement("iframe");
        iframeEl.id = "iframeGame";
        iframeEl.setAttribute("seamless", "true");
        iframeEl.setAttribute("allow", "fullscreen");
        iframeEl.style.border = "0";
        iframeEl.style.position = "fixed";
        iframeEl.style.zIndex = "9999";
        iframeEl.style.top = "0";
        iframeEl.style.left = "0";
        iframeEl.width = document.documentElement.clientWidth + 'px';
        iframeEl.height = document.documentElement.clientHeight + 'px';
        iframeEl.src = gameInfo?.url || '';
        document.body.appendChild(iframeEl);

      } else if (res.code === 2011) {
        // 缺失NFT
        // message.info(res.message);
        setRoleType(res.roleType);
        handleNftOpen();
      } else if (res.code === 2001) {
        // 充值
        // message.info(res.message);
        handleOpenBalanceHero();
      }
    });
  }
  function playGameEnd(event: any) {
    if (event.data.type !== 'TYPE_GAME') return false;
    setGame({ score: event.data.data.score, value: event.data.data.value });
    handleOpenGameRel();
    let params = encrypt.encrypt(JSON.stringify({id: formId,
      key: formKey,
      value: `${event.data.data.value}`}));
    GameEnd({
      data: params
    }).then((res) => {
      setTimeout(() => {
        getGameDetail();
        getGameLogs(1);
        getUserInfo();
      }, 1000);
    });
    // @ts-ignore
    document.body.removeChild(document.querySelector("#iframeGame"));
  }

  function getGameDetail() {
    const params = UrlParams2Obj(window.location.search);
    GameDetailInfo({ id: params.id }).then((res: any) => {
      if (res.code === 1) {
        window.self.location.href = window.location.origin + '/game-hall';
      } else if (!res.code) {
        setGameInfo(res);
      }
    });
  }
  function getGameLogs(pageCrt: number) {
    setIsClick({ pointerEvents: 'none', color: '#7e7e7e' });
    const params = UrlParams2Obj(window.location.search);
    GameLogs({ pageSize: pageSize, page: pageCrt, id: params.id }).then(
      (res: any) => {
        setShowNoData(true);
        if (gameLogs && gameLogs.length > 0 && pageCrt > 1) {
          setGameLogs(gameLogs.concat(res.rows));
        } else {
          setGameLogs(JSON.parse(JSON.stringify([])));
          setGameLogs(res.rows);
        }
        setPageTotal(res.count);
        if (pageCurrent * pageSize >= res.count) {
          setIsClick({ pointerEvents: 'none', color: '#7e7e7e' });
        } else {
          setIsClick({});
        }
        setPageCurrent(pageCrt + 1);
      }
    );
  }
  function getPageMore() {
    getGameLogs(pageCurrent);
  }
  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        setUserAmount(res.metg);
        setRateAmount(res.metgUs);
      }
    });
  }
  function rechargeConfirm() {
    // @ts-ignore
    const val = document.querySelector('#walletAmount').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      message.info(t("validate.information"));
      return false;
    } else if (
      /^(([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/.test(val)
    ) {
      if (Number(val) >= 500) {
        handleClose();
        OrderRecharge({ metg: Number(val) }).then((res: any) => {
          if (!res.code) {
            setOrderConfirmInfo(res);
            handleOpenConfirm();
          }
        });
      } else {
        message.info('Minimum top-up amount is 500 METG');
      }
    } else {
      message.info(t("validate.long"));
    }
  }
  const rechargeUser = () => {
    if (!agreement) return false;
    handleCloseConfirm();
    PayStripe({ orderId: orderConfirmInfo?.id })
      .then((res: any) => {
        if (!res.code && res.url) {
          window.location.href = res.url;
        }
      })
      .catch(() => {
        setOpenMsgRel('Top-up failed');
        handleOpenRel();
      });
  };
  // function topUp() {
  //   handleCloseBalanceHero();
  //   handleOpen();
  // }
  function toUser() {
    window.self.location.href = window.location.origin + '/user';
  }
  function payOrToast() {
    if (gameInfo?.isPlay) {
      handlePayOpen();
    } else {
      playGameStart();
    }
  }
  function toNft() {
    window.self.location.href = window.location.origin + '/nft';
  }

  function disSpace(e: any) {
    if (e.keyCode === 32) {
      e.preventDefault();
      return false;
    }
  }
  return (
    <>
      <span style={{ position: 'fixed', zIndex: '99999' }}>{formKey}</span>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container nft-detail-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{gameInfo?.name}</span>
                </h2>
                <div className="s-flex s-flex-center-end-row hall-list-token">
                  <span className="user-money-title">METG {t("page.gameDetail.Token")}:</span>
                  <span className="user-money">{userAmount}</span>
                </div>
                <div className="row nft-detail game-box">
                  <div className="col col-12 col-lg-5 nft-detail-img">
                    <div className="s-flex s-flex-center-row list-item-ct-game">
                      {gameInfo && gameInfo?.video ? (
                        <video autoPlay muted loop id="hero_video" playsInline>
                          <source src={gameInfo?.video} type="video/mp4" />
                        </video>
                      ) : (
                        <img src={gameInfo?.detailImage} />
                      )}
                      {/* <div className="list-item-line"></div> */}
                    </div>
                  </div>
                  <div className="col col-12 col-lg-7 nft-detail-info">
                    <div>
                      <div className="game-price">
                        <span className="metg-num">Price:</span>
                        <span className="metg-equal">
                          {gameInfo?.value} METG
                        </span>
                      </div>
                      <div className="game-price">
                        <span className="metg-num">
                          {t("page.gameDetail.Type")}: {gameInfo?.roleType === 1 && 'HERO'}
                          {gameInfo?.roleType === 2 && 'HEXA'} GAME
                        </span>
                      </div>
                      <div className="game-price">
                        <span className="metg-num">
                          {t("page.gameDetail.Provider")}: Advokate Co.Ltd.
                        </span>
                      </div>
                    </div>
                    <div className="s-flex s-flex-center-end-row game-btn-box">
                      {gameInfo?.playStatus === 1 && (
                        <button
                          className="btn btn-default btn-nft-detail-list no-left right-bottom"
                          onClick={playGameStart}
                          onKeyDown={disSpace}
                          onKeyUp={disSpace}
                        >
                          {t("page.gameDetail.PLAY")}
                        </button>
                      )}
                      {gameInfo?.playStatus === 2 && (
                        <button
                          className="btn btn-default btn-nft-detail-list no-left right-bottom"
                          onClick={playGameStart}
                          onKeyDown={disSpace}
                          onKeyUp={disSpace}
                        >
                          {t("page.gameDetail.PLAY1")}
                        </button>
                      )}
                      {gameInfo?.playStatus === 3 && (
                        <button
                          className="btn btn-default btn-nft-detail-list no-left right-bottom"
                          onClick={payOrToast}
                          onKeyDown={disSpace}
                          onKeyUp={disSpace}
                        >
                          {t("page.gameDetail.PLAY2")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row nft-detail game-box">
                  <div className="col col-12 game-detail">
                    <img
                      className="detail-bg"
                      src="assets/images/game/game-detail-bg.png"
                    />
                    <a
                      href="#!"
                      className="reward-text"
                      onClick={handleOpenRewardRel}
                    >
                      {t("page.gameDetail.Reward")}
                    </a>
                    <div
                      className="detail-text"
                      dangerouslySetInnerHTML={{
                        __html: gameInfo?.detail,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="row nft-detail game-box">
                  <div className="col col-12 game-detail">
                    <img
                      className="detail-bg"
                      src="assets/images/game/game-history-bg.png"
                    />
                    <div className="history-game-box">
                      {showNoData && !gameLogs.length && (
                        <h3 className="no-data">{t("page.gameDetail.NO")}</h3>
                      )}
                      {gameLogs && gameLogs.length > 0 && (
                        <TableContainer
                          sx={tableStyle}
                          component={Paper}
                          className="game-table"
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  sx={tableStyle}
                                ></StyledTableCell>
                                <StyledTableCell sx={tableStyle} align="center">
                                  {t("page.gameDetail.Cost")}
                                </StyledTableCell>
                                <StyledTableCell sx={tableStyle} align="center">
                                  {t("page.gameDetail.Cost1")}{' '}
                                  <span className="cost-metg">(METG)</span>
                                </StyledTableCell>
                                <StyledTableCell sx={tableStyle} align="center">
                                  {t("page.gameDetail.Earn")}
                                </StyledTableCell>
                                <StyledTableCell sx={tableStyle} align="center">
                                  {t("page.gameDetail.Earn1")}{' '}
                                  <span className="earn-metg">(METG)</span>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {gameLogs &&
                                gameLogs.map((row: any, index) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={tableStyle}
                                        className="cell-min-width"
                                      >
                                        {row.createdAt}
                                      </TableCell>
                                      <StyledTableCell
                                        align="center"
                                        sx={tableStyle}
                                      >
                                        {t("page.gameDetail.Play")}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        sx={tableStyle}
                                        className="cost-metg"
                                      >
                                        - {row.payValue}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        sx={tableStyle}
                                      >
                                        {t("page.gameDetail.Game")}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        sx={tableStyle}
                                        className={
                                          row.getValue
                                            ? 'earn-metg'
                                            : 'earn-null-metg'
                                        }
                                      >
                                        {row.getValue
                                          ? '+' + row.getValue
                                          : '——'}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </div>
                  </div>
                  {gameLogs && pageTotal > 10 && (
                    <div className="s-flex s-flex-center-row user-nft-count">
                      <span className="nft-count">({pageTotal})</span>
                      <div onClick={getPageMore} style={isClick}>
                        <span className="nft-count-see">{t("page.gameDetail.SEE")}</span>
                        <span className="nft-count-more">{t("page.gameDetail.MORE")}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openPay}
        onClose={handlePayClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
      >
        <Fade in={openPay}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handlePayClose}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.Confirmation")}
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text">
              {t("page.gameDetail.Pay")}
              <span className="modal-pay-text-color">
                {gameInfo?.value} METG
              </span>{' '}
              {t("page.gameDetail.to")}
            </div>
            <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
              <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handlePayClose}
              >
                {t("page.gameDetail.CANCEL")}
              </div>
              <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={playGameStart}
              >
                {t("page.gameDetail.CONFIRM")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openNft}
        onClose={handleNftClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
      >
        <Fade in={openNft}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleNftClose}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.Sorry")}
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text">
              {t("page.gameDetail.You")}
              <span className="modal-pay-text-color">
                {roleType === 1 ? 'HERO' : 'HEXA'}
              </span>
              {t("page.gameDetail.yet")}
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text modal-no-margin">
              {t("page.gameDetail.You1")}
            </div>
            <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
              <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleNftClose}
              >
                {t("page.gameDetail.CANCEL")}
              </div>
              <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={toNft}
              >
                {t("page.gameDetail.GO")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openBalanceHero}
        onClose={handleCloseBalanceHero}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-balance-box"
      >
        <Fade in={openBalanceHero}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseBalanceHero}
              />
            </div>
            <div className="modal-balance-pd modal-balance-high modal-balance-title">
              {t("page.gameDetail.Unfortunately")}
            </div>
            <div className="modal-balance-pd modal-balance-high modal-balance-title">
              {t("page.gameDetail.of1")} <span className="modal-balance-weight">METG</span> {t("page.gameDetail.of2")}
              {t("page.gameDetail.of3")}
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text modal-balance-text-mt">
              <span>{t("page.gameDetail.Your")}</span>
              <span>{userAmount || '0'} METG</span>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text">
              <span>Amount Needed</span>
              <span>{gameInfo?.value} METG</span>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd">
              <div className="modal-balance-line"></div>
            </div>
            <div className="s-flex s-flex-center-row modal-balance-pd modal-balance-high modal-balance-desc">
              {t("page.gameDetail.Go1")}
            </div>
            <div className="modal-balance-btn modal-btn-box modal-btn-center modal-pd">
              <div
                className="modal-btn modal-btn-all modal-btn-confirm-lang"
                onClick={toUser}
              >
                {t("page.gameDetail.TOP")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleClose}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.Top")}
            </div>
            <div className="modal-recharge-pd modal-recharge-title">
              {t("page.gameDetail.How")}
            </div>
            <div className="modal-recharge-pd modal-recharge-tip">
              {t("page.gameDetail.Minimum")} <span>500</span> METG
            </div>
            <div className="modal-recharge-pd modal-recharge-value">
              <input
                type="text"
                id="walletAmount"
                maxLength={8}
                placeholder="Enter Here"
              />
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text">
              <span className="modal-recharge-text-weight">1 </span>
              <span>METG ≈ $ {rateAmount} USD</span>
            </div>
            <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
              <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleClose}
              >
                {t("page.gameDetail.CANCEL")}
              </div>
              <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={rechargeConfirm}
              >
                {t("page.gameDetail.NEXT")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openConfirm}
        onClose={handleCloseConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-confirm-box"
      >
        <Fade in={openConfirm}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseConfirm}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.Confirm")}
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-confirm-pd modal-recharge-confirm-text modal-recharge-text-blue">
              {t("page.gameDetail.Amount")}
            </div>
            <div className="s-flex s-flex-center-row modal-recharge-confirm-pd modal-recharge-confirm-text modal-recharge-text-blue modal-recharge-text-bt">
              <span className="modal-recharge-confirm-text-weight">
                {orderConfirmInfo?.metg.toFixed(4)}
              </span>
              <span>METG</span>
            </div>
            <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
              <span>{t("page.gameDetail.PRICE")} </span>
              <span>$ {orderConfirmInfo?.price.toFixed(2)} USD</span>
            </div>
            <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
              <span>{t("page.gameDetail.SERVICE")}</span>
              <span>$ {orderConfirmInfo?.serviceFee.toFixed(2)} USD</span>
            </div>
            <div className="s-flex s-flex-between-row modal-recharge-confirm-pd">
              <div className="modal-recharge-confirm-line"></div>
            </div>
            <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
              <span>{t("page.gameDetail.SUBTOTAL")}</span>
              <span className="s-flex">
                <span className="s-flex-self-end">$</span>
                <span className="modal-recharge-confirm-text-weight">
                  {orderConfirmInfo?.countPrice.toFixed(2)}
                </span>
                <span className="s-flex-self-start modal-recharge-confirm-text-unit">
                  USD
                </span>
              </span>
            </div>
            <div className="modal-recharge-confirm-agree">
              <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-agree">
                {agreement ? (
                  <img
                    className="checkbox-agree"
                    src="./assets/images/user/icon-checkbox-select.png?v=20230413001"
                    onClick={() => {
                      setAgreement(false);
                    }}
                  />
                ) : (
                  <img
                    className="checkbox-agree"
                    src="./assets/images/user/icon-checkbox.png?v=20230413001"
                    onClick={() => {
                      setAgreement(true);
                    }}
                  />
                )}
                <span>
                  {t("page.gameDetail.I")}
                </span>
              </div>
              <div className="modal-recharge-confirm-pd modal-recharge-confirm-btn modal-btn-box modal-btn-center">
                <div
                  className="modal-btn modal-btn-all modal-btn-confirm-lang"
                  onClick={rechargeUser}
                >
                  {t("page.gameDetail.CONFIRM")}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openRel}
        onClose={handleCloseRel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-rel-box"
      >
        <Fade in={openRel}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseRel}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {openMsgRel}
            </div>
            <div className="modal-recharge-rel-btn modal-btn-box modal-btn-center">
              <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={handleCloseRel}
              >
                {t("page.gameDetail.CONFIRM")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openGameRel}
        onClose={handleCloseGameRel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-rel-box"
      >
        <Fade in={openGameRel}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseGameRel}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.REWARD")}
            </div>
            <div className="s-flex s-flex-center-row modal-ct-desc">
              {t("page.gameDetail.Score")}{' '}
              <span className="modal-text-weight">{game?.score || 0}</span>
            </div>
            <div className="s-flex s-flex-center-row modal-ct-desc">
              {t("page.gameDetail.Reward1")}{' '}
              <span className="modal-text-weight">{game?.value || 0}</span> METG
            </div>
            <div className="modal-recharge-rel-btn modal-btn-box modal-btn-center">
              <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={handleCloseGameRel}
              >
                {t("page.gameDetail.CONFIRM")}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openRewardRel}
        onClose={handleCloseRewardRel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-balance-box"
      >
        <Fade in={openRewardRel}>
          <Box sx={style}>
            <div className="s-flex close-box">
              <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseRewardRel}
              />
            </div>
            <div className="s-flex s-flex-center-row modal-ct-title">
              {t("page.gameDetail.GAME")}
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd reward-margin-top">
              <div className="reward-score-box">
                <span className="reward-level">LEV1.</span> Scores 0-999
              </div>
              <div className="s-flex s-flex-center-row reward-coin-box">
                <img
                  className="reward-coin"
                  src="./assets/images/game/reward-coin.png"
                />
                <div className="s-flex s-flex-center-row reward-coin-num">
                  <img src="./assets/images/icon-modal-close.png" />
                  <span>0</span>
                </div>
              </div>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd">
              <div className="reward-score-box">
                <span className="reward-level">LEV2.</span> Scores 1000-29999
              </div>
              <div className="s-flex s-flex-center-row reward-coin-box">
                <img
                  className="reward-coin"
                  src="./assets/images/game/reward-coin.png"
                />
                <div className="s-flex s-flex-center-row reward-coin-num">
                  <img src="./assets/images/icon-modal-close.png" />
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd">
              <div className="reward-score-box">
                <span className="reward-level">LEV3.</span> Scores 30000-59999
              </div>
              <div className="s-flex s-flex-center-row reward-coin-box">
                <img
                  className="reward-coin"
                  src="./assets/images/game/reward-coin.png"
                />
                <div className="s-flex s-flex-center-row reward-coin-num">
                  <img src="./assets/images/icon-modal-close.png" />
                  <span>8</span>
                </div>
              </div>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd">
              <div className="reward-score-box">
                <span className="reward-level">LEV4.</span> Scores 60000-99999
              </div>
              <div className="s-flex s-flex-center-row reward-coin-box">
                <img
                  className="reward-coin"
                  src="./assets/images/game/reward-coin.png"
                />
                <div className="s-flex s-flex-center-row reward-coin-num">
                  <img src="./assets/images/icon-modal-close.png" />
                  <span>12</span>
                </div>
              </div>
            </div>
            <div className="s-flex s-flex-between-row modal-balance-pd reward-margin-bottom">
              <div className="reward-score-box">
                <span className="reward-level">LEV5.</span> Scores 100000 Above
              </div>
              <div className="s-flex s-flex-center-row reward-coin-box">
                <img
                  className="reward-coin"
                  src="./assets/images/game/reward-coin.png"
                />
                <div className="s-flex s-flex-center-row reward-coin-num">
                  <img src="./assets/images/icon-modal-close.png" />
                  <span>18</span>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
