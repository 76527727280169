import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { UserCollection } from './libs/api';

// modal start
import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function ListHistory() {
    const { t } = useTranslation();

    const [userNft, setUserNft] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [isClick, setIsClick] = useState({});
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    getUserNft();
  }, []);

  function getUserNft() {
    setIsClick({ pointerEvents: 'none', color: '#7e7e7e' });
    UserCollection({ pageSize: pageSize, page: pageCurrent, type: '3' }).then(
      (res: any) => {
        setShowNoData(true);
        if (userNft && userNft.length > 0) {
          setUserNft(userNft.concat(res.rows));
        } else {
          setUserNft(res.rows);
        }
        setPageTotal(res.count);
        setPageCurrent(pageCurrent + 1);
        if (pageCurrent * pageSize >= res.count) {
          setIsClick({ pointerEvents: 'none', color: '#7e7e7e' });
        } else {
          setIsClick({});
        }
      }
    );
  }
  function getPageMore() {
    getUserNft();
  }

  function openDetail(url: string) {
    window.self.location.href = url;
  }

  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="container user-box">
                <h2 className="txt-default-title wow fadeInUp user-title">
                  <span className="txt">{t("page.listHis.MY")}</span>
                </h2>
                <div className="row row-ppl row-ppl1 user-nft-list">
                  {showNoData && !userNft.length && (
                    <h3 className="no-data wow fadeInUp"></h3>
                  )}
                  {userNft &&
                    userNft.map((item: any, index) => {
                      return (
                        <div
                          className="col col-6 col-sm-6 col-lg-4 col-xl-3 list-item s-cursor-pointer"
                          onClick={() =>
                            openDetail('/list-detail?uuid=' + item.id)
                          }
                          key={index}
                        >
                          <div className="s-flex s-flex-center-row list-item-ct">
                            <img className="nft-list-img" src={item.image} />
                            <div className="nft-list-name">{item.name}</div>
                            <div className="list-item-line"></div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {userNft && pageTotal > 10 && (
                  <div className="s-flex s-flex-center-row user-nft-count">
                    <span className="nft-count">({pageTotal})</span>
                    <div onClick={getPageMore} style={isClick}>
                      <span className="nft-count-see">{t("page.listHis.SEE")}</span>
                      <span className="nft-count-more">{t("page.listHis.MORE")}</span>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
