import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import {InviteFriendRecord, formatThousandthComma} from "./libs/api";
import {useTranslation} from "react-i18next";
import * as React from "react";

function Invitation() {
    const { t } = useTranslation();

    const [inviteList, setInviteList] = useState<any[]>([]);

    const [invite, setInvite] = useState(0);

    useEffect(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
        document.body.appendChild(s);
        getInviteCode();
        // getTaskInfo();
    }, [])

    function getInviteCode () {
        InviteFriendRecord({}).then((res: any) => {
            if (!res.code) {
                setInvite(res.rawed);
                setInviteList(res.rows);
            }
        });
    }

    function toBack() {
        window.self.location.href = window.location.origin + '/invitation' ;
    }

    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-paper s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container invitation">
                                <h2 className="txt-default-title wow fadeInUp"><span className="txt" >{t("page.invitation.INVITE")}</span></h2>
                                <section>
                                    <div className="row invitation-user">
                                        <div className="col col-12 col-lg-6 s-flex s-flex-row invitation-user-back s-cursor-pointer" onClick={toBack}>
                                            <img className="user-back-img" src="./assets/images/ico/icon-back.png" alt=""/>
                                            GO BACK
                                        </div>
                                        <div
                                            className="col col-12 col-lg-6 s-flex s-flex-row invitation-user-reward">Reward:
                                            <span className="invitation-user-reward-value">{formatThousandthComma(invite)}</span>
                                            <img
                                                className="s-flex s-cursor-pointer user-token"
                                                src="./assets/images/user/token-ga.png"
                                            />
                                        </div>
                                    </div>
                                    <div className="row invitation-progress">
                                        <div className="invitation-line"></div>
                                    </div>
                                </section>
                                <section>
                                    <div className="row invitation-body">
                                        <div className="col col-12 wow fadeInUp invitation-history">
                                            <div className="invitation-list">
                                                <div className="s-flex s-flex-between-row list-title">
                                                    <div className="title-left">HISTORY</div>
                                                    <div className="title-right"> </div>
                                                </div>
                                                <div className="list-data list-data-his">
                                                    {
                                                        inviteList && inviteList.length > 0 && inviteList.map((item: any, index) => {
                                                            return <div className="s-flex s-flex-between-row list-item"
                                                                        key={index}>
                                                                <div
                                                                    className="list-item-row reward-value">
                                                                    <span className="txt-name-unit">{item.reward}</span>
                                                                    <span className="">GAIA</span>
                                                                </div>
                                                                <div
                                                                    className="s-flex s-flex-center-row list-item-row">{item.type === '1' ? 'Tier 1' : item.type === '2' ? "Tier 2" : "Referral Reward"}
                                                                </div>
                                                                <div
                                                                    className="s-flex s-flex-center-row list-item-row">From
                                                                    <span className="reward-name">{item.username}</span>
                                                                </div>
                                                                <div
                                                                    className="s-flex s-flex-center-end-row list-item-row">{item.createdAt}
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {
                                                        inviteList.length === 0 && <div
                                                        className="s-flex s-flex-center-row list-no-data">{t("page.invitation.No")}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                        <Footer/>
                    </main>
                </div>
            </div>
        </>
    );
}

export default Invitation;
