// 获取url参数
export function getUrlParams(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]); return null;
}

//将秒数转换为天时分秒格式
export function dateCalculation(second) {
  if (second > 0) {
    let day = 0
    let hour = 0
    let minute = 0
    let data = {}
    minute = Math.floor(second / 60)
    if (parseInt(minute) > 60) {
      hour = parseInt(minute / 60)
      minute %= 60 //算出有多分钟
    }
    if (parseInt(hour) > 24) {
      day = parseInt(hour / 24)
      hour %= 24 //算出有多小时
    }
    data.day = day
    data.hour = hour
    data.minute = minute

    return `${data.day} D ${data.hour} H ${data.minute} M`
  }
}