import {useEffect} from "react";
import { setCookie } from "./libs/cookies";
import storage from "./libs/storage";

export default function AutoLogin() {
    useEffect(() => {
        if (window.location.search) {
            storage.setItem('userStatus', window.location.search.split("tk=")[1]);
            setCookie("ua_once", window.location.search.split("tk=")[1], 1);
            window.self.location.href = "/user";
        } else {
            window.self.location.href = "/login";
        }
    }, []);
    return(<></>);
}