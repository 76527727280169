import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import {useState, useEffect, useRef} from 'react';
import {ShopOrderList, TokenLogs} from './libs/api';
import * as React from 'react';
import {useTranslation} from "react-i18next";
import enums from "./libs/enums";
import Pagination from "@mui/material/Pagination";
import DialogPay from "./components/DialogPay";

export default function TokenHistory() {
  const { t } = useTranslation();

  // tab
  const [tabIndex, setTabIndex] = useState(1);
  const changeTab = (val: number) => {
    if (val === tabIndex) return false;
    setTabIndex(val);
    setPageCurrent(1);
  }

  const [metgNum, seteMetgNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [tokenList, setTokenList] = useState([]);
  const [showTokenNum, setShowTokenNum] = useState(false);
  const [showNoData, setShowNoData] = useState(false);

  function getTokenList(pageCur: number) {
    setTokenList([]);
    let params = {
      pageSize: pageSize, page: pageCur
    }
    TokenLogs(params).then((res: any) => {
      setShowNoData(res.rows.length === 0);
      setTokenList(res.rows);
      setPageTotal(Math.ceil(res.count / pageSize));
      if (res.metg) {
        if (res.metg !== 0 || res.metg !== '0') {
          setShowTokenNum(true);
          seteMetgNum(parseFloat(res.metg));
        } else {
          setShowTokenNum(false);
        }
      } else {
        setShowTokenNum(false);
      }
    });
  }
  function handleChangePage(event, value) {
    if (value === pageCurrent) return false;
    setPageCurrent(value);
    // hk || hero
    if (tabIndex === 1) {
      getTokenList(value);
    } else {
      getUserShopList(value);
    }
  }

  const [typeStatus, setTypeStatus] = useState(0);
  function getUserShopList(pageCur: number) {
    setTokenList([]);
    let params = {
      pageSize: pageSize, page: pageCur
    }
    if (typeStatus) Object.assign(params, {status: typeStatus.toString()})
    ShopOrderList(params).then(
        (res: any) => {
          setShowNoData(res.rows.length === 0);
          setTokenList(res.rows);
          setPageTotal(Math.ceil(res.count / pageSize));
        }
    );
  }

  // 支付周边商品
  const dialogPayRef = useRef(null);
  const openPayDialog = (rel: any) => {
    dialogPayRef.current.openPayType(rel);
  }

  function toUserGiftDetail(id) {
    window.self.location.href = window.location.origin + "/user-gift?id=" + id;
  }
  useEffect(() => {
    if (window.location.search) {
      changeTab(2);
    }
  }, []);
  useEffect(() => {
    if (tabIndex === 1) {
      getTokenList(1);
    } else {
      getUserShopList(1);
    }
  }, [tabIndex, typeStatus]);
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
        <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />
              <div className="container token-his-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">TRANSACTIONS</span>
                </h2>
                <div className="tab-box-data">
                  <div className="s-flex s-flex-between-row tab-data">
                    <div className="s-flex width-auto-flex">
                      <div className={`tab-data-item s-flex s-flex-center-row s-cursor-pointer ${tabIndex === 1 ? 'tab-data-active' : ''}`} onClick={() => {changeTab(1)}}>
                        <span>DIGITAL</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 user-box tab-data-data">
                  {
                    tabIndex === 1 && <>
                        {showTokenNum &&
                            <div className="s-flex s-flex-center-start-row">
                              <div className="title-tip">{t("page.token.Token")}</div>
                              <div className="token-num">{metgNum}</div>
                            </div>
                        }
                        {showNoData && !tokenList.length && (
                            <h3 className="no-data wow fadeInUp">{t("page.token.Token")}</h3>
                        )}
                        <div className="his-list">
                          {tokenList &&
                              tokenList.map((item: any, index: number) => {
                                return (
                                    <div className="row his-item" key={"token" + index}>
                                      <div className="col col-12 s-flex his-item-info">
                                        <div className="item-info-ts">
                                          <div className="s-flex s-flex-center-start-row item-info-title">
                                            {item.createdAt}
                                          </div>
                                          <div className="s-flex s-flex-center-end-row item-info-status">
                                            <span className="symbol">
                                              {item.metg > 0 ? '+' : '-'}
                                            </span>
                                            {Math.abs(parseFloat(item.metg))}
                                            <span className="unit">METG</span>
                                          </div>
                                        </div>
                                        <div className="item-info-ts-long">
                                          <div className="item-info-address">
                                            <span className="address-title">
                                              {item.type === 6 && 'Receive address:'}
                                              {item.type === 7 && 'Send address:'}
                                            </span>
                                            <span className="address-info">
                                              {String(item.address) === "null" ? "" : String(item.address)}
                                            </span>
                                          </div>
                                          <div className="item-info-address">
                                            <span className="balance-title">
                                              Balance:
                                            </span>
                                            <span className="balance-info">
                                              {parseFloat(String(item.balance || 0))}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="item-info-ts-long">
                                          <div className="item-info-address">
                                            <span className="address-title">Txid:</span>
                                            <span className="address-info">
                                              {item.txid}
                                            </span>
                                          </div>
                                          <div className="item-info-address">
                                            <span className="token-status">
                                              {item.type === 1 && 'TOP-UP'}
                                              {item.type === 2 && 'NFT PURCHASE '}
                                              {item.type === 3 && 'AIRDROP'}
                                              {item.type === 4 && 'TASK REWARD'}
                                              {item.type === 5 && 'NFT TRANSFER GAS FEE'}
                                              {item.type === 6 && 'METG TOKEN TRANSFER'}
                                              {item.type === 7 && 'METG TOKEN RECEIVE'}
                                              {item.type === 8 && 'PURCHASE FROM MARKET PLACE'}
                                              {item.type === 9 && 'SOLD'}
                                              {item.type === 10 && 'LISTING FEE'}
                                              {item.type === 11 && 'RETURN LISTING FEE'}
                                              {item.type === 14 && item.applicationEvent}
                                              {item.type === 20 && 'GAIA EXCHANGE'}
                                              {item.type === 21 && 'USER REWARD'}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="token-line"></div>
                                      </div>
                                    </div>
                                );
                              })}
                        </div>
                        {
                            tokenList && !!tokenList.length &&
                            <div className="col col-12 s-flex s-flex-center-row" style={{marginTop: "1rem", marginBottom: "1rem"}}>
                              {/* 分页 */}
                              <Pagination count={pageTotal} page={pageCurrent} size="small" color="primary" onChange={handleChangePage} />
                            </div>
                        }
                        {/* 无数据 */}
                        {showNoData && !tokenList.length &&
                            <h3 className="tab-connect-box no-data">{t("page.user.NO1")}</h3>
                        }
                      </>
                  }
                  {
                    tabIndex === 2 && <>
                        <div className={`row row-ppl row-ppl1 user-nft-list ${tokenList.length ? "":""}`}>
                          <div className="col col-12 user-nft-btns wow fadeIn">
                            <div className="user-nft-btn-search wow fadeIn">
                              <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 0 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(0)}>{t("page.user.btnAll")}</div>
                              <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 1 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(1)}>UNPAID</div>
                              <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 2 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(2)}>PAID</div>
                              <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 3 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(3)}>SHIPPED</div>
                              <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 5 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(5)}>CANCELED</div>
                            </div>
                          </div>
                          <div className="col col-12 list-shop">
                            {tokenList &&
                                tokenList.map((item: any, index: number) => {
                                  return (
                                      <div key={index}>
                                        {/*PC*/}
                                        <div className="col col-12 list-shop-item s-cursor-pointer">
                                          <div className="s-flex s-flex-between-row list-shop-title">
                                            <div className="s-flex"><span className="list-shop-time">{item.createdAt}</span><span className="list-shop-code">ORDER CODE: {item.id}</span></div>
                                            <div className="list-shop-status">{enums.EnumGiftOrderStatus[item.status]}</div>
                                          </div>
                                          <div className="list-shop-line"></div>
                                          {
                                              item.products && item.products.map((row, i) => {
                                                return <div className="list-shop-info s-flex s-flex-between-row" key={i}>
                                                  <div className="s-flex s-flex-between-row shop-info-left" onClick={() => toUserGiftDetail(item.id)}>
                                                    <div className="s-flex info-left-left">
                                                      <img className="list-shop-img" src={row.specImage} alt=""/>
                                                      <span className="s-flex s-flex-center-row list-shop-name">{row.name}</span>
                                                    </div>
                                                    <div className="s-flex info-left-right">
                                                      <div className="list-shop-num">x{row.quantity}</div>
                                                      <div className="list-shop-price">HK$ {row.payPrice}</div>
                                                    </div>
                                                  </div>
                                                  <div className="s-flex shop-info-right">
                                                    {
                                                        i !== item.products.length-1 && <div className="list-shop-btn"> </div>
                                                    }
                                                    {
                                                        i === item.products.length-1 && item.status === 1 && <button className="btn btn-default s-btn-border-none list-shop-btn" onClick={() => openPayDialog({orderId: item.id, orderType: "3"})}>PAY</button>
                                                    }
                                                    {
                                                        i === item.products.length-1 && item.status !== 1 && <div className="list-shop-btn"> </div>
                                                    }
                                                  </div>
                                                </div>
                                              })
                                          }
                                        </div>
                                        {/* M */}
                                        <div className="col col-12 list-shop-item_m s-cursor-pointer">
                                          <div className="list-shop-title">
                                            <div className="s-flex"><span className="list-shop-time">{item.createdAt}</span></div>
                                            <div className="s-flex"><span className="list-shop-code">ORDER CODE: {item.id}</span></div>
                                          </div>
                                          <div className="list-shop-line"></div>
                                          {
                                              item.products && item.products.map((row, i) => {
                                                return <div key={i}>
                                                  <div className="list-shop-info s-flex" onClick={() => toUserGiftDetail(item.id)}>
                                                    <div className="s-flex">
                                                      <img className="list-shop-img" src={row.specImage} alt=""/>
                                                    </div>
                                                    <div className="info-body">
                                                      <div className="list-shop-name text-ellipsis">{row.name}</div>
                                                      <div className="list-shop-num">x{row.quantity}</div>
                                                      <div className="list-shop-price">HK${row.hkPrice}</div>
                                                    </div>
                                                  </div>
                                                  {
                                                      i === item.products.length-1 && <div className="s-flex s-flex-between-row">
                                                        <div className="list-shop-status">{enums.EnumGiftOrderStatus[item.status]}</div>
                                                        {
                                                            item.status === 1 && <button className="btn btn-default s-btn-border-none list-shop-btn" onClick={() => openPayDialog({orderId: item.id, orderType: "3"})}>PAY</button>
                                                        }
                                                        {
                                                            item.status !== 1 && <div className="list-shop-btn"> </div>
                                                        }
                                                      </div>
                                                  }
                                                </div>
                                              })
                                          }
                                        </div>
                                      </div>
                                  );
                                })}
                          </div>

                          {
                              tokenList && !!tokenList.length &&
                              <div className="col col-12 s-flex s-flex-center-row" style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                {/* 分页 */}
                                <Pagination count={pageTotal} page={pageCurrent} size="small" color="primary" onChange={handleChangePage} />
                              </div>
                          }
                          {/* 无数据 */}
                          {showNoData && !tokenList.length &&
                              <h3 className="tab-connect-box no-data">{t("page.user.NO1")}</h3>
                          }
                        </div>
                      </>
                  }
                </div>

              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
      <DialogPay ref={dialogPayRef}></DialogPay>
    </>
  );
}
