import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import {useState, useEffect} from 'react';
import {
  UserInfo,
  UserCollection,
  OrderRecharge,
  PayStripe,
  UserTransfer,
  unbindWallet,
  bindWallet,
  getGaiaConfig,
  mtgGaia,
  rechargeGaia,
  mtgHk,
  UserCollectionHk,
  HkNftCombConf,
  HkNftCombToken,
  HkNftCombCreate
} from './libs/api';
import storage from './libs/storage';
import { removeCookie, getCookie } from './libs/cookies';
import message from './components/Message';
import Pagination from '@mui/material/Pagination';
// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import useClipboard from 'react-use-clipboard';
import WalletBind from './mint/wallet-bind';
import {useTranslation} from "react-i18next";

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
  );
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#000000',
  border: '1px solid #00FFFF',
  boxShadow: 0,
  p: 1,
};
const styleNoBorder = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  border: 'none',
  boxShadow: 0,
  p: 1,
};
// modal end
interface OrderConfirmInfo {
  countPrice: number;
  id: string;
  metg: number;
  price: number;
  serviceFee: number;
}
export default function User() {
  const { t } = useTranslation();

  const [userName, setUserName] = useState('USER NAME');
  const [userAvatar, setUserAvatar] = useState('');
  const [userAmount, setUserAmount] = useState(0);
  const [userHk, setUserHk] = useState(0);
  const [userGaia, setUserGaia] = useState(0);
  const [userNft, setUserNft] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [agreement, setAgreement] = useState(true);
  const [rateAmount, setRateAmount] = useState(0);
  const [userAddress, setUserAddress] = useState('');
  const [showNoData, setShowNoData] = useState(true);
  const [hasWallet, setHasWallet] = useState(false);
  const [walletInfo, setWalletInfo] = useState(1);
  const [walletAddress, setWalletAddress] = useState('');
  const [orderConfirmInfo, setOrderConfirmInfo] = useState<OrderConfirmInfo>();
  const [solanaAddress, setSolanaAddress] = useState("");
  const [solanaBalance, setSolanaBalance] = useState("");
  const [solanaBalanceLocked, setSolanaBalanceLocked] = useState("");
  // 切换用户及solana数据tab
  const [tabUserAccount, setTabUserAccount] = useState(1);
  /**
   * hk "status": "string" init 1 初始状态, onConsignment 2 寄售中, synthesized 5 已合成
   * hh "type": "string" 1 我收藏的 3 寄售中的 4 solana 钱包中的, 6 租赁中的
   */
  const [typeStatus, setTypeStatus] = useState(0);
  function changeTabUserAccount(tab: number | string) {
    setTabUserAccount(Number(tab) || 1);
    if (Number(tab) === 2) {
      tabChange(9);
    } else {
      tabChange(1);
    }
  }
  // usertab = 1  1 NFT hk 2 NFT hh | usertab = 2  9 SOLANA
  const [tabIndex, setTabIndex] = useState(2);
  function tabChange(value: number) {
    if (value === tabIndex) return false;
    if (value === 9) {
      setTypeStatus(4);
    } else {
      setTypeStatus(0);
    }
    setTabIndex(value);
    setPageCurrent(1);
  }
  // 格式化金额
  function formatPrice(price: number | string) {
    let ps = price.toString().split(".")[0] || 0;
    let pe = price.toString().split(".")[1] || 0;
    return String(ps).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "." +pe;
  }
  // 获取个人信息
  function getUserInfo() {
    UserInfo({}).then((res: any) => {
      if (!res.code) {
        storage.setItem('invit_code', res.username);
        setUserAddress(res.address);
        setUserAvatar(res.avatar);
        setUserName(res.username);
        setUserGaia(res.gaia);
        setUserAmount(res.metg);
        setUserHk(res.hk);
        setRateAmount(res.metgUs);
        setSolanaAddress(res.solanaAddress);
        setSolanaBalance(res.solanaBalance);
        if (res.solanaAddress && res.solanaBalance) {
          setSolanaBalance((Number(res.solanaBalance) / 1000000000).toFixed(4));
          setSolanaBalanceLocked((Number(res.lockedSolanaBalance) / 1000000000).toFixed(4));
          changeTabUserAccount(2);
        }
        if (JSON.stringify(res.solWallet) === '{}') {
          setHasWallet(false);
        } else {
          setHasWallet(true);
          setWalletInfo(res.solWallet.type);
          setWalletAddress(res.solWallet.address);
        }
      }
    });
  }

  // 获取个人NFT HH
  function getUserNft(pageCur: number) {
    setUserNft([]);
    let params = {
      pageSize: pageSize, page: pageCur
    }
    if (typeStatus) Object.assign(params, {type: typeStatus.toString()})
    UserCollection(params).then(
        (res: any) => {
          setShowNoData(res.rows.length === 0);
          setUserNft(res.rows);
          setPageTotal(Math.ceil(res.count / pageSize));
        }
    );
  }
  // 获取个人NFT HK
  function getUserNftHk(pageCur: number) {
    setUserNft([]);
    let params = {
      pageSize: pageSize, page: pageCur
    }
    if (typeStatus && [1,2,5].includes(typeStatus)) Object.assign(params, {status: typeStatus.toString()})
    UserCollectionHk(params).then(
        (res: any) => {
          setShowNoData(res.rows.length === 0);
          setUserNft(res.rows);
          setPageTotal(Math.ceil(res.count / pageSize));
        }
    );
  }

  // 分页
  function handleChangePage(event, value) {
    if (value === pageCurrent) return false;
    setPageCurrent(value);
    // hk || hero
    if (tabIndex === 1) {
      getUserNftHk(value);
    } else {
      // hero & solana
      getUserNft(value);
    }
    // hero & solana
    // getUserNft(value);
  }

  useEffect(() => {
    if (tabIndex === 1) {
      getUserNftHk(1);
    } else {
      // hero & solana
      getUserNft(1);
    }
    // hero & solana
    // getUserNft(1);
  }, [tabIndex, typeStatus]);

  function toInvite() {
    window.self.location.href = window.location.origin + '/invitation';
  }
  // modal start
  // 充值选择
  const [openSelect, setOpenSelect] = useState(false);
  const handleOpenSelect = () => setOpenSelect(true);
  const handleCloseSelect = () => setOpenSelect(false);
  const [selectVal, setSelectVal] = useState(0);
  // METG 充值
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const [openRel, setOpenRel] = React.useState(false);
  const [openMsgRel, setOpenMsgRel] = React.useState(t("message.rechargeSuccess"));
  const handleOpenRel = () => setOpenRel(true);
  const handleCloseRel = () => setOpenRel(false);
  // METG 转移
  const [openTrans, setOpenTrans] = React.useState(false);
  const handleOpenTrans = () => {
    if (JSON.parse(window.localStorage.getItem("isPhone"))) {
      setOpenTrans(true);
    } else {
      toPhone();
    }
  }
  const handleCloseTrans = () => setOpenTrans(false);
  const [openDisconnect, setOpenDisconnect] = React.useState(false);
  const handleOpenDisconnect = () => setOpenDisconnect(true);
  const handleCloseDisconnect = () => setOpenDisconnect(false);
  const [openDisconnected, setOpenDisconnected] = React.useState(false);
  const handleOpenDisconnected = () => setOpenDisconnected(true);
  const handleCloseDisconnected = () => setOpenDisconnected(false);
  // modal end
  const [copyAdd, setCopyAdd] = useClipboard(userAddress, {
    successDuration: 1000,
  });
  const [cpData, setCpData] = useClipboard(solanaAddress, {
    successDuration: 1000,
  });

  function rechargeConfirm() {
    // @ts-ignore
    const val = document.querySelector('#walletAmount').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      message.info(t("validate.information"));
      return false;
    } else if (
        /^(([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/.test(val)
    ) {
      if (Number(val) >= 500) {
        handleClose();
        OrderRecharge({ metg: Number(val) }).then((res: any) => {
          if (!res.code) {
            setOrderConfirmInfo(res);
            handleOpenConfirm();
          }
        });
      } else {
        message.info('Minimum top-up amount is 500 METG');
      }
    } else {
      message.info(t("validate.long"));
    }
  }
  const rechargeUser = () => {
    if (!agreement) return false;
    handleCloseConfirm();
    PayStripe({ orderId: orderConfirmInfo?.id })
        .then((res: any) => {
          if (!res.code && res.url) {
            window.location.href = res.url;
          }
        })
        .catch(() => {
          setOpenMsgRel('Top-up failed');
          handleOpenRel();
        });
  };
  function openDetail(url: string) {
    window.self.location.href = url;
  }

  function toEditUser() {
    window.self.location.href = window.location.origin + '/settings';
  }

  function toNftHistory() {
    window.self.location.href = window.location.origin + '/nft-list';
  }

  function toTokenList() {
    window.self.location.href = window.location.origin + '/token-list';
  }
  function transConfirm() {
    // @ts-ignore
    const amount = document.querySelector('#transAmount').value.trim();
    // @ts-ignore
    const address = document.querySelector('#transAddress').value.trim();
    if (
        amount === 'null' ||
        amount === 'undefined' ||
        amount === '' ||
        !amount
    ) {
      message.info('Please input the transfer amount');
      return false;
    } else if (!/^[1-9]\d{1,8}$/.test(amount)) {
      message.info('Your input is incorrect');
      return false;
    } else if (Number(amount) < 50) {
      message.info('Minimum transfer amount is 50 METG');
      return false;
    } else if (Number(amount) > userAmount) {
      message.info('Insufficient account balance');
      return false;
    } else if (
        address === 'null' ||
        address === 'undefined' ||
        address === '' ||
        !address
    ) {
      message.info('Please input the transfer address');
      return false;
    } else if (address === userAddress) {
      message.info("You can't transfer it to yourself");
      return false;
    }
    UserTransfer({
      value: Number(amount),
      address: address,
    })
        .then((res: any) => {
          handleCloseTrans();
          if (!res.code) {
            setOpenMsgRel('Transfer successful');
            handleOpenRel();
          } else {
            setOpenMsgRel('Transfer failed');
            handleOpenRel();
          }
        })
        .catch(() => {
          handleCloseTrans();
          setOpenMsgRel('Transfer failed');
          handleOpenRel();
        });
  }

  function getWalletInfo(value: any) {
    if (!value) return false;
    bindWallet({
      type: value?.name === 'Phantom' ? '1' : '2',
      address: value?.address || '',
    }).then((res: any) => {
      if (res.code) {
        window.localStorage.removeItem('address_key');
      }
      getUserInfo();
      getUserNft(1);
    });
  }

  function toDisconnect() {
    unbindWallet({})
        .then((res: any) => {
          handleCloseDisconnect();
          if (!res.code) {
            handleOpenDisconnected();
            getUserInfo();
          }
          window.localStorage.removeItem('address_key');
        })
        .catch(() => {
          handleCloseDisconnect();
        });
  }
  // hk 充值
  const [openHkRecharge, setOpenHkRecharge] = useState(false);
  const handleOpenHkRecharge = () => setOpenHkRecharge(true);
  const handleCloseHkRecharge = () => setOpenHkRecharge(false);

  // gaia 充值
  const [openGaiaRecharge, setOpenGaiaRecharge] = useState(false);
  const handleOpenGaiaRecharge = () => setOpenGaiaRecharge(true);
  const handleCloseGaiaRecharge = () => setOpenGaiaRecharge(false);
  // gaia hk 兑换
  const [exchangeType, setExchangeType] = useState(1);
  const [openGaiaExchange, setOpenGaiaExchange] = useState(false);
  const handleOpenGaiaExchange = (type: number) => {
    setExchangeType(type);
    setExchangeVal(0);
    setCostMetg(0);
    setOpenGaiaExchange(true);
  }
  const handleCloseGaiaExchange = () => setOpenGaiaExchange(false);
  const [gaiaConfig, setGaiaConfig] = useState<any[]>([]);
  const [exchangeVal, setExchangeVal] = useState(0);
  const [costMetg, setCostMetg] = useState(0);
  const getGaiaConfigList = () => {
    getGaiaConfig({scene: 1, type: selectVal.toString()}).then((res: any) => {
      if(!res.code) {
        setGaiaConfig(res.rows);
      }
    });
  }
  const submitGaiaRecharge = (val) => {
    rechargeGaia({id: val}).then((res: any) => {
      if (!res.code) {
        window.location.href = res.url;
      }
    });
  };
  const changeSelect = () => {
    if (selectVal === 0) {
      handleOpen();
    }
    if (selectVal === 2) {
      handleOpenHkRecharge();
      getGaiaConfigList();
    }
    if (selectVal === 1) {
      handleOpenGaiaRecharge();
      getGaiaConfigList();
    }
    handleCloseSelect();
  }

  // 1m = 100g
  const changeGaiaExchangeVal = (value) => {
    if (!value) {
      setExchangeVal(value);
      return false;
    }
    if (/^[1-9]+\d*$/.test(value)) {
      validSetExchange(value);
    } else {
      validSetExchange(parseInt(value));
      message.info("Please enter number more than 1");
    }
  }
  const validSetExchange = (value) => {
    if (exchangeType === 1) {

      if (Number(value) / 100 > Number(userAmount)) {
        setExchangeVal(parseInt((Number(userAmount) * 100).toString()));
        setCostMetg(parseInt((Number(userAmount) * 100).toString()) / 100);
      } else {
        setExchangeVal(value);
        setCostMetg(Number(value) / 100);
      }
    } else if (exchangeType === 2) {
      if (Number(value) / 10 > Number(userAmount)) {
        setExchangeVal(parseInt((Number(userAmount) * 10).toString()));
        setCostMetg(parseInt((Number(userAmount) * 10).toString()) / 10);
      } else {
        setExchangeVal(value);
        setCostMetg(Number(value) / 10);
      }
    }
  }
  const submitGaiaExchange = () => {
    if (exchangeType === 1) {
      mtgGaia({amount: exchangeVal.toString()}).then((res: any) => {
        handleCloseGaiaExchange();
        getUserInfo();
      });
    } else if (exchangeType === 2) {
      mtgHk({amount: exchangeVal.toString()}).then((res: any) => {
        handleCloseGaiaExchange();
        getUserInfo();
      });
    }
  };

  // hk 合成 start
  const [openCombine, setOpenCombine] = useState(false);
  const handleOpenCombine = () => setOpenCombine(true);
  const handleCloseCombine = () => setOpenCombine(false);
  const [openCombineSelect, setOpenCombineSelect] = useState(false);
  const handleOpenCombineSelect = () => setOpenCombineSelect(true);
  const handleCloseCombineSelect = () => setOpenCombineSelect(false);
  const [openCombineResult, setOpenCombineResult] = useState(false);
  const handleOpenCombineResult = () => setOpenCombineResult(true);
  const handleCloseCombineResult = () => setOpenCombineResult(false);
  // nft 合成 列表
  const [combineConf, setCombineConf] = useState<any[]>([]);
  // nft 合成 详情列表
  const [combineSelect, setCombineSelect] = useState<any[]>([]);
  // nft 合成 结果
  const [combineResult, setCombineResult] = useState<any>(null);
  // nft 合成 表单 选中id
  const [formId, setFormId] = useState("");
  // nft 合成 表单 对象 {nftDetailId: "", userNftId: "", img: ""}
  const [formDetails, setFormDetails] = useState<any[]>([]);
  // nft 合成 选中 id
  const [formSelectIds, setFormSelectIds] = useState<any[]>([]);
  // nft 合成 选中 下标
  const [formSelectIndex, setFormSelectIndex] = useState(0);
  // nft 合成 选中 详情
  const [formSelectDetailObj, setFormSelectDetailObj] = useState<any>(null);

  const getCombineConf = () => {
    setCombineConf([]);
    HkNftCombConf({brandRemark: "HKitty"}).then((res: any) => {
      if (!res.code) {
        setCombineConf(res.rows);
        if (res.rows.length) {
          setFormId(res.rows[0].id);
          setFormDetails(res.rows[0].needNftDetailIds);
          let arr = [];
          for (let i = 0; i < res.rows[0].needNftDetailIds.length; i++) {
            arr.push("");
          }
          setFormSelectIds(arr);
        }
      }
    });
  }
  const changeFormId = (id, detailIds) => {
    setFormId(id);
    setFormDetails(detailIds);
    let arr = [];
    for (let i = 0; i < detailIds.length; i++) {
      arr.push("");
    }
    setFormSelectIds(arr);
  }
  const changeFormDetailId = (item, index) => {
    getCombineSelect(item.nftDetailId);
    setFormSelectIndex(index);
    handleOpenCombineSelect();
  }
  const getCombineSelect = (nftDetailId) => {
    setCombineSelect([]);
    HkNftCombToken({nftDetailId: nftDetailId}).then((res: any) => {
      if (!res.code) {
        setCombineSelect(res.rows);
        setFormSelectDetailObj(null);
        if (res.rows.length) {
          setFormSelectDetailObj(res.rows[0]);
        }
      }
    });
  }
  const changeFormSelect = (item) => {
    setFormSelectDetailObj(item);
  }
  const confirmFormSelect = () => {
    if (formSelectDetailObj) {
      let params = {
        userNftId: formSelectDetailObj.userNftId,
        coverImage: formSelectDetailObj.coverImage,
      }

      formSelectIds[formSelectIndex] = formDetails[formSelectIndex].nftDetailId;
      setFormSelectIds(formSelectIds);
      formDetails[formSelectIndex] = Object.assign({...formDetails[formSelectIndex]}, params)
      setFormDetails(formDetails);
    }
    handleCloseCombineSelect();
  }
  const checkForm = () => {
    let temp = true;
    for (let o of formDetails) {
      if (!o.coverImage) temp = false;
    }
    return temp;
  }
  const combineSubmit = () => {
    if (!checkForm()) {
      message.info("Please fill in completely");
      return false;
    }
    let params = {
      id: formId,
      needNftDetailIds: JSON.stringify(formDetails),
    }
    HkNftCombCreate({...params}).then((res: any) => {
      if (!res.code) {
        setCombineResult(res);
        handleCloseCombine();
        handleCloseCombineSelect();
        handleOpenCombineResult();
      }
    });
  }
  // hk 合成 end

  useEffect(() => {
    window.localStorage.removeItem('address_key');
    getUserInfo();
    if (window.location.search && getCookie('ua_once')) {
      if (window.location.search.includes('rel=1')) {
        setOpenMsgRel('Top-up successful');
        removeCookie('ua_once');
        handleOpenRel();
      } else {
        setOpenMsgRel('Top-up failed');
        handleOpenRel();
      }
    }
    getCombineConf();
  }, []);

  function toPhone() {
    window.self.location.href = window.location.origin + '/login-phone?redirect=' + window.location.pathname;
  }

  return <>
    <Helmet>
      <title>MetaGaia Metaverse FAQs & Support</title>
      <meta
          name="description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
      />
      <meta
          property="og:description"
          content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. "
      />
    </Helmet>
    <div className="bg-hexahero">
      <div className="wrapper">
        <Header />
        <main>
          <section className="s-nft s-default">
            <img className="img-bg" src="./assets/images/bg1.png?v=20230413001" data-anchor-target=".s-nft" data-center="transform: translateY(0%)" data-top-bottom="transform: translateY(0%)"/>
            <div className="container user-box">
              <div className="row s-flex s-flex-between-row wow fadeInUp user-info">
                {/* 头像、名称、设置 */}
                <div className="s-flex s-flex-center-start-row col col-12 user-info-an">
                  <div className="s-flex s-flex-center-row user-logo-box">
                    <img className="user-logo-border" src="./assets/images/user/user-logo-border.png?v=20230413001"/>
                    <img className="user-logo" src={userAvatar || './assets/images/user/user-logo.png?v=20230413001'}/>
                  </div>
                  <div className="s-flex s-flex-column user-edit-name">
                    <div className="user-name">{userName}</div>
                    <img className="s-cursor-pointer user-edit-img" src="./assets/images/ico/icon-setting.png?v=20230413001" onClick={toEditUser}/>
                  </div>
                </div>
                {/* 切换 tab 用户 solana */}
                {
                  solanaAddress && solanaBalance ? <div className="col col-12 tab-box-acc user-btn-box">
                    <div className="s-flex tab-acc">
                      <div className={`tab-acc-item s-flex s-flex-center-row s-cursor-pointer ${tabUserAccount === 2 ? 'tab-acc-active' : ''}`} onClick={() => {changeTabUserAccount(2)}}>
                        <span>SOLANA</span>
                      </div>
                      <div className={`tab-acc-item s-flex s-flex-center-row s-cursor-pointer ${tabUserAccount === 1 ? 'tab-acc-active' : ''}`} onClick={() => {changeTabUserAccount(1)}}>
                        <span>INFINIUM</span>
                      </div>
                    </div>
                    {
                        tabUserAccount === 1 && <div
                            className="s-cursor-pointer user-btn-recharge"
                            onClick={handleOpenSelect}
                        >
                          <img src="./assets/images/user/icon-recharge.png" />
                          <span>{t("page.user.Top")}</span>
                        </div>
                    }
                  </div> : <div className="col col-12 tab-box-acc user-btn-box">
                    <div className="s-flex tab-acc">
                      <div className={`tab-acc-item s-flex s-flex-center-row s-cursor-pointer ${tabUserAccount === 1 ? 'tab-acc-active' : ''}`} onClick={() => {changeTabUserAccount(1)}}>
                        <span>INFINIUM</span>
                      </div>
                      <div className={`tab-acc-item s-flex s-flex-center-row s-cursor-pointer ${tabUserAccount === 2 ? 'tab-acc-active' : ''}`} onClick={() => {changeTabUserAccount(2)}}>
                        <span>SOLANA</span>
                      </div>
                    </div>
                    {
                        tabUserAccount === 1 && <div
                            className="s-cursor-pointer user-btn-recharge"
                            onClick={handleOpenSelect}
                        >
                          <img src="./assets/images/user/icon-recharge.png" />
                          <span>{t("page.user.Top")}</span>
                        </div>
                    }
                  </div>
                }
                {
                    tabUserAccount === 1 && <>
                      <div className="col col-12 user-info-m">
                        <div className="">
                          <div className="s-flex">
                            <img src="./assets/images/user/token-mg.png" alt=""
                                 style={{width: "2rem", marginRight: "1rem"}}/>
                            <span className="user-money-title">{t("page.user.METG")}</span>
                            <span className="user-money">{formatPrice(userAmount)}</span>
                          </div>
                          <div className="s-flex"
                               style={{alignItems: 'flex-end', lineHeight: 1,}}>
                            <span className="s-flex user-add-title">{t("page.user.Address")} </span>
                            <span className="s-flex user-add">
                          {userAddress.replace(/^(.{4}).+(.{4})$/, '$1…$2')}
                        </span>
                            <img
                                className="s-flex s-cursor-pointer user-add-copy icon-copy"
                                src="./assets/images/ico/icon-copy.png?v=20230413001"
                                onClick={() => {
                                  message.info('Copied');
                                  setCopyAdd();
                                }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="s-flex user-btn-box">
                            <div
                                className="s-cursor-pointer user-btn-trans"
                                onClick={handleOpenTrans}
                            >
                              <img src="./assets/images/user/icon-trans.png"/>
                              <span>Transfer</span>
                            </div>
                            <button
                                className="btn btn-default btn-border-none user-btn-trans-list"
                                onClick={toTokenList}
                            >
                              {t("page.user.TRANSACTIONS")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div style={{
                        width: "100%",
                        borderTop: "1px dashed rgba(0, 251, 251, 1)",
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem"
                      }}></div>

                      <div className="col col-12 user-info-m">
                        <div className="">
                          <div className="s-flex">
                            <img src="./assets/images/user/token-hk.png" alt=""
                                 style={{width: "2rem", marginRight: "1rem"}}/>
                            <span className="user-money-title">Star Chips:</span>
                            <span className="user-money">{formatPrice(userHk)}</span>
                          </div>
                        </div>
                        <div className="">
                          <div className="s-flex user-btn-box">
                            <div
                                className="s-cursor-pointer user-btn-trans"
                                onClick={() => {
                                  handleOpenGaiaExchange(2)
                                }}
                            >
                              <img src="./assets/images/user/icon-exchange.png" alt=""/>
                              <span>Exchange</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-12 user-info-m">
                        <div className="">
                          <div className="s-flex">
                            <img src="./assets/images/user/token-ga.png" alt=""
                                 style={{width: "2rem", marginRight: "1rem"}}/>
                            <span className="user-money-title">{t("page.user.GAIA")}</span>
                            <span className="user-money">{formatPrice(userGaia)}</span>
                          </div>
                        </div>
                        <div className="">
                          <div className="s-flex user-btn-box">
                            <div
                                className="s-cursor-pointer user-btn-trans"
                                onClick={() => {
                                  handleOpenGaiaExchange(1)
                                }}
                            >
                              <img src="./assets/images/user/icon-exchange.png"/>
                              <span>Exchange</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                }
                {
                    tabUserAccount === 2 && solanaAddress && solanaBalance &&
                    <div className="col col-12 user-info-m user-info-sol">
                      <div className="">
                        <div className="s-flex">
                          <span className="user-money-title">Locked METG (LMETG) Token:</span>
                          <span className="user-money">
                            {formatPrice(solanaBalanceLocked)}
                          </span>
                        </div>
                        <div className="s-flex"
                             style={{alignItems: 'flex-end', lineHeight: 1,}}>
                          <span className="s-flex user-add-title">Address: </span>
                          <span className="s-flex user-add">
                            {solanaAddress.replace(/^(.{4}).+(.{4})$/, '$1…$2')}
                          </span>
                          <img
                              className="s-flex s-cursor-pointer user-add-copy icon-copy"
                              src="./assets/images/ico/icon-copy.png?v=20230413001"
                              onClick={() => {
                                message.info('Copied');
                                setCpData();
                              }}
                          />
                        </div>
                      </div>
                      <div className="line-common" style={{margin: '15px 0 10px'}}></div>
                      <div className="">
                        <div className="s-flex">
                          <span className="user-money-title">Unlocked METG (METG) Token:</span>
                          <span className="user-money">
                            {formatPrice(solanaBalance)}
                          </span>
                        </div>
                        <div className="s-flex"
                             style={{alignItems: 'flex-end', lineHeight: 1,}}>
                          <span className="s-flex user-add-title">Address: </span>
                          <span className="s-flex user-add">
                            {solanaAddress.replace(/^(.{4}).+(.{4})$/, '$1…$2')}
                          </span>
                          <img
                              className="s-flex s-cursor-pointer user-add-copy icon-copy"
                              src="./assets/images/ico/icon-copy.png?v=20230413001"
                              onClick={() => {
                                message.info('Copied');
                                setCpData();
                              }}
                          />
                        </div>
                      </div>
                    </div>
                }
              </div>
              {/* 账户数据 NFT */}
              {
                  tabUserAccount === 1 && <>
                    <div className="s-flex s-flex-center-row" style={{display: "none"}}>
                      <button
                          className="btn btn-default btn-border-none wow fadeInUp user-goto-ph history-margin-top"
                          onClick={toNftHistory}
                      >
                        {t("page.user.NFT")}
                      </button>
                    </div>
                    {/* nft 数据 列表 */}
                    <div className="col col-12 tab-box-data">
                      <div className="s-flex s-flex-between-row tab-data">
                        <div className="s-flex width-auto-flex">
                          <div className={`tab-data-item s-flex s-flex-center-row s-cursor-pointer ${tabIndex === 2 ? 'tab-data-active' : ''}`} onClick={() => {tabChange(2)}}>
                            <span>{t("page.user.nftHH")}</span>
                          </div>
                          <div className={`tab-data-item s-flex s-flex-center-row s-cursor-pointer ${tabIndex === 1 ? 'tab-data-active' : ''}`} onClick={() => {tabChange(1)}}>
                            <span>{t("page.user.nftHK")}</span>
                          </div>
                        </div>
                        <div className="s-flex s-flex-center-end-row market-list-token" style={{display: "none"}}>
                          <button
                              className="btn btn-default btn-border-none user-goto-ph"
                              onClick={toNftHistory}
                          >
                            {t("page.user.NFT1")}
                          </button>
                        </div>
                      </div>
                    </div>
                    {
                        tabIndex === 1 && <div className="col col-12">
                          {/* hk 数据 */}
                          <div className={`row row-ppl row-ppl1 user-nft-list tab-data-data ${userNft.length ? "":""}`}>
                            <div className="col col-12 user-nft-btns wow fadeIn">
                              <div className="user-nft-btn-search wow fadeIn">
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 0 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(0)}>{t("page.user.btnAll")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 1 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(1)}>{t("page.user.btnMint")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 2 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(2)}>{t("page.user.btnListed")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 5 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(5)}>{t("page.user.btnCombine")}</div>
                              </div>
                              <div className="s-flex s-flex-center-row s-cursor-pointer nft-btn-search-ot" onClick={handleOpenCombine}>{t("page.user.btnCombine")}</div>
                            </div>

                            {userNft &&
                                userNft.map((item: any, index) => {
                                  return (
                                      <div
                                          className="col col-6 col-sm-4 col-lg-3 col-xl-3 list-hk-item s-cursor-pointer"
                                          onClick={() => {
                                            openDetail('/hk-detail?uuid=' + item.userNftid);
                                          }
                                          }
                                          key={index}
                                      >
                                        <img src={item.image} alt="" />
                                        <div className="hk-item-title">{item.name}</div>
                                        <div className="hk-item-number">#{item.token}</div>
                                        <div className="hk-item-desc">{item.status === 1 ? "MINT" : item.status === 2 ? "LESTED" : item.status === 5 ? "COMBINE" : "MINT"}</div>
                                      </div>
                                  );
                                })}

                            {
                                userNft && !!userNft.length &&
                                <div className="col col-12 s-flex s-flex-center-row" style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                  {/* 分页 */}
                                  <Pagination count={pageTotal} page={pageCurrent} size="small" color="primary" onChange={handleChangePage} />
                                </div>
                            }
                            {/* 无数据 */}
                            {
                                showNoData && !userNft.length &&
                                <h3 className="tab-connect-box no-data">{t("page.user.NO1")}</h3>
                            }
                          </div>
                        </div>
                    }
                    {/* hex hero */}
                    {tabIndex === 2 && (
                        <div className="col col-12">
                          <div className={`row row-ppl row-ppl1 user-nft-list tab-data-data ${userNft.length ? "":""}`}>
                            <div className="col col-12 user-nft-btns wow fadeIn">
                              <div className="user-nft-btn-search wow fadeIn">
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 0 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(0)}>{t("page.user.btnAll")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 1 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(1)}>{t("page.user.btnMint")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 3 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(3)}>{t("page.user.btnListed")}</div>
                                <div className={`s-flex s-flex-center-row s-cursor-pointer nft-btn-search ${typeStatus === 6 ? "nft-btn-search-active" : ""}`} onClick={() => setTypeStatus(6)}>LEASED</div>
                              </div>
                            </div>
                            {userNft &&
                                userNft.map((item: any, index) => {
                                  return (
                                      <div
                                          className="col col-6 col-sm-6 col-lg-4 col-xl-3 list-item s-cursor-pointer"
                                          onClick={() => {
                                            openDetail('/nft-detail?uuid=' + item.id);
                                          }
                                          }
                                          key={index}
                                      >
                                        <div className="s-flex s-flex-center-row list-item-ct">
                                          <img
                                              className="nft-list-img"
                                              src={item.image}
                                          />
                                          {
                                              item.lease && <img className="nft-icon-img" src="assets/images/user/iocn-lease.png"></img>
                                          }
                                          <div className="nft-list-name">{item.name}</div>
                                          <div className="list-item-line"></div>
                                        </div>
                                      </div>
                                  );
                                })}

                            {
                                userNft && !!userNft.length &&
                                <div className="col col-12 s-flex s-flex-center-row" style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                  {/* 分页 */}
                                  <Pagination count={pageTotal} page={pageCurrent} size="small" color="primary" onChange={handleChangePage} />
                                </div>
                            }
                            {/* 无数据 */}
                            {showNoData && !userNft.length &&
                                  <h3 className="tab-connect-box no-data">{t("page.user.NO1")}</h3>
                            }
                          </div>
                        </div>
                    )}
                  </>
              }
              {/* solana数据 */}
              {
                  tabUserAccount === 2 && <>
                    <div className="s-flex s-flex-center-row">
                      {!hasWallet && (
                          <button
                              className="btn btn-default btn-border-none wow fadeInUp user-goto-ph history-margin-top"
                          >
                            {
                              solanaAddress && solanaBalance ? <WalletBind getInfo={getWalletInfo} text={"CONNECT ANOTHER SOL WALLET"} /> :
                                  <WalletBind getInfo={getWalletInfo} text={t("page.user.CONNECT")} />
                            }
                          </button>
                      )}
                      {hasWallet && walletInfo === 1 && (
                          <button
                              className="btn btn-default btn-border-none wow fadeInUp user-goto-ph history-margin-top"
                              style={{
                                backgroundColor: 'none',
                                color: '#FFF',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                              }}
                              onClick={handleOpenDisconnect}
                          >
                            <img
                                src="assets/images/user/phantom-icon.png"
                                className="phantom-icon"
                            />
                            Phantom
                          </button>
                      )}
                      {hasWallet && walletInfo === 2 && (
                          <button
                              className="btn btn-default btn-border-none wow fadeInUp user-goto-ph history-margin-top"
                              style={{
                                backgroundColor: 'none',
                                color: '#FFF',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                              }}
                              onClick={handleOpenDisconnect}
                          >
                            <img
                                src="assets/images/user/solflare-icon.png"
                                className="phantom-icon"
                            />
                            Solflare
                          </button>
                      )}
                    </div>
                    {hasWallet && (
                        <div className="col col-12 tab-box-data">
                          <div className="s-flex s-flex-between-row tab-data">
                            <div className="s-flex">
                              <div className={`tab-data-item tab-data-item-all s-flex s-flex-center-row s-cursor-pointer tab-data-active`}>
                                {walletInfo === 1 && <span className="s-flex s-flex-center-row">
                            <img
                                src="assets/images/user/phantom-icon.png"
                                className="phantom-icon"
                            /> Phantom</span>}
                                {walletInfo === 2 && <span className="s-flex s-flex-center-row">
                            <img
                                src="assets/images/user/solflare-icon.png"
                                className="phantom-icon"
                            /> Solflare</span>}
                              </div>
                            </div>
                            <div className="s-flex s-flex-center-end-row market-list-token">
                              <button
                                  className="btn btn-default btn-border-none user-goto-ph"
                                  onClick={handleOpenDisconnect}
                              >
                                {t("page.user.Disconnect")}
                              </button>
                            </div>
                          </div>
                        </div>
                    )}
                    {!hasWallet && (
                        <div className="s-flex s-flex-center-row tab-connect-box">
                          <button className="btn btn-default tab-connect">
                            {
                              solanaAddress && solanaBalance ? <WalletBind getInfo={getWalletInfo} text={"CONNECT ANOTHER SOL WALLET"} /> :
                                  <WalletBind getInfo={getWalletInfo} text={"CONNECT SOL WALLET"} />
                            }
                          </button>
                        </div>
                    )}
                    {tabIndex === 9 && hasWallet && (
                        <div className="col col-12">
                          <div className={`row row-ppl row-ppl1 user-nft-list tab-data-data`}>
                            {hasWallet &&
                                userNft &&
                                userNft.map((item: any, index) => {
                                  return (
                                      <div
                                          className="col col-6 col-sm-6 col-lg-4 col-xl-3 list-item s-cursor-pointer"
                                          onClick={() =>
                                              openDetail('/nft-detail?uuid=' + item.id)
                                          }
                                          key={index}
                                      >
                                        <div className="s-flex s-flex-center-row list-item-ct">
                                          <img
                                              className="nft-list-img"
                                              src={item.image}
                                          />
                                          <div className="nft-list-name">{item.name}</div>
                                          <div className="list-item-line"></div>
                                        </div>
                                      </div>
                                  );
                                })}

                            {
                                userNft && !!userNft.length &&
                                <div className="col col-12 s-flex s-flex-center-row" style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                  {/* 分页 */}
                                  <Pagination count={pageTotal} page={pageCurrent} size="small" color="primary" onChange={handleChangePage} />
                                </div>
                            }
                            {showNoData && hasWallet && !userNft.length && (
                                  <h3 className="tab-connect-box no-data">NO DATA</h3>
                            )}
                          </div>

                        </div>
                    )}
                  </>
              }

              {/* 邀请好友 */}
              {
                  tabUserAccount === 1 && <div className="s-flex s-flex-center-row invite-box">
                    <button
                        onClick={toInvite}
                        className="btn btn-default btn-form-invite active"
                        style={{
                          justifyContent: 'space-between',
                          textAlign: 'center',
                        }}
                    >
                      <img
                          src="assets/images/user/invite-icon.png"
                          className="invite-icon"
                      />
                      {t("page.user.Invite")}
                    </button>
                  </div>
              }
            </div>
          </section>
          <Footer />
        </main>
      </div>
    </div>
    {/* 充值 start */}
    {/* 充值选择 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openSelect}
        onClose={handleCloseSelect}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
    >
      <Fade in={openSelect}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseSelect}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.dialogReSelectTitle")}
          </div>
          <div className="modal-select">
            <div className={`s-flex s-flex-center-row s-cursor-pointer modal-select-item ${selectVal === 0 ? "selected-bg" : "unselect-bg"}`} onClick={() => {setSelectVal(0)}}>
              <img className="modal-select-ico" src="./assets/images/user/token-mg.png" alt=""/>
              <span>{t("page.user.METG")}</span>
            </div>
            <div className={`s-flex s-flex-center-row s-cursor-pointer modal-select-item ${selectVal === 2 ? "selected-bg" : "unselect-bg"}`} onClick={() => {setSelectVal(2)}} style={{display: "none"}}>
            <img className="modal-select-ico" src="./assets/images/user/token-hk.png" alt=""/>
            <span>{t("page.user.tokenHK")}</span>
            </div>
            <div className={`s-flex s-flex-center-row s-cursor-pointer modal-select-item ${selectVal === 1 ? "selected-bg" : "unselect-bg"}`} onClick={() => {setSelectVal(1)}}>
              <img className="modal-select-ico" src="./assets/images/user/token-ga.png" alt=""/>
              <span>{t("page.user.GAIA")}</span>
            </div>
          </div>
          <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
            <div
                className="modal-btn modal-btn-all modal-btn-confirm"
                onClick={changeSelect}
            >
              {t("page.user.dialogBtnUpConfirm")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>

    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleClose}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.Top")}
          </div>
          <div className="modal-recharge-pd modal-recharge-title">
            {t("page.user.How")}
          </div>
          <div className="modal-recharge-pd modal-recharge-tip">
            {t("page.user.Minimum")} <span>500</span> METG
          </div>
          <div className="modal-recharge-pd modal-recharge-value">
            <input
                type="text"
                id="walletAmount"
                maxLength={8}
                placeholder="Enter Here"
            />
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text">
            <span className="modal-recharge-text-weight">1 </span>
            <span>METG ≈ $ {rateAmount} USD</span>
          </div>
          <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
            <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleClose}
            >
              {t("page.user.CANCEL")}
            </div>
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={rechargeConfirm}
            >
              {t("page.user.NEXT")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openConfirm}
        onClose={handleCloseConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-confirm-box"
    >
      <Fade in={openConfirm}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseConfirm}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.Confirm")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-confirm-pd modal-recharge-confirm-text modal-recharge-text-blue">
            {t("page.user.Amount")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-confirm-pd modal-recharge-confirm-text modal-recharge-text-blue modal-recharge-text-bt">
              <span className="modal-recharge-confirm-text-weight">
                {orderConfirmInfo?.metg.toFixed(4)}
              </span>
            <span>METG</span>
          </div>
          <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
            <span>PRICE</span>
            <span>$ {orderConfirmInfo?.price.toFixed(2)} USD</span>
          </div>
          <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
            <span>SERVICE FEE</span>
            <span>$ {orderConfirmInfo?.serviceFee.toFixed(2)} USD</span>
          </div>
          <div className="s-flex s-flex-between-row modal-recharge-confirm-pd">
            <div className="modal-recharge-confirm-line"></div>
          </div>
          <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-confirm-text">
            <span>SUBTOTAL</span>
            <span className="s-flex">
                <span className="s-flex-self-end">$</span>
                <span className="modal-recharge-confirm-text-weight">
                  {orderConfirmInfo?.countPrice.toFixed(2)}
                </span>
                <span className="s-flex-self-start modal-recharge-confirm-text-unit">
                  USD
                </span>
              </span>
          </div>
          <div className="modal-recharge-confirm-agree">
            <div className="s-flex s-flex-between-row modal-recharge-confirm-pd modal-recharge-agree">
              {agreement ? (
                  <img
                      className="checkbox-agree"
                      src="./assets/images/user/icon-checkbox-select.png?v=20230413001"
                      onClick={() => {
                        setAgreement(false);
                      }}
                  />
              ) : (
                  <img
                      className="checkbox-agree"
                      src="./assets/images/user/icon-checkbox.png?v=20230413001"
                      onClick={() => {
                        setAgreement(true);
                      }}
                  />
              )}
              <span>
                  {t("page.user.I")}
                </span>
            </div>
            <div className="modal-recharge-confirm-pd modal-recharge-confirm-btn modal-btn-box modal-btn-center">
              <div
                  className="modal-btn modal-btn-all modal-btn-confirm-lang"
                  onClick={rechargeUser}
              >
                {t("page.user.CONFIRM")}
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openRel}
        onClose={handleCloseRel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-rel-box"
    >
      <Fade in={openRel}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseRel}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {openMsgRel}
          </div>
          <div className="modal-recharge-rel-btn modal-btn-box modal-btn-center">
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={handleCloseRel}
            >
              CONFIRM
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openTrans}
        onClose={handleCloseTrans}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
    >
      <Fade in={openTrans}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseTrans}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.METG1")}
          </div>
          <div className="modal-recharge-pd modal-recharge-title">
            {t("page.user.How1")}
          </div>
          <div className="modal-recharge-pd modal-recharge-tip">
            {t("page.user.You")} <span>{userAmount}</span> METG
          </div>
          <div className="modal-recharge-pd modal-recharge-tip">
            {t("page.user.Minimum1")} <span>50</span> METG
          </div>
          <div className="modal-recharge-pd modal-recharge-value">
            <input
                type="text"
                id="transAmount"
                maxLength={16}
                placeholder="Enter Here"
            />
          </div>
          <div className="modal-recharge-pd modal-recharge-title">
            {t("page.user.Enter")}
          </div>
          <div className="modal-recharge-pd modal-recharge-value">
            <input
                type="text"
                id="transAddress"
                maxLength={100}
                placeholder="Enter Here"
            />
          </div>
          <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between modal-trans-btn">
            <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleCloseTrans}
            >
              {t("page.user.CANCEL")}
            </div>
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={transConfirm}
            >
              {t("page.user.CONFIRM")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* 解绑钱包弹窗 start */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDisconnect}
        onClose={handleCloseDisconnect}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box"
    >
      <Fade in={openDisconnect}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseDisconnect}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.Disconnect")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text">
            {t("page.user.Are")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text modal-no-margin no-margin-bottom">
            {t("page.user.disconnect")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text wallet-address">
            {t("page.user.Wallet")}
          </div>
          <div className="s-flex s-flex-center-row modal-recharge-pd modal-recharge-text modal-pay-text wallet-address-num">
            {walletAddress}
          </div>
          <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between">
            <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleCloseDisconnect}
            >
              {t("page.user.CANCEL")}
            </div>
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={toDisconnect}
            >
              {t("page.user.CONFIRM")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* 解绑钱包弹窗 end */}

    {/* 解绑钱包成功弹窗 start */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openDisconnected}
        onClose={handleCloseDisconnected}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-rel-box"
    >
      <Fade in={openDisconnected}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseDisconnected}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.Successfully")}
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title no-margin">
            {t("page.user.Disconnected")}
          </div>
          <div className="modal-recharge-rel-btn modal-btn-box modal-btn-center">
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={handleCloseDisconnected}
            >
              {t("page.user.CONFIRM")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* 解绑钱包成功弹窗 end */}
    {/* 兑换弹窗 start */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openGaiaExchange}
        onClose={handleCloseGaiaExchange}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-box modal-recharge-box modal-exchange-box"
    >
      <Fade in={openGaiaExchange}>
        <Box sx={style}>
          <div className="s-flex close-box">
            <img
                src="./assets/images/icon-modal-close.png"
                onClick={handleCloseGaiaExchange}
            />
          </div>
          <div className="s-flex s-flex-center-row modal-ct-title">
            {t("page.user.EXCHANGE")}
          </div>
          <div className="modal-recharge-pd modal-recharge-title">
            {exchangeType === 1 ? t("page.user.How3") : t("page.user.How2")}
          </div>
          <div className="modal-recharge-pd modal-recharge-value">
            <input
                type="text"
                maxLength={16}
                placeholder="Enter Here"
                value={exchangeVal}
                onChange={(e: any) => {changeGaiaExchangeVal(e.target.value)}}
            />
          </div>
          <div className="modal-recharge-pd modal-exchange-tip modal-exchange-sub-title">
            {t("page.user.Cost")} <span>{costMetg}</span> METG
          </div>
          <div className="modal-recharge-pd modal-exchange-tip s-flex s-flex-center-row">
            {t("page.user.You1")} <span>{userAmount}</span> METG
          </div>
          <div className="modal-recharge-pd modal-exchange-tips s-flex s-flex-center-row">
            1 METG ≈ {exchangeType === 1 ? "100 GAIA" : "10 Star Chips"}
          </div>
          <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between modal-trans-btn">
            <div
                className="modal-btn modal-btn-half modal-btn-cancel"
                onClick={handleCloseGaiaExchange}
            >
              {t("page.user.CANCEL")}
            </div>
            <div
                className="modal-btn modal-btn-half modal-btn-confirm"
                onClick={submitGaiaExchange}
            >
              {t("page.user.CONFIRM")}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* gaia 兑换 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openGaiaRecharge}
        onClose={handleCloseGaiaRecharge}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-full modal-recharge-gaia-box"
    >
      <Fade in={openGaiaRecharge}>
        <Box sx={styleNoBorder}>
          <div className="gaia-recharge-box">
            {
                gaiaConfig.length && gaiaConfig.map((item: any, index: number) => {
                  return <div className="gaia-item" key={index}>
                    <div className="s-flex s-flex-center-row gaia-ico-ico"><img className="gaia-icon" src={item?.bgImage} alt=""/></div>
                    <div className="s-flex s-flex-center-row gaia-ico-tip">GAIA</div>
                    <div className="s-flex s-flex-center-row gaia-ico-tip">X</div>
                    <div className="s-flex s-flex-center-row gaia-amount">{item?.quantity}</div>
                    <div className="s-flex s-flex-center-row gaia-pay">
                      <div className="s-flex s-flex-center-row s-cursor-pointer gaia-btn" onClick={() => {submitGaiaRecharge(item?.id)}}>${item?.payPrice}</div>
                    </div>
                  </div>
                })
            }
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* hk 充值 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openHkRecharge}
        onClose={handleCloseHkRecharge}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-full modal-recharge-hk-box"
    >
      <Fade in={openHkRecharge}>
        <Box sx={styleNoBorder}>
          <div className="s-flex s-flex-center-row hk-recharge-box">
            <div className="s-flex hk-body">
              <div className="s-flex hk-body-cont">
                {
                    gaiaConfig.length && gaiaConfig.map((item: any, index: number) => {
                      return <div className="s-flex s-flex-center-row hk-token-item" key={index}>
                        <div className="token-item-body">
                          <div className="s-flex s-flex-center-row token-item-img"><img src={item?.bgImage} alt=""/></div>
                          <div className="token-item-opt">
                            <div className="s-flex s-flex-center-row token-item-num">
                              <img src="./assets/images/hellokity/ico-token-hk.png" alt=""/>
                              <span>X {item?.quantity}</span>
                            </div>
                            <div className="s-flex s-flex-center-row s-cursor-pointer token-item-btn" onClick={() => {submitGaiaRecharge(item?.id)}}>${item?.payPrice}</div>
                          </div>
                        </div>
                      </div>
                    })
                }
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* hk 合成 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openCombine}
        onClose={handleCloseCombine}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-full modal-combine-box"
    >
      <Fade in={openCombine}>
        <Box sx={styleNoBorder}>
          <div className="s-flex s-flex-center-row comb-box">
            <div className="comb-body">
              <div className="s-flex s-flex-center-row comb-title">
                <div className="s-flex s-flex-center-row">
                  <span>Combine</span>
                  <img className="s-cursor-pointer btn-ques" src="./assets/images/hellokity/ico-question.png" alt="" />
                </div>
                <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={handleCloseCombine}/>
              </div>
              <div className="comb-container">
                <div className="s-flex comb-select-btn">
                  {
                    combineConf && combineConf.map((item) => {
                        return <div className={`s-flex s-flex-center-row s-cursor-pointer select-btn-img ${item.id === formId ? "comb-select-active" : ""}`} key={item.id} onClick={() => changeFormId(item.id, item.needNftDetailIds)}>{item.name}</div>
                    })
                  }
                </div>
                <div className="s-flex comb-select-content">
                  {
                      formDetails && formDetails.map((item, index) => {
                      return <div key={item.nftDetailId}>
                      {
                        formSelectIds.includes(item.nftDetailId) &&
                          <div className="s-flex s-flex-center-row s-cursor-pointer select-content selected-content">
                            <img src={item.coverImage} alt=""/>
                          </div>
                      }
                      {
                        !formSelectIds.includes(item.nftDetailId) &&
                          <div className="s-flex s-flex-center-row s-cursor-pointer select-content unselect-content" onClick={() => changeFormDetailId(item, index)}>
                            <img className="s-flex select-ico" src="./assets/images/hellokity/ico-add.png" alt=""/>
                          </div>
                      }
                      </div>
                    })
                  }
                </div>
                <div className="s-flex s-flex-center-row">
                  <div className="s-flex s-flex-center-row s-cursor-pointer btn-confirm" onClick={combineSubmit}>COMBINE</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* hk 合成选择 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openCombineSelect}
        onClose={handleCloseCombineSelect}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-full modal-combine-box"
    >
      <Fade in={openCombineSelect}>
        <Box sx={styleNoBorder}>
          <div className="s-flex s-flex-center-row comb-box">
            <div className="comb-body selected-body">
              <div className="s-flex s-flex-center-row comb-title">
                <div className="s-flex s-flex-center-row">
                  <span>Select</span>
                </div>
                <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={handleCloseCombineSelect}/>
              </div>
              <div className="comb-container">
                <div className="s-flex s-flex-center-row selected-title">{formSelectDetailObj ? formSelectDetailObj.name : ""}</div>
                <div className="s-flex s-flex-center-row comb-select-content">
                  <div className="s-flex s-flex-center-row s-cursor-pointer select-content selected-content">
                    <img src={formSelectDetailObj ? formSelectDetailObj.coverImage : ""} alt=""/>
                  </div>
                </div>
                <div className="s-flex comb-select-btn">
                  {
                    combineSelect && combineSelect.map((item) => {
                      return <div className={`s-flex s-flex-center-row s-cursor-pointer select-btn-img ${item.token === (formSelectDetailObj ? formSelectDetailObj.token : "") ? "comb-select-active" : ""}`}
                                  key={item.token} onClick={() => changeFormSelect(item)}>#{item.token}</div>
                    })
                  }
                </div>
                <div className="s-flex s-flex-center-row">
                  <div className="s-flex s-flex-center-row s-cursor-pointer btn-confirm" onClick={confirmFormSelect}>CONFIRM</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* hk 合成结果 */}
    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openCombineResult}
        onClose={handleCloseCombineResult}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-full modal-combine-box"
    >
      <Fade in={openCombineResult}>
        <Box sx={styleNoBorder}>
          <div className="s-flex s-flex-center-row comb-box">
            <div className="comb-body result-body">
              <div className="s-flex s-flex-center-row comb-title">
                <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={handleCloseCombineResult}/>
              </div>
              <div className="comb-container">
                <div className="s-flex s-flex-center-row">
                  <img className="result-title" src="./assets/images/hellokity/combine-result-title.png" alt=""/>
                </div>
                <div className="result-desc">You successfully combined</div>
                <div className="result-desc">{combineResult && combineResult.name} Wonderlord Pass</div>
                <div className="s-flex s-flex-center-row comb-select-content">
                  <div className="s-flex s-flex-center-row s-cursor-pointer result-video">
                    <video autoPlay muted disablePictureInPicture preload="auto" width="50%">
                      <source
                          src={combineResult && combineResult.video}
                          type="video/mp4"></source>
                    </video>
                  </div>
                </div>
                <div className="s-flex s-flex-center-row">
                  <div className="s-flex s-flex-center-row s-cursor-pointer btn-confirm btn-result">CHECK MY COLLECTION</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    {/* 兑换弹窗 end */}
  </>;
};
