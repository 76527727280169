
import {
  GET_WALLET,
  GetWalletStateType,
  WalletActionType

} from '../types/WalletTypes';

const initialStateGetPosts: GetWalletStateType = {
  wallet: { balance: 0, publicKey: "", name: "" }
};

export const walletReducer = (
  state = initialStateGetPosts,
  action: WalletActionType
): GetWalletStateType => {
  switch (action.type) {
    case GET_WALLET:
      return {
        ...state,
        wallet: action.payload
      };
    default:
      return state;
  }
};