import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import {useState, useEffect} from "react";
import * as React from "react";
import message from '../components/Message';
import {emailKey} from "../libs/api";
import {useTranslation} from "react-i18next";

function InviteCode() {
	const { t } = useTranslation();

	const [mail, setMail] = useState("");
	function inviteCodeEmail() {
		if (mail === null || !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig.test(mail)) {
			message.info("Please check your email");
		} else {
			emailKey({email: mail}).then((res: any) => {
				if (!res.code) {
					message.info("Invite key send success");
				}
			}).catch((e: any) => {
				message.info("Server Error");
			});
		}
	}

	return (
		<div className="bg-hexahero bg-faq invite-code">
			<Helmet>
				<title>MetaGaia Metaverse FAQs & Support</title>
				<meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
				<meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
			</Helmet>

			<div className="wrapper">
				<Header />

				<main>
					<section className="s-nft s-default">
						<div className="container">
							<div className="box-title wow fadeInUp s-nft-hero">
								<img className="img-title" src="./assets/images/game/title-invitecode.png?v=20230413001" />
								<div className="p-default-title max-lg">
									<div className="b-subscription max-md">
										<input
											value={mail}
											onChange={e => setMail(e.target.value)}
											className="form-input"
											placeholder={t("placeholder.email")}
										/>
										<button className="btn btn-default" onClick={inviteCodeEmail}>{t("button.GETKEY")}</button>
									</div>
								</div>
								<div className="container">
									<div className="invite-code-title">{t("page.invite.THE1")} <span className="invite-code-title-primary">{t("page.invite.THE2")}</span></div>
									<div className="invite-code-desc">{t("page.invite.Enter")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-1.png?v=20230413001" />
									</div>
									<div className="invite-code-title"><span className="invite-code-title-primary">{t("page.invite.CREATE1")}</span> {t("page.invite.CREATE2")}</div>
									<div className="invite-code-title">{t("page.invite.AVATAR1")} <span className="invite-code-title-primary">{t("page.invite.AVATAR2")}</span> {t("page.invite.AVATAR3")}</div>
									<div className="invite-code-desc">{t("page.invite.Step")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-2.png?v=20230413001" />
									</div>
									<div className="invite-code-title"><span className="invite-code-title-primary">{t("page.invite.ENGAGE1")} </span> {t("page.invite.ENGAGE2")}</div>
									<div className="invite-code-title">{t("page.invite.AND1")} <span className="invite-code-title-primary">{t("page.invite.AND2")}</span> {t("page.invite.AND3")}</div>
									<div className="invite-code-desc">{t("page.invite.With")}</div>
									<div>
										<img className="invite-code-img" src="./assets/images/game/invite-code-img-3.png?v=20230413001" />
									</div>
									<div className="invite-code-title">{t("page.invite.JOIN1")} <span className="invite-code-title-primary">{t("page.invite.JOIN2")}</span> {t("page.invite.JOIN3")}</div>
									<div className="invite-code-desc">{t("page.invite.Ready")}</div>
									<div className="invite-code-desc">{t("page.invite.Submit")}</div>
								</div>
								<div className="p-default-title max-lg">
									<div className="b-subscription max-md">
										<input
											value={mail}
											onChange={e => setMail(e.target.value)}
											className="form-input"
											placeholder={t("placeholder.email")}
										/>
										<button className="btn btn-default" onClick={inviteCodeEmail}>{t("button.GETKEY")}</button>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</main>
			</div>
		</div>
	);
}

export default InviteCode;
