import Header from "../Header";
import Footer from "../Footer";
import * as React from "react";

export default function LotteryAgree() {

    return <>
        <div className="hello-kitty mark-lottery">
            <div className="wrapper">
                <Header />
                <main className="hello-kitty-bg-fixed">
                    <section className="hk-info hk-bg-ico">
                        <div className="s-flex s-flex-center-row lottery-logo">
                            <img className="logo-img" src="assets/images/mark/logo.png" alt=""/>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </div>
    </>;
}
