import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import {GetModelList} from "../libs/api";
import Storage from "../libs/storage";
import {getCookie} from "../libs/cookies";
import {useTranslation} from "react-i18next";

export default function UnityList() {
    const { t } = useTranslation();

    const [modelList, setModelList] = useState([]);

    function toDetail(item) {
        Storage.setItem("unityData", item);
        window.self.location.href = window.location.origin + "/ut-detail";
    }

    useEffect(() => {
        GetModelList({
            token: Storage.getItem("unity-token") || getCookie('ua_once') || getCookie('ua_user'),
            status: 2
        }).then((res: any) => {
            if (!res.code) {
                setModelList(res.rows);
            }
        });
    }, []);

    return (
        <div className="bg-hexahero">
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta property="og:description" content="" />
            </Helmet>

            <div className="wrapper">
                <main>
                    <section className="s-default unity-box">
                        <img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-nft"
                             data-top="transform: translateY(0%)"
                             data-top-bottom="transform: translateY(0%)" />

                        <div className="container unity-list">
                            <div className="wow fadeInUp unity-title">
                                MY COLLECTION
                                <div className="wow fadeInUp s-flex s-flex-center-row s-cursor-pointer unity-back"
                                    onClick={() => {window.history.back();}}>
                                    <img className="unity-back-icon" src="assets/images/unity/btn-back.png?v=20230413001"/>
                                    <img className="unity-back-txt" src="assets/images/unity/btn-back-txt.png?v=20230413001"/>
                                </div>
                            </div>
                            <div className="wow fadeInUp list-obj-box">
                                {
                                    modelList && modelList.map((item, index) =>
                                        <div className="wow fadeInUp list-obj-item" key={index}>
                                            <img src={item.image}/>
                                            <div className="s-cursor-pointer s-flex s-flex-center-row btn-view" onClick={() => {
                                                toDetail(item)
                                            }}>{t("page.unity.VIEW")}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}
