import { useEffect } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { useWalletModal } from '../reactui';
import { useDispatch } from 'react-redux';
import { getWalletAction } from '../redux/actions/wallet';
import { AppState } from '../redux/store';
import { useSelector } from 'react-redux';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as anchor from "@project-serum/anchor";

require('../reactui/styles.css');

export default function WalletBind(props: any) {
  const wallet = useWallet();
  // const con = useConnection();
    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
    const connection = new anchor.web3.Connection(rpcHost);
  const modal = useWalletModal();

  const mywallet = useSelector((state: AppState) => state.wallet.wallet);
  const dispatch = useDispatch();
  let text = props.text || "Connect SOLANA";

  useEffect(() => {
    const getBalance = async () => {
      if (wallet.publicKey !== null) {
        try {
          let info = await connection.getBalance(wallet.publicKey);
          if (!window.localStorage.getItem("address_key")) {
              window.localStorage.setItem("address_key", wallet.publicKey.toString());
              props.getInfo({
                  address: wallet.publicKey.toString(),
                  name: wallet.wallet?.adapter.name ?? '',
              });
          }
            await wallet.disconnect();
        } catch {}
      }
    };
    getBalance();
  }, [wallet]);

  return (
      <div>
        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}
            onClick={async (e) => {
              e.preventDefault();
              if (wallet.connected) {
                await wallet.disconnect();
              } else {
                if (wallet.wallet) {
                  wallet.disconnect();
                  window.open(wallet.wallet.adapter.url);
                } else {
                  modal.setVisible(true);
                }
              }
            }}
        >
          {wallet.wallet && (
              <img
                  src={wallet.wallet.adapter.icon}
                  style={{ width: 16, height: 16, marginRight: 10 }}
              />
          )}
          {wallet.connecting
              ? 'Connecting'
              : wallet.connected
                  ? 'Disconnect'
                  : text}
        </div>
        {wallet.connected && (
            <div className="box-sub collapse">
              <ul className="nav nav-sub">
                <li>
                  <a
                      href="#"
                      onClick={async () => {
                        if ('clipboard' in navigator) {
                          await navigator.clipboard.writeText(
                              wallet.publicKey!.toString()
                          );
                        } else {
                          document.execCommand(
                              'copy',
                              true,
                              wallet.publicKey!.toString()
                          );
                        }
                      }}
                  >
                    Copy address
                  </a>
                </li>
                <li>
                  <a
                      href="#"
                      onClick={() => {
                        modal.setVisible(true);
                      }}
                  >
                    Change wallet
                  </a>
                </li>
              </ul>
            </div>
        )}
      </div>
  );
}
