import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {useState, useRef, forwardRef, useImperativeHandle, useEffect} from "react";
import message from '../components/Message';
import {HkCoupon, ShopCartList, ShopOrderCreate, ShopCartDelete, EmailCode, BindEmail, UserInfo} from "../libs/api";
import {useTranslation} from "react-i18next";

const ShopCartGift = forwardRef((props: any, ref) => {
    const isLogin = !!window.localStorage.getItem('userStatus');
    const [orderId, setOrderId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [couponPrice, setCouponPrice] = useState("");
    const [couponValue, setCouponValue] = useState("");
    // 购物车 start
    const [dialogCart, setDialogCart] = useState(false);
    const openCart = () => {
        setCouponCode("");
        setCouponPrice("");
        setCouponValue("");
        setDialogCart(true);
    }
    const closeCart = () => {
        openCouponShow();
        setDialogCart(false);
    }
    // 删除购物车商品
    const removeItem = (id: any) => {
        openCouponShow();
        ShopCartDelete({id: id}).then((res: any) => {
            if (!res.code) {
                getCartList();
            }
        })
    }
    // 结算填写地址，生成订单
    const [dialogAddress, setDialogAddress] = useState(false);
    const openAddress = () => {
        if (!cartGiftList.length) {
            return;
        }
        closeCart();
        setDialogAddress(true);
    }
    const closeAddress = () => {
        setDialogAddress(false);
    }
    // 购物车 end

    // 对外暴露方法，调用结算
    useImperativeHandle(ref, () => ({
        handlePay: (id: any) => {
            return {orderId: id || orderId, orderType: "3"};
        }
    }));

    // 兑换券 start
    // 输入兑换码
    const [modalVoucher, setModalVoucher] = useState(false);
    const openVoucher = () => {
        closeCart();
        setModalVoucher(true);
    }
    const closeVoucher = () => setModalVoucher(false);
    // 兑换成功弹窗，兑换失败toast
    const [modalVoucherRel, setModalVoucherRel] = useState(false);
    const openVoucherRel = () => setModalVoucherRel(true);
    const closeVoucherRel = () => setModalVoucherRel(false);
    const [voucherVal, setVoucherVal] = useState("");
    const [voucherRel, setVoucherRel] = useState<{image?: string;}>(null);
    const voucherRef = useRef(null);
    const changeVoucherVal = () => {
        setVoucherVal(voucherRef.current.value);
    }
    const voucherSubmit = () => {
        if (!voucherVal) {
            message.info("Please input code");
            return false;
        }
        HkCoupon({code: voucherVal}).then((res: any) => {
            if (!res.code) {
                setVoucherRel(res);
                closeVoucher();
                openVoucherRel();
            }
        })
    }
    // 兑换券 end

    const [cartGiftList, setCartGiftList] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [payPrice, setPayPrice] = useState(0);
    const getCartList = () => {
        if (!isLogin) {
            window.self.location.href = window.location.origin + '/login?redirect=' + window.location.pathname + window.location.search;
        } else {
            if (!dialogCart) {
                openCart();
            }
            getUserInfo();
            ShopCartList({}).then((res: any) => {
                if (!res.code) {
                    setCartGiftList(res.rows);
                    setTotalPrice(res.totalPrice);
                    setDiscountPrice(res.discountPrice);
                    setPayPrice(res.payPrice);
                }
            });
        }
    }

    const formNameRef = useRef(null);
    const formMobileRef = useRef(null);
    const formDistrictRef = useRef(null);
    const formAddressRef = useRef(null);
    const formAddressDetailRef = useRef(null);
    // 下单
    const addOrder = () => {
        if (!formNameRef.current.value) {
            message.info("Name Not Null");
            return false;
        }
        if (!formMobileRef.current.value) {
            message.info("Mobile Not Null");
            return false;
        }
        if (!/^\d+$/.test(formMobileRef.current.value)) {
            message.info("Mobile Must Be Number");
            return false;
        }
        if (formMobileRef.current.value.length < 7 || formMobileRef.current.value.length > 9) {
            message.info("Mobile Error");
            return false;
        }
        if (!formDistrictRef.current.value) {
            message.info("District Not Null");
            return false;
        }
        if (!formAddressRef.current.value) {
            message.info("Address Not Null");
            return false;
        }
        if (!formAddressDetailRef.current.value) {
            message.info("Address Not Null");
            return false;
        }
        setIsLoading(true);
        const params = {
            name: formNameRef.current.value,
            mobile: "852-" + formMobileRef.current.value,
            district: formDistrictRef.current.value,
            address: formAddressRef.current.value + " " + formAddressDetailRef.current.value,
        }
        if (couponCode) {
            params["couponCode"] = couponCode;
        }
        ShopOrderCreate(params).then((res: any) => {
            setIsLoading(false);
           if (!res.code) {
               setOrderId(res.id);
               props.handlePay({orderId: res.id, orderType: "3"});
               closeAddress();
           }
        }).catch(() => {
            setIsLoading(false);
        });
    }
function formatMoney(value) {
    if (!value) return 0;
    value = Number(value);
    return (+value || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

    // Mail
    const { t } = useTranslation();
    const [isEmail, setIsEmail] = useState(false)
    const [dialogEmail, setDialogEmail] = useState(false)
    const openDialogEmail = () => {
        setDialogEmail(true);
    }
    const closeDialogEmail = () => {
        setDialogEmail(false);
    }
    const [validMsgEmail, setValidMsgEmail] = useState('');
    const [validMsgEmailCode, setValidMsgEmailCode] = useState('');
    const timeNum = 120;
    const [isClickEmail, setIsClickEmail] = useState(false);
    const [isClickEmailMsg, setIsClickEmailMsg] = useState(t("button.CODE"));
    const [isClickEmailNum, setIsClickEmailNum] = useState(timeNum);
    function validEmail() {
        // @ts-ignore
        const val = document.querySelector('#email').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmail(t("validate.information"));
            message.info(t("validate.information"));
            return false;
        }
        if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(val)) {
            setValidMsgEmail('');
            return true;
        } else if (val.length > 100) {
            setValidMsgEmail(t("validate.long"));
            message.info(t("validate.information"));
            return false;
        } else {
            setValidMsgEmail(t("validate.address"));
            message.info(t("validate.information"));
            return false;
        }
    }
    function validEmailCode() {
        // @ts-ignore
        const val = document.querySelector('#emailCode').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmailCode(t("validate.information"));
            message.info(t("validate.information"));
            return false;
        } else if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
            setValidMsgEmailCode('');
            return true;
        } else if (val.length > 6) {
            setValidMsgEmailCode(t("validate.long"));
            message.info(t("validate.information"));
            return false;
        } else {
            setValidMsgEmailCode(t("validate.code"));
            message.info(t("validate.information"));
            return false;
        }
    }
    function getEmailCode() {
        // @ts-ignore
        let emailVal = document.querySelector('#email').value.trim();
        if (!validEmail()) {
            return false;
        }
        setValidMsgEmailCode('');
        // setOpen(true);
        EmailCode({ email: emailVal, type: 4 }).then();
        let time = timeNum;
        setIsClickEmail(true);
        const interval = setInterval(() => {
            setIsClickEmailNum(time--);
            setIsClickEmailMsg(time + ' s');
            if (time < 0) {
                setIsClickEmail(false);
                setIsClickEmailNum(timeNum);
                setIsClickEmailMsg(t("button.CODE"));
                clearInterval(interval);
            }
        }, 1000);
        return true;
    }
    function submitEmail() {
        // @ts-ignore
        let emailVal = document.querySelector('#email').value.trim();
        // @ts-ignore
        const emailCodeVal = document.querySelector('#emailCode').value.trim();
        if (!validEmail()) {
            return false;
        }
        if (!validEmailCode()) {
            return false;
        }
        setIsLoading(true);
        BindEmail({email: emailVal, code: emailCodeVal}).then((res: any) => {
            setIsLoading(false);
            if (!res.code) {
                message.success("Bind Email Success");
                closeDialogEmail();
                openAddress();
            }
        }).catch(() => {
            setIsLoading(true);
        })
    }
    function getUserInfo() {
        UserInfo({}).then((res: any) => {
            if (!res.code) {
                // setIsEmail(!res.email);
                setIsEmail(!!res.email);
            }
        });
    }

    function submitOrder() {
        if (isEmail) {
            openAddress();
        } else {
            closeCart();
            openDialogEmail();
        }
    }

    const [couponShow, setCouponShow] = useState(true);
    function openCouponShow() {
        setCouponShow(true);
    }
    function closeCouponShow() {
        setCouponShow(false);
    }
    function inputCoupon() {
        // @ts-ignore
        const couponVal = document.querySelector('#couponVal').value.trim();
        if (!couponVal) {
            message.info("Please enter the coupon code");
            return false;
        }

        closeCouponShow();

        ShopCartList({couponCode: couponVal}).then((res: any) => {
            if (!res.code) {
                setCouponCode(couponVal);
                setCouponValue(res.coupon.discountVal);
                setCouponPrice(res.coupon.couponPrice);

                setCartGiftList(res.rows);
                setTotalPrice(res.totalPrice);
                setDiscountPrice(res.discountPrice);
                setPayPrice(res.payPrice);
            } else {
                openCouponShow();
            }
        }).catch(() => {
            openCouponShow();
        });
    }

    return <>
        <div className="gift-body">
            <img src="./assets/images/hellokity/shopping-cart.png" alt="" className="s-cursor-pointer shopping-cart" onClick={getCartList}/>
        </div>
        <Dialog onClose={closeCart} open={dialogCart} className="modal-no-close">
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-cart">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="cart-title">CART</div>
                        <div className="cart-list">
                            {
                                cartGiftList.map((item: any, index) => {
                                    return <div className="cart-item s-flex s-flex-between-row" key={index}>
                                        <img className="cart-item-img" src={item.product && item.product.specImage} alt=""/>
                                        <div className="s-flex s-flex-between-column cart-item-info">
                                            <div className="s-flex s-flex-between-row">
                                                <div
                                                    className="cart-item-name">{item.product && item.product.name}</div>
                                                <div>
                                                    <div
                                                        className="cart-item-price">HK$ {item.product && (Number(item.product.hkPrice) - Number(item.product.discountPrice) / item.quantity - Number(item.product.couponPrice) / item.quantity).toFixed(2)}</div>
                                                    {
                                                        item.product.isDiscount === 1 && <div className="text-line-through cart-item-price-small">HK$ {item.product && item.product.hkPrice || 0}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="s-flex s-flex-between-row">
                                            <div
                                                    className="cart-item-num">{item.product && item.product.spec}</div>
                                                    <div className="cart-item-num">X {item.quantity || 1}</div>
                                            </div>
                                            <div className="s-flex s-flex-between-row">
                                                {
                                                    item.product && !!item.product.limit && <div
                                                    className="cart-item-num">Limited to <span
                                                    className="cart-item-price">{item.product.limit}</span> pieces
                                                  </div>
                                                }
                                                {
                                                    item.product && !item.product.limit && <div
                                                    className="cart-item-num"> </div>
                                                }
                                                <img className="cart-icon-del s-cursor-pointer"
                                                     src="./assets/images/modal/del.png" alt=""
                                                     onClick={() => removeItem(item.id)}/>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="cart-line"></div>
                        {/*<div className="s-flex s-flex-center-row sale-coupon-code">*/}
                        {/*    <img src={`./assets/images/hellokity/box-coupon-code.png`} alt="" className="s-cursor-pointer coupon-code-img" onClick={openVoucher} />*/}
                        {/*</div>*/}
                        <div className="s-flex s-flex-center-row sale-coupon-code">
                            <input type="text" placeholder="I have a coupon code" disabled={!couponShow} id="couponVal" className="coupon-code-input"/>
                            {
                                couponShow && <button className="coupon-code-btn"
                                                      onClick={inputCoupon}>OK</button>
                            }
                        </div>
                        <div className="cart-total">
                            <div className="s-flex s-flex-between-row">
                                <span className="cart-total-title">TOTAL</span>
                                <span className="cart-total-amount">HK${formatMoney(totalPrice)}</span>
                            </div>
                            <div className="s-flex s-flex-between-row">
                                <span className="cart-total-title">DISCOUNT
                                    <span className="cart-total-amount" style={{marginLeft: "5px"}}>10%</span>
                                </span>
                                <span className="cart-total-amount">- HK${formatMoney(discountPrice)}</span>
                            </div>
                            {
                                couponValue && couponPrice &&
                              <div className="s-flex s-flex-between-row">
                                <span className="cart-total-title">COUPON
                                    <span className="cart-total-amount"
                                          style={{marginLeft: "5px"}}>{couponValue}% Off</span>
                                </span>
                                <span className="cart-total-amount">- HK${formatMoney(couponPrice)}</span>
                              </div>
                            }
                            <div className="s-flex s-flex-between-row">
                                <span className="cart-total-title">SUBTOTAL</span>
                                <span className="cart-total-amount">HK${formatMoney(payPrice)}</span>
                            </div>
                        </div>
                        <div className="cart-btn s-flex s-flex-between-row">
                            <div className="s-cursor-pointer btn-confirm btn-text-white" onClick={submitOrder}>CHECKOUT</div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog onClose={closeAddress} open={dialogAddress}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-address">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row modal-title">
                            <div className="s-flex s-flex-center-row title-text-btn">
                                <span className="">Checkout</span>
                            </div>
                            <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closeAddress}/>
                        </div>
                        <div className="modal-sub-title">Your Delivery Address</div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Name</div>
                            <div className="form-item-value">
                                <input className="form-item-in" ref={formNameRef} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Mobile</div>
                            <div className="s-flex form-item-value">
                                <input style={{width: "4.5rem"}} className="form-item-in" defaultValue="+852" disabled  autoComplete="off"/>
                                <input style={{marginLeft: "0.5rem"}} className="form-item-in" ref={formMobileRef}  autoComplete="off"/>
                            </div>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Select District</div>
                            <div className="form-item-value">
                                <select className="form-item-in" ref={formDistrictRef}>
                                    <option value="Northern District">Northern District</option>
                                    <option value="Eastern District">Eastern District</option>
                                    <option value="Tai Po District">Tai Po District</option>
                                    <option value="Kwun Tong District">Kwun Tong District</option>
                                    <option value="Wong Tai Sin District">Wong Tai Sin District</option>
                                    <option value="Kowloon City District">Kowloon City District</option>
                                    <option value="Kwai Tsing District">Kwai Tsing District</option>
                                    <option value="Island District">Island District</option>
                                    <option value="Southern District">Southern District</option>
                                    <option value="Tsuen Wan District">Tsuen Wan District</option>
                                    <option value="Sham Shui Po District">Sham Shui Po District</option>
                                    <option value="Sha Tin District">Sha Tin District</option>
                                    <option value="Tuen Mun District">Tuen Mun District</option>
                                    <option value="Wan Chai District">Wan Chai District</option>
                                    <option value="Sai Kung District">Sai Kung District</option>
                                    <option value="Yuen Long District">Yuen Long District</option>
                                    <option value="Yau Tsim Mong District">Yau Tsim Mong District</option>
                                    <option value="Central And Western District">Central And Western District</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Address</div>
                            <div className="form-item-value">
                                <input className="form-item-in" ref={formAddressRef} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label"></div>
                            <div className="form-item-value">
                                <input className="form-item-in" ref={formAddressDetailRef} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="modal-line"></div>
                        <div className="form-item s-flex s-flex-between-row">
                            <div className="s-flex">Shipping Fee:</div>
                            <div className="s-flex">SF Express Cash on Delivery</div>
                        </div>
                        <div className="cart-btn s-flex s-flex-center-row">
                            {
                                isLoading && <div className="s-cursor-pointer btn-confirm btn-text-white">NEXT</div>
                            }
                            {
                                !isLoading && <div className="s-cursor-pointer btn-confirm btn-text-white" onClick={addOrder}>NEXT</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

        {/*兑换输入*/}
        <Dialog onClose={closeVoucher} open={modalVoucher}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-voucher">
                <div className="modal-body modal-body-hk">
                    <div className="s-flex s-flex-center-row modal-title">
                        <div className="s-flex s-flex-center-row title-text-btn">
                            <span className="">Coupon</span>
                        </div>
                        <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closeVoucher}/>
                    </div>
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row title-bold voucher-title">
                            Input coupon code
                        </div>
                        <div className="s-flex s-flex-center-row voucher-input">
                            <div className="s-flex s-flex-center-row input-box">
                                <input className="input-text" maxLength={12} defaultValue={""} ref={voucherRef} onChange={changeVoucherVal}/>
                            </div>
                        </div>
                    </div>
                    <div className="s-flex s-flex-center-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={voucherSubmit}>CONFIRM</div>
                    </div>
                </div>
            </div>
        </Dialog>
        {/*兑换结果详情*/}
        <Dialog onClose={closeVoucherRel} open={modalVoucherRel}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-voucher-result">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row">
                            <img className="result-title-img" src="./assets/images/hellokity/combine-result-title.png" alt="" />
                        </div>
                        <div className="s-flex s-flex-center-row result-desc">
                            You just get a coupon
                        </div>
                        <div className="s-flex s-flex-center-row">
                            <img className="result-img" src={voucherRel && voucherRel.image} alt="" />
                        </div>
                        <div className="s-flex s-flex-center-row result-desc">
                            <div className="result-desc-tips">
                                This coupon could be used for purchaseing <span>Wonder Pass</span>
                            </div>
                        </div>
                    </div>
                    <div className="s-flex s-flex-center-row modal-btn">
                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-text btn-half btn-half-confirm" onClick={closeVoucherRel}>GO TO PURCHASE</div>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog onClose={closeDialogEmail} open={dialogEmail}>
            <div className="s-flex s-flex-center-row modal-box modal-box-hk modal-box-address">
                <div className="modal-body modal-body-hk">
                    <div className="modal-container">
                        <div className="s-flex s-flex-center-row modal-title">
                            <div className="s-flex s-flex-center-row title-text-btn">
                                <span className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <img className="s-cursor-pointer btn-close" src="./assets/images/hellokity/ico-close.png" alt="" onClick={closeDialogEmail}/>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Email</div>
                            <div className="form-item-value">
                                <input className="form-item-in" id="email" ref={formNameRef} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="form-item s-flex">
                            <div className="s-flex form-item-label">Confirm</div>
                            <div className="s-flex form-item-value">
                                <input className="form-item-in" ref={formMobileRef} autoComplete="off" id="emailCode" maxLength={8}/>
                                <span style={{width: "10rem", marginLeft: "0.5rem", fontSize: "1rem"}} className="s-cursor-pointer s-flex s-flex-center-row" onClick={getEmailCode}>{isClickEmailMsg}</span>
                            </div>
                        </div>
                        <div className="cart-btn s-flex s-flex-center-row" style={{marginTop: "2rem"}}>
                            {
                            isLoading && <div className="s-cursor-pointer btn-confirm btn-text-white">CONFIRM</div>
                            }
                            {
                                !isLoading && <div className="s-cursor-pointer btn-confirm btn-text-white" onClick={submitEmail}>CONFIRM</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

    </>
});

export default ShopCartGift;
