import {useEffect, useRef, useState} from "react";
import * as React from "react";
import message from "../components/Message";

import Footer from "../Footer";
import {Helmet} from 'react-helmet';

import { GameStart, GameEnd } from "../libs/api";

export default function Demo(props: any) {

    const iframeElement = useRef<HTMLIFrameElement>(null);
    useEffect(() => {
        const iframe: HTMLIFrameElement | null = iframeElement.current;
        if (iframe) {
            iframe.width = "0px";
            iframe.height = "0px";
            iframe.src = ""
            // iframe.src = "./demoChild.html"
        }
        const iframeGame: HTMLIFrameElement | null = iframeElement.current;
        if (iframe) {
            iframe.width = "0px";
            iframe.height = "0px";
            iframe.src = ""
            // iframe.src = "./demoChild.html"
        }
    }, []);

    function add() {
        window.addEventListener("message", pLog, false);
        const iframe: HTMLIFrameElement | null = iframeElement.current;
        if (iframe) {
            iframe.width = document.documentElement.clientWidth + "px";
            iframe.height = document.documentElement.clientHeight + "px";
            iframe.src = "https://test-www.metagaia.io/example/iframeChild.html"
            // iframe.src = "http://localhost:3000/example/iframeChild.html"
            // iframe.src = "./demoChild.html"
            iframe.onload = function () {
                iframe.contentWindow!.postMessage({ type: "========-==" }, "*");
            }
        }
    }
    function pLog(event: any) {
        console.log(JSON.stringify(event.data));
        if (event.data.type === "game") {
            const iframe: HTMLIFrameElement | null = iframeElement.current;
            if (iframe) {
                iframe.width = "0px";
                iframe.height = "0px";
                iframe.src = ""
                // iframe.src = "./example/demoChild.html"
            }
        }
    }

    const iframeGame = useRef<HTMLIFrameElement>(null);
    const [formId, setFormId] = useState("2");
    const [formKey, setFormKey] = useState("");
    useEffect(() => {
        const iframe: HTMLIFrameElement | null = iframeGame.current;
        if (iframe) {
            iframe.width = "0px";
            iframe.height = "0px";
            iframe.src = ""
            // iframe.src = "./demoChild.html"
        }
    }, []);
    function gameStart() {
        GameStart({id: formId}).then((res: any) => {
            if (res.code === 2011) {
                // 缺失NFT
                message.info(res.message);
            }
            if (res.code === 2001) {
                // 充值
                message.info(res.message);
            }
            if (!res.code) {
                setFormKey(res.key);
                window.addEventListener("message", gameEnd, false);
                const iframe: HTMLIFrameElement | null = iframeGame.current;
                if (iframe) {
                    iframe.width = document.documentElement.clientWidth + "px";
                    iframe.height = document.documentElement.clientHeight + "px";
                    iframe.src = window.location.origin + "/game-swmd/index.html"
                    iframe.onload = function () {
                        iframe.contentWindow!.postMessage({ type: "game over end" }, "*");
                    }
                }
            }
        });
    }
    function gameEnd(event: any) {
        if (event.data.type !== "TYPE_GAME") return false;
        console.log("接收到了结果 = ", event.data);
        GameEnd({id: formId, key: formKey, value: `${event.data.data.score}`}).then((res) => {});
        const iframe: HTMLIFrameElement | null = iframeGame.current;
        if (iframe) {
            iframe.width = "0px";
            iframe.height = "0px";
            iframe.src = ""
        }
    }
    return (
        <>
            <Helmet>
                <title>Demo</title>
                <meta name="description" content="Demo" />
                <meta property="og:description" content="Demo" />
            </Helmet>

            <button onClick={() => message.success("aaa")}>message</button>
            <iframe ref={iframeElement} id="game" seamless allow="fullscreen" style={{border:0, position: "fixed", zIndex: 9999, top: 0, left: 0}}></iframe>
            {/*<iframe ref={iframeElement} id="game" src={iframeOption.src} seamless allow="fullscreen" style={{border:0, width: iframeOption.width, height: iframeOption.height}}></iframe>*/}
            {/*iframe*/}

            <button onClick={add} style={{marginTop: '100px'}}>add</button>
            <iframe ref={iframeGame} id="iframeGame" seamless allow="fullscreen" style={{border:0, position: "fixed", zIndex: 9999, top: 0, left: 0}}></iframe>
            <button onClick={gameStart} style={{marginTop: '100px'}}>game</button>

            <Footer />
        </>
    );
};
