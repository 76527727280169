import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useRef, useState} from "react";
import {Helmet} from 'react-helmet';
import message from "./components/Message";
import storage from './libs/storage';
import useClipboard from "react-use-clipboard";
import {getTask, taskReceive, InviteFriendList, formatThousandthComma} from "./libs/api";


// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {useTranslation} from "react-i18next";

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #00FFFF',
    boxShadow: 0,
    p: 1,
};
// modal end

interface InviteObject {
    id: number,
    friendUsername: string,
    createdAt: string
}
interface TaskInfoObject {
    awardTotal: number,
    endTime: string,
    isEnd: number
    name: string,
    sendTotal: number,
    taskAward: [TaskAward, TaskAward]
}
interface TaskAward {
    awardWhere: number,
    isNotice: number,
    status: number
}
function Invitation() {
    const { t } = useTranslation();

    const [inviteList, setInviteList] = useState<InviteObject[]>([]);
    const [inviteCode, setInviteCode] = useState("");
    const [inviteLink, setInviteLink] = useState("");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [invite, setInvite] = useState(0);
    const [numMetg, setNumMetg] = useState(0);
    const [taskInfo, setTaskInfo] = useState<TaskInfoObject>();


    const [copyCode, setCopyCode] = useClipboard(inviteCode, {
        successDuration: 1000,
    });
    const [copyLink, setCopyLink] = useClipboard(inviteLink, {
        successDuration: 1000,
    });

    useEffect(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
        document.body.appendChild(s);
        getInviteCL();
        getInviteCode();
        // getTaskInfo();
    }, [])

    function getInviteCode () {
        InviteFriendList({}).then((res: any) => {
            if (!res.code) {
                setInvite(res.rawed || 0);
                setInviteList(res.inviteRes);
            }
        });
    }

    function getInviteCL() {
        const code = storage.getItem("invit_code") || "000000";
        const link = window.location.origin + "/register?utm_source=MGRFR01&utm_medium=MGWeb&utm_campaign=MGRFR&utm_id=MGRFR01&code=" + code;
        setInviteCode(code);
        setInviteLink(link);
    }

    function getTaskInfo() {
        getTask({taskId: 1}).then((res: any) => {
            if (!res.code) {
                setTaskInfo(res);
            }
        });
    }

    function openBox(awardWhere: any) {
        if (awardWhere === "5" || awardWhere === 5) {
            setNumMetg(50);
        } else {
            setNumMetg(150);
        }
        taskReceive({taskId: "1", awardWhere: awardWhere.toString()}).then((res: any) => {
            if (!res.code) {
                handleOpen();
                getTaskInfo();
            }
        });
    }

    function toHis(id: any) {
        window.self.location.href = window.location.origin + '/invitation-his?id=' + id;
    }

    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-paper s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container invitation">
                                <h2 className="txt-default-title wow fadeInUp"><span className="txt" >{t("page.invitation.INVITE")}</span></h2>
                                <section>
                                    <div className="row invitation-user">
                                        <div className="col col-12 col-lg-6 invitation-user-info">
                                            <div className="invitation-user-name-title">User Name <span className="invitation-user-name">{inviteCode}</span></div>
                                            <div className="s-flex s-flex-row invitation-user-code-title">Invite Code:
                                                <span className="invitation-user-code">{inviteCode}</span>
                                                <img
                                                    className="s-flex s-cursor-pointer user-add-copy icon-copy"
                                                    src="./assets/images/ico/icon-copy-white.png?v=20230413001"
                                                    onClick={() => {
                                                        message.info('Copied');
                                                        setCopyCode();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="col col-12 col-lg-6 s-flex s-flex-row invitation-user-reward">Reward:
                                            <span className="invitation-user-reward-value s-cursor-pointer" onClick={() => toHis(1)}>{formatThousandthComma(invite)}</span>
                                            <img
                                                className="s-flex s-cursor-pointer user-token"
                                                src="./assets/images/user/token-ga.png"
                                                onClick={() => toHis(2)}
                                            />
                                            <img
                                                className="s-flex s-cursor-pointer invitation-user-reward-fh"
                                                src="./assets/images/ico/icon-right.png"
                                                onClick={() => toHis(3)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row invitation-progress">
                                        <div className="invitation-line"></div>
                                    </div>
                                </section>
                                <section>
                                    <div className="row invitation-body">
                                        <div className="col col-12 wow fadeInUp invitation-history">
                                            <div className="invitation-list">
                                                <div className="s-flex s-flex-between-row list-title">
                                                    <div className="title-left">{t("page.invitation.INVITE1")}</div>
                                                    <div className="title-right">{t("page.invitation.ALREADY1")} <span
                                                        className="txt-high">({inviteList.length})</span></div>
                                                </div>
                                                <div className="list-data">
                                                    {
                                                        inviteList && inviteList.length > 0 && inviteList.map((item: any, index) => {
                                                            return <div className="s-flex s-flex-between-row list-item"
                                                                        key={index}>
                                                                <div className="s-flex s-flex-center-start-row list-item-row"><span
                                                                    className="txt-name" style={{
                                                                    maxWidth: "10rem",
                                                                    width: "auto"
                                                                }}>{item.username}</span></div>
                                                                <div className="s-flex s-flex-center-row list-item-row">{item.createdAt}</div>
                                                                <div
                                                                    className="s-flex s-flex-center-end-row list-item-row">
                                                                    <span>Invited:</span>
                                                                    <span
                                                                        className="txt-high">{item.count}</span>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {
                                                        inviteList.length === 0 && <div
                                                        className="s-flex s-flex-center-row list-no-data">{t("page.invitation.No")}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invitation-line"></div>
                                    </div>
                                </section>
                                <section>
                                    <h2 className="wow fadeInUp invitation-share-title">{t("page.invitation.My")}</h2>
                                    <div className="row">
                                    <div className="col col-12 col-lg-7 wow fadeInUp share-link">
                                            <div className="s-flex s-flex-center-start-row">
                                                <span className="share-link-text">{inviteLink}</span>
                                                <button className="s-flex s-flex-center-row btn btn-default btn-share" onClick={() => {message.info("Copied"); setCopyLink();}}>
                                                    {t("page.invitation.COPY")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-lg-5 wow fadeInUp share-code">
                                            <div className="s-flex s-flex-center-start-row">
                                                <span className="share-code-text">{t("page.invitation.REFERRAL")}：{inviteCode}</span>
                                                <button className="s-flex s-flex-center-row btn btn-default btn-share" onClick={() => {message.info("Copied"); setCopyCode();}}>
                                                    {t("page.invitation.COPY1")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-invitation-box"
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img src="./assets/images/icon-modal-close.png" onClick={handleClose} />
                        </div>
                        <div className="s-flex s-flex-center-row modal-invitation-title">{t("page.invitation.Congratulations")}</div>
                        <div className="modal-pd txt-center modal-invitation-content">
                            {t("page.invitation.You1")}
                            {t("page.invitation.You2")} <span className="txt-high">{numMetg || 50} $METG</span> {t("page.invitation.You3")}
                            {t("page.invitation.You4")} <span className="txt-high">{t("page.invitation.You5")}</span>.</div>
                        <div className="modal-btn-box modal-btn-center modal-pd">
                            <div className="modal-btn modal-btn-half modal-btn-confirm modal-invitation-btn" onClick={handleClose}>OK</div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default Invitation;
