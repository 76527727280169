import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { IsOpenTFA } from './libs/api';
import storage from './libs/storage';

import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function Security() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    isOpen();
  }, []);

  function isOpen() {
    if (storage.getItem('tfaStatus')) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }
  function toOpen() {
    IsOpenTFA({
      status: true,
    })
      .then((res: any) => {
        if (!res.code) {
          storage.setItem('tfaObj', res);
          window.self.location.href = window.location.origin + '/sign-2fa';
        }
      })
      .catch(() => {
        storage.removeItem('tfaObj');
      });
  }
  function toCloseOpen() {
    IsOpenTFA({
      status: false,
    })
      .then((res: any) => {
        if (!res.code) {
          setOpen(false);
          storage.removeItem('tfaStatus');
        }
      })
      .catch(() => {});
  }
  function toChangePwd() {
    window.self.location.href = window.location.origin + '/change';
  }
  return (
    <>
      <Helmet>
        <title>
          DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs
        </title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container security-box">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.security.SECURITY")}</span>
                </h2>
                <div className="s-flex s-flex-center-row">
                  <div className="row form-box security-body">
                    <div className="col col-12 s-flex s-flex-center-row">
                      <span className="security-title">
                        {t("page.security.TWO")}
                      </span>
                    </div>
                    <div className="col col-12 s-flex s-flex-between-row security-switch">
                      <span>
                        {t("page.security.Protect")}
                      </span>
                      {open ? (
                        <img
                          className="security-icon"
                          src="./assets/images/ico/icon-on.png?v=20230413001"
                          onClick={toCloseOpen}
                        />
                      ) : (
                        <img
                          className="security-icon"
                          src="./assets/images/ico/icon-off.png?v=20230413001"
                          onClick={toOpen}
                          // onClick={() => {
                          //   setOpen(false);
                          // }}
                        />
                      )}
                    </div>
                    <div className="col col-12 s-flex s-flex-center-row security-line"></div>
                    <div className="col col-12 s-flex s-flex-row update-title">
                      {t("page.security.UPDATE")}
                    </div>
                    <div className="col col-12 s-flex s-flex-between-row reset-box">
                      <span>*******************</span>
                      <img
                        className="s-cursor-pointer user-edit-img"
                        src="./assets/images/ico/icon-edit.png?v=20230413001"
                        onClick={toChangePwd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
