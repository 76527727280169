import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { EmailSubscribe } from "./libs/api";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function Home() {
	const { t } = useTranslation();

	const [mail, setMail] = useState("");
	const [message, setMessage] = useState("");
	const [result, setResult] = useState(false);
	let [videoVolume, setVideoVolume] = useState(true);
	const [show,setShow] = useState(false);

	const videoDom = useRef(null);
	const [videoHeight, setVideoHeight] = useState("");
	useEffect(() => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
		document.body.appendChild(s);
		// @ts-ignore
		const bw = document.querySelector("body").clientWidth;
		if (videoDom && window.matchMedia("(min-width: 990px)").matches) {
			// @ts-ignore
			videoDom.current.addEventListener("loadedmetadata", () => {
				// @ts-ignore
				const vh = document.querySelector(".video-box").clientHeight;
				setVideoHeight(vh * 0.8 + "px");
			});
		}
	}, [])
	function addEmailSubscribe() {
		if (mail === null || !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig.test(mail)) {
			setResult(false);
			setMessage(t("message.home1"));
		} else {
			EmailSubscribe({ email: mail }).then((resp: any) => {
				if (resp.code) {
					setResult(false);
					setMessage(t("message.home2"));
					(window as any).pop();
				} else {
					if (resp.subscribed) {
						setResult(true);
						setMessage(t("message.home3"));
						(window as any).pop();
					} else {
						setResult(false);
						setMessage(t("message.home4"));
						(window as any).pop();
					}
				}
			}).catch(() => {
				setResult(false);
				setMessage(t("message.home5"));
				(window as any).pop();
			});
		}
	}
	function toUri(url: string) {
		window.location.href = window.location.origin + url;
	}

	const [isShow, setIsShow] = useState(true);

	function closeBanner() {
		setIsShow(false);
	}
	return (
		<div className="wrapper wrapper-home">
			<Header />
			<main>
				<section className="s-hero" id="introduction">
					<img className="img-bg" src="assets/images/bg1.png?v=20230413001" data-anchor-target=".s-hero"
						data-center="transform: translateY(0%)"
						data-top-bottom="transform: translateY(40%)" />
					<div className="container">

						<div className="box-planet">
							<div className="video-box">
								<img className="img-planet" src="assets/images/planet_v3_1.png?v=20230413001" />
								<div className="video-hi" style={{height: videoHeight}}>
									<video autoPlay muted loop id="hero_video" playsInline ref={videoDom} style={{mixBlendMode: "screen", backgroundColor: "transparent"}}>
										<source src="./assets/images/output_video.webm" type="video/mp4" />
										<source src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/movie_011_hd-hb2.mp4?v=20230413001" type="video/mp4" />
									</video>
								</div>
							</div>

							<div className="box-title" data-anchor-target=".s-hero"
								data-center="transform: translateX(-50%) translateY(-50%); opacity(1)"
								data-top-bottom="transform: translateX(-50%) translateY(-100%); opacity(0)">
								<img className="img-metagaia" src="assets/images/logo_metagaia2_v2.png?v=20230413001" />
								<div className="txt-title txt-tagline">{t("page.home.titleDesc")}</div>
							</div>
						</div>

						<div className="box-powered-by">
							<div className="txt-title txt-powered-by wow fadeInUp">POWERED BY</div>
							<a href="https://www.advokategroup.io/" target="_blank"><img className="img-logo logo-advokate wow fadeInUp" data-wow-delay="0.2s" src="assets/images/logo_advokate.png?v=20230413001" /></a>
						</div>
					</div>
				</section>

				<section className="s-default s-trailer video-ctrl-box home-video-ctrl-box" id="welcome-to-metagaia">
					<div className="container p-default-title max-lg home-welcome-box">
						<h4 className="txt-default-title wow fadeInUp"><span className="txt-title" >{t("page.home.WELCOME")}</span></h4>
						<div className="txt-default-desc wow fadeInUp" data-wow-delay="0.3s">{t("page.home.MetaGaia1")}</div>
					</div>
					<video onPlay={()=>{
						setShow(true)
					}} className="trail-video" autoPlay loop muted={videoVolume} playsInline preload="metadata">
						<source src="https://s3.ap-east-1.amazonaws.com/avatar.bucket/avatar.bucket.prod/web-home-video.mp4?v=20230413001" type="video/mp4" />
					</video>
					{
						show && <img className="video-ctrl-audio shape-pointer" onClick={()=>{
							setVideoVolume(!videoVolume)}
						} src={videoVolume ? "assets/images/video-volume-close.png?v=20230413001" : "assets/images/video-volume-open.png?v=20230413001"}/>
					}
				</section>

				<section className="s-default s-what-is-metagaia">
					<img className="img-bg" src="assets/images/bg2.png?v=20230413001" data-anchor-target=".s-what-is-metagaia"
						data-bottom-top="transform: translateY(0%)"
						data-top-bottom="transform: translateY(-50%)" />
					<div className="container">
						<div className="row row-feature">
							<div className="col col-12 col-lg-12">
								<div className="p-default-title max-lg">
									<h2 className="txt-default-title wow fadeInUp"><span className="txt">{t("page.home.EXPLORE1")} </span><span className="txt-cyan"> {t("page.home.EXPLORE2")}</span></h2>
									<div className="txt-default-desc wow fadeInUp text-tips" data-wow-delay="0.3s">{t("page.home.MetaGaia2")}</div>
								</div>
							</div>
							<div className="col col-12 col-xl-7" data-sticky-container >
								<div className="box-video-frame sticky" data-margin-top="200">
									<div className="swiper-container b-slider-white swiperVideo" id="swiperVideo">
										<div className="swiper-wrapper">
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FirstChatGPTMetaverse.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/CreateandCustomize.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/Experience-to-Earn.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/SocializeandConnect.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/ShopVirtualGoods.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
											<div className="swiper-slide">
												<img className="img-frame" src="assets/images/img_video_frame.png?v=20230413001" />
												<div className="b-video">
													<video playsInline poster="">
														<source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/ImmersiveGames.mp4?v=20230413001" type="video/mp4" />
													</video>
													<button className="btn-play"></button>
												</div>
											</div>
										</div>
										<div className="swiper-pagination"></div>
									</div>
								</div>
							</div>

							<div className="col col-12 col-xl-5 margin-top-no">
								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_chat.png?v=20230413001" />
										</div>
										<div className="txt txt-default-subtitle">{t("page.home.First")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.As")}</div>
								</div>

								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_nft.png?v=20230413001" />
										</div>
										<div className="txt txt-default-subtitle">{t("page.home.Create")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.Step")}</div>
								</div>

								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_qianbao.png?v=20230413001" />
										</div>

										<div className="txt txt-default-subtitle">{t("page.home.Experience")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.With")}</div>
								</div>

								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_social.png?v=20230413001" />
										</div>

										<div className="txt txt-default-subtitle">{t("page.home.Socialize")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.MetaGaia3")}</div>
								</div>

								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_shop.png?v=20230413001" />
										</div>
										<div className="txt txt-default-subtitle">{t("page.home.Shop")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.Shopping")}</div>
								</div>

								<div className="b-feature wow fadeInRight">
									<div className="txt-with-ico txt-title txt-feature-title">
										<div className="ico">
											<img src="assets/images/ico/ico_games.png?v=20230413001" />
										</div>
										<div className="txt txt-default-subtitle">{t("page.home.Immersive")}</div>
									</div>
									<div className="txt-feature-desc">{t("page.home.Gaming")}</div>
								</div>

							</div>
						</div>
					</div>
				</section>

				<section className="s-default s-what-is-meta" id="metg-token">
					<div className="container">
						<div className="p-default-title max-lg">
							<h2 className="txt-default-title wow fadeInUp"><span className="txt-cyan">{t("page.home.TOKEN")}</span></h2>
							<div className="txt-default-desc wow fadeInUp" data-wow-delay="0.3s">{t("page.home.is")}</div>
						</div>

						<div className="row row-ctx">
							<div className="col col-12 col-img col-md-5">
								<div className="img-coin wow fadeIn">
									<img className="img" src="assets/images/img-coin-energy.png?v=20230413001" />
									<img className="img_glowing" src="assets/images/img-coin-glow.png?v=20230413001" />
									<img className="img" src="assets/images/img-coin-coin.png?v=20230413001" />
								</div>
							</div>
							<div className="col col-12 col-ctx col-md-7">
								<div className="txt-meta-feature wow fadeInRight">
									<div className="txt-title txt-default-subtitle ">{t("page.home.Payment")}</div>
									<div className="txt-desc">{t("page.home.our")}</div>
								</div>

								<div className="txt-meta-feature wow fadeInRight">
									<div className="txt-title txt-default-subtitle" data-wow-delay="0.2s">{t("page.home.Governance")}</div>
									<div className="txt-desc">{t("page.home.Soon")}</div>
								</div>

								<div className="txt-meta-feature wow fadeInRight">
									<div className="txt-title txt-default-subtitle" data-wow-delay="0.4s">{t("page.home.Staking")}</div>
									<div className="txt-desc">{t("page.home.Gamers")}</div>
								</div>


							</div>
						</div>

						<div className="txt-center">
							<div className="txt-alert wow fadeInUp">
								<img className="img-alert" src="assets/images/img_alert.png?v=20230413001" />
								<div className="txt txt-title">
									<span className="txt-cyan">{t("page.home.available1")} </span>
									{t("page.home.available2")}{i18n.language !== "en-US" && <br/>}
									{t("page.home.available3")}<a href="/register" className="txt-cyan"> {t("page.home.available4")}</a>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="s-default s-roadmap" id="roadmap">
					<img className="img-bg" src="assets/images/bg4.png?v=20230413001" data-anchor-target=".s-roadmap"
						data-bottom-top="transform: translateY(0%)"
						data-top-bottom="transform: translateY(-30%)" />
					<div className="container">
						<div className="" style={{marginBottom: "1rem"}}>
							<h2 className="txt-default-title wow fadeInUp"><span>{t("page.home.ROAD1")}<span className="txt-cyan">{t("page.home.ROAD2")}</span></span></h2>
						</div>
<div className="roadmap-box" id="roadmapTimeLine">
                        <div className="roadmap-timeline">
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-start.png?v=20230413001" alt="" />
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-1">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q3 2022</div>
                                    <div className="txt-title">Desktop Version Launch</div>
                                    <ul>
                                        <li>Desktop version Launch</li>
                                        <li>FTLife Headquarter Launch</li>
                                        <li>Meeting Function Launch</li>
                                        <li>Shop Function Launch</li>
                                        <li>Avatar Creation Launch</li>
                                    </ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-2">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q1 2023</div>
									<div className="txt-title">Mobile Version Launch</div>
									<ul>
										<li>Mobile 1.0 version Launch</li>
										<li>AR Games Launch</li>
										<li>HexaHero Beta Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-3">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q2 2023</div>
									<div className="txt-title">Brand Partnership Announcement</div>
									<ul>
										<li>Sanrio Partnership Announcement</li>
										<li>Networking Development</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-4">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q3 2023</div>
									<div className="txt-title">Hello Kitty Seven Wonders Launch</div>
									<ul>
										<li>Mobile 2.0 Version Launch</li>
										<li>Hello Kitty Seven Wonders Launch (with Immersive O2O Event)</li>
										<li>AI Enhanced NPC Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-5">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q4 2023</div>
									<div className="txt-title">Users Experience Enhancement</div>
									<ul>
										<li>Hello Kitty Seven Wonders Seasonal Campaign Launch</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-6">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q1 2024</div>
									<div className="txt-title">Hello Kitty Seven Wonders Expansion</div>
									<ul>
										<li>Hello Kitty Seven Wonders Expansion (Gift Shop/ Zones)</li>
										<li>JuJuRaiders & Ring Racer Beta Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-7">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q2 2024</div>
									<div className="txt-title">UCS Launch</div>
									<ul>
										<li>Hello Kitty Seven Wonder Charity Campaign Launch</li>
										<li>Engagement Meter Launch</li>
										<li>Users’ Customized Space (UCS) Beta Launch</li>
										<li>Users’ Assets Creation (UAC) Launch</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-8">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q3 2024</div>
									<div className="txt-title">Token listing</div>
									<ul>
										<li>$METG Token Listing</li>
										<li>AI Conferencing Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-9">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q4 2024</div>
									<div className="txt-title">Petopia & Sneakers Communities Beta Launch</div>
									<ul>
										<li>Sneakers Communities Beta Launch</li>
										<li>Petopia Beta Launch</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-10">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q1 2025</div>
									<div className="txt-title">Music & Empowerment Communities Beta Launch</div>
									<ul>
										<li>Music Communities Beta Launch</li>
										<li>Empowerment Communities Beta Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-11">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q2 2025</div>
									<div className="txt-title">AI Education Beta Launch</div>
									<ul>
										<li>Sneakers Communities Official Launch</li>
										<li>Petopia Official Launch</li>
										<li>AI Education Beta Launch</li>
										<li>Sports Communities Launch</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-12">
                                <div className="item-content content-top"></div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-bottom.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom">
                                    <div className="txt-time">Q3 2025</div>
									<div className="txt-title">Sneakers & Music Communities Official Launch</div>
									<ul>
										<li>Sneakers Communities Official Launch</li>
										<li>Music Communities Official Launch</li>
									</ul>
                                </div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item item-13">
                                <div className="item-content content-top">
                                    <div className="txt-time">Q4 2025</div>
									<div className="txt-title">AI Education & Empowerment Communities Official Launch</div>
									<ul>
										<li>AI Education Official Launch</li>
										<li>Empowerment Communities Official Launch</li>
									</ul>
                                </div>
                                <div className="timeline-ico"><img className="timeline-img img-start" src="./assets/images/road/tl-top.png?v=20230413001" alt="" /></div>
                                <div className="item-content content-bottom"></div>
                            </div>
                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-line.png?v=20230413001" alt="" />
                            </div>

                            <div className="timeline-item timeline-ico">
                                <img className="timeline-img img-start" src="./assets/images/road/tl-end.png?v=20230413001" alt="" />
                            </div>
                        </div>
                        <div className="timeline-scroll">
                            <img className="img-ico img-left" src="./assets/images//ico/scroll-left.png" alt="" />
                            <img className="img-ico img-right" src="./assets/images//ico/scroll-right.png" alt="" />
                        </div>
</div>
					</div>
				</section>

				<section className="s-default s-team" id="meet-the-team">
					<div className="container">
						<h2 className="txt-default-title wow fadeInUp"><span className="txt">{t("page.home.meet")}</span></h2>

						<div className="row row-ppl row-ppl1 wow fadeIn">
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-01.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">GK88</div>
									<div className="txt-ppl-title">{t("user.GORDON1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.GORDON2")}</p>
												<p>{t("user.GORDON3")}</p>
												<p>{t("user.GORDON4")}</p>
												<p>{t("user.GORDON5")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-02.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">AC</div>
									<div className="txt-ppl-title">{t("user.AUDREY1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.AUDREY2")}</p>
												<p>{t("user.AUDREY3")}</p>
												<p>{t("user.AUDREY4")}</p>
												<p>{t("user.AUDREY5")}</p>
												<p>{t("user.AUDREY6")}</p>
												<p>{t("user.AUDREY7")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-03.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">BANG</div>
									<div className="txt-ppl-title">{t("user.BEN1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.BEN2")}</p>
												<p>{t("user.BEN3")}</p>
												<p>{t("user.BEN4")}</p>
												<p>{t("user.BEN5")} </p>
												<p>{t("user.BEN6")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-04.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">CS</div>
									<div className="txt-ppl-title">{t("user.CHRIS1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.CHRIS2")}</p>
												<p>{t("user.CHRIS3")}</p>
												<p>{t("user.CHRIS4")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>

							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-06.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">ING</div>
									<div className="txt-ppl-title">{t("user.SHIRLEY1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.SHIRLEY2")} </p>
												<p>{t("user.SHIRLEY3")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-07.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">FAYA</div>
									<div className="txt-ppl-title">{t("user.FAYER1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.FAYER2")}</p>
												<p>{t("user.FAYER3")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-08.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">QIANG</div>
									<div className="txt-ppl-title">{t("user.QIANG1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.QIANG2")}</p>
												<p>{t("user.QIANG3")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-10.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">BAM</div>
									<div className="txt-ppl-title">{t("user.JASON1")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.JASON2")}</p>
												<p>{t("user.JASON3")}</p>

											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>

						</div>

						<h2 className="txt-default-title wow fadeInUp"><span className="txt">{t("page.home.advisory")}</span></h2>

						<div className="row row-ppl row-ppl1 wow fadeIn">
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-A01.png?v=20220331119)"}}></div>
									<div className="txt-ppl-name">KMAN</div>
									<div className="txt-ppl-type">{t("user.KENNY1")}</div>
									<div className="txt-ppl-title">{t("user.KENNY2")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.KENNY3")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>

							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-A02.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">SUN</div>
									<div className="txt-ppl-type">{t("user.STEVE1")}</div>
									<div className="txt-ppl-title">{t("user.STEVE2")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.STEVE3")} </p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-A03.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">AL</div>
									<div className="txt-ppl-type">{t("user.ALAN1")}</div>
									<div className="txt-ppl-title">{t("user.ALAN2")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.ALAN3")} </p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3" style={{display: "none"}}>
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-A04.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">JACKSON TSE</div>
									<div className="txt-ppl-type">{t("user.JACKSON1")}</div>
									<div className="txt-ppl-title">{t("user.JACKSON2")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.JACKSON3")} </p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
							<div className="col col-6 col-sm-6 col-lg-4 col-xl-3">
								<div className="b-ppl">
									<div className="img-ppl"
										 style={{backgroundImage: "url(assets/images/team/team-A05.png?v=20230413001)"}}></div>
									<div className="txt-ppl-name">JESSIE</div>
									<div className="txt-ppl-type">{t("user.JESSIE1")}</div>
									<div className="txt-ppl-title">{t("user.JESSIE2")}</div>
									<div className="box-detail">
										<div className="box-txt">
											<div className="box-txt-wrapper">
												<p>{t("user.JESSIE3")}</p>
											</div>
										</div>
										<div className="line-bottom"></div>
										<div className="ico-close"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="s-default s-partners" id="partners">
					<div className="container">
						<h2 className="txt-default-title wow fadeInUp"><span className="txt">{t("page.home.partners")}</span></h2>

						<div className="row row-triangle wow fadeIn">
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://digitaleyes.market/collections/HexaHero" target="_blank">
									<img src="assets/images/partners/logo-digitaleyes.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.enterbay.com/" target="_blank">
									<img src="assets/images/partners/logo-Enterbay.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.enterbay.com/" target="_blank">
									<img src="assets/images/partners/logo-ft.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.heemingroup.com/" target="_blank">
									<img src="assets/images/partners/logo-Heemin-Capital.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.iahgames.com/" target="_blank">
									<img src="assets/images/partners/logo-IAH-Games.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.legendsnft.io/index" target="_blank">
									<img src="assets/images/partners/logo-legends.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://magiceden.io/marketplace/hexaherogame" target="_blank">
									<img src="assets/images/partners/logo-magic-eden.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://soleaddicttunds.online/" target="_blank">
									<img src="assets/images/partners/logo-Sole-Addictt.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://solsea.io/collection/621dc54fc903a7dbd2325734" target="_blank">
									<img src="assets/images/partners/logo-solsea.png?v=20230413001" />
								</a>
							</div>
							<div className="col col-6 col-lg-3 col-img">
								<a href="https://www.stormco.com.hk/" target="_blank">
									<img src="assets/images/partners/logo-Storm.png?v=20230413001" />
								</a>
							</div>
						</div>
					</div>
				</section>

				<section className="s-default s-news" id="latestNews">
					<div className="container">
						<h2 className="txt-default-title wow fadeInUp"><span className="txt">{t("page.home.LATEST")}</span></h2>

						<div className="row row-triangle wow fadeIn">
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-fox59.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://fox59.com/business/press-releases/ein-presswire/674645466/hello-kitty-seven-wonders-unveils-joyful-christmas-special-in-metagaia-metaverse/" target="_blank">
										Hello Kitty Seven Wonders Unveils Joyful Christmas Special in MetaGaia Metaverse
									</a>
								</div>
								<div className="news-title-date">Dec 12, 2023</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-standard.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://www.thestandard.com.hk/section-news/fc/12/254774/Hello-Kitty-wonders-await-your-exploration-at-Citygate-Outlets" target="_blank">
										Hello Kitty wonders await your exploration at Citygate Outlets
									</a>
								</div>
								<div className="news-title-date">Aug 1, 2023</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-econotimes.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://www.econotimes.com/Sanrios-Hello-Kitty-MetaGaia-Partner-to-Lanunch-New-Metaverse-Experience-1658279" target="_blank">
										Hello Kitty Invades Metaverse: Sanrio Hong Kong Joins MetaGaia for Virtual Experience
									</a>
								</div>
								<div className="news-title-date">June 22, 2023</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-trendhunter.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://www.trendhunter.com/trends/hello-kitty-seven-wonders" target="_blank">
										AI Chat Metaverses
									</a>
								</div>
								<div className="news-title-date">September 7, 2023</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-yahoo.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://finance.yahoo.com/news/metagaia-metaverse-announces-launch-party-194500754.html" target="_blank">
										{t("page.home.MetaGaia4")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20230308")}</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-acn.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://www.acnnewswire.com/press-release/traditionalchinese/77092/%E5%AF%8C%E9%80%9A%E4%BF%9D%E9%9A%AA%E5%89%B5%E5%BB%BA%E9%A6%96%E5%BA%A7%E3%80%8C%E5%85%83%E5%AE%87%E5%AE%99%E3%80%8D%E7%B8%BD%E9%83%A8%E5%A4%A7%E6%A8%93" target="_blank">
										{t("page.home.FTLife1")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20220815")}</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-coin.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="https://cointelegraph.com/news/majority-of-us-consumers-say-no-to-meta-owning-metaverse-data-survey" target="_blank">
										{t("page.home.MetaGaia6")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20220204")}</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-xinbao.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="http://startupbeat.hkej.com/?p=117853" target="_blank">
										{t("page.home.finance")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20220425")}</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-xinbao.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="http://startupbeat.hkej.com/?p=117880" target="_blank">
										{t("page.home.create")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20220425")}</div>
							</div>
							<div className="col col-12 col-lg-4 home-news">
								<div><img src="./assets/images/home/home-news-start-up-beat.png?v=20230413001" /></div>
								<div className="news-title-link">
									<a href="http://startupbeat.hkej.com/?p=117887" target="_blank">
										{t("page.home.Chain")}
									</a>
								</div>
								<div className="news-title-date">{t("date.20220425")}</div>
							</div>
						</div>
					</div>
				</section>

				<section className="s-default s-subscription" id="subscription">
					<div className="container">
						<div className="p-default-title max-lg">
							<h2 className="txt-default-title wow fadeInUp">
								<span className="txt-cyan">SUBSCRIBE OUR NEWSLETTER</span>
							</h2>
							<div className="txt-default-desc wow fadeInUp" data-wow-delay="0.3s">
								Receive the latest updates from MetaGaia and become a part of the MetaGaia community today.
							</div>
							<div className="b-subscription max-md">
								<input
									value={mail}
									onChange={e => setMail(e.target.value)}
									className="form-input"
									placeholder={t("placeholder.email")}
								/>
								<button className="btn btn-default" onClick={addEmailSubscribe}>{t("button.join")}</button>
							</div>
						</div>
					</div>
				</section>
				{
					isShow && <section className="s-default s-down-ft" style={{display: 'none'}}>
						<div className="container s-flex s-flex-center-column">
							<img className="s-down-close s-cursor-pointer wow fadeInUp" src="./assets/images/ico/ico_close_line.png" onClick={closeBanner}  alt=""/>
							<img className="ft-down-img ft-down-img-pc s-cursor-pointer wow fadeInUp" src="./assets/images/ft/down-bg-4-m.png" alt="" onClick={() => toUri("/hello-kitty")}/>
							<img className="ft-down-img ft-down-img-m s-cursor-pointer wow fadeInUp" src="./assets/images/ft/down-bg-4-m.png" alt="" onClick={() => toUri("/hello-kitty")}/>
							<div className="s-cursor-pointer ft-down-btn" onClick={() => toUri("/hello-kitty")}>Learn More</div>
						</div>
					</section>
				}
				<Footer />
			</main>

			<div className="b-popup b-popup-inline lity-hide" id="popup_inline_confirm">
				<div className="box-ctx">
					<img style={{ width: "70px", height: "79px", margin: "auto" }} src={result ? "assets/images/message-success.png" : "assets/images/message-waring.png"} />
					<div className="txt-title txt-default-title">{message}</div>
				</div>
			</div>

		</div>
	);
}

export default Home;
