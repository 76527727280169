import http from './http';
import storage from './storage';

const baseAPI = '/api/v1';

export function GetRateSOL() {
  const timestamp = new Date().getTime();
  const timestampOld = Number(storage.getItem('rateTimestamp'));
  const minVal = 60 * 1000;
  if (timestampOld && timestamp - timestampOld < minVal) {
    return new Promise((resolve, reject) => {
      resolve(storage.getItem('ratePrice'));
    });
  } else {
    return new Promise((resolve, reject) => {
      http('get', '/v2/coin/zNZHO_Sjf/price', {}).then(
        (res: any) => {
          if (
            timestamp.toString().length - res.data.timestamp.toString().length
          ) {
            const len: number =
              timestamp.toString().length -
              res.data.timestamp.toString().length;
            let val: number = 0;
            switch (len) {
              case 1:
                val = 10;
                break;
              case 2:
                val = 100;
                break;
              case 3:
                val = 1000;
                break;
              case 4:
                val = 10000;
                break;
              default:
                val = 1000;
                break;
            }
            storage.setItem('rateTimestamp', res.data.timestamp * val);
          } else {
            storage.setItem('rateTimestamp', res.data.timestamp);
          }
          // storage.setItem("ratePrice", Number(res.data.price).toFixed(2));
          // resolve (Number(res.data.price).toFixed(2));
          storage.setItem('ratePrice', Number(res.data.price));
          resolve(Number(res.data.price));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

/**
 * {
 *   "email": "string"
 *   "type": "1" 2
 *   // application: 1
 * }
 * */
export function EmailSubscribe(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/email/subscribe', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "email": "string"
 * }
 * */
export function EmailCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/email/code', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "email": "string",
 *   "password": "string"
 * }
 * */
export function LoginEmail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/login/pwd', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "username": "string",
 *   "password": "string"
 * }
 * */
export function LoginNew(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/login', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "email": "string",
 *   "code": "string"
 * }
 * */
export function CheckEmailCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/retrieve-pwd', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "id": "string",
 *   "password": "string"
 * }
 * */
export function ResetPwd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/confirm-pwd', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "username": "string",
 *   "surname": "string",
 *   "givenname": "string",
 *   "email": "string",
 *   "code": "string",
 *   "password": "string"
 * }
 * */
export function RegisterEmail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/email', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "username": "string",
 *   "password": "string"
 * }
 * */
export function RegisterNew(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/username', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "phone": "string",
 *   "areaCode": "string",
 * }
 * */
export function SendPhoneCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/sms/send-code', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "phone": "string",
 *   "areaCode": "string",
 *   "phoneCode": "string",
 * }
 * */
export function BindPhone(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/upgrade-wallet', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "taskId": "string",
 * }
 * */
export function getTask(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/task/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "taskId": "string",
 *   "awardWhere": "string",
 * }
 * */
export function taskReceive(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/task/receive', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function InviteCodeList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/invite-friend', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *     "avatar": "string",
 *     "username": "string",
 * }
 * */
export function EditUserInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/update-detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function CartList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/cart/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "batchId": "string"
 * }
 * */
export function CartAdd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/cart/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "id": "string"
 * }
 * */
export function CartDelete(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('delete', baseAPI + `/cart/${params.id}`, params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "merchantId": "string"
 * }
 * */
export function NftSellInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft/sell-batch', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function NftMerchant(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft/merchant', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "merchantId": "string"
 * }
 * */
export function NftGet(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "id": "string"
 * }
 * */
export function NftDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "userNftId": "string"
 * }
 * */
export function NftDetailHk(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft/detail-hk', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "address": "string",
 *   "nftId": "string"
 *   "type": "number" 1 metagaia 2 solana
 * }
 * */
export function NftTransfer(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/nft/transfer', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "batchId": "string",
 *   "cartIds": [
 *     "string"
 *   ]
 * }
 * */
export function OrderNft(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/order/nft', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "metg": 0
 * }
 * */
export function OrderRecharge(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/order/recharge', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "orderId": "string"
 * }
 * */
export function PayStripe(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/pay/stripe', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "orderId": "string"
 * }
 * */
export function PayStripeCallback(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/pay/stripe-callback', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 微信支付 获取openid
 * {
 *   "code": "string"
 * }
 * */
export function PayWxOpenid(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/pay/wx-openid', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 微信支付 支付
 * {
 *   "orderId": "string",
 *   "openid": "string",
 *   "type": "2" 1=> rechange order, 2=>blindbox order
 * }
 * */
export function PayWx(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/pay/wx-jsapi', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function UserInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 *   "type": "string" 1 我收藏的 3 寄售中的 4 solana 钱包中的, 6 租赁中的
 * }
 * */
export function UserCollection(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/collection', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 *   "status": init 1 初始状态, onConsignment 2 寄售中, synthesized 5 已合成
 * }
 * */
export function UserCollectionHk(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/collection-hk', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 * }
 * */
export function UserNftHistory(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/nft-history', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "username": "number"
 *   "password": "number"
 * }
 * */
export function adminLogin(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/airdorp/login', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * */
export function adminUserListMint(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/airdorp/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "ids": []
 * }
 * */
export function adminMintConfirm(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/airdorp/confirm', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "gToken": "string"
 * }
 * */
export function LoginGoogle(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/login/google', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "gToken": "string",
 *   "username": "string",
 *   "password": "string",
 *   "inviteCode": "string"
 * }
 * */
export function RegisterGoogle(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/google', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "oldPwd": "string",
 *   "password": "string",
 * }
 * */
export function ChangePwd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/setting/edit-pwd', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "status": "boolean"
 * }
 * */
export function IsOpenTFA(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/setting/tfa', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "secret": "string",
 *   "code": "string"
 * }
 * */
export function ConfirmTFA(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/setting/tfa-confirm', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "code": "string",
 *   "key": "string"
 * }
 * */
export function CheckTFA(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/login/tfa-check', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 * }
 * */
export function TokenLogs(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user-account/logs', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "value": "number",
 *   "address": "string"
 * }
 * */
export function UserTransfer(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-account/transfer', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 *   "type": "string"
 * }
 * */
export function ListLogs(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/collection', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "userNftId": "string"
 *   "metg": "number"
 * }
 * */
export function CreateList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/consignment/create', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "userNftId": "string"
 *   "price": "number"
 * }
 * */
export function LeaseCreate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/lease/create', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "userNftId": "string"
 * }
 * */
export function LeaseCancel(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/lease/cancel', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "consignmentId": "string"
 * }
 * */
export function ListCancel(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/consignment/cancel', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * {
 *   "page": "number", * 1
 *   "pageSize": "number", * 10
 *   "type": "sting", * 1 hero, 2 hexa
 *   "order": "sting", / 1 编号正序, 2 编号倒序, 3 价格正序, 4 价格倒序, 5 时间倒序
 *   "rarityFilter": "sting", / common, rare, super rare, ultra rare, extreme
 *   "classFilter": "sting", / Demolisher, Sniper, Tanker, Striker, Shooter, Bomber, Supporter, Influencer, Commander
 * }
 * */
export function MarketListData(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/consignment/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "id": "string",
 * }
 * */
export function MarketDetailData(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/consignment/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**consignment
 * {
 *   "id": "string"
 * }
 * */
export function MarketBuy(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/consignment/buy', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**consignment
 * {
 *   "id": "string"
 * }
 * */
export function GameStart(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/application/game-start', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**consignment
 * {
 *   "id": "string",
 *   "key": "string",
 *   "value": "string",
 * }
 * */
export function GameEnd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/application/game-award', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**consignment
 * {
 *   "url": "string"
 * }
 * */
export function UrlParams2Obj(params: string) {
  let obj = {
    id: '',
  };
  // @ts-ignore
  params.replace(
    /([^?=&#]+)=([^?=&#]+)/g,
    // @ts-ignore
    (_, key, value) => (obj[key] = value)
  );
  // @ts-ignore
  params.replace(/#([^?=&#]+)/g, (_, hash) => (obj['HASH'] = hash));
  return obj;
}

/**
 * {
 * }
 * */
export function GameList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/application/games', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "id": "string",
 * }
 * */
export function GameDetailInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/application/game-info', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "page": "number"
 *   "pageSize": "number"
 *   "id": "string"
 * }
 * */
export function GameLogs(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/application/game-logs', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *  type: string,
 *  address: string
 * }
 * */
export function bindWallet(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/bind-wallet', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function unbindWallet(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/unbind-wallet', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "username": "string",
 *   "surname": "string",
 *   "givenname": "string",
 *   "email": "string",
 *   "code": "string",
 *   "password": "string"
 * }
 * */
export function RegisterFT(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/ftlife', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "number": "number",
 *   "answer": "number",
 * }
 * */
export function FtAnswer(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/ft-life/answer', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function FtRanking(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/games/level-up-ranks', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * }
 * */
export function FtAnsRel(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/ft-life/answer-result', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * applicationMark:
 * token:
 * }
 * */
export function GMRanking(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/games/level-up-ranks', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 * token:
 * }
 * */
export function HexaRanking(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/games/hexahero-ranks', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * {
 *   "key": "string",
 *   "password": "string"
 * }
 * */
export function ComResetPwd(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/register/perfect-pwd', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获取模型列表
 * {
 * token:
 * status: 2
 * }
 * */
export function GetModelList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user-model/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获取模型列表
 * {
 * token:
 * status: 2
 * }
 * */
export function AddModelList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-model/create', params, {
      headers: {
        ContentType: 'multipart/form-data',
      },
    }).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获取模型列表
 * {
 * downloadMark:
 * }
 * */
export function getChatHis(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/chat/message-history', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 获取gaia配置列表
 * {
 * scene: 1=>web, 2=>app
 * type: 1=>gaia, 2=>hk
 * }
 * */
export function getGaiaConfig(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/order/recharge-conf', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * gaia充值
 * {
 * id:
 * }
 * */
export function rechargeGaia(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/order/recharge-gaia', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 兑换gaia
 * {
 * amount:
 * }
 * */
export function mtgGaia(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-account/mtg', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * hk
 * {
 * amount:
 * }
 * */
export function mtgHk(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-account/mth', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 下载埋点
 * {
 * type: 1 w 2 m
 * }
 * */
export function downloadCount(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/application/download-log', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 邮箱发送码
 * {
 * email:
 * }
 * */
export function emailKey(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/email/alphaKey', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 盲盒 hellokitty
 * 获取盲盒信息
 * {
 * mark: mark
 * }
 * */
export function boxHkDetail(params: any = { mark: 'mark' }) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/blindbox/detail-mark', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 盲盒 hellokitty
 * 下单
 * {
 *   "blindboxId": "string",
 *   "userCouponId": "string",
 *   "channel": "string"
 * }
 * */
export function boxHkOrder(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/order/blindbox', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 盲盒 hellokitty
 * 查询订单购买后对应的商品详情
 * {
 *   "id": "string",
 * }
 * */
export function boxHkProdDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/order/blindbox-detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 生成二维码
 * {
 *   "text": "string",
 * }
 * */
export function qrGenerate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/qrcode/generate', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 转移
 * {
 *   "userNftId": "string",
 *   "address": "string"
 * }
 * */
export function HkTrans(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/nft/transfer-hk', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 转移
 * {
 *   "userNftId": "string",
 *   "receiver": "string"
 * }
 * */
export function HkTransSolana(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/nft/solana-mint', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 寄售
 * {
 *   "page": "number",
 *   "pageSize": "number",
 *   "order": "string" 1 Number ASC 2 Number Desc 3 price ASC 4 price Desc 5 time Desc
 *   "seriesFilter": "string" Chef, Dreamcatcher, Inventor, Pilot, Poet, Spellcaster, Treasure Hunter
 * }
 * */
export function HkListedList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user-consignment/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 寄售
 * {
 *   "id": "string",
 * }
 * */
export function HkListedDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user-consignment/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 寄售
 * {
 *   "userNftId": "string",
 *   "price": "string",
 *   "virtualTokenType": "3" 1=>metg 2=> gaia 3=> Star Chips
 * }
 * */
export function HkListedCreate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-consignment/create', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 寄售
 * {
 *   "id": "string"
 * }
 * */
export function HkListedCancel(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-consignment/cancel', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 寄售
 * {
 *   "id": "string"
 * }
 * */
export function HkListedBuy(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-consignment/buy', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 合成 查询
 * {
 *   "brandRemark": "string" HKitty
 * }
 * */
export function HkNftCombConf(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft-composite/conf', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 合成 选择
 * {
 *   "nftDetailId": "string"
 * }
 * */
export function HkNftCombToken(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/nft-composite/nft-token', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 合成 提交
 * {
 *   "id": "string"
 *   "needNftDetailIds": "string" [{"nftDetailId": "", "userNftId": ""}]
 * }
 * */
export function HkNftCombCreate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/nft-composite/submit', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * hk 兑换券兑换
 * {
 *   "code": "string"
 * }
 * */
export function HkCoupon(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/coupon/get', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 绑定信息 手机号 邮箱
 * {
 *   "email": "string"
 *   "emailCode": "string"
 *   "phone": "string"
 *   "areaCode": "string"
 *   "phoneCode": "string"
 * }
 * */
export function SubmitBindInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/upgrade-wallet', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 账户注销
 * {
 * }
 * */
export function AccountRemove(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user-account/delete', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 账户注销
 * {
 * email: string
 * code: string
 * }
 * */
export function BindEmail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/user/bind-email', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * 抽奖
 * {
 * lotteryId: string
 * }
 * */
export function LotteryOne(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/lottery', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 抽奖
 * {
 * page: number 1
 * pageSize: number 99999
 * }
 * */
export function LotteryInfoList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/user/coupon', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 抽奖校验
 * {
 * page: number 1
 * pageSize: number 99999
 * }
 * */
export function LotteryInfoCheck(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/lottery/check-today', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 列表
 * {
 *    page: number 1
 *    pageSize: number 99999
 * }
 * */
export function ShopList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/product/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 详情
 * {
 *    id: String 1
 * }
 * */
export function ShopDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/product/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 购物车列表
 * {
 * }
 * */
export function ShopCartList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/product-shopping-cart/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 购物车加购
 * {
 *    productId: String 1
 *    productSpecId: String 1
 *    quantity: String 1
 * }
 * */
export function ShopCartCreate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/product-shopping-cart/add', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 购物车选中下单
 * {
 *    id: String 1
 *    buyStatus: String 1
 * }
 * */
export function ShopCartCreateId(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/product-shopping-cart/buy-status', params).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
    );
  });
}
/**
 * 周边 购物车删除
 * {
 *    id: String 1
 * }
 * */
export function ShopCartDelete(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/product-shopping-cart/del', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 订单列表
 * {
 *    page: number 1
 *    pageSize: number 99999
 *    id: String
 *    status: String
 * }
 * */
export function ShopOrderList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/product-order/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 订单详情
 * {
 *    id: String
 * }
 * */
export function ShopOrderDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/product-order/detail', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
/**
 * 周边 订单下单
 * {
 *    name: String
 *    mobile: String
 *    district: String
 *    address: String
 * }
 * */
export function ShopOrderCreate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', baseAPI + '/product-order/generate', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function InviteFriendList(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/invite-friend/list', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function InviteFriendRecord(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', baseAPI + '/invite-friend/award-record', params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function urlToObj(url: string) {
  if (!url) return null;
  let obj = {
    id: '',
  };
  // @ts-ignore
  url.replace(/([^?=&#]+)=([^?=&#]+)/g, (_, key, value) => (obj[key] = value));
  // @ts-ignore
  url.replace(/#([^?=&#]+)/g, (_, hash) => (obj['HASH'] = hash));
  return obj;
}

// 格式化 千分位
export function formatThousandthComma(
  value: string | number,
  decimals?: string | number,
  thousands_sep?: string,
  dec_point?: string
): number | string {
  value = String(value || 0);
  if (!value) return 0;
  if (!decimals && !isFinite(Number(decimals))) decimals = 0;
  if (!thousands_sep) thousands_sep = ',';
  if (!dec_point) dec_point = '.';
  value = (value + '').replace(/[^0-9+-Ee.]/g, '');
  let n = !isFinite(+value) ? 0 : +value,
    prec = !isFinite(+decimals) ? 0 : Math.abs(Number(decimals)),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s: string[] = [],
    toFixedFix = (n, prec) => {
      let k = Math.pow(10, prec);
      return '' + Math.ceil(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}
