import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function Faq() {
	const { t } = useTranslation();

	const [walletStepBg1, setWalletStepBg1] = useState(false);
	const [walletStepBg2, setWalletStepBg2] = useState(false);
	const [walletStepBg3, setWalletStepBg3] = useState(false);

	const [result, setResult] = useState("");
	useEffect(() => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
		document.body.appendChild(s);

	}, []);
	return (
		<div className="bg-hexahero bg-faq">
			<Helmet>
            <title>MetaGaia Metaverse FAQs & Support</title>
            <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
          	</Helmet>

			<div className="wrapper">
				<Header />

				<main>
					<section className="s-nft s-default">
						<img className="img-bg" src="assets/images/nft/img_bg.jpg?v=20230413001" data-anchor-target=".s-nft"
							data-top="transform: translateY(0%)"
							data-top-bottom="transform: translateY(0%)" />

						<div className="container">
							<div className="box-title wow fadeInUp">
								<img className="img-title" src="assets/images/title_FAQs.png?v=20230413001" />
								<div className="max-md">

									<div className="box-bar faq-box">
										<div className="b-bar" >
											<div className="faq-q">{t("page.faq.What")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.MetaGaia")}</div>
										</div>
										<div className="b-bar" >
											<div className="faq-q">How is MetaGaia using ChatGPT?</div>
											<div className="faq-more"></div>
											<div className="faq-ans">MetaGaia incorporates ChatGPT into an AI-powered assistant known as Gaia to provide captivating conversations and enhanced interactions within the metaverse. Gaia serves as an omniscient oracle that users can engage with for deep discussions and it adds a new dimension of interactivity and intelligence to the metaverse experience.</div>
										</div>
										<div className="b-bar" >
											<div className="faq-q">Why would businesses want to work with MetaGaia?</div>
											<div className="faq-more"></div>
											<div className="faq-ans">Businesses can work with MetaGaia to develop immersive virtual environments where they can showcase their products and services, engage with customers through personalized interactions, collaborate with partners in innovative ways, and reach younger generations who are digital natives. By partnering with MetaGaia, businesses can tap into the limitless potential of the metaverse and unlock new opportunities for success.</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.Which")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.Solana")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.What1")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.HexaHero1")} <br />{t("page.faq.HexaHero2")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.Where")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.We")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.When")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.We1")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.What2")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.There")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.Who")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.The1")} <a href="https://www.advokategroup.io/" target="_blank">{t("page.faq.The2")}</a>{t("page.faq.The3")}</div>
										</div>

										<div className="b-bar connect-wallet">
											<div className="faq-q">{t("page.faq.How")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans" id="walletFirst">
												<div className="col col-12 faq-wallet-step">
													<div className="col col-3 wallet-step-box" onMouseEnter={() => {
														setWalletStepBg1(true);
													}} onMouseLeave={() => {
														setWalletStepBg1(false);
													}}>{
														walletStepBg1 ?
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg1-h.png?v=20230413001"/>
															:
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg1.png?v=20230413001"/>
													}
														<span className="wallet-step-num"><a href="#walletFirst">1.</a></span>
														<span className="wallet-step-ct"><a href="#walletFirst">{t("page.faq.Create")}</a></span>
													</div>
													<div className="col col-5 wallet-step-box" onMouseEnter={() => {
														setWalletStepBg2(true);
													}} onMouseLeave={() => {
														setWalletStepBg2(false);
													}}>{
														walletStepBg2 ?
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg2-h.png?v=20230413001"/>
															:
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg2.png?v=20230413001"/>
													}
														<span className="wallet-step-num"><a href="#walletSecond">2.</a></span>
														<span className="wallet-step-ct"><a href="#walletSecond">{t("page.faq.Buy")}</a></span>
													</div>
													<div className="col col-4 wallet-step-box" onMouseEnter={() => {
														setWalletStepBg3(true);
													}} onMouseLeave={() => {
														setWalletStepBg3(false);
													}}>{
														walletStepBg3 ?
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg3-h.png?v=20230413001"/>
															:
															<img className="wallet-step-bg" src="./assets/images/faq/faq-wallet-step-bg3.png?v=20230413001"/>
													}
														<span className="wallet-step-num"><a href="#walletThree">3.</a></span>
														<span className="wallet-step-ct"><a href="#walletThree">{t("page.faq.Mint")}</a></span>
													</div>
												</div>
												<div className="faq-wallet-body">
													<div className="li" id="walletSecond">
														<div className="faq-wallet-title">{t("page.faq.Create1")}</div>
														<div className="faq-wallet-desc">{t("page.faq.Select")}</div>
														<div className="col col-12 faq-wallet-creat">
															<div className="btn btn-default col wallet-creat-box1 wallet-creat-box">
																<a href="https://help.phantom.app/hc/en-us/articles/4406388623251-How-to-create-a-new-wallet" target="_blank">
																<div className="wallet-creat-img">
																	<img className="wallet-step-bg" src="./assets/images/faq/faq-logo-pt.png?v=20230413001"/>
																</div>
																</a>
															</div>
															<div className="btn btn-default col wallet-creat-box2 wallet-creat-box">
																<a href="https://solflare.com/" target="_blank">
																<div className="wallet-creat-img">
																	<img className="wallet-step-bg" src="./assets/images/faq/faq-logo-sf.png?v=20230413001"/>
																</div>
																</a>
															</div>
															<div className="btn btn-default col wallet-creat-box3 wallet-creat-box">
																<a href="https://www.sollet.io/" target="_blank">
																<div className="wallet-creat-img">
																	<img className="wallet-step-bg" src="./assets/images/faq/faq-logo-so.png?v=20230413001"/>
																</div>
																</a>
															</div>
														</div>
													</div>
													<div className="li">
														<div className="faq-wallet-title">{t("page.faq.Buy1")}</div>
														<div className="faq-wallet-desc">{t("page.faq.Here")}</div>
														<div className="col col-12 faq-wallet-buy">
															<div className="ol">
																<div className="ol-li">
																	<div className="faq-flex-start"><div className="li-num">{t("page.faq.A1")}</div> <div>{t("page.faq.A2")}</div></div>
																	<div className="faq-swiper-buy faq-wallet-swiper-box">
																		<img src="./assets/images/faq/faq-wallet-buy-1.png?v=20230413001"/>
																	</div>
																</div>
																<div className="ol-li" id="walletThree">
																	<div className="faq-flex-start"><div className="li-num">{t("page.faq.B1")}</div> <div>{t("page.faq.B2")}</div></div>
																	<div className="wallet-buy-sol">
																		<div>{t("page.faq.Binance")}</div>
																		<div className="wallet-buy-split"></div>
																		<div>{t("page.faq.Coinbase")}</div>
																		<div className="wallet-buy-split"></div>
																		<div>{t("page.faq.Gate")}</div>
																		<div className="wallet-buy-split"></div>
																		<div>{t("page.faq.Huobi")}</div>
																		<div className="wallet-buy-split"></div>
																		<div>{t("page.faq.Kraken")}</div>
																	</div>
																	<div className="li-ct">{t("page.faq.After")}</div>
																</div>
															</div>
														</div>
													</div>
													<div className="li">
														<div className="faq-wallet-title">{t("page.faq.Mint1")}</div>
														<div className="faq-wallet-desc-title faq-text-center"><span>{t("page.faq.Mobile")}</span><a href="https://nft-1259661131.cos.ap-hongkong.myqcloud.com/howtomint_mobile.mp4?v=20230413001" target="_blank"><img src="./assets/images/faq/faq-link-video.png?v=20230413001" /></a></div>
														<div className="col col-12 faq-wallet-mint faq-wallet-swiper-box">
															<div className="swiper faq-swiper-mint-m" id="faqWalletMintM">
																<div className="swiper-wrapper">
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-1.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.In")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-2.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Go")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-3.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Connect")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-4.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Click")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-5.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Approve")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-6.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Which")}</div>
																	</div>
																	<div className="swiper-slide">
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-m-7.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Check")}</div>
																	</div>
																</div>
															</div>
															<div className="swiper-pagination faq-swiper-pg-mint-m"></div>
															<div className="faq-wallet-swiper-btn">
																<div className="btn-ctrl btn-prev swiper-button-prev faq-swiper-prev-mint-m"></div>
																<div className="btn-ctrl btn-next swiper-button-next faq-swiper-next-mint-m"></div>
															</div>
														</div>
														<div className="faq-wallet-desc-title faq-text-center"><span>{t("page.faq.Desktop")}</span><a href="https://nft-1259661131.cos.ap-hongkong.myqcloud.com/howtomint_square.mp4?v=20230413001" target="_blank"><img src="./assets/images/faq/faq-link-video.png?v=20230413001" /></a></div>
														<div className="col col-12 faq-wallet-mint faq-wallet-swiper-box">
															<div className="swiper faq-swiper-mint-pc" id="faqWalletMintPC">
																<div className="swiper-wrapper">
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-1.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-1.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Go1")}</div>
																	</div>
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-2.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-2.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Connect1")}</div>
																	</div>
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-3.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-3.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Click1")}</div>
																	</div>
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-4.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-4.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Approve1")}</div>
																	</div>
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-5.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-5.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Minting1")}</div>
																	</div>
																	<div className="swiper-slide" onClick={() => {setResult("./assets/images/faq/faq-swiper-mint-pc-6.png?v=20230413001"); (window as any).pop();}}>
																		<div className="faq-wallet-swiper-img">
																			<img src="./assets/images/faq/faq-swiper-mint-pc-6.png?v=20230413001"/>
																		</div>
																		<div className="faq-wallet-swiper-text">{t("page.faq.Check1")}</div>
																	</div>
																</div>
																<div className="swiper-pagination faq-swiper-pg-mint-pc"></div>
																<div className="faq-wallet-swiper-btn">
																	<div className="btn-ctrl btn-prev swiper-button-prev faq-swiper-prev-mint-pc"></div>
																	<div className="btn-ctrl btn-next swiper-button-next faq-swiper-next-mint-pc"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.I")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.MetaGaia2")} <a className="txt-color-primary" href="https://www.metagaia.io/register" target="_blank">{t("page.faq.MetaGaia3")}</a></div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.What3")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.MetaGaia4")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.What4")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.$METG")}</div>
											<div className="faq-ans">{t("page.faq.We2")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.When1")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.Target")}</div>
										</div>

										<div className="b-bar">
											<div className="faq-q">{t("page.faq.I1")}</div>
											<div className="faq-more"></div>
											<div className="faq-ans">{t("page.faq.User")}</div>
										</div>

									</div>

								</div>
							</div>

						</div>
					</section>

					<Footer />
				</main>
			</div>
			<div className="lity-hide" id="popup_inline_confirm">
				<div className="">
					<img style={{ width: "100%", height: "100%", margin: "auto" }} src={result} />
				</div>
			</div>
		</div>
	);
}

export default Faq;
