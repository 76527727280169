import storage from "./libs/storage";
import {removeCookie} from "./libs/cookies";
import ThemeConfig from "./i18n/themeConfig";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t, i18n } = useTranslation();
  function changeI18n(value) {
    i18n.changeLanguage(value);
    storage.setItem("i18n-local", value);
    return false;
  }

  const isLogin = !!window.localStorage.getItem('userStatus');

  function logout() {
    storage.removeItem('userStatus');
    removeCookie('ua_user');
    removeCookie('ua_once');
    if (storage.getItem('tfaStatus')) {
      storage.removeItem('tfaStatus');
    }
    window.self.location.href = window.location.origin + '/login';
  }

  function toLogin() {
    if (isLogin) return false;
    window.self.location.href = window.location.origin + '/login';
  }

  function toUser() {
    window.self.location.href = window.location.origin + '/user';
  }


  return (
    <header className="s-header">
      <div className="s-header-wrapper">
        <div className="container">
          <div className="logo-wrapper">
            <a href="/">
              <div className="box-logo">
                <img
                  className="img-logo"
                  src="assets/images/logo_metagaia2_v2.png?v=20230413001"
                />
              </div>
            </a>

            <div className="btn btn-menu" aria-label="Toggle navigation">
              <span className="line line-1"></span>
              <span className="line line-2"></span>
              <span className="line line-3"></span>
            </div>
          </div>
          <div className="main-wrapper">
            <div className="box-ctx">
              <ul className="nav nav-main">
                <li className="has-sub">
                  <a href="/">{t("menu.about")}</a>
                  <div className="box-sub collapse">
                    <ul className="nav nav-sub">
                      <li>
                        <a href="/#introduction">{t("menu.aboutIntroduction")}</a>
                        <br />
                        <a href="/#welcome-to-metagaia">{t("menu.aboutWelcome")}</a>
                        <br />
                        <a href="/#upcoming-crypto-games">
                          {t("menu.aboutUpcoming")}
                        </a>
                        <br />
                        <a href="/#metg-token">{t("menu.aboutToken")}</a>
                        <br />
                        <a href="/#roadmap">{t("menu.aboutRoadmap")}</a>
                        <br />
                        <a href="/#meet-the-team">{t("menu.aboutMeet")}</a>
                        <br />
                        <a href="/#partners">{t("menu.aboutPartners")}</a>
                        <br />
                        <a href="/#latestNews">{t("menu.aboutNews")}</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="has-sub">
                  <a href="#">
                    {t("menu.games")}<span className="ico-sub"></span>
                  </a>
                  <div className="box-sub collapse">
                    <ul className="nav nav-sub">
                      <li>
                        <a href="/hexahero">HEXAHERO</a>
                        <br/>
                        <a href="/juju-raiders">JUJU RAIDERS</a>
                        <br/>
                        <a href="#">HEXA RINGRACER</a>
                        <br/>
                        <a href="#">MAZE FANTASY</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="has-sub">
                <a href="#">{t("menu.nft")}</a>
                  <div className="box-sub collapse">
                    <ul className="nav nav-sub">
                      <li>
                        <a href="/nft#head_hero">{t("menu.nftCollection1")}</a>
                        <br />
                        <a href="/nft#mint_hero">{t("menu.nftMint1")}</a>
                        <br />
                        <a href="/nft#head_hexa">{t("menu.nftCollection2")}</a>
                        <br />
                        <a href="/nft#mint_hexa">{t("menu.nftMint2")}</a>
                        <br />
                        <a href="/nft#market">{t("menu.nftMarketplace")}</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="/faq">{t("menu.faqs")}</a>
                </li>
                {/*<li>*/}
                {/*  <a href="/paper">LITE PAPER</a>*/}
                {/*</li>*/}
                <li>
                  <a href="/game-download">{t("menu.download")}</a>
                </li>

                <li className="has-sub" style={{display: "none"}}>
                  <a href="#">{t("menu.language")}</a>
                  <div className="box-sub collapse">
                    <ul className="nav nav-sub">
                      <li>
                        <a href="#!" onClick={() => changeI18n(ThemeConfig.i18nEnum.ZHCN.value)}>{ThemeConfig.i18nEnum.ZHCN.name}</a>
                        <br />
                        <a href="#!" onClick={() => changeI18n(ThemeConfig.i18nEnum.ZHTW.value)}>{ThemeConfig.i18nEnum.ZHTW.name}</a>
                        <br />
                        <a href="#!" onClick={() => changeI18n(ThemeConfig.i18nEnum.ENUS.value)}>{ThemeConfig.i18nEnum.ENUS.name}</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className={isLogin ? 'has-sub' : ''}>
                  <a href="#">
                    <button
                        className="btn btn-default"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          lineHeight: '1.4rem',
                        }}
                        onClick={toLogin}
                    >
                      {
                        isLogin ? t("menu.userConnected") : t("menu.userConnectedWallet")
                      }
                    </button>
                  </a>
                  {isLogin && (
                    <div className="box-sub collapse">
                      <ul className="nav nav-sub">
                        <li style={{ marginTop: '12px', marginBottom: '4px' }}>
                          <a href="#" style={{ fontSize: '1rem' }} onClick={toUser}>
                            {t("menu.userMy")}
                          </a>
                        </li>
                        <li>
                          <a
                              href="#"
                              style={{ fontSize: '1rem', color: '#00FFFF' }}
                              onClick={logout}
                          >
                            {t("menu.userLogout")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
