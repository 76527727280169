import Header from './Header';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useEffect, useState } from 'react';
import { CheckTFA } from './libs/api';
import storage from './libs/storage';
import { setCookie, removeCookie } from './libs/cookies';

import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function SignSecurity() {
  const { t } = useTranslation();

  const [validMsgCode, setValidMsgCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function validAuthentication() {
    // @ts-ignore
    const val = document.querySelector('#code').value.trim();
    if (val === 'null' || val === 'undefined' || val === '' || !val) {
      setValidMsgCode(t("validate.information"));
      return false;
    } else if (val.length < 6) {
      setValidMsgCode('Verification code error');
      return false;
    } else {
      setValidMsgCode('');
      return true;
    }
  }
  function enterSecurity() {
    if (validAuthentication()) {
      // @ts-ignore
      const code = document.querySelector('#code').value.trim();
      setIsLoading(true);
      CheckTFA({
        code: code,
        key: storage.getItem('tfaKey'),
      })
        .then((res: any) => {
          setIsLoading(false);
          if (!res.code) {
            storage.setItem('userStatus', res.token);
            setCookie('ua_user', res.token || '');
            storage.removeItem('tfaKey');
            toHome();
          }
        })
        .catch(() => {
          setIsLoading(false);
          removeCookie('ua_user');
          storage.removeItem('userStatus');
          storage.removeItem('tfaKey');
        });
    }
  }
  function toHome() {
    const redirectUrl = window.location.href.split('redirect=')[1];
    if (
      redirectUrl &&
      !(redirectUrl.includes('forgot') || redirectUrl.includes('reset'))
    ) {
      window.self.location.href = window.location.origin + redirectUrl;
    } else {
      window.self.location.href = window.location.origin;
    }
  }
  return (
    <>
      <Helmet>
        <title>MetaGaia Metaverse FAQs & Support</title>
        <meta
          name="description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
        <meta
          property="og:description"
          content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops."
        />
      </Helmet>
      <div className="bg-hexahero">
        <div className="wrapper">
          <Header />
          <main>
            <section className="s-nft s-default">
              <img
                className="img-bg"
                src="./assets/images/bg1.png?v=20230413001"
                data-anchor-target=".s-nft"
                data-center="transform: translateY(0%)"
                data-top-bottom="transform: translateY(0%)"
              />

              <div className="container signsec-body">
                <h2 className="txt-default-title wow fadeInUp">
                  <span className="txt">{t("page.signSecurity.SECURE")}</span>
                </h2>
                <div className="col col-12 s-flex s-flex-center-row">
                  <img
                    className="security-img"
                    src="./assets/images/user/sign-security-img.png?v=20230413001"
                  />
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <div className="sign-title">{t("page.signSecurity.Two")}</div>
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <div className="sign-tip">
                    {t("page.signSecurity.Enter")}
                  </div>
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <input
                    className="code-input"
                    type="text"
                    id="code"
                    minLength={6}
                    maxLength={6}
                    onBlur={validAuthentication}
                  />
                </div>
                <div className="col col-12 s-flex error-tip">
                  <div className="form-valid">{validMsgCode}</div>
                </div>
                <div className="col col-12 s-flex s-flex-center-row">
                  <button
                    className="btn btn-default enter-btn"
                    disabled={isLoading}
                    onClick={enterSecurity}
                  >
                    {t("page.signSecurity.ENTER")}
                  </button>
                </div>
              </div>
            </section>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}
