// Home TODO
import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';

export default function Land() {

    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container">
                                111
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
}
