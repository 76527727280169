// import Header from "../Header";
// import Footer from "../Footer";
import { FtRanking } from "../libs/api";
import * as React  from "react";
import {useTranslation} from "react-i18next";

export default function Ranking() {
    const { t } = useTranslation();

    const [rankList, setRankList] = React.useState([]);
    const initData = () => {
        FtRanking({}).then((res: any) => {
            if (res.rows) {
                setRankList(res.rows);
            }
        });
    }
    React.useEffect(() => {
        initData();
    }, []);
    return (<div className="wrapper wrapper-home download-box">
        {/*<Header />*/}
        <main>
            <section className="s-hero">
                <img className="img-bg" src="assets/images/bg1.png" data-anchor-target=".s-hero"
                     data-center="transform: translateY(0%)"
                     data-top-bottom="transform: translateY(40%)" />
                <div className="container ft-rank-box">
                    <div className="rank-list">
                        <img className="rank-bg" src="assets/images/ft/rank-bg.png"/>
                        <div className="top-last">
                            <div className="top-item">
                                <div className="item-name top-2">{rankList[1] && rankList[1].username}</div>
                                <div className="item-score top-2">{rankList[1] && rankList[1].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name top-1">{rankList[0] && rankList[0].username}</div>
                                <div className="item-score top-1">{rankList[0] && rankList[0].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name top-3">{rankList[2] && rankList[2].username}</div>
                                <div className="item-score top-3">{rankList[2] && rankList[2].val}</div>
                            </div>
                        </div>
                        <div className="top-other">
                            <div className="top-item">
                                <div className="item-name">{rankList[3] && rankList[3].username}</div>
                                <div className="item-score">{rankList[3] && rankList[3].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[4] && rankList[4].username}</div>
                                <div className="item-score">{rankList[4] && rankList[4].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[5] && rankList[5].username}</div>
                                <div className="item-score">{rankList[5] && rankList[5].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[6] && rankList[6].username}</div>
                                <div className="item-score">{rankList[6] && rankList[6].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[7] && rankList[7].username}</div>
                                <div className="item-score">{rankList[7] && rankList[7].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[8] && rankList[8].username}</div>
                                <div className="item-score">{rankList[8] && rankList[8].val}</div>
                            </div>
                            <div className="top-item">
                                <div className="item-name">{rankList[9] && rankList[9].username}</div>
                                <div className="item-score">{rankList[9] && rankList[9].val}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<Footer />*/}
        </main>
    </div>);
}
