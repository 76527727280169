// Box NFT
import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import * as React from "react";

export default function LandBoxNft() {

    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default s-land">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container land-box">
                                <div className="s-flex s-flex-center-row">
                                    <h2 className="wow fadeInUp land-title">CONGURATULATIONS</h2>
                                </div>
                                <div className="nft-list">
                                    <div className="list-box">
                                        {
                                            [1,2,3,4,5,6].map(item => <div className="s-flex s-flex-center-column list-item">
                                                <img src="assets/images/heros/4.png?v=20230413001" className="item-img" alt=""/>
                                                <div className="item-name"><span className="item-name-normal">Moon Land </span><span className="item-name-bold">#96302 1{item}</span></div>
                                                <div className="item-location">Location: 220.988</div>
                                                <div className="item-landform">landform: Plain</div>
                                            </div>)
                                        }
                                    </div>
                                    <div className="s-flex s-flex-center-row nft-list-btn">
                                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-custom btn-border-custom">CLOSE</div>
                                        <div className="s-flex s-flex-center-row s-cursor-pointer btn-custom btn-full-custom">CHECK</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
}
