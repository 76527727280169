import Header from "../Header";
import Footer from "../Footer";
import * as React from "react";
import {LotteryInfoList} from "../libs/api";
import {useEffect, useState} from "react";

export default function LotteryList() {
    const [lotteryListData, setLotteryListData] = useState([])
    const getList = () => {
        LotteryInfoList({page: 1, pageSize: 1}).then((res: any) => {
            if (!res.code) {
                setLotteryListData(res.rows);
            }
        });
    }
    useEffect(() => {
        getList();
    }, []);
    const toSale = () => {
        window.location.href = window.location.origin + "/hello-kitty#hk_box";
    }
    return <>
        <div className="hello-kitty mark-lottery">
            <div className="wrapper">
                <Header />
                <main className="hello-kitty-bg-fixed">
                    <section className="hk-info hk-bg-ico">
                        <div className="s-flex s-flex-center-row lottery-logo">
                            <img className="logo-img" src="./assets/images/mark/logo.png" alt=""/>
                        </div>
                        <div className="lottery-list">
                            {lotteryListData && lotteryListData?.map((item, index) => {
                                return (
                                    <div className="lottery-list-item" key={index}>
                                        <div className="s-flex s-flex-center-row list-item-title">MY PRIZE</div>
                                        <div className={`s-flex s-flex-between-row list-item-info ${ item.status === 2 ? "info-useed" : "info-use" }`}>
                                            <div className="info-left">
                                                <div className="info-left-title">COUPON</div>
                                                <div className="info-left-sale">{item.remark}% OFF</div>
                                                <div className="info-left-desc">For Purchasing Wonder Pass</div>
                                            </div>
                                            {
                                                item.status === 0 &&
                                              <div className="info-right">
                                                <div className="info-right-date">Expiry Date:</div>
                                                <div className="info-right-date">{item.expireAt.split(' ')[0]}</div>
                                                <div className="s-flex s-flex-center-row info-right-btn" onClick={toSale}>USE</div>
                                                {/*<div className="info-right-link">Coupon Info</div>*/}
                                              </div>
                                            }
                                            {
                                                item.status === 1 &&
                                              <div className="s-flex s-flex-center-row info-right info-right-one">
                                                <img className="info-right-one-btn" src="./assets/images/mark/btn-used.png" alt=""/>
                                              </div>
                                            }
                                            {
                                                item.status === 2 &&
                                              <div className="s-flex s-flex-center-row info-right info-right-one">
                                                Expired
                                              </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </div>
    </>;
}
