import Header from "../Header";
import Footer from "../Footer";
import * as React from "react";
import {getUrlParams} from "../utils";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {EmailCode, SendPhoneCode, SubmitBindInfo} from "../libs/api";

export default function BindInfo() {
    const { t } = useTranslation();

    const [validMsgEmail, setValidMsgEmail] = useState('');
    const [validMsgEmailCode, setValidMsgEmailCode] = useState('');
    const [validMsgPhone, setValidMsgPhone] = useState('');
    const [validMsgPhoneCode, setValidMsgPhoneCode] = useState('');
    function validEmail() {
        // @ts-ignore
        const val = document.querySelector('#email').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmail(t("validate.information"));
            return false;
        }
        if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(val)) {
            setValidMsgEmail('');
            return true;
        } else if (val.length > 100) {
            setValidMsgEmail(t("validate.long"));
            return false;
        } else {
            setValidMsgEmail(t("validate.address"));
            return false;
        }
    }
    function validEmailCode() {
        // @ts-ignore
        const val = document.querySelector('#emailCode').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgEmailCode(t("validate.information"));
            return false;
        } else if (val && /^[a-zA-Z\d]{6}$/gi.test(val)) {
            setValidMsgEmailCode('');
            return true;
        } else if (val.length > 6) {
            setValidMsgEmailCode(t("validate.long"));
            return false;
        } else {
            setValidMsgEmailCode(t("validate.code"));
            return false;
        }
    }
    function validPhoneArea() {
        // @ts-ignore
        const val = document.querySelector('#phoneArea').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPhone(t("validate.information"));
            return false;
        }
        if (/^\d+$/gi.test(val)) {
            setValidMsgPhone('');
            return true;
        } else {
            setValidMsgPhone(t("validate.number"));
            return false;
        }
    }
    function validPhone() {
        // @ts-ignore
        const val = document.querySelector('#phone').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPhone(t("validate.information"));
            return false;
        } else {
            setValidMsgPhone('');
            return true;
        }
    }
    function validPhoneCode() {
        // @ts-ignore
        const val = document.querySelector('#phoneCode').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPhoneCode(t("validate.information"));
            return false;
        }
        if (/^\d{6}$/gi.test(val)) {
            setValidMsgPhoneCode('');
            return true;
        } else {
            setValidMsgPhoneCode(t("validate.number"));
            return false;
        }
    }

    const timeNum = 120;
    const [isClickEmail, setIsClickEmail] = useState(false);
    const [isClickEmailMsg, setIsClickEmailMsg] = useState(t("button.CODE"));
    const [isClickEmailNum, setIsClickEmailNum] = useState(timeNum);
    function getEmailCode() {
        // @ts-ignore
        let emailVal = document.querySelector('#email').value.trim();
        if (!validEmail()) {
            return false;
        }
        setValidMsgEmailCode('');
        // setOpen(true);
        EmailCode({ email: emailVal, type: 4 }).then();
        let time = timeNum;
        setIsClickEmail(true);
        const interval = setInterval(() => {
            setIsClickEmailNum(time--);
            setIsClickEmailMsg(time + 's');
            if (time < 0) {
                setIsClickEmail(false);
                setIsClickEmailNum(timeNum);
                setIsClickEmailMsg(t("button.CODE"));
                clearInterval(interval);
            }
        }, 1000);
        return true;
    }
    const [isClickPhone, setIsClickPhone] = useState(false);
    const [isClickPhoneMsg, setIsClickPhoneMsg] = useState('GET CODE');
    const [isClickPhoneNum, setIsClickPhoneNum] = useState(timeNum);
    function getPhoneCode() {
        if (validPhoneArea() && validPhone()) {
            // @ts-ignore
            const phoneArea = document.querySelector('#phoneArea').value.trim();
            // @ts-ignore
            const phone = document.querySelector('#phone').value.trim();
            if (!validPhone()) {
                return false;
            }
            setValidMsgPhoneCode('');
            SendPhoneCode({
                phone: phone,
                areaCode: phoneArea,
            }).then(() => {
                let time = timeNum;
                setIsClickPhone(true);
                const interval = setInterval(() => {
                    setIsClickPhoneNum(time--);
                    setIsClickPhoneMsg(time + 's');
                    if (time < 0) {
                        setIsClickPhone(false);
                        setIsClickPhoneNum(timeNum);
                        setIsClickPhoneMsg('GET CODE');
                        clearInterval(interval);
                    }
                }, 1000);
            });
            return true;
        }
    }

    function submitForm() {
        if (validEmail() && validPhone() && validEmailCode() && validPhoneCode() && validPhoneArea()) {
            // @ts-ignore
            let email = document.querySelector('#email').value.trim();
            // @ts-ignore
            const emailCode = document.querySelector('#emailCode').value.trim();
            // @ts-ignore
            const phoneArea = document.querySelector('#phoneArea').value.trim();
            // @ts-ignore
            const phone = document.querySelector('#phone').value.trim();
            // @ts-ignore
            const phoneCode = document.querySelector('#phoneCode').value.trim();
            SubmitBindInfo({email: email, emailCode: emailCode, phone: phone, phoneCode: phoneCode, areaCode: phoneArea}).then((res: any) => {
                if (!res.code) {
                    toBack();
                }
            });
        }
    }

    function toBack() {
        window.location.href = window.location.origin + window.location.search.replace("?redirect=", "");
    }

    return (
        <>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main className="bind-info">
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container">
                                <div className="s-flex s-flex-center-row">
                                    <h2 className="txt-default-title wow fadeInUp"><span className="txt">ACTIVATE WALLET</span></h2>
                                </div>
                                <div className="s-flex s-flex-center-row">
                                    <div className="row form-box">
                                        <div className="col col-12 col-lg-6 form-item form-left">
                                            <div className="form-label">{t("page.register.Email")}</div>
                                            <div className="form-value">
                                                <input type="email" id="email" maxLength={100} placeholder="" onBlur={validEmail} />
                                            </div>
                                            <div className="form-valid">{validMsgEmail}</div>
                                        </div>
                                        <div className="col col-12 col-lg-6 form-item">
                                            <div className="form-label label-code">Verify</div>
                                            <div className="form-value s-flex">
                                                <button className="btn btn-default btn-border-none btn-send" onClick={getEmailCode} disabled={isClickEmail}>{isClickEmailMsg}</button>
                                                <input type="text" id="emailCode" minLength={6} maxLength={6} className="input-code" placeholder={t("placeholder.Code")} onBlur={validEmailCode} />
                                            </div>
                                            <div className="form-valid">{validMsgEmailCode}</div>
                                        </div>
                                        <div className="col col-12 col-lg-6 form-item form-left">
                                            <div className="form-label">Mobile</div>
                                            <div className="s-flex">
                                                <div className="form-value s-flex s-flex-center-row form-area-input">
                                                    <label>+</label>
                                                    <input type="text" id="phoneArea" minLength={1} maxLength={6} defaultValue={86} onBlur={validPhoneArea} />
                                                </div>
                                                <div className="form-value form-value-area">
                                                    <input type="text" id="phone" minLength={1} maxLength={16} placeholder="" onBlur={validPhone} />
                                                </div>
                                            </div>
                                            <div className="form-valid">{validMsgPhone}</div>
                                        </div>
                                        <div className="col col-12 col-lg-6 form-item">
                                            <div className="form-label label-code">Verify</div>
                                            <div className="form-value s-flex">
                                                <button className="btn btn-default btn-border-none btn-send" onClick={getPhoneCode} disabled={isClickPhone}>{isClickPhoneMsg}</button>
                                                <input type="text" id="phoneCode" minLength={6} maxLength={6} className="input-code" placeholder={t("placeholder.Code")} onBlur={validPhoneCode} />
                                            </div>
                                            <div className="form-valid">{validMsgPhoneCode}</div>
                                        </div>
                                        <div className="col col-12 form-item s-flex s-flex-center-row">
                                            <button className="btn btn-default btn-transparent btn-half-cancel" onClick={toBack}>CANCEL</button>
                                            <button className="btn btn-default btn-half-confirm" onClick={submitForm}>CONFIRM</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
}
