import { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as anchor from '@project-serum/anchor';

import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { useWalletModal, } from "./reactui";
import {Helmet} from 'react-helmet';

import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  getCandyMachineState,
  getMinters,
  mintOneToken,
} from './mint/candymachine';

import { AlertState } from './mint/utils';

// modal start
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';
import {UserInfo, NftSellInfo, OrderNft, GetRateSOL} from "./libs/api";
import {useTranslation} from "react-i18next";

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
  );
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#000000',
  border: '1px solid #00FFFF',
  boxShadow: 0,
  p: 1,
};
// modal end

interface NftInfo {
  attribute: any,
  batch: string,
  batchNumber: number,
  detail: any,
  id: string,
  image: string[],
  sellNumber: number,
  solPrice: string,
  startTime: string,
  title: string,
  metgPrice: number,
  usdPrice: number,
  gasFee: number,

  type: number
  video: string,
  isStartSell: boolean
}

export default function NftPreSale() {
  const { t } = useTranslation();

  const wallet = useWallet();

  const modal = useWalletModal();
  const [balance, setBalance] = useState(0);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });
  const [isMinting, setIsMinting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txId, setTxId] = useState("");

  const anchorWallet = useMemo(() => {
    if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  let maHero: string = process.env.REACT_APP_MACHINE_ID_HERO || "";
  let maHexa: string = process.env.REACT_APP_MACHINE_ID_HEXA || "";

  const [candyMachineHero, setCandyMachineHero] = useState<CandyMachineAccount>();
  const [candyMachineHexa, setCandyMachineHexa] = useState<CandyMachineAccount>();
  // const con = useConnection();
  const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
  const connection = new anchor.web3.Connection(rpcHost);

  const onMint = async (id: string) => {
    const candyMachine = id === maHero ? candyMachineHero : candyMachineHexa;
    if (candyMachine === null || isMinting || candyMachine?.state.isSoldOut || !candyMachine?.state.isActive) return;
    try {
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {

        setIsMinting(true);
        const result = await mintOneToken(candyMachine!, wallet.publicKey);
        const mintTxId = result[0];

        let status: any = { err: true };
        if (mintTxId) {

          status = await awaitTransactionSignatureConfirmation(
              mintTxId,
              60000,
              connection,
              'singleGossip',
              true,
          );

          if (!status?.err) {
            setAlertState({
              open: true,
              message: 'Congratulations! Mint succeeded!',
              severity: 'success',
            });

            let info = await connection.getTransaction(mintTxId!);
            setTxId(info?.meta?.postTokenBalances![0].mint ?? "");

          } else {

            setAlertState({
              open: true,
              message: 'Mint failed! Please try again!',
              severity: 'error',
            });
          }
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }

        (window as any).pop();
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });

      (window as any).pop();
    } finally {
      if (anchorWallet) {
        const balance = await connection.getBalance(anchorWallet.publicKey);
        setBalance(balance);
      }
      setIsMinting(false);

      refreshCandyMachineState(id);
    }
  };

  const refreshCandyMachineState = (id: string) => {
    (async () => {

      try {
        const cndy = await getCandyMachineState(
            anchorWallet!,
            new PublicKey(id),
            connection
        );

        id === maHero ? setCandyMachineHero(cndy) : setCandyMachineHexa(cndy);
        setLoading(false)

        // await getMinters(new PublicKey(id),
        // connection);
      } catch { }
    })();
  };
  useEffect(() => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = "function pop(){lity('#popup_inline_confirm');}";
    document.body.appendChild(s);
    getNftInfoSellHero();
    getNftInfoSellHexa();
  }, [])

  useEffect(() => {
    (async () => {
      if (!wallet.connected) {
        setLoading(true);
      }
      if (!anchorWallet) {
        return;
      }

      if (process.env.REACT_APP_MACHINE_ID_HERO && !isMinting) {
        refreshCandyMachineState(maHero);
        setTabIndexHero(0);
      }
      if (process.env.REACT_APP_MACHINE_ID_HEXA && !isMinting) {
        refreshCandyMachineState(maHexa);
        setTabIndexHexa(0);
      }
    })();
  }, [
    anchorWallet,
    connection,
    isMinting
  ]);

  // modal start
  const [openHero, setOpenHero] = React.useState(false);
  const handleOpenHero = () => setOpenHero(true);
  const handleCloseHero = () => setOpenHero(false);
  const [openHexa, setOpenHexa] = React.useState(false);
  const handleOpenHexa = () => setOpenHexa(true);
  const handleCloseHexa = () => setOpenHexa(false);
  const [openPayRel, setOpenPayRel] = React.useState(false);
  const handleOpenPayRel = () => setOpenPayRel(true);
  const handleClosePayRel = () => setOpenPayRel(false);
  const [openBalanceHero, setOpenBalanceHero] = React.useState(false);
  const handleOpenBalanceHero = () => setOpenBalanceHero(true);
  const handleCloseBalanceHero = () => setOpenBalanceHero(false);
  const [openBalanceHexa, setOpenBalanceHexa] = React.useState(false);
  const handleOpenBalanceHexa = () => setOpenBalanceHexa(true);
  const handleCloseBalanceHexa = () => setOpenBalanceHexa(false);

  // modal end

  // tab start
  const isLoginUser = window.localStorage.getItem("userStatus");
  const defaultTabIndexHero = isLoginUser ? 1 : 0;
  const defaultTabIndexHexa = isLoginUser ? 1 : 0;
  const [tabIndexHero, setTabIndexHero] = useState(defaultTabIndexHero);
  const [tabIndexHexa, setTabIndexHexa] = useState(defaultTabIndexHexa);
  // tab end
  const [userAmount, setUserAmount] = useState(0);
  const [nftInfoHero, setNftInfoHero] = useState<NftInfo>();
  const [nftInfoHexa, setNftInfoHexa] = useState<NftInfo>();

  function getNftInfoSellHero() {
    NftSellInfo({merchantId: 1, type: 1}).then((res: any) => {
      if (!res.code && res && res.rows) {
        setNftInfoHero(res.rows[0]);
      }
    });
  }
  function getNftInfoSellHexa() {
    NftSellInfo({merchantId: 1, type: 2}).then((res: any) => {
      if (!res.code && res.rows) {
        setNftInfoHexa(res.rows[0]);
      }
    });
  }
  const onMintUser = (id: string) => {
    if (JSON.parse(window.localStorage.getItem("isPhone"))) {
      id === maHero ? handleOpenHero() : handleOpenHexa();
      UserInfo({}).then((res: any) => {
        setUserAmount(res?.metg);
      });
    } else {
      toPhone();
    }
  }
  function toPhone() {
    window.self.location.href = window.location.origin + '/login-phone?redirect=' + window.location.pathname;
  }
  const showPayOrder = (id: string) => {
    id === maHero ? handleCloseHero() : handleCloseHexa();
    const bId = id === maHero ? nftInfoHero?.id : nftInfoHexa?.id;
    OrderNft({batchId: bId}).then((res: any) => {
      if (res.code === 2001) {
        id === maHero ? handleOpenBalanceHero() : handleOpenBalanceHexa();
      } else if (!res.code) {
        handleOpenPayRel();
      }
      id === maHero ? getNftInfoSellHero() : getNftInfoSellHexa();
    }).catch(() => {
      id === maHero ? getNftInfoSellHero() : getNftInfoSellHexa();
    });
  };
  function toLogin() {
    window.self.location.href = window.location.origin + '/login';
  }

  function toUser() {
    window.self.location.href = window.location.origin + "/user";
  }
  return (
      <>
        <Helmet>
          <title>DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs</title>
          <meta name="description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
          <meta property="og:description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
        </Helmet>
        <div className="bg-hexahero">
          <div className="wrapper">
            <Header />
            <main>
              <section className="s-nft">
                <img
                    className="img-bg"
                    src="assets/images/nft/img_bg.jpg"
                    data-anchor-target=".s-nft"
                    data-center="transform: translateY(0%)"
                    data-top-bottom="transform: translateY(0%)"
                />

                <div className="container">
                  <section className="s-default s-nft-hero" id="head_hero">
                    <div className="s-hexa-box">
                      <img className="img-title" src="assets/images/nft/txt_hero_nft.png?v=20230413001" />
                      <div className="max-md box-video-card">
                        <video className="video" autoPlay loop muted playsInline preload="metadata">
                          <source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/compressed.mp4?v=20230413001" type="video/mp4" />
                        </video>
                      </div>
                      <div className="max-lg box-position">
                        <div className="txt-default-desc"></div>
                        <p className="text-high-green">{t("page.nft.Are1")} <span>{t("page.nft.Are2")}</span> {t("page.nft.Are3")} <span>{t("page.nft.Are4")}</span>{t("page.nft.Are5")} <span>{t("page.nft.Are6")}</span> {t("page.nft.Are7")}</p>
                        <p className="text-high-green">{t("page.nft.With")}</p>
                        <p className="text-high-green">{t("page.nft.Your")}</p>
                        <p className="text-high-green">{t("page.nft.Both1")} <span>{t("page.nft.Both2")}</span>{t("page.nft.Both3")}</p>
                        <div className="txt-default-desc b-slider-nft-title">
                          {t("page.nft.9999")}
                        </div>
                        <div className="swiper b-slider-nft">
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-p-01.jpg?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-p-02.jpg?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-p-03.jpg?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-p-04.jpg?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-p-05.jpg?v=20230413001" />
                              </div>
                            </div>
                          </div>
                          <div className="btn-ctrl btn-prev"></div>
                          <div className="btn-ctrl btn-next"></div>
                        </div>

                        <div className="txt-default-desc">
                          {t("page.nft.Once")}{" "}
                        </div>
                        <ul className="list-dot">
                          <li>{t("page.nft.Use")}</li>
                          <li>{t("page.nft.Gain")}</li>
                          <li>{t("page.nft.Use1")}</li>
                          <li id="mint_hero">{t("page.nft.Use2")}</li>
                          <li>{t("page.nft.Get")}</li>
                        </ul>
                      </div>
                      <div className="txt-center" style={{ display: "none" }}>
                        <div className="txt-alert wow fadeInUp" style={{ display: "" }}>
                          <img className="img-alert" src="assets/images/img_alert.png?v=20230413001" />
                          <div className="txt txt-title">
                            {t("page.nft.h1")} <span className="txt-cyan">{t("page.nft.h2")}</span> {t("page.nft.h3")}
                          </div>
                        </div>
                        <div className="nft-notice-box">
                          <div className="nft-notice">
                            <div className="notice-title">{t("page.nft.Whitelisting")}</div>
                            <div className="notice-desc">{t("page.nft.Time")}</div>
                            <div className="notice-desc txt-color-primary s-cursor-pointer" onClick={() => {window.open("https://discord.com/invite/Nqk2P57u7Y", "_blank")}}>{t("page.nft.JOIN")}</div>
                            <div className="notice-title">{t("page.nft.Pre")}</div>
                            <div className="notice-desc">{t("page.nft.Time1")}</div>
                            <div className="notice-desc">{t("page.nft.Quantity")}</div>
                            <div className="notice-title">{t("page.nft.Public")}</div>
                            <div className="notice-desc">{t("page.nft.Time2")}</div>
                            <div className="notice-desc">{t("page.nft.Quantity1")}</div>
                          </div>
                        </div>
                      </div>
                      <div className="txt-center" style={{ display: "" }}>
                        {/* 需要替换 start */}
                        <div className="max-lg box-position b-purchase-mt">
                          {/*数据库调用查询数据 start*/}
                          <div className="tabs-tab b-purchase wow fadeInUp">
                            <div className="row">
                              <div className="col col-12 col-lg-5 col-img">
                                <div className="swiper-container b-slider-white b-slider-purchase">
                                  <div className="swiper-wrapper">
                                    {
                                      Array.from(Array(15).keys()).map((_, i) => <div className="swiper-slide" key={i}>
                                        <img className="swiper-lazy" data-src={"assets/images/heros/" + (i + 1) + ".png?v=20230413001"} />
                                      </div>)
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col col-12 col-lg-7 col-ctx" style={{ padding: '8px 40px' }}>
                                <div className="box-ctx">
                                  <div className="txt-title" style={{ 'lineHeight': 1,'marginTop':'10px' }}>{nftInfoHero && nftInfoHero?.title || "Mint* a Hero from HexaHero"}</div>

                                  <div className="txt-desc" style={{'marginBottom':'10px'}}>
                                    Selling Price: {nftInfoHero && Number(nftInfoHero?.metgPrice).toFixed(4) || "..."} METG
                                    ≈ ${nftInfoHero && Number(nftInfoHero?.usdPrice).toFixed(2) || "..."} USD
                                  </div>
                                  <div className="row">
                                    <div className="col col-12 col-md-6">
                                      {!isLoginUser && <button className="btn btn-default btn-mint" onClick={toLogin} style={{ fontSize: 18 }} >Connect Wallet</button>}

                                      {isLoginUser && nftInfoHero && nftInfoHero?.batchNumber && (Number(nftInfoHero?.batchNumber - nftInfoHero?.sellNumber) <= 0) && <div className="btn btn-default btn-mint btn-soldout">SOLD OUT</div>}

                                      {isLoginUser && nftInfoHero && !nftInfoHero?.isStartSell && <div className="btn btn-default btn-mint btn-soldout" style={{fontSize:20}}>START AT AUG. 24TH 10:30 AM EDT</div>}

                                      {isLoginUser && nftInfoHero && nftInfoHero?.batchNumber && (Number(nftInfoHero?.batchNumber - nftInfoHero?.sellNumber) > 0) && nftInfoHero?.isStartSell && <><div className="txt-subtitle">Quantity</div>
                                        <input className="form-input center" readOnly value="1" style={{ textAlign: 'center', fontWeight: 'bold' }} />
                                        <br />
                                        <button className="btn btn-default btn-mint" onClick={() => {onMintUser(maHero)}}>MINT</button>
                                      </>
                                      }
                                    </div>
                                    <div className="col col-12 col-md-6">
                                      <div className="txt-title">Collection:</div>
                                      <div className="txt-subtitle">Total Available: {(isLoginUser && nftInfoHero && nftInfoHero?.isStartSell) && nftInfoHero && nftInfoHero?.batchNumber || "..."}</div>
                                      <div className="txt-subtitle">Redeemed: {(isLoginUser && nftInfoHero && nftInfoHero?.isStartSell) && nftInfoHero && nftInfoHero?.sellNumber || "..."}</div>
                                      <div className="txt-subtitle">Remaining: {(isLoginUser && nftInfoHero && nftInfoHero?.isStartSell) && nftInfoHero && nftInfoHero ? Number(nftInfoHero?.batchNumber - nftInfoHero?.sellNumber) : "..."}</div>
                                    </div>
                                  </div>
                                  <div style={{ width: "1rem", height: "5rem" }}></div>
                                  <div style={{ 'fontSize': '14px','marginTop':'8px' }}>* Each Hero is randomly minted</div>
                                  <div style={{ 'fontSize': '14px','marginTop':'8px' }}>* Total DIGITAL ASSET is 90</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*数据库调用查询数据 end*/}
                        </div>
                        {/* 需要替换 end */}
                      </div>
                    </div>
                  </section>
                  <section className="s-default s-nft-hexa" id="head_hexa">
                    <div className="box-title wow fadeInUp">
                      <img className="img-title" src="assets/images/nft/txt_hexa_nft.png?v=20230413001" style={{marginBottom: "4rem"}} />
                      <div className="max-md box-video-card" style={{ textAlign: "center", maxWidth: "500px" }}>
                        <video className="video" autoPlay loop muted playsInline preload="metadata">
                          <source src="https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/nft-video-top.mp4?v=20230413001" type="video/mp4" />
                        </video>
                      </div>
                      <div className="max-lg box-position">
                        <div className="txt-default-desc"></div>
                        <p className="text-high-green">{t("page.nft.hh1")} <span>{t("page.nft.hh2")}</span>{t("page.nft.hh3")}</p>
                        <p className="text-high-green">{t("page.nft.evolving")}</p>
                        <p className="text-high-green">{t("page.nft.Once1")}</p>
                        <p className="text-high-green">{t("page.nft.Both4")} <span>{t("page.nft.Both5")}</span>{t("page.nft.Both6")}</p>
                        <div className="txt-default-desc b-slider-nft-title">
                          {t("page.nft.9000")}
                        </div>

                        <div className="swiper b-slider-nft" style={{ display: "" }}>
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-01.jpg?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-02.png?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-03.png?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-04.png?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-05.png?v=20230413001" />
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div className="box-ntf">
                                <img src="assets/images/nft/rarity-r-06.png?v=20230413001" />
                              </div>
                            </div>
                          </div>
                          <div className="btn-ctrl btn-prev"></div>
                          <div className="btn-ctrl btn-next"></div>
                        </div>

                        <div className="txt-default-desc">
                          {t("page.nft.Once2")}{" "}
                        </div>
                        <ul className="list-dot" id="mint_hexa">
                          <li>{t("page.nft.Use3")}</li>
                          <li>{t("page.nft.Two")}</li>
                          <li>{t("page.nft.Get1")}</li>
                        </ul>
                      </div>
                      <div className="txt-center" style={{ display: "none" }}>
                        <div className="txt-alert wow fadeInUp" style={{ display: "" }}>
                          <img className="img-alert" src="assets/images/img_alert.png?v=20230413001" />
                          <div className="txt txt-title">
                            {t("page.nft.HexaHero1")} <span className="txt-cyan">{t("page.nft.HexaHero2")}</span> {t("page.nft.HexaHero3")}
                          </div>
                        </div>
                        <div className="nft-notice-box">
                          <div className="nft-notice">
                            <div className="notice-title">{t("page.nft.Whitelisting1")}</div>
                            <div className="notice-desc">{t("page.nft.Time3")}</div>
                            <div className="notice-desc txt-color-primary s-cursor-pointer" onClick={() => {window.open("https://discord.com/invite/Nqk2P57u7Y", "_blank")}}>{t("page.nft.JOIN1")}</div>
                            <div className="notice-title">{t("page.nft.Pre1")}</div>
                            <div className="notice-desc">{t("page.nft.Time4")}</div>
                            <div className="notice-desc">{t("page.nft.Quantity2")}</div>
                            <div className="notice-title">{t("page.nft.Public1")}</div>
                            <div className="notice-desc">{t("page.nft.Time5")}</div>
                            <div className="notice-desc">{t("page.nft.Quantity3")}</div>
                          </div>
                        </div>
                      </div>
                      <div className="txt-center" style={{ display: "" }}>
                        {/* 需要替换 start */}
                        <div className="max-lg box-position b-purchase-mt">
                          {/*数据库调用查询数据 start*/}
                          <div className="tabs-tab b-purchase wow fadeInUp">
                            <div className="row">
                              <div className="col col-12 col-lg-5 col-img">
                                <div className="swiper-container b-slider-white b-slider-purchase">
                                  <div className="swiper-wrapper">
                                    {
                                      Array.from(Array(6).keys()).map((_, i) => <div className="swiper-slide" key={i}>
                                        <img className="swiper-lazy" data-src={"assets/images/robots/" + (i + 1) + ".png?v=20230413001"} />
                                      </div>)
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col col-12 col-lg-7 col-ctx" style={{ padding: '8px 40px' }}>
                                <div className="box-ctx">
                                  <div className="txt-title" style={{ 'lineHeight': 1,'marginTop':'10px' }}>Mint* a Hexa from HexaHero</div>

                                  <div className="txt-desc" style={{'marginBottom':'10px'}}>
                                    Selling Price: {nftInfoHexa && Number(nftInfoHexa?.metgPrice).toFixed(4) || "--"} METG
                                    ≈ ${nftInfoHexa && Number(nftInfoHexa?.usdPrice).toFixed(2) || "--"} USD
                                  </div>
                                  <div className="row">
                                    <div className="col col-12 col-md-6">
                                      {!isLoginUser && <button className="btn btn-default btn-mint" onClick={toLogin} style={{ fontSize: 18 }} >Connect Wallet</button>}

                                      {isLoginUser && nftInfoHexa && nftInfoHexa?.batchNumber && (Number(nftInfoHexa?.batchNumber - nftInfoHexa?.sellNumber) <= 0) && <div className="btn btn-default btn-mint btn-soldout">SOLD OUT</div>}

                                      {isLoginUser && nftInfoHexa && !nftInfoHexa?.isStartSell && <div className="btn btn-default btn-mint btn-soldout" style={{fontSize:20}}>START AT AUG. 24TH 10:30 AM EDT</div>}

                                      {isLoginUser && nftInfoHexa && nftInfoHexa?.batchNumber && (Number(nftInfoHexa?.batchNumber - nftInfoHexa?.sellNumber) > 0) && nftInfoHexa?.isStartSell && <><div className="txt-subtitle">Quantity</div>
                                        <input className="form-input center" readOnly value="1" style={{ textAlign: 'center', fontWeight: 'bold' }} />
                                        <br />
                                        <button className="btn btn-default btn-mint" onClick={() => {onMintUser(maHexa)}}>MINT</button>
                                      </>
                                      }
                                    </div>
                                    <div className="col col-12 col-md-6">
                                      <div className="txt-title">Collection:</div>
                                      <div className="txt-subtitle">Total Available: {(isLoginUser && nftInfoHexa && nftInfoHexa?.isStartSell) && nftInfoHexa && nftInfoHexa?.batchNumber || "..."}</div>
                                      <div className="txt-subtitle">Redeemed: {(isLoginUser && nftInfoHexa && nftInfoHexa?.isStartSell) && nftInfoHexa && nftInfoHexa?.sellNumber || "..."}</div>
                                      <div className="txt-subtitle">Remaining: {(isLoginUser && nftInfoHexa && nftInfoHexa?.isStartSell) && nftInfoHexa && nftInfoHexa ? Number(nftInfoHexa?.batchNumber - nftInfoHexa?.sellNumber) : "..."}</div>
                                    </div>
                                  </div>
                                  <div style={{ width: "1rem", height: "5rem" }}></div>
                                  <div style={{ 'fontSize': '14px','marginTop':'8px' }}>* Each Hexa is randomly minted</div>
                                  <div style={{ 'fontSize': '14px','marginTop':'8px' }}>* Total DIGITAL ASSET is 90</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*数据库调用查询数据 end*/}
                        </div>
                        {/* 需要替换 end */}
                      </div>
                    </div>
                  </section>
                  <section className="s-default s-nft-market" id="market">
                    <div className="txt-center">
                      <div className="max-lg">
                        <img className="img-title" src="assets/images/nft/txt_market_nft.png?v=20230413001" />
                        <div style={{ width: "100%", height: "4rem"}}></div>
                        <div className="wow fadeInUp nft-hexa-content col col-12">
                          <img className="img-alert-content" src="assets/images/img_alert_content.png?v=20230413001" />
                          <div className="txt txt-title">
                            <div className="txt-align-left txt-content-title">
                              Our Heroes Collection are verified and listed on:
                            </div>
                            <div className="row row-triangle wow fadeIn nft-logo-box">
                              <div className="col col-12 col-lg-6 col-img nft-logo">
                                <a href="/market-list" target="_self">
                                  <img className="nft-logo-img" style={{ objectFit: "contain" }} src="assets/images/nft/logo-metg.png?v=20230413001" />
                                </a>
                              </div>
                              <div className="col col-12 col-lg-6 col-img nft-logo">
                                <a href="https://magiceden.io/marketplace/hexaherogame" target="_blank">
                                  <img className="nft-logo-img" style={{ objectFit: "contain" }} src="assets/images/nft/logo-magiceden.png?v=20230413001" />
                                </a>
                              </div>
                              <div className="col col-12 col-lg-6 col-img nft-logo">
                                <a href="https://solsea.io/collection/621dc54fc903a7dbd2325734" target="_blank">
                                  <img className="nft-logo-img" style={{ objectFit: "contain" }} src="assets/images/nft/logo-solsea.png?v=20230413001" />
                                </a>
                              </div>
                            </div>
                            <div className="txt-align-left txt-content-desc">These are the only reliable secondary platforms for our NFTs, the rest are non verified and very likely to be fake. Please note, our NFTs are on Solana chain, which is impossible to trade on OpenSea. If you find any HexaHero DIGITAL ASSETs on OpenSea, please note that they are fake!</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <Footer />
            </main>
          </div>
          <div className="b-popup b-popup-inline lity-hide" id="popup_inline_confirm">
            <div className="box-ctx">
              {
                  alertState.severity === "success" && <div className="txt-title txt-default-title">Congratulations!<br />Mint succeeded!</div>
              }

              {
                  alertState.severity !== "success" && <div className="txt-title txt-default-title">{alertState.message}</div>
              }

              {
                  alertState.severity == "success" && <a href={"https://solscan.io/token/" + txId + "?cluster=" + process.env.REACT_APP_SOLANA_NETWORK} target="_blank" className="txt-desc">
                    <button className="btn btn-default">View details on Solscan</button>
                  </a>
              }

            </div>
          </div>
          <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openHero}
              onClose={handleCloseHero}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-box modal-order-box"
          >
            <Fade in={openHero}>
              <Box sx={style}>
                <div className="s-flex close-box"><img src="./assets/images/icon-modal-close.png" onClick={handleCloseHero} /></div>
                <div className="s-flex s-flex-center-row modal-ct-title">CONFIRM ORDER</div>
                <div className="s-flex s-flex-center-row modal-order-title">Mint a Hero from HexaHero</div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>PRICE</span><span>{nftInfoHero && nftInfoHero?.metgPrice || "..."} METG</span></div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>GAS FEE</span><span>{nftInfoHero && nftInfoHero?.gasFee || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-order-pd"><div className="modal-order-line"></div></div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>SUBTOTAL</span><span className="modal-order-text-weight">{nftInfoHero && (Number(nftInfoHero?.metgPrice) + Number(nftInfoHero?.gasFee)).toFixed(4) || "..."} METG</span></div>
                <div className="modal-order-pd modal-order-btn modal-btn-box modal-btn-between">
                  <div className="modal-btn modal-btn-half modal-btn-cancel" onClick={handleCloseHero}>CANCEL</div>
                  <div className="modal-btn modal-btn-half modal-btn-confirm" onClick={() => {showPayOrder(maHero)}}>CONFIRM</div>
                </div>
              </Box>
            </Fade>
          </Modal>
          <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openHexa}
              onClose={handleCloseHexa}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-box modal-order-box"
          >
            <Fade in={openHexa}>
              <Box sx={style}>
                <div className="s-flex close-box"><img src="./assets/images/icon-modal-close.png" onClick={handleCloseHexa} /></div>
                <div className="s-flex s-flex-center-row modal-ct-title">CONFIRM ORDER</div>
                <div className="s-flex s-flex-center-row modal-order-title">Mint a Hexa from HexaHero</div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>PRICE</span><span>{nftInfoHexa && nftInfoHexa?.metgPrice || "..."} METG</span></div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>GAS FEE</span><span>{nftInfoHexa && nftInfoHexa?.gasFee || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-order-pd"><div className="modal-order-line"></div></div>
                <div className="s-flex s-flex-between-row modal-order-pd modal-order-text"><span>SUBTOTAL</span><span className="modal-order-text-weight">{nftInfoHexa && (Number(nftInfoHexa?.metgPrice) + Number(nftInfoHexa?.gasFee)).toFixed(4) || "..."} METG</span></div>
                <div className="modal-order-pd modal-order-btn modal-btn-box modal-btn-between">
                  <div className="modal-btn modal-btn-half modal-btn-cancel" onClick={handleCloseHexa}>CANCEL</div>
                  <div className="modal-btn modal-btn-half modal-btn-confirm" onClick={() => {showPayOrder(maHexa)}}>CONFIRM</div>
                </div>
              </Box>
            </Fade>
          </Modal>

          <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openPayRel}
              onClose={handleClosePayRel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-box modal-pay-rel-box"
          >
            <Fade in={openPayRel}>
              <Box sx={style}>
                <div className="s-flex close-box"><img src="./assets/images/icon-modal-close.png" onClick={handleClosePayRel} /></div>
                <div className="s-flex s-flex-center-row modal-ct-title">PURCHASE</div>
                <div className="s-flex s-flex-center-row modal-ct-title">SUCCESSFUL</div>
                <div className="modal-pay-rel-top modal-btn-box modal-btn-center modal-pd">
                  <div className="modal-btn modal-btn-all modal-btn-cancel-lang" onClick={handleClosePayRel}>MINT AGAIN</div>
                </div>
                <div className="modal-pay-rel-btm modal-btn-box modal-btn-center modal-pd">
                  <div className="modal-btn modal-btn-all modal-btn-confirm-lang" onClick={toUser}>GO TO MY ACOUNT</div>
                </div>
              </Box>
            </Fade>
          </Modal>
          <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openBalanceHero}
              onClose={handleCloseBalanceHero}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-box modal-balance-box"
          >
            <Fade in={openBalanceHero}>
              <Box sx={style}>
                <div className="s-flex close-box"><img src="./assets/images/icon-modal-close.png" onClick={handleCloseBalanceHero} /></div>
                <div className="modal-balance-pd modal-balance-high modal-balance-title">
                  Unfortunately, you have insufficient balance
                </div>
                <div className="modal-balance-pd modal-balance-high modal-balance-title">
                  of <span className="modal-balance-weight">METG</span>to complete this purchase
                </div>
                <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text modal-balance-text-mt"><span>Your Balance</span><span>{userAmount || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text"><span>Amount Needed</span><span>{nftInfoHero && (Number(nftInfoHero?.metgPrice) + Number(nftInfoHero?.gasFee)).toFixed(4) || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-balance-pd"><div className="modal-balance-line"></div></div>
                <div className="s-flex s-flex-center-row modal-balance-pd modal-balance-high modal-balance-desc">Go to your wallet and top-up now</div>
                <div className="modal-balance-btn modal-btn-box modal-btn-center modal-pd">
                  <div className="modal-btn modal-btn-all modal-btn-confirm-lang" onClick={toUser}>TOP-UP NOW</div>
                </div>
              </Box>
            </Fade>
          </Modal>
          <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openBalanceHexa}
              onClose={handleCloseBalanceHexa}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className="modal-box modal-balance-box"
          >
            <Fade in={openBalanceHexa}>
              <Box sx={style}>
                <div className="s-flex close-box"><img src="./assets/images/icon-modal-close.png" onClick={handleCloseBalanceHexa} /></div>
                <div className="modal-balance-pd modal-balance-high modal-balance-title">
                  Unfortunately, you have insufficient balance
                </div>
                <div className="modal-balance-pd modal-balance-high modal-balance-title">
                  of <span className="modal-balance-weight">METG</span>to complete this purchase
                </div>
                <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text modal-balance-text-mt"><span>Your Balance</span><span>{userAmount || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-balance-pd modal-balance-text"><span>Amount Needed</span><span>{nftInfoHexa && (Number(nftInfoHexa?.metgPrice) + Number(nftInfoHexa?.gasFee)).toFixed(4) || "0"} METG</span></div>
                <div className="s-flex s-flex-between-row modal-balance-pd"><div className="modal-balance-line"></div></div>
                <div className="s-flex s-flex-center-row modal-balance-pd modal-balance-high modal-balance-desc">Go to your wallet and top-up now</div>
                <div className="modal-balance-btn modal-btn-box modal-btn-center modal-pd">
                  <div className="modal-btn modal-btn-all modal-btn-confirm-lang" onClick={toUser}>TOP-UP NOW</div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
  );
}
