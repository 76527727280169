// Box Detail
import Header from "../Header";
import Footer from "../Footer";
import {Helmet} from 'react-helmet';
import * as React from "react";

// modal start
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useSpring, animated } from 'react-spring';

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}
const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props,
    ref
) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000000',
    border: '1px solid #00FFFF',
    boxShadow: 0,
    p: 1,
};
// modal end
export default function LandBoxDetail() {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const changeDialogOpenOpen = () => setDialogOpen(true);
    const changeDialogOpenClose = () => setDialogOpen(false);
    return (
        <>
            <Helmet>
                <title>MetaGaia Metaverse FAQs & Support</title>
                <meta name="description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
                <meta property="og:description" content="See a list of our Frequently Asked Questions for the MetaGaia Metaverse, HexaHero game, and crypto and DIGITAL ASSET marketplace surrounding the online world. " />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default s-land">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />
                            <div className="container land-box">
                                <div className="s-flex s-flex-center-row">
                                    <h2 className="wow fadeInUp land-title">Land Blind Box Norma</h2>
                                </div>
                                <div className="wow fadeInUp box-detail">
                                    <div className="detail-ct">
                                        <div className="detail-img-box"><img className="detail-img" src="assets/images/heros/15.png?v=20230413001" alt=""/></div>
                                        <div className="nft-swiper nft-swiper-m">
                                            <div className="swiper slider-land-box">
                                                <div className="swiper-wrapper">
                                                    {
                                                        ["1","2","3","4","5","6","7","8"].map((item, index) =>
                                                            <div className="swiper-slide s-flex">
                                                                { index === 0 && <div className="box-space"></div>}
                                                                <div style={{width: "100%"}}>
                                                                    <div className="slide-img-box">
                                                                        <img className="slide-img" src="assets/images/heros/15.png?v=20230413001" alt=""/>
                                                                    </div>
                                                                    <div className="slide-text">
                                                                        Slide {item}
                                                                    </div>
                                                                </div>
                                                                { index === ["1","2","3","4","5","6","7","8"].length - 1 && <div className="box-space"></div>}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="swiper-button-prev"></div>
                                                <div className="swiper-button-next"></div>
                                            </div>
                                        </div>
                                        <div className="detail-text">
                                            <div className="item-btn"><button className="btn btn-default btn-border-none" onClick={changeDialogOpenOpen}>OPEN IT</button></div>
                                            <div className="text-attribute">
                                                <div className="item-title-sub attr-source">FROM</div>
                                                <div className="item-text">Phantom Wallet</div>
                                                <div className="item-title-sub attr-desc">Land Style: Moon Lan</div>
                                                <div className="item-title-sub">landform: Plain</div>
                                                <div className="item-title-sub desc">DESCRIPTION</div>
                                                <div className="item-text">jdhsdfk. hdshdlsdhlkashd. skhdkd dasdkhd akshdoeur oruwp eo eucjnc kdjfh kskfhh nvnv o  dn an dnfqi mxlzoiucnmawo mxmc jdhsdfk. hdshdlsdhlkashd. skhdkd dasdkhd akshdoeur oruwp eo </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nft-swiper nft-swiper-pc">
                                        <div className="swiper slider-land-box">
                                            <div className="swiper-wrapper">
                                                {
                                                    ["1","2","3","4","5","6","7","8"].map((item, index) =>
                                                        <div className="swiper-slide s-flex">
                                                            { index === 0 && <div className="box-space"></div>}
                                                            <div style={{width: "100%"}}>
                                                                <div className="slide-img-box">
                                                                    <img className="slide-img" src="assets/images/heros/15.png?v=20230413001" alt=""/>
                                                                </div>
                                                                <div className="slide-text">
                                                                    Slide {item}
                                                                </div>
                                                            </div>
                                                            { index === ["1","2","3","4","5","6","7","8"].length - 1 && <div className="box-space"></div>}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="swiper-button-prev"></div>
                                            <div className="swiper-button-next"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
            {/* 开启盲盒 */}
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={dialogOpen}
                onClose={changeDialogOpenClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal-box modal-recharge-box"
            >
                <Fade in={dialogOpen}>
                    <Box sx={style}>
                        <div className="s-flex close-box">
                            <img
                                src="./assets/images/icon-modal-close.png"
                                onClick={changeDialogOpenClose}
                            />
                        </div>
                        <div className="s-flex s-flex-center-row modal-ct-title">
                            Confirm Openning
                        </div>
                        <div className="modal-recharge-pd modal-order-btn modal-btn-box modal-btn-between modal-btn-land-box">
                            <div
                                className="modal-btn modal-btn-half modal-btn-cancel"
                                onClick={changeDialogOpenClose}
                            >
                                CANCEL
                            </div>
                            <div
                                className="modal-btn modal-btn-half modal-btn-confirm"
                                onClick={changeDialogOpenClose}
                            >
                                CONFIRM
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
