import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// 检测当前浏览器的语言或者从服务器获取配置资源,不过也没有什么用处
import Backend from "i18next-http-backend";
// 嗅探当前浏览器语言
import LanguageDetector from "i18next-browser-languagedetector";
import Storage from "../libs/storage";
import ThemeConfig from "./themeConfig";

import zhCNLocale from "./modules/zhCN.json";
import zhTWLocale from "./modules/zhTW.json";
import enUSLocale from "./modules/enUS.json";

// @ts-ignore
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            [ThemeConfig.i18nEnum.ZHCN.value]: {
                translation: {
                    ...zhCNLocale,
                },
            },
            [ThemeConfig.i18nEnum.ZHTW.value]: {
                translation: {
                    ...zhTWLocale,
                },
            },
            [ThemeConfig.i18nEnum.ENUS.value]: {
                translation: {
                    ...enUSLocale,
                },
            },
        },
        fallbackLng: Storage.getItem("i18n-local") || ThemeConfig.i18nDef,
        lng: Storage.getItem("i18n-local") || ThemeConfig.i18nDef,
        preload: ThemeConfig.i18nKeyArr,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        detection: ["localStorage", "sessionStorage", "cookie"],
    });
export default i18n;
