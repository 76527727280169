import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useEffect, useRef, useState} from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import * as React from "react";
import CloudPdfViewer from "@openbook/cloudpdf-viewer";
import {Elements} from "@stripe/react-stripe-js";

function Agreement() {
    const pm = window.location.search;

    const viewerTC = useRef(null);
    useEffect(() => {
        const id = pm.includes("=0") ? "b0eef83a-48e7-4059-bec0-165673c12ad7" : "1c02bdc8-7547-4d1e-9983-b375abb73b85";
        CloudPdfViewer(
            {
                documentId: id,
                darkMode: true,
                themeColor: '#0B0E33',
                appBarColored: true
            },
            // @ts-ignore
            viewerTC.current
        ).then((instance) => {});
        // @ts-ignore
        // document.querySelector(".viewer").contentWindow.document.querySelector(".jss273").style.display = "none";

        const int = setInterval(() => {
            if (isClear || countClear === 60) {
                clearInterval(int);
            }
            hideDialog();
        }, 1000);
    }, []);
    const [isClear, setIsClear] = useState(false);
    const [countClear, setCountClear] = useState(0);
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === 'IFRAME';
    const hideDialog = () => {
        setCountClear(countClear+1);
        let frame = document.querySelector("iframe");
        if (isIFrame(frame) && frame.contentWindow) {
            // frame.contentWindow.postMessage({}, '*');
            const dm = frame.contentWindow.document.querySelector<HTMLElement>(".MuiDialog-root");
            if (dm) {
                dm.style.display = "none";
                setIsClear(true);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs</title>
                <meta name="description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
                <meta property="og:description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-paper s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container paper">
                                {
                                    ( pm.includes("=0") || pm.includes("=1") ) &&
                                      <div className="pdf-box" style={{ marginTop: "60px" }}>
                                        <div className="viewer" ref={viewerTC}></div>
                                      </div>
                                        // <DocViewer style={{ height: "80vh" }} documents={[{ fileType:'docx', uri: "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/MetaGaia_privacy.docx?v=20230413001" }]} pluginRenderers={DocViewerRenderers} />
                                        // <DocViewer style={{ height: "80vh" }} documents={[{ fileType:'docx', uri: "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/MetaGaia_T&C.docx?v=20230413001" }]} pluginRenderers={DocViewerRenderers} />
                                }
                                {
                                    pm.includes("=2") &&
                                        <DocViewer style={{ height: "80vh" }} documents={[{ fileType:'docx', uri: "https://advokate-cdn-1259661131.cos.ap-hongkong.myqcloud.com/metagaia/FTLife_PICS.docx?v=20230413001" }]} pluginRenderers={DocViewerRenderers} />
                                }
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
};
export default Agreement;
