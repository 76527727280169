import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from 'react-helmet';
import {useEffect, useState} from "react";
import { ResetPwd } from "./libs/api";
import storage from "./libs/storage";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function Login() {
    const { t } = useTranslation();

    const [validMsgPassword, setValidMsgPassword] = useState('');
    const [validMsgPasswordConfirm, setValidMsgPasswordConfirm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!storage.getItem("emailCode") || storage.getItem("emailCode") === undefined) {
            window.self.location.href = window.location.origin + "/login";
        }
    }, []);

    function validPassword() {
        // @ts-ignore
        const val = document.querySelector("#password").value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPassword(t("validate.information"));
            return false;
        }
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPassword(t("validate.information"));
            return false;
        }
        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,50}$/ig.test(val)) {
            setValidMsgPassword('');
            return true;
        } else if (val.length > 50) {
            setValidMsgPassword(t("validate.long"));
            return false;
        } else {
            setValidMsgPassword(t("validate.Enter"));
            return false;
        }
    }

    function validPasswordConfirm() {
        // @ts-ignore
        const val = document.querySelector('#passwordConfirm').value.trim();
        // @ts-ignore
        const valRe = document.querySelector('#password').value.trim();
        if (val === 'null' || val === 'undefined' || val === '' || !val) {
            setValidMsgPasswordConfirm(t("validate.information"));
            return false;
        }
        if (valRe !== val) {
            setValidMsgPasswordConfirm(t("validate.different"));
            return false;
        } else if (val.length > 50) {
            setValidMsgPasswordConfirm(t("validate.long"));
            return false;
        } else {
            setValidMsgPasswordConfirm('');
            return true;
        }
    }
    function resetpwd() {
        if (validPassword() && validPasswordConfirm()) {
            // @ts-ignore
            const password = document.querySelector("#password").value.trim();
            setIsLoading(true);
            ResetPwd({
                id: storage.getItem("emailCode"),
                password: password
            }).then((res: any) => {
                setIsLoading(false);
                if (!res.code) {
                    storage.removeItem("emailCode");
                    toLogin();
                }
            }).catch(() => {
                setIsLoading(false);
            });
        }
    }
    function toLogin() {
        window.self.location.href = window.location.origin + '/login';
    }
    return (
        <>
            <Helmet>
                <title>DIGITAL ASSETs for MetaGaia & HexaHero: Purchase & Mint MetaGaia DIGITAL ASSETs</title>
                <meta name="description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
                <meta property="og:description" content="Discover all 9 Heroes, each Hero Collection has 1000 DIGITAL ASSET cards with 5 rarity levels and different in-game stats. Stay tuned for information on upcoming drops." />
            </Helmet>
            <div className="bg-hexahero">
                <div className="wrapper">
                    <Header />
                    <main>
                        <section className="s-nft s-default">
                            <img
                                className="img-bg"
                                src="./assets/images/bg1.png?v=20230413001"
                                data-anchor-target=".s-nft"
                                data-center="transform: translateY(0%)"
                                data-top-bottom="transform: translateY(0%)"
                            />

                            <div className="container">
                                <h2 className="txt-default-title wow fadeInUp pwd-re-title"><span className="txt">{t("page.reset.Reset")}</span></h2>
                                <div className="s-flex s-flex-center-row">
                                    <div className="row form-box login-body pwd-re-body">
                                        <div className="login-ct">
                                            <div className="col col-12 form-item">
                                                <div className="form-label">{t("page.reset.Password")}</div>
                                                <div className="form-value"><input type="password" id="password" minLength={8} maxLength={50} onBlur={validPassword}
                                                                                   onKeyDown={(e: any) => {if (e.key === " ") {
                                                                                       e.preventDefault();
                                                                                   }}}
                                                                                   onPaste = {(e: any) => e.preventDefault()} /></div>
                                                {
                                                    !validMsgPassword && <div className="">{t("page.reset.Enter")}</div>
                                                }
                                                {
                                                    validMsgPassword && <div className="form-valid">{validMsgPassword}</div>
                                                }
                                            </div>
                                            <div className="col col-12 form-item">
                                                <div className="form-label">{t("page.reset.Confirm")}</div>
                                                <div className="form-value"><input type="password" id="passwordConfirm" minLength={8} maxLength={50} onBlur={validPasswordConfirm} /></div>
                                                <div className="form-valid">{validMsgPasswordConfirm}</div>
                                            </div>
                                            <div className="col col-12 form-item s-flex s-flex-center-row">
                                                <button onClick={resetpwd} className="btn btn-default btn-form-login" disabled={isLoading}>{t("page.reset.CONFIRM")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    );
};
